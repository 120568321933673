import { useMemo } from 'react';

import { growerBundlesConfigService } from '../../../../../services/growerBundlesConfig.service';
import { createApiSlice } from '../../../utils/createApiSlice';

const deleteGrowerBundleSlice = createApiSlice({
  reducerPath: ['gff', 'growers', 'bundles', 'deleteGrowerBundle'],
  apiFunction: growerBundlesConfigService.deleteBundleV2,
  apiArgumentsFn: ({ growerId, bundleId }) => [growerId, bundleId]
});

const { actions, useApiSelectors } = deleteGrowerBundleSlice;

export const deleteGrowerBundleActions = actions;

export const useDeleteGrowerBundleSelectors = () => {
  const { inProgress, success, errorMessage, response, deletedAt } =
    useApiSelectors();

  return useMemo(
    () => ({ inProgress, success, errorMessage, response, deletedAt }),
    [errorMessage, inProgress, response, success, deletedAt]
  );
};

export default deleteGrowerBundleSlice;
