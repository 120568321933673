import growthActivitiesSlices from './activities';
import bulkUpdateGrowthModelsSlice from './bulkUpdateGrowthModelsSlice';
import bulkUpdateGrowthsSlice from './bulkUpdateGrowthsSlice';
import farmFeedbackSlices from './farmFeedback';
import farmWorkOrdersSlice from './farmWorkOrders';
import managementZonesSlices from './managementZones';
import snapshotsSlices from './snapshots';

const growthsSlices = [
  snapshotsSlices,
  growthActivitiesSlices,
  managementZonesSlices,
  farmFeedbackSlices,
  bulkUpdateGrowthsSlice,
  bulkUpdateGrowthModelsSlice,
  farmWorkOrdersSlice
];

export default growthsSlices;
