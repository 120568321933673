import React, { useMemo } from 'react';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';

import { useActivitiesConfig } from '../../_store/selectors/configSelectors';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center'
  },
  typeCircle: (props) => ({
    marginRight: '0.5rem',
    backgroundColor: props.color,
    width: '1rem',
    height: '1rem',
    minWidth: '1rem',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }),
  completedIcon: {
    color: '#FFFFFF',
    fontSize: '0.75rem'
  }
}));

const ActivityType = ({ activityType, isCompleted = false }) => {
  const { activityTypes } = useActivitiesConfig();

  const backgroundColor = useMemo(
    () => _.get(activityTypes, [activityType, 'color']),
    [activityType, activityTypes]
  );

  const name = useMemo(
    () => _.get(activityTypes, [activityType, 'name']),
    [activityType, activityTypes]
  );

  const styles = useStyles({
    color: backgroundColor
  });

  return (
    <Box className={styles.container}>
      <Tooltip title={isCompleted ? 'Completed' : ''}>
        <Box className={styles.typeCircle}>
          {isCompleted && (
            <FontAwesomeIcon className={styles.completedIcon} icon={faCheck} />
          )}
        </Box>
      </Tooltip>
      <Box component="span">{name}</Box>
    </Box>
  );
};

export default ActivityType;
