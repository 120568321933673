import React, { useMemo } from 'react';

import { Tooltip } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import _ from 'lodash';

import { useGrowthStagesConfig } from '../../../_store/selectors/configSelectors';
import CommonDataGrid from '../../../Components/DataGrid/CommonDataGrid';

import { useCommonReportsColumns } from './_hooks/useCommonReportsColumns';

function getGrowthStageColumnParams(field, headerName) {
  return [
    {
      field,
      headerName,
      width: 150,
      editable: false,
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) =>
        _.get(params, ['row', 'growthStages', field, 'date']),
      renderCell: (params) => {
        const observed = _.get(params, [
          'row',
          'growthStages',
          field,
          'observed'
        ]);
        return (
          <>
            {observed && (
              <Tooltip title="Observed" placement="top">
                <CheckIcon style={{ marginRight: '0.5rem' }} fontSize="small" />
              </Tooltip>
            )}
            <span>{_.get(params, 'value')}</span>
          </>
        );
      }
    },
    {
      field: `${field}-observed`,
      headerName: `${headerName} Observed`,
      width: 150,
      editable: false,
      type: 'boolean',
      hide: true,
      valueGetter: (params) =>
        _.get(params, ['row', 'growthStages', field, 'observed'])
    }
  ];
}

const GrowthModelReportTable = ({
  reportGrowthStages,
  growthModelReportResponse,
  onVisibleRowIdsChange = _.noop
}) => {
  const { growthStagesConfig } = useGrowthStagesConfig();

  const { commonColumns } = useCommonReportsColumns();

  const columns = useMemo(
    () => [
      ...commonColumns,
      ..._.flatMap(reportGrowthStages, (stage) =>
        getGrowthStageColumnParams(
          stage,
          _.get(growthStagesConfig, [stage, 'name'])
        )
      )
    ],
    [commonColumns, growthStagesConfig, reportGrowthStages]
  );

  const rows = useMemo(
    () =>
      _.chain(growthModelReportResponse)
        .get('fields')
        .map((field) => {
          const growthStages = _.chain(field)
            .get('growthStages')
            .keyBy('growthStage')
            .value();
          return {
            id: _.get(field, 'field.fieldId'),
            ..._.get(field, 'field', {}),
            ..._.get(field, 'variety', {}),
            growthStages
          };
        })
        .value(),
    [growthModelReportResponse]
  );

  return (
    <CommonDataGrid
      rows={rows}
      columns={columns}
      onVisibleRowIdsChange={onVisibleRowIdsChange}
    />
  );
};

export default GrowthModelReportTable;
