import React, { useCallback, useContext } from 'react';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { mapLayerIds } from '../../_constants/mapLayersConstants';
import { AuthContext } from '../../_context/AuthContext';
import { FarmStructureContext } from '../../_context/FarmStructureContext';
import { selectMapLayer } from '../../_store/actions/uiActions';
import {
  ContentBody,
  ContentContainer,
  ResponsiveContentHeader
} from '../../Layout/Content';
import FarmWorkOrders from '../WorkOrders/FarmWorkOrders';

import { useFilteredWorkOrders } from './_hooks/useFilteredWorkOrders';
import WorkOrdersFilters from './WorkOrdersFilters';

const WorkOrdersSection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { workOrders } = useFilteredWorkOrders();

  const { selectedField } = useContext(FarmStructureContext);
  const { isUserView } = useContext(AuthContext);

  const handleAddClick = useCallback(() => {
    dispatch(selectMapLayer(mapLayerIds.WorkOrders));
    navigate(`/map?edit=true`);
  }, [dispatch, navigate]);

  return (
    <ContentContainer>
      <ResponsiveContentHeader>
        <span>
          {selectedField
            ? `Work Orders - ${_.get(selectedField, 'name')}`
            : 'Work Orders'}
        </span>
        {!isUserView && (
          <span>
            <Button
              size="small"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddClick}
            >
              Add Work Order
            </Button>
            <WorkOrdersFilters />
          </span>
        )}
      </ResponsiveContentHeader>
      <ContentBody>
        <FarmWorkOrders workOrders={workOrders} />
      </ContentBody>
    </ContentContainer>
  );
};

export default WorkOrdersSection;
