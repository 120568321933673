import { useMemo } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';

import { requestStatus } from '../helpers/requestStatus';

export const useNotificationsConfigSelector = () => {
  const status = useSelector((state) =>
    _.get(state, 'notificationsConfig.notificationsConfig.status')
  );
  const notificationsConfig = useSelector((state) =>
    _.get(state, 'notificationsConfig.notificationsConfig.config')
  );
  const inProgress = status === requestStatus.IN_PROGRESS;
  const success = status === requestStatus.SUCCESS;
  const error = useSelector((state) =>
    _.get(state, 'notificationsConfig.notificationsConfig.error.message')
  );

  return useMemo(
    () => ({ notificationsConfig, inProgress, success, error }),
    [error, inProgress, notificationsConfig, success]
  );
};

export const useSaveNotificationsConfigSelector = () => {
  const status = useSelector((state) =>
    _.get(state, 'notificationsConfig.saveNotificationsConfig.status')
  );
  const inProgress = status === requestStatus.IN_PROGRESS;
  const success = status === requestStatus.SUCCESS;
  const error = useSelector((state) =>
    _.get(state, 'notificationsConfig.saveNotificationsConfig.error.message')
  );

  return useMemo(
    () => ({ inProgress, success, error }),
    [error, inProgress, success]
  );
};
