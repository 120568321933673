import { useMemo } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';

import { createDataSlice } from '../../utils/createDataSlice';

const selectedActivitiesConfigsSlice = createDataSlice({
  reducerPath: ['activities', 'sandbox', 'selectedActivitiesConfigs'],
  initialState: { selectedBundleId: undefined, selectedConfigIds: {} },
  reducers: {
    setSelectedBundleId(state, action) {
      const bundleId = _.get(action, 'payload.bundleId');
      _.set(state, ['selectedBundleId'], bundleId);
    },
    toggleSelected(state, action) {
      const bundleId = _.get(action, 'payload.bundleId');
      const protocolId = _.get(action, 'payload.protocolId');
      const configId = _.get(action, 'payload.configId');

      _.set(
        state,
        ['selectedConfigIds', bundleId, protocolId, configId],
        !_.get(state, ['selectedConfigIds', bundleId, protocolId, configId])
      );
    },
    setConfigs(state, action) {
      const bundleId = _.get(action, 'payload.bundleId');
      const configIdsByProtocolId = _.get(action, 'payload.configIds');
      const value = _.get(action, 'payload.value', true);

      const selectedConfigIds = _.mapValues(
        configIdsByProtocolId,
        (configIds) =>
          _.chain(configIds)
            .map((id) => ({
              id
            }))
            .keyBy('id')
            .mapValues(() => value)
            .value()
      );

      _.set(state, ['selectedConfigIds', bundleId], selectedConfigIds);
    }
  }
});

const { actions, baseSelector: selectedActivitiesConfigsSelector } =
  selectedActivitiesConfigsSlice;

export const selectedActivitiesConfigsActions = actions;

export const useSelectedBundleIdSelectors = () => {
  const { selectedBundleId } = useSelector(selectedActivitiesConfigsSelector);
  return useMemo(
    () => ({
      selectedBundleId
    }),
    [selectedBundleId]
  );
};

export const useSelectedActivitiesConfigsByProtocolIdSelectors = () => {
  const { selectedBundleId } = useSelectedBundleIdSelectors();

  const { selectedConfigIds } = useSelector(selectedActivitiesConfigsSelector);

  const selectedConfigIdsByProtocolId = useMemo(
    () => _.get(selectedConfigIds, [selectedBundleId], {}),
    [selectedBundleId, selectedConfigIds]
  );

  return useMemo(
    () => ({ selectedConfigIdsByProtocolId }),
    [selectedConfigIdsByProtocolId]
  );
};

export const useSelectedActivitiesConfigsSelectors = (protocolId) => {
  const { selectedConfigIdsByProtocolId } =
    useSelectedActivitiesConfigsByProtocolIdSelectors();

  const selectedConfigIds = _.get(selectedConfigIdsByProtocolId, [protocolId]);

  return useMemo(() => ({ selectedConfigIds }), [selectedConfigIds]);
};

export default selectedActivitiesConfigsSlice;
