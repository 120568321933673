import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import tinycolor from 'tinycolor2';

import { saveGrowthStageConfigAction } from '../../../../_store/actions/configActions';
import {
  useGrowthStagesConfig,
  useSaveGrowthStageConfig
} from '../../../../_store/selectors/configSelectors';
import ResponsiveDialog from '../../../../Components/ResponsiveDialog';
import ResponsiveDialogContent from '../../../../Components/ResponsiveDialogContent';

import ColorPicker from './ColorPicker';
import ExampleFieldMap from './ExampleFieldMap';

const defaultFillOpacity = 0.6;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  pickersGrid: {
    marginTop: theme.spacing(2)
  },
  buttonWrapper: {
    position: 'relative'
  }
}));

const ColorPickerDialog = ({ selectedStage, onClose }) => {
  const dispatch = useDispatch();

  const { growthStagesConfig } = useGrowthStagesConfig();
  const { inProgress, success, error } = useSaveGrowthStageConfig();

  const styles = useStyles();
  const [outlineColor, setOutlineColor] = useState();
  const [fillColor, setFillColor] = useState();
  const [textColor, setTextColor] = useState();
  const [description, setDescription] = useState();

  const stageConfig = _.get(growthStagesConfig, selectedStage);
  const initialOutlineColor = _.get(stageConfig, 'outlineColor', '#000000');
  const initialFillColor = _.get(
    stageConfig,
    'fillColor',
    'rgba(0, 0, 0, 0.6)'
  );
  const initialTextColor = _.get(stageConfig, 'textColor', '#FFFFFF');

  useEffect(() => {
    if (!selectedStage) {
      return;
    }
    setOutlineColor(initialOutlineColor);
    setFillColor(initialFillColor);
    setTextColor(initialTextColor);
  }, [
    growthStagesConfig,
    initialFillColor,
    initialOutlineColor,
    initialTextColor,
    selectedStage
  ]);

  useEffect(() => {
    setDescription(_.get(stageConfig, 'description'));
  }, [selectedStage, stageConfig]);

  useEffect(() => {
    if (success) {
      onClose();
    }
  }, [dispatch, onClose, success]);

  const open = !!selectedStage;

  const stageName = useMemo(
    () => _.get(growthStagesConfig, [selectedStage, 'name']),
    [growthStagesConfig, selectedStage]
  );

  const handleBorderColorChange = useCallback((color) => {
    setOutlineColor(color);
    const fillColor = tinycolor(color);
    fillColor.setAlpha(defaultFillOpacity);
    setFillColor(fillColor.toRgbString());
    const textColor = tinycolor
      .mostReadable(color, ['#FFFFFF', '#000000'], {
        includeFallbackColors: true
      })
      .toHexString();
    setTextColor(textColor);
  }, []);

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSave = useCallback(() => {
    dispatch(
      saveGrowthStageConfigAction(selectedStage, {
        name: stageName,
        description: _.isEmpty(description) ? null : description,
        outlineColor,
        fillColor,
        textColor
      })
    );
  }, [
    description,
    dispatch,
    fillColor,
    outlineColor,
    selectedStage,
    stageName,
    textColor
  ]);

  const handleDescriptionChange = useCallback((e) => {
    setDescription(e.target.value);
  }, []);

  return (
    <ResponsiveDialog open={open} maxWidth={false}>
      <DialogTitle>{stageName}</DialogTitle>
      <ResponsiveDialogContent>
        <ExampleFieldMap
          height={265}
          outlineColor={outlineColor}
          fillColor={fillColor}
          textColor={textColor}
          label={stageName}
        />
        <Grid container spacing={2} className={styles.pickersGrid}>
          <Grid item xs={12}>
            <ColorPicker
              title="Main Color"
              initialColor={initialOutlineColor}
              onChange={handleBorderColorChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={description || ''}
              onChange={handleDescriptionChange}
              multiline
              variant="outlined"
              fullWidth
              id="description"
              label="Description"
              name="description"
            />
          </Grid>
          {error && (
            <Grid item xs={12}>
              <FormHelperText error>{error}</FormHelperText>
            </Grid>
          )}
        </Grid>
      </ResponsiveDialogContent>
      <DialogActions>
        <Button onClick={handleCancel} variant="contained" color="default">
          Cancel
        </Button>
        <Box className={styles.buttonWrapper}>
          <Button
            onClick={handleSave}
            variant="contained"
            color="primary"
            disabled={inProgress}
          >
            Save
          </Button>
          {inProgress && (
            <CircularProgress size={24} className={styles.buttonProgress} />
          )}
        </Box>
      </DialogActions>
    </ResponsiveDialog>
  );
};

export default ColorPickerDialog;
