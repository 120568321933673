import cloneGlobalBundleSlice from './cloneGlobalBundleSlice';
import cloneGrowerBundleSlice from './cloneGrowerBundleSlice';
import createGrowerBundleSlice from './createGrowerBundleSlice';
import deleteGrowerBundleSlice from './deleteGrowerBundleSlice';
import resetDefaultGrowerBundleSlice from './resetDefaultGrowerBundleSlice';
import setDefaultGrowerBundleSlice from './setDefaultGrowerBundleSlice';
import updateGrowerBundleSlice from './updateGrowerBundleSlice';

const growerBundlesSlices = [
  createGrowerBundleSlice,
  updateGrowerBundleSlice,
  deleteGrowerBundleSlice,
  setDefaultGrowerBundleSlice,
  cloneGrowerBundleSlice,
  cloneGlobalBundleSlice,
  resetDefaultGrowerBundleSlice
];

export default growerBundlesSlices;
