import { useMemo } from 'react';

import { faCogs } from '@fortawesome/free-solid-svg-icons';

import { userRoles } from '../../_constants/userConstants';

const GrowersConfigPageKey = 'GROWERS_CONFIG_DEMO_PAGE';

const baseConfig = {
  key: GrowersConfigPageKey,
  icon: faCogs,
  label: 'Growers Configuration',
  path: 'growersConfig',
  allowedRoles: [userRoles.Admin, userRoles.Consultant]
};

export const useGrowersConfigPageConfig = () => {
  return useMemo(() => ({ config: baseConfig }), []);
};
