import { useContext, useMemo } from 'react';

import { PagesConfigContext } from '../../_context/PagesConfigContext';

export const usePage = () => {
  const { currentPage } = useContext(PagesConfigContext);

  return useMemo(
    () => ({
      page: currentPage
    }),
    [currentPage]
  );
};
