import React, { useCallback, useContext, useMemo, useState } from 'react';

import { FormControlLabel, Switch } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import _ from 'lodash';
import { useResizeDetector } from 'react-resize-detector';

import { AuthContext } from '../../_context/AuthContext';
import GrowthStagesLegend from '../Growths/GrowthStagesLegend';
import GrowthTimelineSelector from '../Growths/GrowthTimeline/GrowthTimelineSelector';

import ActivitiesTimelineChart from './ActivitiesTimelineChart';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  timelineContainer: {
    position: 'relative',
    width: '100%'
  },
  counceModelSwitchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  }
}));

const ActivitiesTimeline = ({
  inProgress,
  timelineActivities,
  minDate,
  maxDate,
  showModifiedDate,
  disableFarmName,
  onActivityClick
}) => {
  const styles = useStyles();

  const [hideRecommendations, setHideRecommendations] = useState(false);

  const { width, ref: containerRef } = useResizeDetector();

  const { isAdmin } = useContext(AuthContext);

  const [showVStages, setShowVStages] = useState(false);

  const [rangeSelection, setRangeSelection] = useState();
  const handleRangeSelected = useMemo(
    () => _.debounce(setRangeSelection, 10),
    []
  );

  const hasGrowths = _.get(timelineActivities, 'length', 0) > 0;

  const handleToggleVStages = useCallback(() => {
    setShowVStages((show) => !show);
  }, []);

  const handleToggleRecommendations = useCallback(() => {
    setHideRecommendations((hide) => !hide);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.timelineContainer} ref={containerRef}>
        {!inProgress && hasGrowths && !_.isNaN(width) && _.isNumber(width) && (
          <>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box className={styles.counceModelSwitchContainer}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={hideRecommendations}
                      onChange={handleToggleRecommendations}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Hide Recommendations"
                />
              </Box>
              {isAdmin && (
                <Box className={styles.counceModelSwitchContainer}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showVStages}
                        onChange={handleToggleVStages}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Show Counce Growth Model Stages (V and R)"
                  />
                </Box>
              )}
            </Box>
            <ActivitiesTimelineChart
              timelineActivities={timelineActivities}
              containerWidth={width}
              minDate={minDate}
              maxDate={maxDate}
              rangeSelection={rangeSelection}
              showVStages={showVStages}
              showModifiedDate={showModifiedDate}
              disableFarmName={disableFarmName}
              onActivityClick={onActivityClick}
              hideRecommendations={hideRecommendations}
            />
            <GrowthTimelineSelector
              timelineGrowths={timelineActivities}
              containerWidth={width}
              minDate={minDate}
              maxDate={maxDate}
              onRangeSelected={handleRangeSelected}
            />
            <GrowthStagesLegend />
          </>
        )}
      </div>
      {!inProgress && !hasGrowths && (
        <Alert severity="info">
          <AlertTitle>No Growths</AlertTitle>
          There are no active growths configured for the selected Grower. In
          order to display the growths timeline, please create a growth.
        </Alert>
      )}
      {inProgress && <CircularProgress />}
    </div>
  );
};

export default ActivitiesTimeline;
