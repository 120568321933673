import React, { useCallback, useMemo } from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';

import {
  ContentBody,
  ContentContainer,
  ResponsiveContentHeader
} from '../../../Layout/Content';

import NotificationChannels from './NotificationChannels';

const useStyles = makeStyles(() => ({
  body: {
    maxWidth: '32rem'
  },
  growerLine: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  growerName: {
    fontWeight: '500',
    marginRight: '1rem'
  }
}));

const GrowerNotificationsConfig = ({
  growerId,
  name,
  channelsConfig,
  onChange
}) => {
  const styles = useStyles();

  const handleChange = useCallback(
    (channel, checked) => {
      onChange(growerId, channel, checked);
    },
    [growerId, onChange]
  );

  return (
    <Box className={styles.growerLine}>
      <Typography className={styles.growerName}>{name}</Typography>
      <NotificationChannels channels={channelsConfig} onChange={handleChange} />
    </Box>
  );
};

export default function PerGrowerNotificationsConfig({
  title,
  notificationsConfig,
  growers,
  onChange
}) {
  const styles = useStyles();

  const growersWithConfig = useMemo(
    () =>
      _.map(growers, (grower) => ({
        ...grower,
        notificationsConfig: _.get(notificationsConfig, _.get(grower, 'id'))
      })),
    [growers, notificationsConfig]
  );

  return (
    <ContentContainer className={styles.body}>
      <ResponsiveContentHeader>{title}</ResponsiveContentHeader>
      <ContentBody>
        {_.map(growersWithConfig, (grower) => (
          <GrowerNotificationsConfig
            key={_.get(grower, 'id')}
            growerId={_.get(grower, 'id')}
            name={_.get(grower, 'name')}
            channelsConfig={_.get(grower, 'notificationsConfig')}
            onChange={onChange}
          />
        ))}
      </ContentBody>
    </ContentContainer>
  );
}
