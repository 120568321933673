import React, { useMemo } from 'react';

import Alert from '@material-ui/lab/Alert';
import _ from 'lodash';

import LoadingIndicator from '../../../Components/LoadingIndicator';
import {
  ContentBody,
  ContentContainer,
  ContentHeader,
  ResponsiveHeaderButtonsContainer
} from '../../../Layout/Content';

import { useGrowthModelReportWithDistricts } from './_hooks/useGrowthModelReportWithDistricts';
import { useReportsChartsControls } from './_hooks/useReportsChartsControls';
import HarvestedYieldReportTable from './HarvestedYieldReportTable';
import HarvestYieldReportChart from './HarvestYieldReportChart';
import ReportsDataGridWrapper from './ReportsDataGridWrapper';
import TableChartToggle from './TableChartToggle';

const HarvestedYieldReport = () => {
  const { inProgress, errorMessage, response } =
    useGrowthModelReportWithDistricts();

  const { showChart, toggleChart, visibleRows, handleVisibleRowIdsChange } =
    useReportsChartsControls(response);

  const responseFieldsWithYield = useMemo(
    () => ({
      fields: _.chain(response)
        .get('fields')
        .filter((field) => !!_.get(field, 'yieldBushelsPerAcre'))
        .value()
    }),
    [response]
  );

  return (
    <ContentContainer>
      <ContentHeader>
        <span>Harvested Yield Report</span>
        <ResponsiveHeaderButtonsContainer>
          <TableChartToggle showChart={showChart} onToggle={toggleChart} />
        </ResponsiveHeaderButtonsContainer>
      </ContentHeader>
      <ContentBody>
        {inProgress && <LoadingIndicator />}
        {!inProgress && errorMessage && (
          <Alert severity="error">{errorMessage}</Alert>
        )}
        {!inProgress && !errorMessage && (
          <>
            <ReportsDataGridWrapper hide={showChart}>
              <HarvestedYieldReportTable
                response={responseFieldsWithYield}
                onVisibleRowIdsChange={handleVisibleRowIdsChange}
              />
            </ReportsDataGridWrapper>
            {showChart && <HarvestYieldReportChart fields={visibleRows} />}
          </>
        )}
      </ContentBody>
    </ContentContainer>
  );
};

export default HarvestedYieldReport;
