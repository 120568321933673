import { useMemo } from 'react';

import { seasonService } from '../../../../services';
import { createApiSlice } from '../../utils/createApiSlice';

const deleteManagementZoneSlice = createApiSlice({
  reducerPath: ['newGrowths', 'managementZones', 'deleteManagementZone'],
  apiFunction: seasonService.deleteManagementZoneV2
});

const { actions, useApiSelectors } = deleteManagementZoneSlice;

export const deleteManagementZoneActions = actions;

export const useDeleteManagementZoneSelectors = () => {
  const { inProgress, errorMessage, success, updatedAt } = useApiSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      updatedAt
    }),
    [errorMessage, inProgress, success, updatedAt]
  );
};

export default deleteManagementZoneSlice;
