import { addToken, client } from './client';

export const activitiesSandboxService = {
  generateActivities
};

async function generateActivities(token, request) {
  const response = await client.post(
    `/v2/growth-models-sandbox/model`,
    request,
    addToken(token)
  );
  return response.data;
}
