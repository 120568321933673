import addFarmWorkOrderNoteSlice from './addFarmWorkOrderNoteSlice';
import createFarmWorkOrderSlice from './createFarmWorkOrderSlice';
import deleteFarmWorkOrderSlice from './deleteFarmWorkOrderSlice';
import updateFarmWorkOrderSlice from './updateFarmWorkOrderSlice';

const farmWorkOrdersSlice = [
  createFarmWorkOrderSlice,
  updateFarmWorkOrderSlice,
  deleteFarmWorkOrderSlice,
  addFarmWorkOrderNoteSlice
];

export default farmWorkOrdersSlice;
