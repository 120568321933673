import { useMemo } from 'react';

import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import _ from 'lodash';

import { userRoles } from '../../_constants/userConstants';
import { useGrowersSelectors } from '../../_store/selectors/growersSelectors';

import { useGrowersWithPumps } from './Components/_hooks/useGrowersWithPumps';
import { NotificationsConfigTabIds } from './NotificationsConfigPage';

const NotificationsConfigPageKey = 'NOTIFICATIONS_CONFIG_PAGE';

export const NotificationsConfigPageSubpageKeys = {
  GrowthModel: `${NotificationsConfigPageKey}_GROWTH_MODEL`,
  Activities: `${NotificationsConfigPageKey}_ACTIVITIES`,
  PumpSystems: `${NotificationsConfigPageKey}_PUMP_SYSTEMS`
};

const baseConfig = {
  key: NotificationsConfigPageKey,
  icon: faEnvelope,
  label: 'Notifications',
  path: 'notificationsConfig',
  allowedRoles: [
    userRoles.Admin,
    userRoles.Consultant,
    userRoles.User,
    userRoles.UserView
  ],
  subPages: [
    {
      key: NotificationsConfigPageSubpageKeys.GrowthModel,
      label: 'Growth Model',
      path: NotificationsConfigTabIds.GROWTH_MODEL
    },
    {
      key: NotificationsConfigPageSubpageKeys.Activities,
      label: 'Activities',
      path: NotificationsConfigTabIds.ACTIVITIES
    },
    {
      key: NotificationsConfigPageSubpageKeys.PumpSystems,
      label: 'Pumps',
      path: NotificationsConfigTabIds.PUMP_SYSTEMS
    }
  ]
};

export const useNotificationsConfigPageConfig = () => {
  const { growers } = useGrowersSelectors();

  const { growersWithPumps } = useGrowersWithPumps(growers);

  const hasPumps = !_.isEmpty(growersWithPumps);

  const config = useMemo(
    () => ({
      ...baseConfig,
      subPages: _.chain(baseConfig)
        .get('subPages')
        .filter((subPage) => {
          const key = _.get(subPage, 'key');
          if (key === NotificationsConfigPageSubpageKeys.PumpSystems) {
            return hasPumps;
          }
          return true;
        })
        .value()
    }),
    [hasPumps]
  );

  return useMemo(() => ({ config }), [config]);
};
