import _ from 'lodash';

import { getErrorMessage } from '../../services/errors';
import { saveGrowthStageConfigRequestActionTypes } from '../actionTypes/configActionTypes';
import { requestStatus } from '../helpers/requestStatus';
import { cloneBundleActions } from '../slices/config/bundles/cloneBundleSlice';
import { createBundleActions } from '../slices/config/bundles/createBundleSlice';
import { deleteBundleActions } from '../slices/config/bundles/deleteBundleSlice';
import { setDefaultBundleActions } from '../slices/config/bundles/setDefaultBundleSlice';
import { updateBundleActions } from '../slices/config/bundles/updateBundleSlice';
import { addActivityActions } from '../slices/config/protocols/addActivitySlice';
import { createProtocolActions } from '../slices/config/protocols/createProtocolSlice';
import { deleteActivityActions } from '../slices/config/protocols/deleteActivitySlice';
import { deleteProtocolActions } from '../slices/config/protocols/deleteProtocolSlice';
import { updateActivityActions } from '../slices/config/protocols/updateActivitySlice';
import { updateProtocolActions } from '../slices/config/protocols/updateProtocolSlice';
import { authActions } from '../slices/user/authSlice';

export const configReducer = (state = {}, action) => {
  switch (action.type) {
    case authActions.success.type:
      return {
        ...state,
        ...action.payload.response.config
      };
    case saveGrowthStageConfigRequestActionTypes.REQUEST:
      return {
        ...state,
        saveStageConfig: {
          status: requestStatus.IN_PROGRESS
        }
      };
    case saveGrowthStageConfigRequestActionTypes.SUCCEEDED:
      return {
        ...state,
        saveStageConfig: {
          status: requestStatus.SUCCESS
        },
        growthModel: {
          ..._.get(state, 'growthModel'),
          ...action.payload.response
        }
      };
    case saveGrowthStageConfigRequestActionTypes.FAILED:
      return {
        ...state,
        saveStageConfig: {
          status: requestStatus.FAILURE,
          error: {
            message: getErrorMessage(action.payload.apiError),
            status: _.get(action, 'payload.apiError.response.status')
          }
        }
      };
    case saveGrowthStageConfigRequestActionTypes.CLEAR:
      return _.omit(state, 'saveStageConfig');
    case createBundleActions.success.type:
    case updateBundleActions.success.type:
    case deleteBundleActions.success.type:
    case setDefaultBundleActions.success.type:
    case cloneBundleActions.success.type:
    case createProtocolActions.success.type:
    case updateProtocolActions.success.type:
    case deleteProtocolActions.success.type:
    case addActivityActions.success.type:
    case updateActivityActions.success.type:
    case deleteActivityActions.success.type:
      return {
        ...state,
        activities: action.payload.response
      };
    default:
      return state;
  }
};
