import { useMemo } from 'react';

import _ from 'lodash';

import { useReportsAppliedFieldsSelectionSelectors } from '../../../../_store/slices/reports/reportsAppliedFieldsSelectionSlice';

export function useSeasonAppliedFields() {
  const { fieldsChecked } = useReportsAppliedFieldsSelectionSelectors();

  const appliedFields = useMemo(
    () =>
      _.chain(fieldsChecked)
        .flatMap((seasons, growerId) => {
          return _.flatMap(seasons, (farms, seasonId) => {
            return _.flatMap(farms, (fields, farmId) => {
              return _.chain(fields)
                .map((checked, fieldId) => ({ fieldId, checked }))
                .filter((field) => !!_.get(field, 'checked'))
                .map((field) => ({
                  growerId,
                  seasonId,
                  farmId,
                  fieldId: _.get(field, 'fieldId')
                }))
                .value();
            });
          });
        })
        .value(),
    [fieldsChecked]
  );

  return useMemo(() => ({ appliedFields }), [appliedFields]);
}
