import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';
import MapGL, { Layer, Source } from 'react-map-gl';

import { BuildViewPort } from '../../../../_utilities/mappingUtils';
import { mapboxToken } from '../../../../config';
import FarmStructureMarker from '../../../Map/Components/FarmStructureMarker';
import MapBadge from '../../../Map/Components/MapBadge';

const useStyles = makeStyles(() => ({
  container: {},
  mapContainer: {
    position: 'relative',
    width: '100%'
  }
}));

const initialCoordinates = [-94.0, 33.0];

const exampleFieldFeatureCollection = {
  type: 'Feature',
  properties: {},
  geometry: {
    type: 'MultiPolygon',
    coordinates: [
      [
        [
          [-92.17866245513663, 34.93738903676095],
          [-92.17869313127538, 34.94055757746561],
          [-92.17455185252794, 34.94055757746561],
          [-92.1747359093614, 34.937087264594695],
          [-92.17866245513663, 34.93738903676095]
        ]
      ]
    ]
  }
};

const badgeItem = {
  center: {
    coordinates: [-92.1766224919, 34.938822421]
  }
};

const ExampleFieldMap = ({
  height = 200,
  outlineColor,
  fillColor,
  textColor,
  label
}) => {
  const styles = useStyles();

  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);

  const [viewport, setViewport] = useState({
    latitude: initialCoordinates[1],
    longitude: initialCoordinates[0],
    zoom: 4.0
  });
  const [mapLoaded, setMapLoaded] = useState(false);

  const layerStyle = useMemo(
    () => ({
      id: 'fields',
      type: 'fill',
      paint: { 'fill-color': fillColor || '#FFFFFF' }
    }),
    [fillColor]
  );

  const outlineLayerStyle = useMemo(
    () => ({
      id: 'fields-outline',
      type: 'line',
      paint: { 'line-color': outlineColor || '#FFFFFF' }
    }),
    [outlineColor]
  );

  useEffect(() => {
    if (mapLoaded && mapContainerRef && mapContainerRef.current) {
      const { width, height } = mapContainerRef.current.getBoundingClientRect();
      const coordinates =
        exampleFieldFeatureCollection.geometry.coordinates[0][0];
      const newViewport = BuildViewPort(coordinates, width, height, 10);
      setViewport(newViewport);
    }
  }, [mapLoaded, mapContainerRef]);

  const handleMapLoaded = useCallback(() => {
    setMapLoaded(true);
  }, []);

  return (
    <Box className={styles.mapContainer} ref={mapContainerRef} height={height}>
      <MapGL
        {...viewport}
        ref={mapRef}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/mapbox/satellite-streets-v11"
        mapboxApiAccessToken={mapboxToken}
        onLoad={handleMapLoaded}
      >
        <Source type="geojson" data={exampleFieldFeatureCollection}>
          <Layer {...layerStyle} />
          <Layer {...outlineLayerStyle} />
        </Source>
        {!!label && (
          <FarmStructureMarker item={badgeItem}>
            <MapBadge
              backgroundColor={outlineColor}
              textColor={textColor}
              text={label}
              onClick={_.noop}
            />
          </FarmStructureMarker>
        )}
      </MapGL>
    </Box>
  );
};

export default ExampleFieldMap;
