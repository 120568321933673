import { useMemo } from 'react';

import { growthService } from '../../../../services';
import { createApiSlice } from '../../utils/createApiSlice';

const updateActivityFeedbackSlice = createApiSlice({
  reducerPath: ['newGrowths', 'activities', 'updateActivityFeedback'],
  apiFunction: growthService.updateActivityFeedbackV2
});

const { actions, useApiSelectors } = updateActivityFeedbackSlice;

export const updateActivityFeedbackActions = actions;

export const useUpdateActivityFeedbackSelectors = () => {
  const { inProgress, errorMessage, success, updatedAt } = useApiSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      updatedAt
    }),
    [errorMessage, inProgress, success, updatedAt]
  );
};

export default updateActivityFeedbackSlice;
