import { addToken, client } from './client';

export const protocolsConfigService = {
  createProtocolV2,
  updateProtocolV2,
  deleteProtocolV2,
  addActivityV2,
  updateActivityV2,
  deleteActivityV2
};

async function createProtocolV2(token, bundleId, protocol) {
  const response = await client.post(
    `/v2/config/bundles/${bundleId}/protocols`,
    protocol,
    addToken(token)
  );
  return response.data;
}

async function updateProtocolV2(token, bundleId, protocolId, protocol) {
  const response = await client.patch(
    `/v2/config/bundles/${bundleId}/protocols/${protocolId}`,
    protocol,
    addToken(token)
  );
  return response.data;
}

async function deleteProtocolV2(token, bundleId, protocolId) {
  const response = await client.delete(
    `/v2/config/bundles/${bundleId}/protocols/${protocolId}`,
    addToken(token)
  );
  return response.data;
}

async function addActivityV2(token, bundleId, protocolId, activity) {
  const response = await client.post(
    `/v2/config/bundles/${bundleId}/protocols/${protocolId}/activities`,
    activity,
    addToken(token)
  );
  return response.data;
}

async function updateActivityV2(
  token,
  bundleId,
  protocolId,
  activityId,
  activity
) {
  const response = await client.patch(
    `/v2/config/bundles/${bundleId}/protocols/${protocolId}/activities/${activityId}`,
    activity,
    addToken(token)
  );
  return response.data;
}

async function deleteActivityV2(token, bundleId, protocolId, activityId) {
  const response = await client.delete(
    `/v2/config/bundles/${bundleId}/protocols/${protocolId}/activities/${activityId}`,
    addToken(token)
  );
  return response.data;
}
