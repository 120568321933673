import React, { useCallback } from 'react';

import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MapIcon from '@material-ui/icons/Map';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { mapLayerIds } from '../../_constants/mapLayersConstants';
import { selectMapLayer } from '../../_store/actions/uiActions';

const GoToWorkOrderMapButton = ({ workOrder }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(selectMapLayer(mapLayerIds.WorkOrders));
    navigate(`/map?workOrderId=${_.get(workOrder, 'id')}`);
  }, [workOrder, dispatch, navigate]);

  return (
    <Tooltip title="Show on Map">
      <IconButton onClick={handleClick} size="small" color="primary">
        <MapIcon />
      </IconButton>
    </Tooltip>
  );
};

export default GoToWorkOrderMapButton;
