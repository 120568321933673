import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import _ from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';

const CheckboxField = ({ field, path }) => {
  const { control } = useFormContext();

  const label = _.get(field, 'label');

  return (
    <Controller
      control={control}
      name={path}
      id={path}
      defaultValue={false}
      render={({ onChange, value, ref }) => (
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => onChange(e.target.checked)}
              checked={value}
              inputRef={ref}
            />
          }
          label={label}
        />
      )}
    />
  );
};

export default CheckboxField;
