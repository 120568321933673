import { useEffect } from 'react';

import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { pageViewActionActions } from '../_store/slices/user/pageViewActionSlice';

import { usePage } from './Pages/usePage';
import { useSubPage } from './Pages/useSubpage';

export function usePageViewAction() {
  const dispatch = useDispatch();

  const { page } = usePage();
  const { subPage } = useSubPage();

  const pageName = _.get(page, 'label');
  const subPageName = _.get(subPage, 'label');

  useEffect(() => {
    if (pageName) {
      dispatch(
        pageViewActionActions.request({ page: pageName, tab: subPageName })
      );
    }
  }, [dispatch, pageName, subPageName]);
}
