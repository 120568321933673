import React, { useCallback, useMemo } from 'react';

import _ from 'lodash';

import DrawerItem from './DrawerItem';

const FieldDrawerItem = ({ field, even, selected, onClick }) => {
  const acres = _.get(field, 'acres');

  const handleClick = useCallback(() => {
    onClick(field);
  }, [field, onClick]);

  const shapes = useMemo(() => [_.get(field, 'shape')], [field]);

  return (
    <DrawerItem
      item={field}
      title={_.get(field, 'name')}
      subtitle={`${acres} Acre${field === 1 ? '' : 's'}`}
      even={even}
      onClick={handleClick}
      selected={selected}
      shapes={shapes}
    />
  );
};

export default FieldDrawerItem;
