import React, { useCallback } from 'react';

import { TablePagination } from '@material-ui/core';

export default function CommonTablePagination({ paginationControls }) {
  const { count, page, rowsPerPage, onPageChange, onRowsPerPageChange } =
    paginationControls;

  const handlePageChange = useCallback(
    (event, newPage) => {
      onPageChange(newPage);
    },
    [onPageChange]
  );

  const handleRowsPerPageChange = useCallback(
    (event) => {
      onRowsPerPageChange(event.target.value);
    },
    [onRowsPerPageChange]
  );

  return (
    <TablePagination
      count={count}
      page={page}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
      rowsPerPage={rowsPerPage}
    />
  );
}
