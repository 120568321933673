import { useMemo } from 'react';

import { faUsersCog } from '@fortawesome/free-solid-svg-icons';

import { userRoles } from '../../_constants/userConstants';

const UserAdminPageKey = 'USER_ADMIN_DEMO_PAGE';

const baseConfig = {
  key: UserAdminPageKey,
  icon: faUsersCog,
  label: 'User Management',
  path: 'usersConfig',
  allowedRoles: [userRoles.Admin]
};

export const useUserAdminPageConfig = () => {
  return useMemo(() => ({ config: baseConfig }), []);
};
