import React, { useCallback, useContext, useMemo } from 'react';

import { FormControlLabel, Switch } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import _ from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch } from 'react-redux';

import { mapLayerIds } from '../../_constants/mapLayersConstants';
import { FarmStructureContext } from '../../_context/FarmStructureContext';
import { useGrowersDrawerState } from '../../_hooks/ui/useGrowersDrawerState';
import { usePathname } from '../../_hooks/usePathname';
import { useMapLayersSelectors } from '../../_store/selectors/uiSelectors';
import {
  cropDashboardActions,
  useCropDashboardSelectors
} from '../../_store/slices/ui/cropDashboardSlice';
import LoadingIndicator from '../../Components/LoadingIndicator';

import DrawerError from './DrawerError';
import DrawerTitle from './DrawerTitle';
import FarmDrawerItem from './FarmDrawerItem';
import FieldDrawerItem from './FieldDrawerItem';
import GrowerDrawerItem from './GrowerDrawerItem';
import ManagementZonesLayersDrawerList from './ManagementZonesLayersDrawerList';

const useStyles = makeStyles((theme) => ({
  drawer: {},
  drawerPaper: {
    width: theme.dimensions.gffDrawerWidth,
    paddingTop: theme.dimensions.headerHeight,
    backgroundColor: 'rgb(46, 64, 87)'
  },
  scrollbar: {
    '& > .ps__rail-y > .ps__thumb-y': {
      backgroundColor: 'rgb(21, 28, 43, 1)'
    }
  },
  buttonLabel: {
    color: '#D1D1D1',
    marginTop: '0.25rem',
    marginBottom: '0.25rem'
  },
  switch: {
    marginLeft: '1.5rem',
    marginRight: '0.25rem'
  },
  farmHeader: {
    marginTop: '1rem',
    borderBottom: '2px rgb(21, 28, 43) solid'
  }
}));

const GrowersDrawerContent = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const { toggleGrowersDrawer } = useGrowersDrawerState();
  const { showAllForGrower } = useCropDashboardSelectors();

  const {
    growers,
    farms,
    fields,
    loading,
    selectedGrower,
    selectedSeason,
    selectedFarm,
    selectedField,
    growersError,
    farmsError,
    fieldsError,
    selectGrower,
    selectFarm,
    selectField,
    clearGrower,
    clearFarm,
    clearField
  } = useContext(FarmStructureContext);

  const { pathname } = usePathname();

  const theme = useTheme();
  const closeDrawerOnFieldSelect = !useMediaQuery(theme.breakpoints.up('md'));

  const { layerId } = useMapLayersSelectors();
  const isManagementZonesLayer =
    layerId === mapLayerIds.ManagementZones && pathname.startsWith('/map');

  const showGrowerList = !loading && !selectedGrower && !selectedFarm;
  const showSeasonNotConfigured =
    !showGrowerList && !selectedSeason && !loading;
  const showFarmsList =
    !loading &&
    !showAllForGrower &&
    !!selectedGrower &&
    !!selectedSeason &&
    !selectedFarm;
  const showFieldsList =
    !loading &&
    !showAllForGrower &&
    !!selectedGrower &&
    !!selectedSeason &&
    !!selectedFarm &&
    (!isManagementZonesLayer || !selectedField);
  const showManagementZonesLayersList =
    !loading &&
    !showAllForGrower &&
    !!selectedGrower &&
    !!selectedSeason &&
    !!selectedFarm &&
    !!selectedField &&
    isManagementZonesLayer;
  const showFieldsCheckbox = !loading && selectedGrower && selectedSeason;
  const showAllFarmsFieldsList =
    !loading && !!showAllForGrower && !!selectedGrower && !!selectedSeason;

  const growersCount = _.get(_.values(growers), 'length', 0);
  const farmsCount = _.get(_.values(farms), 'length', 0);

  const farmsWithFields = useMemo(
    () =>
      _.chain(farms)
        .values()
        .filter((farm) => !_.isEmpty(_.get(farm, 'fields')))
        .value(),
    [farms]
  );

  const title =
    isManagementZonesLayer && selectedField
      ? _.get(selectedField, 'name')
      : !showAllForGrower && selectedFarm
      ? _.get(selectedFarm, 'name')
      : selectedGrower
      ? _.get(selectedGrower, 'name')
      : 'Growers';
  const showBackButton =
    (selectedGrower && growersCount > 1) ||
    (selectedFarm && (growersCount > 1 || farmsCount > 1)) ||
    selectedField;

  const handleGrowerSelect = useCallback(
    (item) => {
      selectGrower(_.get(item, 'id'));
    },
    [selectGrower]
  );

  const handleFarmSelect = useCallback(
    (item) => {
      selectFarm(
        _.get(item, 'growerId'),
        _.get(item, 'seasonId'),
        _.get(item, 'id')
      );
    },
    [selectFarm]
  );

  const handleFieldSelect = useCallback(
    (item) => {
      const fieldId = _.get(item, 'id');
      if (fieldId === _.get(selectedField, 'id')) {
        clearField();
        if (showAllForGrower) {
          clearFarm();
        }
      } else {
        selectField(
          _.get(item, 'growerId'),
          _.get(item, 'seasonId'),
          _.get(item, 'farmId'),
          fieldId
        );
        if (closeDrawerOnFieldSelect) {
          toggleGrowersDrawer();
        }
      }
    },
    [
      clearFarm,
      clearField,
      closeDrawerOnFieldSelect,
      selectField,
      selectedField,
      showAllForGrower,
      toggleGrowersDrawer
    ]
  );

  const handleBackButtonClick = useCallback(() => {
    if (
      showFarmsList ||
      showSeasonNotConfigured ||
      (showAllForGrower && !selectedFarm)
    ) {
      clearGrower();
    }
    if (
      showFieldsList ||
      (showAllForGrower && !selectedField && selectedFarm)
    ) {
      clearFarm();
    }
    if (!!selectedField) {
      clearField();
    }
  }, [
    clearFarm,
    clearField,
    clearGrower,
    selectedFarm,
    selectedField,
    showAllForGrower,
    showFarmsList,
    showFieldsList,
    showSeasonNotConfigured
  ]);

  const handleShowAllFieldsToggle = useCallback(() => {
    dispatch(cropDashboardActions.toggleShowAllForGrower());
  }, [dispatch]);

  return (
    <>
      <DrawerTitle
        title={title}
        onBackClick={handleBackButtonClick}
        showBackButton={showBackButton}
      />
      <PerfectScrollbar className={styles.scrollbar}>
        {loading && <LoadingIndicator />}
        {showGrowerList && (
          <>
            {growersError && <DrawerError message={growersError} />}
            {!growersError && _.isEmpty(growers) && (
              <DrawerError message="No Growers" />
            )}
            {_.map(growers, (grower, idx) => {
              return (
                <GrowerDrawerItem
                  key={_.get(grower, 'id')}
                  grower={grower}
                  even={idx % 2 === 0}
                  onClick={handleGrowerSelect}
                />
              );
            })}
          </>
        )}
        {showSeasonNotConfigured && (
          <DrawerError message="Season is not configured" />
        )}
        {showFieldsCheckbox && (
          <FormControlLabel
            className={styles.buttonLabel}
            control={
              <Switch
                className={styles.switch}
                checked={showAllForGrower}
                onChange={handleShowAllFieldsToggle}
                name="showAllFieldsSwitch"
                color="default"
                size="small"
              />
            }
            label="Show all Fields"
          />
        )}
        {showFarmsList && (
          <>
            {farmsError && <DrawerError message={farmsError} />}
            {!farmsError && _.isEmpty(farms) && (
              <DrawerError message="No Farms" />
            )}
            {_.map(farms, (farm, idx) => {
              return (
                <FarmDrawerItem
                  key={_.get(farm, 'id')}
                  farm={farm}
                  showFieldsCount
                  even={idx % 2 === 0}
                  onClick={handleFarmSelect}
                />
              );
            })}
          </>
        )}
        {showFieldsList && (
          <>
            {fieldsError && <DrawerError message={fieldsError} />}
            {!fieldsError && _.isEmpty(fields) && (
              <DrawerError message="No Fields" />
            )}
            {_.map(fields, (field, idx) => {
              return (
                <FieldDrawerItem
                  key={_.get(field, 'id')}
                  field={field}
                  even={idx % 2 === 0}
                  onClick={handleFieldSelect}
                  selected={_.get(field, 'id') === _.get(selectedField, 'id')}
                />
              );
            })}
          </>
        )}
        {showAllFarmsFieldsList && (
          <>
            {farmsError && <DrawerError message={farmsError} />}
            {!farmsError && _.isEmpty(farms) && (
              <DrawerError message="No Farms" />
            )}
            {!!_.isEmpty(farmsWithFields) && (
              <DrawerError message="No Fields" />
            )}
            {!farmsError &&
              !_.isEmpty(farmsWithFields) &&
              _.map(farmsWithFields, (farm) => (
                <>
                  <Box className={styles.farmHeader}>
                    <FarmDrawerItem
                      key={_.get(farm, 'id')}
                      farm={farm}
                      onClick={handleFarmSelect}
                      showAll
                      selected={
                        _.get(farm, 'id') === _.get(selectedFarm, 'id') &&
                        !selectedField
                      }
                    />
                  </Box>
                  {_.chain(farm)
                    .get('fields')
                    .values()
                    .map((field, idx) => {
                      return (
                        <FieldDrawerItem
                          key={_.get(field, 'id')}
                          field={field}
                          even={idx % 2 === 0}
                          onClick={handleFieldSelect}
                          selected={
                            _.get(field, 'id') === _.get(selectedField, 'id')
                          }
                        />
                      );
                    })
                    .value()}
                </>
              ))}
          </>
        )}
        {showManagementZonesLayersList && <ManagementZonesLayersDrawerList />}
      </PerfectScrollbar>
    </>
  );
};

export default GrowersDrawerContent;
