import { useMemo } from 'react';

import _ from 'lodash';

export function useVisibleRows(rows, visibleRowsIds) {
  const visibleRows = useMemo(() => {
    const ids = _.chain(visibleRowsIds)
      .map((id) => ({ id }))
      .keyBy('id')
      .value();
    return _.filter(rows, (row) => _.has(ids, [_.get(row, 'id')]));
  }, [rows, visibleRowsIds]);

  return useMemo(
    () => ({
      visibleRows
    }),
    [visibleRows]
  );
}
