import { useMemo } from 'react';

import { faCog } from '@fortawesome/free-solid-svg-icons';

import { userRoles } from '../../_constants/userConstants';

import { ApplicationConfigurationTabsIds } from './ApplicationConfigurationPage';

const ApplicationConfigurationPageKey = 'APPLICATION_CONFIGURATION_DEMO_PAGE';

export const ApplicationConfigurationSubpageKey = {
  GrowthStages: `${ApplicationConfigurationPageKey}_GROWTH_STAGES`,
  ProtocolBundles: `${ApplicationConfigurationPageKey}_PROTOCOL_BUNDLES`,
  TankMixes: `${ApplicationConfigurationPageKey}_TANK_MIXES`
};

const baseConfig = {
  key: ApplicationConfigurationPageKey,
  icon: faCog,
  label: 'Application Configuration',
  path: 'application-config',
  allowedRoles: [userRoles.Admin],
  subPages: [
    {
      key: ApplicationConfigurationSubpageKey.GrowthStages,
      label: 'Growth Sages',
      path: ApplicationConfigurationTabsIds['growth-stages']
    },
    {
      key: ApplicationConfigurationSubpageKey.ProtocolBundles,
      label: 'Protocol Bundles',
      path: ApplicationConfigurationTabsIds['protocol-bundles']
    },
    {
      key: ApplicationConfigurationSubpageKey.TankMixes,
      label: 'Tank Mixes',
      path: ApplicationConfigurationTabsIds['tank-mixes']
    }
  ]
};

export const useApplicationConfigurationPageConfig = () => {
  return useMemo(() => ({ config: baseConfig }), []);
};
