import React, { useContext, useMemo } from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import _ from 'lodash';

import { FarmStructureContext } from '../../_context/FarmStructureContext';
import { useAllGrowerFieldsSelector } from '../../_store/selectors/fieldsSelectors';

import ActivitiesAccordion from './ActivitiesAccordion';
import ActivitiesTable from './ActivitiesTable';

const ResponsiveActivities = ({ size }) => {
  const showTable = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const { fields } = useAllGrowerFieldsSelector();

  const { selectedFieldId } = useContext(FarmStructureContext);

  const filteredFields = useMemo(
    () =>
      selectedFieldId
        ? _.filter(fields, (field) => _.get(field, 'id') === selectedFieldId)
        : fields,
    [fields, selectedFieldId]
  );

  return (
    <>
      {!showTable && <ActivitiesAccordion fields={filteredFields} />}
      {showTable && <ActivitiesTable size={size} fields={filteredFields} />}
    </>
  );
};

export default ResponsiveActivities;
