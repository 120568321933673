import { addToken, client } from './client';

export const farmFeedbackService = {
  createFarmFeedbackV2,
  deleteFarmFeedbackV2,
  getFeedbackPhotoV2
};

async function createFarmFeedbackV2(
  token,
  growerId,
  seasonId,
  farmId,
  feedback
) {
  const response = await client.post(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/feedback`,
    feedback,
    addToken(token)
  );
  return response.data;
}

async function deleteFarmFeedbackV2(
  token,
  growerId,
  seasonId,
  farmId,
  feedbackId
) {
  await client.delete(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/feedback/${feedbackId}`,
    addToken(token)
  );
}

async function getFeedbackPhotoV2(
  token,
  growerId,
  seasonId,
  farmId,
  feedbackId,
  photoId
) {
  const response = await client.get(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/feedback/${feedbackId}/photos/${photoId}`,
    addToken(token, { responseType: 'blob' })
  );
  return response.data;
}
