import { useMemo } from 'react';

import { tankMixesConfigService } from '../../../../services/tankMixesConfig.service';
import { createApiSlice } from '../../utils/createApiSlice';

const updateTankMixSlice = createApiSlice({
  reducerPath: ['newConfig', 'tankMixes', 'updateTankMix'],
  apiFunction: tankMixesConfigService.updateTankMixV2,
  apiArgumentsFn: ({ tankMixId, tankMix }) => [tankMixId, tankMix]
});

const { actions, useApiSelectors } = updateTankMixSlice;

export const updateTankMixActions = actions;

export const useUpdateTankMixSelectors = () => {
  const { inProgress, success, errorMessage, response, updatedAt } =
    useApiSelectors();

  return useMemo(
    () => ({ inProgress, success, errorMessage, response, updatedAt }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default updateTankMixSlice;
