import { useMemo } from 'react';

import { growerProtocolsConfigService } from '../../../../../services/growerProtocolsConfig.service';
import { createApiSlice } from '../../../utils/createApiSlice';

const updateGrowerProtocolSlice = createApiSlice({
  reducerPath: ['gff', 'growers', 'protocols', 'updateGrowerProtocol'],
  apiFunction: growerProtocolsConfigService.updateProtocolV2,
  apiArgumentsFn: ({ growerId, bundleId, protocolId, protocol }) => [
    growerId,
    bundleId,
    protocolId,
    protocol
  ]
});

const { actions, useApiSelectors } = updateGrowerProtocolSlice;

export const updateGrowerProtocolActions = actions;

export const useUpdateGrowerProtocolSelectors = () => {
  const { inProgress, success, errorMessage, response, updatedAt } =
    useApiSelectors();

  return useMemo(
    () => ({ inProgress, success, errorMessage, response, updatedAt }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default updateGrowerProtocolSlice;
