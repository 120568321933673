import { useMemo } from 'react';

import { tankMixesConfigService } from '../../../../services/tankMixesConfig.service';
import { createApiSlice } from '../../utils/createApiSlice';

const deleteTankMixSlice = createApiSlice({
  reducerPath: ['newConfig', 'tankMixes', 'deleteTankMix'],
  apiFunction: tankMixesConfigService.deleteTankMixV2,
  apiArgumentsFn: ({ tankMixId }) => [tankMixId]
});

const { actions, useApiSelectors } = deleteTankMixSlice;

export const deleteTankMixActions = actions;

export const useDeleteTankMixSelectors = () => {
  const { inProgress, success, errorMessage, response, updatedAt } =
    useApiSelectors();

  return useMemo(
    () => ({ inProgress, success, errorMessage, response, updatedAt }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default deleteTankMixSlice;
