import addGrowerActivitySlice from './addGrowerActivitySlice';
import createGrowerProtocolSlice from './createGrowerProtocolSlice';
import deleteGrowerActivitySlice from './deleteGrowerActivitySlice';
import deleteGrowerProtocolSlice from './deleteGrowerProtocolSlice';
import updateGrowerActivitySlice from './updateGrowerActivitySlice';
import updateGrowerProtocolSlice from './updateGrowerProtocolSlice';

const growersProtocolsSlices = [
  createGrowerProtocolSlice,
  addGrowerActivitySlice,
  deleteGrowerActivitySlice,
  deleteGrowerProtocolSlice,
  updateGrowerActivitySlice,
  updateGrowerProtocolSlice
];

export default growersProtocolsSlices;
