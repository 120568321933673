import React from 'react';

import { growthStagesTypes } from '../../../_constants/growthsConstants';

import GrowthModelReport from './GrowthModelReport';

const reportGrowthStages = [
  growthStagesTypes.LateBoot,
  growthStagesTypes.Heading50X,
  growthStagesTypes.PhysiologicalMaturity,
  growthStagesTypes.Harvest,
  growthStagesTypes.Historical
];

const MidToFullSeasonGrowthModelReport = () => (
  <GrowthModelReport reportGrowthStages={reportGrowthStages} />
);

export default MidToFullSeasonGrowthModelReport;
