import React, { useCallback } from 'react';

import { DataGrid } from '@mui/x-data-grid';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import {
  reportsPageSizeActions,
  useReportsPageSizeSelectors
} from '../../_store/slices/reports/reportsPageSizeSlice';

import DataGridContainer from './DataGridContainer';
import DataGridToolbar from './DataGridToolbar';

const CommonDataGrid = ({ rows, columns, onVisibleRowIdsChange = _.noop }) => {
  const dispatch = useDispatch();

  const { pageSize } = useReportsPageSizeSelectors();

  const handlePageSizeChange = useCallback(
    (size) => {
      dispatch(reportsPageSizeActions.setPageSize(size));
    },
    [dispatch]
  );

  const handleStateChange = useCallback(
    (state) => {
      const rowsIds =
        _.get(state, 'visibleRows.visibleRows') ||
        _.get(state, 'rows.allRows', []);
      onVisibleRowIdsChange(rowsIds);
    },
    [onVisibleRowIdsChange]
  );

  return (
    <DataGridContainer>
      <DataGrid
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        pageSize={pageSize}
        onPageSizeChange={handlePageSizeChange}
        rowsPerPageOptions={[10, 25, 50, 100]}
        pagination
        components={{
          Toolbar: DataGridToolbar
        }}
        onStateChange={handleStateChange}
      />
    </DataGridContainer>
  );
};

export default CommonDataGrid;
