import React, { useMemo } from 'react';

import _ from 'lodash';
import moment from 'moment';

import TimelineRectangle from '../Charts/TimelineRectangle';
import { parseDate } from '../Growths/GrowthTimeline/_utils/growthTimelineUtils';

export const ActivityTypes = {
  CompletedRecommendation: 'CompletedRecommendation',
  ZonedActivity: 'ZonedActivity'
};

const CompletedActivitiesTimeline = ({
  svgWidth,
  barHeight,
  paddingTop,
  rectangleRadius,
  timelineGrowths,
  selectedTimeScale,
  onMouseOver = _.noop,
  onMouseOut = _.noop,
  onClick = _.noop
}) => {
  const growthRectangles = useMemo(() => {
    return _.map(timelineGrowths, (growth) => {
      const growthId = _.get(growth, 'id');
      const startY = _.get(growth, 'startY');

      const completedActivities = _.chain(growth)
        .get('completedActivities')
        .map((item) => ({
          ...item,
          activityDate: moment(_.get(item, 'dateCompleted')).format(
            'YYYY-MM-DD'
          ),
          activityType: ActivityTypes.CompletedRecommendation
        }))
        .value();

      const zonedActivities = _.chain(growth)
        .get('zonedActivities')
        .map((item) => ({
          ...item,
          activityDate: moment(_.get(item, 'date')).format('YYYY-MM-DD'),
          activityType: ActivityTypes.ZonedActivity
        }))
        .value();

      const activitiesByDate = _.groupBy(
        [...completedActivities, ...zonedActivities],
        'activityDate'
      );

      const rectangles = _.chain(activitiesByDate)
        .map((activities, startDate) => {
          const endDate = moment(startDate).add(1, 'day').format('YYYY-MM-DD');

          const x0 = selectedTimeScale(parseDate(startDate));
          const rectWidth =
            selectedTimeScale(parseDate(endDate)) -
            selectedTimeScale(parseDate(startDate));
          const x1 = x0 + rectWidth;

          const rangeX0 = selectedTimeScale.range()[0];
          const rangeX1 = selectedTimeScale.range()[1];

          const visibleX0 = x0 < rangeX0 ? rangeX0 : x0;
          const visibleX1 = x1 > rangeX1 ? rangeX1 : x1;
          const visibleMidX =
            x0 >= rangeX0
              ? (visibleX1 - visibleX0) / 2
              : rangeX0 - x0 + (visibleX1 - visibleX0) / 2;

          const y = startY + paddingTop;

          const height = barHeight;
          const color = 'red';
          const textColor = 'white';
          return {
            growthId,
            id: `${growthId}-${startDate}`,
            x: x0,
            y,
            width: rectWidth,
            height,
            color,
            textColor,
            dateCompleted: startDate,
            visibleMidX,
            activities
          };
        })
        .filter((rectangle) => rectangle.width > 0)
        .value();
      return { growthId, rectangles };
    });
  }, [barHeight, paddingTop, selectedTimeScale, timelineGrowths]);

  return (
    <g>
      {svgWidth > 0 && (
        <>
          {_.map(growthRectangles, (growth) => (
            <g key={_.get(growth, 'growthId')}>
              {_.map(_.get(growth, 'rectangles'), (rectangle) => (
                <TimelineRectangle
                  key={_.get(rectangle, 'id')}
                  rectangleRadius={rectangleRadius}
                  rectangle={rectangle}
                  onMouseOver={onMouseOver}
                  onMouseOut={onMouseOut}
                  onClick={onClick}
                />
              ))}
            </g>
          ))}
        </>
      )}
    </g>
  );
};

export default CompletedActivitiesTimeline;
