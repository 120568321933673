import { useMemo } from 'react';

import { faStar } from '@fortawesome/free-regular-svg-icons';

import { userRoles } from '../../_constants/userConstants';

const AddUserFeedbackPageKey = 'ADD_USER_FEEDBACK_PAGE';

const baseConfig = {
  key: AddUserFeedbackPageKey,
  icon: faStar,
  label: 'Share Feedback',
  path: 'share-feedback',
  hideFromMenu: true,
  allowedRoles: [
    userRoles.Admin,
    userRoles.Consultant,
    userRoles.User,
    userRoles.UserView
  ]
};

export const useAddUserFeedbackPageConfig = () => {
  return useMemo(() => ({ config: baseConfig }), []);
};
