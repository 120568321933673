import { useMemo } from 'react';

import _ from 'lodash';
import moment from 'moment';

import {
  getActivities,
  getCounceModel,
  getGrowthStages
} from '../../../_helpers/Growths/growthModelHelpers';
import { useGrowthsCropTechnologies } from '../../../_hooks/useCropTechnology';
import {
  useActivitiesConfig,
  useGrowthStagesConfig
} from '../../../_store/selectors/configSelectors';
import {
  useGrowthModelSelectors,
  useSelectedActivityTypesSelectors
} from '../../../_store/selectors/uiSelectors';
import { momentFormat } from '../../Growths/GrowthTimeline/_utils/growthTimelineUtils';

export function useGrowthsTimelineActivities({ inProgress, growths }) {
  const { activityTypes } = useActivitiesConfig();
  const { selectedActivityTypes } = useSelectedActivityTypesSelectors();

  const growthsTechnologies = useGrowthsCropTechnologies(growths);

  const { growthStagesConfig } = useGrowthStagesConfig();

  const { selectedGrowthModel } = useGrowthModelSelectors();

  const timelineActivities = useMemo(() => {
    if (inProgress || !growths) {
      return null;
    }

    return _.map(growths, (growth) => {
      const fieldName = _.get(growth, 'fieldName');
      const farmName = _.get(growth, 'farmName');
      const irrigationType = _.get(growth, 'irrigationType');
      const growerId = _.get(growth, 'growerId');
      const seasonId = _.get(growth, 'seasonId');
      const farmId = _.get(growth, 'farmId');
      const fieldId = _.get(growth, 'fieldId');
      const id = _.get(growth, 'id');

      const counceModel = getCounceModel(growth, selectedGrowthModel);

      const activities = _.chain(getActivities(growth, selectedGrowthModel))
        .filter(
          (activity) =>
            !!_.get(selectedActivityTypes, [_.get(activity, 'type')])
        )
        .map((activity) => ({
          ...activity,
          endDate: activity.endDate
            ? activity.endDate
            : moment(activity.date).add(1, 'day').format('YYYY-MM-DD'),
          backgroundColor: _.get(activityTypes, [activity.type, 'color'])
        }))
        .value();
      const activitiesByType = _.groupBy(activities, (activity) =>
        _.get(activity, 'type') === 'Drydown' ||
        _.get(activity, 'type') === 'MultipleAwd'
          ? 'Irrigation'
          : _.get(activity, 'type')
      );

      const growthStages = getGrowthStages(growth, selectedGrowthModel);

      const feedbackByStage = _.chain(growth)
        .get('feedback')
        .keyBy('stage')
        .value();

      const stages = _.map(growthStages, (stage) => {
        const growthStage = _.get(stage, 'growthStage');
        const backgroundColor = _.get(growthStagesConfig, [
          growthStage,
          'outlineColor'
        ]);
        const textColor = _.get(growthStagesConfig, [growthStage, 'textColor']);
        const growthStageName = _.get(growthStagesConfig, [
          growthStage,
          'name'
        ]);
        const start = moment(_.get(stage, 'date'));
        const end = moment(_.get(stage, 'endDate'));
        const startDate = start.format(momentFormat);
        const endDate = end
          ? moment(end).format(momentFormat)
          : start.add(10, 'days').format(momentFormat);

        const accumulatedGdd = _.get(stage, 'accumulatedGdd');
        const observed = _.get(stage, 'observed');

        return {
          growthStage,
          growthStageName: growthStageName,
          startDate: startDate,
          endDate: endDate,
          backgroundColor,
          textColor,
          observed,
          accumulatedGdd,
          dateModified: _.get(feedbackByStage, [growthStage, 'dateModified'])
        };
      });

      const activitiesStartDates = _.map(
        activities,
        (activity) => activity.date
      );

      const activitiesEndDates = _.map(
        activities,
        (activity) => activity.endDate
      );

      const growthModelStartDates = _.map(stages, (stage) => stage.startDate);

      const growthModelEndDates = _.map(stages, (stage) => stage.endDate);

      const allDates = [
        ...activitiesStartDates,
        ...activitiesEndDates,
        ...growthModelStartDates,
        ...growthModelEndDates
      ];

      const minDate = _.min(allDates);
      const maxDate = _.max(allDates);

      return {
        id,
        fieldName,
        farmName,
        irrigationType,
        growerId,
        seasonId,
        farmId,
        fieldId,
        activities,
        activitiesByType,
        stages,
        technology: _.get(growthsTechnologies, [id]),
        counceModel,
        minDate,
        maxDate
      };
    });
  }, [
    inProgress,
    growths,
    selectedGrowthModel,
    growthsTechnologies,
    selectedActivityTypes,
    activityTypes,
    growthStagesConfig
  ]);

  const minDate = useMemo(
    () =>
      _.chain(timelineActivities)
        .map((item) => _.get(item, 'minDate'))
        .min()
        .value(),
    [timelineActivities]
  );
  const maxDate = useMemo(
    () =>
      _.chain(timelineActivities)
        .map((item) => _.get(item, 'maxDate'))
        .max()
        .value(),
    [timelineActivities]
  );

  return useMemo(
    () => ({
      timelineActivities,
      minDate,
      maxDate
    }),
    [maxDate, minDate, timelineActivities]
  );
}
