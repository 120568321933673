import React from 'react';

import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';

const TextFormField = ({ field, path, ...props }) => {
  const { register, errors } = useFormContext();

  const label = _.get(field, 'label');
  const required = _.get(field, 'validation.required', false);

  return (
    <TextField
      {...props}
      inputRef={register({
        required: required ? `${label} is required` : undefined
      })}
      multiline
      required={required}
      id={path}
      label={label}
      name={path}
      error={!!_.get(errors, path)}
      helperText={_.get(errors, `${path}.message`)}
    />
  );
};

export default TextFormField;
