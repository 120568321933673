import React, { useCallback, useContext } from 'react';

import { Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CachedIcon from '@material-ui/icons/Cached';
import { useDispatch } from 'react-redux';

import { FarmStructureContext } from '../../_context/FarmStructureContext';
import { useGrowersDrawerState } from '../../_hooks/ui/useGrowersDrawerState';
import { usePathname } from '../../_hooks/usePathname';
import { forceUpdateFarmStructureAction } from '../../_store/actions/farmStructureActions';

import DrawerSeasonMenu from './DrawerSeasonMenu';
import GrowersDrawerContent from './GrowersDrawerContent';
import ReportsDrawerContent from './ReportsDrawerContent';

const useStyles = makeStyles((theme) => ({
  drawer: {},
  drawerPaper: {
    width: theme.dimensions.gffDrawerWidth,
    paddingTop: theme.dimensions.headerHeight,
    backgroundColor: 'rgb(46, 64, 87)'
  },
  scrollbar: {
    '& > .ps__rail-y > .ps__thumb-y': {
      backgroundColor: 'rgb(21, 28, 43, 1)'
    }
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '#D1D1D180 1px solid'
  },
  refreshIcon: { color: '#F8F8F8' }
}));

const GrowersDrawer = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const { pathname } = usePathname();

  const { growersDrawerOpen } = useGrowersDrawerState();

  const { loading } = useContext(FarmStructureContext);

  const isReportsPath = pathname.startsWith('/reports');

  const handleRefreshClick = useCallback(() => {
    dispatch(forceUpdateFarmStructureAction());
  }, [dispatch]);

  return (
    <Drawer
      open={growersDrawerOpen}
      anchor="right"
      className={styles.drawer}
      classes={{ paper: styles.drawerPaper }}
      variant="persistent"
      PaperProps={{ elevation: 10 }}
    >
      <Box className={styles.header}>
        <DrawerSeasonMenu />
        <Tooltip title="Refresh">
          <span>
            <IconButton
              className={styles.refreshIcon}
              disabled={loading}
              onClick={handleRefreshClick}
            >
              <CachedIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
      {!isReportsPath && <GrowersDrawerContent />}
      {isReportsPath && <ReportsDrawerContent />}
    </Drawer>
  );
};

export default GrowersDrawer;
