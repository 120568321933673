import { useMemo } from 'react';

import { faCompass } from '@fortawesome/free-regular-svg-icons';

import { userRoles } from '../../_constants/userConstants';

const ShapefilePageKey = 'SHAPEFILE_PAGE';

const baseConfig = {
  key: ShapefilePageKey,
  icon: faCompass,
  label: 'Shapefile Demo',
  path: 'shapefile-demo',
  allowedRoles: [userRoles.Admin]
};

export const useShapefilePageConfig = () => {
  return useMemo(() => ({ config: baseConfig }), []);
};
