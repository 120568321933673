import React, { useCallback } from 'react';

import _ from 'lodash';

import DrawerItem from './DrawerItem';

const GrowerDrawerItem = ({ grower, even, selected, onClick }) => {
  const farmsCount = _.get(grower, 'farmsCount');

  const handleClick = useCallback(() => {
    onClick(grower);
  }, [grower, onClick]);

  return (
    <DrawerItem
      item={grower}
      title={_.get(grower, 'name')}
      subtitle={`${farmsCount} Farm${farmsCount === 1 ? '' : 's'}`}
      even={even}
      onClick={handleClick}
      selected={selected}
    />
  );
};

export default GrowerDrawerItem;
