import { uiActionTypes } from '../actionTypes/uiActionTypes';

export const openCreateGrowthDialog = (
  growerId,
  seasonId,
  farmId,
  fieldId
) => ({
  type: uiActionTypes.OPEN_CREATE_GROWTH_DIALOG,
  payload: {
    growerId,
    seasonId,
    farmId,
    fieldId
  }
});

export const openModifyGrowthDialog = (
  growerId,
  seasonId,
  farmId,
  fieldId,
  growthId
) => ({
  type: uiActionTypes.OPEN_MODIFY_GROWTH_DIALOG,
  payload: {
    growerId,
    seasonId,
    farmId,
    fieldId,
    growthId
  }
});

export const closeCreateGrowthDialog = () => ({
  type: uiActionTypes.CLOSE_CREATE_GROWTH_DIALOG
});

export const openGrowthFeedbackDialog = (
  growerId,
  seasonId,
  farmId,
  fieldId,
  growthStage = null
) => ({
  type: uiActionTypes.OPEN_GROWTH_FEEDBACK_DIALOG,
  payload: {
    growerId,
    seasonId,
    farmId,
    fieldId,
    growthStage
  }
});

export const closeGrowthFeedbackDialog = () => ({
  type: uiActionTypes.CLOSE_GROWTH_FEEDBACK_DIALOG
});

export const selectGrowthModelTab = (tabId) => ({
  type: uiActionTypes.SELECT_GROWTH_MODEL_TAB,
  payload: {
    tabId
  }
});

export const openAddIrrigationActivityDialog = (
  growerId,
  seasonId,
  farmId,
  fieldId
) => ({
  type: uiActionTypes.OPEN_ADD_IRRIGATION_ACTIVITY_DIALOG,
  payload: {
    growerId,
    seasonId,
    farmId,
    fieldId
  }
});

export const openModifyIrrigationActivityDialog = (
  growerId,
  seasonId,
  farmId,
  fieldId,
  activityId
) => ({
  type: uiActionTypes.OPEN_MODIFY_IRRIGATION_ACTIVITY_DIALOG,
  payload: {
    growerId,
    seasonId,
    farmId,
    fieldId,
    activityId
  }
});

export const closeIrrigationActivityDialog = () => ({
  type: uiActionTypes.CLOSE_IRRIGATION_ACTIVITY_DIALOG
});

export const openAddFieldAccessibilityFeedbackDialog = (
  growerId,
  seasonId
) => ({
  type: uiActionTypes.OPEN_ADD_FIELD_ACCESSIBILITY_FEEDBACK_DIALOG,
  payload: {
    growerId,
    seasonId
  }
});

export const closeAddFieldAccessibilityFeedbackDialog = () => ({
  type: uiActionTypes.CLOSE_ADD_FIELD_ACCESSIBILITY_FEEDBACK_DIALOG
});

export const disableScroll = () => ({
  type: uiActionTypes.DISABLE_SCROLL
});

export const enableScroll = () => ({
  type: uiActionTypes.ENABLE_SCROLL
});

export const selectMapLayer = (mapLayerId) => ({
  type: uiActionTypes.SELECT_MAP_LAYER,
  payload: {
    mapLayerId
  }
});

export const toggleDetailsMapLayer = () => ({
  type: uiActionTypes.TOGGLE_DETAILS_MAP_LAYER
});

export const openSetTargetMoistureDialog = (
  growerId,
  seasonId,
  farmId,
  fieldId,
  growthId
) => ({
  type: uiActionTypes.OPEN_SET_TARGET_MOISTURE_DIALOG,
  payload: {
    growerId,
    seasonId,
    farmId,
    fieldId,
    growthId
  }
});

export const closeSetTargetMoistureDialog = () => ({
  type: uiActionTypes.CLOSE_SET_TARGET_MOISTURE_DIALOG
});

export const openAddMoistureSampleDialog = (
  growerId,
  seasonId,
  farmId,
  fieldId,
  growthId,
  sampleId
) => ({
  type: uiActionTypes.OPEN_ADD_MOISTURE_SAMPLE_DIALOG,
  payload: {
    growerId,
    seasonId,
    farmId,
    fieldId,
    growthId,
    sampleId
  }
});

export const closeAddMoistureSampleDialog = () => ({
  type: uiActionTypes.CLOSE_ADD_MOISTURE_SAMPLE_DIALOG
});

export const selectIrrigationView = (view) => ({
  type: uiActionTypes.SELECT_IRRIGATION_VIEW,
  payload: {
    view
  }
});

export const setWeatherParameters = (parameters) => ({
  type: uiActionTypes.SET_WEATHER_PARAMETERS,
  payload: {
    parameters
  }
});

export const toggleActivityAction = (type) => ({
  type: uiActionTypes.TOGGLE_ACTIVITY,
  payload: {
    type
  }
});

export const toggleUserFeedbackStatusAction = (type) => ({
  type: uiActionTypes.TOGGLE_USER_FEEDBACK_STATUS,
  payload: {
    type
  }
});

export const setMapActivityTypeAction = (type) => ({
  type: uiActionTypes.SET_MAP_ACTIVITY_TYPE,
  payload: {
    type
  }
});

export const toggleZoomToField = () => ({
  type: uiActionTypes.TOGGLE_ZOOM_TO_FIELD
});

export const selectGrowthModel = (growthModel) => ({
  type: uiActionTypes.SELECT_GROWTH_MODEL,
  payload: { growthModel }
});
