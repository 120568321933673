import React, { useCallback, useContext, useMemo, useState } from 'react';

import * as d3 from 'd3';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { FarmStructureContext } from '../../../_context/FarmStructureContext';
import { openGrowthFeedbackDialog } from '../../../_store/actions/uiActions';
import { useTimelineFieldNamesWidth } from '../../Charts/_hooks/useTimelineFieldNamesWidth';
import ChartTimeAxis from '../../Charts/ChartTimeAxis';
import ChartTodayLine from '../../Charts/ChartTodayLine';
import TimelineFieldNames from '../../Charts/TimelineFieldNames';

import { parseDate } from './_utils/growthTimelineUtils';
import GrowthStagePopover from './GrowthStagePopover';
import GrowthStagesTimeline from './GrowthStagesTimeline';
import TechnologyPopover from './TechnologyPopover';

const regularTimelineBarHeight = 25;
const smallTimelineBarHeight = 16;
const regularTimelineGap = 6;
const smallTimelineGap = 6;
const regularScaleMargin = 20;
const smallScaleMargin = 0;
const regularScaleHeight = 40;
const smallScaleHeight = 25;
const todayLineExcess = 10;

const GrowthTimelineChart = ({
  timelineGrowths,
  containerWidth,
  minDate,
  maxDate,
  rangeSelection,
  small = false
}) => {
  const dispatch = useDispatch();

  const timelineBarHeight = small
    ? smallTimelineBarHeight
    : regularTimelineBarHeight;
  const timelineGap = small ? smallTimelineGap : regularTimelineGap;
  const scaleMargin = small ? smallScaleMargin : regularScaleMargin;
  const scaleHeight = small ? smallScaleHeight : regularScaleHeight;

  const [clipPathId] = useState(`growthTimelineClipPath-${uuid()}`);

  const [hoverParams, setHoverParams] = useState();
  const [technologyHoverParams, setTechnologyHoverParams] = useState();

  const { selectField } = useContext(FarmStructureContext);

  const { fieldNamesWidth } = useTimelineFieldNamesWidth(containerWidth);

  const growthsCount = _.get(timelineGrowths, 'length', 0);

  const fieldNames = useMemo(
    () =>
      _.map(timelineGrowths, (growth) => ({
        id: _.get(growth, 'id'),
        fieldName: _.get(growth, 'fieldName'),
        farmName: _.get(growth, 'farmName'),
        technology: _.get(growth, 'technology')
      })),
    [timelineGrowths]
  );

  const timelinesHeight = useMemo(() => {
    const h = growthsCount * (timelineBarHeight + timelineGap) - timelineGap;
    return h > 0 ? h : 0;
  }, [growthsCount, timelineBarHeight, timelineGap]);

  const height = useMemo(
    () => timelinesHeight + 2 * todayLineExcess + scaleMargin + scaleHeight,
    [scaleHeight, scaleMargin, timelinesHeight]
  );

  const timeScale = useMemo(
    () =>
      d3
        .scaleTime()
        .domain([parseDate(minDate), parseDate(maxDate)])
        .range([fieldNamesWidth, containerWidth]),
    [minDate, maxDate, fieldNamesWidth, containerWidth]
  );

  const selectedTimeScale = useMemo(() => {
    if (
      rangeSelection &&
      rangeSelection.length === 2 &&
      !isNaN(rangeSelection[0]) &&
      !isNaN(rangeSelection[1])
    ) {
      return timeScale.copy().domain(rangeSelection);
    }
    return timeScale;
  }, [rangeSelection, timeScale]);

  const handleMouseOver = useCallback((params) => {
    setHoverParams(params);
  }, []);

  const handleMouseOut = useCallback(() => {
    setHoverParams((params) => ({ ...params, element: null }));
  }, []);

  const handleClick = useCallback(
    (rectangle) => {
      const growthStage = _.get(rectangle, 'growthStage');
      const canEditFeedback = _.get(rectangle, 'canEditFeedback');

      if (!canEditFeedback) {
        return;
      }

      const growth = _.find(
        timelineGrowths,
        (growth) => _.get(growth, 'id') === _.get(rectangle, 'growthId')
      );

      const growerId = _.get(growth, 'growerId');
      const seasonId = _.get(growth, 'seasonId');
      const farmId = _.get(growth, 'farmId');
      const fieldId = _.get(growth, 'fieldId');

      selectField(growerId, seasonId, farmId, fieldId);
      dispatch(
        openGrowthFeedbackDialog(
          growerId,
          seasonId,
          farmId,
          fieldId,
          growthStage
        )
      );
    },
    [dispatch, selectField, timelineGrowths]
  );

  const handleTechnologyMouseOver = useCallback((params) => {
    setTechnologyHoverParams(params);
  }, []);

  const handleTechnologyMouseOut = useCallback(() => {
    setTechnologyHoverParams((params) => ({ ...params, element: null }));
  }, []);

  return (
    <>
      <svg width={containerWidth} height={height}>
        <TimelineFieldNames
          fieldNames={fieldNames}
          paddingTop={todayLineExcess}
          barHeight={timelineBarHeight}
          barGap={timelineGap}
          fieldNamesWidth={fieldNamesWidth}
          showTechnology
          onMouseOver={handleTechnologyMouseOver}
          onMouseOut={handleTechnologyMouseOut}
        />
        <ChartTimeAxis
          paddingTop={height - scaleHeight}
          selectedTimeScale={selectedTimeScale}
        />
        <g clipPath={`url(#${clipPathId})`}>
          <GrowthStagesTimeline
            svgWidth={containerWidth}
            paddingTop={todayLineExcess}
            barHeight={timelineBarHeight}
            barGap={timelineGap}
            rectangleRadius={3}
            timelineGrowths={timelineGrowths}
            selectedTimeScale={selectedTimeScale}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onClick={handleClick}
          />
          <ChartTodayLine
            paddingTop={0}
            height={timelinesHeight + 2 * todayLineExcess}
            selectedTimeScale={selectedTimeScale}
            lineWidth={2}
            roundToDay
          />
        </g>
        <clipPath id={clipPathId}>
          <rect
            x={fieldNamesWidth}
            y={0}
            width={containerWidth - fieldNamesWidth}
            height={timelinesHeight + 2 * todayLineExcess}
          />
        </clipPath>
      </svg>
      <GrowthStagePopover hoverParams={hoverParams} />
      <TechnologyPopover hoverParams={technologyHoverParams} />
    </>
  );
};

export default GrowthTimelineChart;
