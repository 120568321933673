import React, { useContext } from 'react';

import { Alert } from '@material-ui/lab';

import { FarmStructureContext } from '../../../_context/FarmStructureContext';
import { useFieldsWithGrowthSelector } from '../../../_store/selectors/fieldsSelectors';
import LoadingIndicator from '../../../Components/LoadingIndicator';
import { ContentBody, FormContainer } from '../../../Layout/Content';

import FarmCropManagement from './FarmCropManagement';

const GrowthsTab = () => {
  const { selectedGrowerId, selectedSeasonId, selectedFarmId, selectedFarm } =
    useContext(FarmStructureContext);

  const { inProgress: fieldsInProgress } = useFieldsWithGrowthSelector(
    selectedGrowerId,
    selectedSeasonId,
    selectedFarmId
  );

  return (
    <>
      {!selectedFarmId && !fieldsInProgress && (
        <FormContainer>
          <ContentBody>
            <Alert severity="info">Please select a farm</Alert>
          </ContentBody>
        </FormContainer>
      )}
      {fieldsInProgress && (
        <FormContainer>
          <ContentBody>
            <LoadingIndicator />
          </ContentBody>
        </FormContainer>
      )}
      {selectedFarm && !fieldsInProgress && <FarmCropManagement />}
    </>
  );
};

export default GrowthsTab;
