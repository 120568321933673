import { useMemo } from 'react';

import { faFaucet } from '@fortawesome/free-solid-svg-icons';

import { userRoles } from '../../_constants/userConstants';

const IrrigationDashboardPageKey = 'IRRIGATION_DASHBOARD_PAGE';

const baseConfig = {
  key: IrrigationDashboardPageKey,
  icon: faFaucet,
  label: 'Irrigation Dashboard',
  path: 'irrigationDashboard',
  allowedRoles: [
    userRoles.Admin,
    userRoles.Consultant,
    userRoles.User,
    userRoles.UserView
  ]
};
export const useIrrigationDashboardPageConfig = () => {
  return useMemo(() => ({ config: baseConfig }), []);
};
