import React from 'react';

import { Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import PieChartIcon from '@material-ui/icons/PieChart';
import Alert from '@material-ui/lab/Alert';

import LoadingIndicator from '../../../Components/LoadingIndicator';
import {
  ContentBody,
  ContentContainer,
  ContentHeader,
  ResponsiveHeaderButtonsContainer
} from '../../../Layout/Content';

import { useGrowthModelReportWithDistricts } from './_hooks/useGrowthModelReportWithDistricts';
import { useReportsChartsControls } from './_hooks/useReportsChartsControls';
import GrowthModelReportChart from './GrowthModelReportChart';
import GrowthModelReportTable from './GrowthModelReportTable';

const GrowthModelReport = ({ reportGrowthStages }) => {
  const { inProgress, errorMessage, response } =
    useGrowthModelReportWithDistricts();

  const { showChart, toggleChart, visibleRows, handleVisibleRowIdsChange } =
    useReportsChartsControls(response);

  return (
    <ContentContainer>
      <ContentHeader>
        <span>Growth Stages Report</span>
        <ResponsiveHeaderButtonsContainer>
          <Tooltip title="Show Growth Stages Chart">
            <Button
              onClick={toggleChart}
              startIcon={<PieChartIcon />}
              color={showChart ? 'primary' : 'default'}
              variant="contained"
              disableElevation
              size="small"
            >
              Chart
            </Button>
          </Tooltip>
        </ResponsiveHeaderButtonsContainer>
      </ContentHeader>
      <ContentBody>
        {inProgress && <LoadingIndicator />}
        {!inProgress && errorMessage && (
          <Alert severity="error">{errorMessage}</Alert>
        )}
        {!inProgress && !errorMessage && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={showChart ? 8 : 12} xl={showChart ? 8 : 12}>
              <GrowthModelReportTable
                reportGrowthStages={reportGrowthStages}
                growthModelReportResponse={response}
                onVisibleRowIdsChange={handleVisibleRowIdsChange}
              />
            </Grid>
            {showChart && (
              <Grid item xs={12} md={4} xl={4}>
                <GrowthModelReportChart
                  fields={visibleRows}
                  reportGrowthStages={reportGrowthStages}
                />
              </Grid>
            )}
          </Grid>
        )}
      </ContentBody>
    </ContentContainer>
  );
};

export default GrowthModelReport;
