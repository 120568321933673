import React from 'react';

import Grid from '@material-ui/core/Grid';
import _ from 'lodash';

import FormField from '../../DynamicForms/FormFields/FormField';

const ActivityFeedbackDynamicForm = ({ form }) => {
  const fields = _.get(form, 'formFields');

  return (
    <>
      {_.map(fields, (field) => (
        <Grid item xs={12} key={_.get(field, 'id')}>
          <FormField
            field={field}
            prefix="formValues"
            variant="standard"
            fullWidth
          />
        </Grid>
      ))}
    </>
  );
};

export default ActivityFeedbackDynamicForm;
