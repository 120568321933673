import React, { useMemo } from 'react';

import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';
import moment from 'moment';

import {
  WorkOrderPayloadType,
  WorkOrderStatus,
  WorkOrderType
} from '../../_constants/workOrderConstants';
import { activityTypes } from '../../_store/selectors/configSelectors';
import { useWorkOrderParams } from '../../Pages/Map/Components/hooks/useWorkOrderParams';
import FeedbackTargets from '../Activities/FeedbackTargets';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    flexWrap: 'nowrap',
    '& > :not(:first-child)': {
      marginLeft: '0.75rem'
    },
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    paddingTop: '0.5rem',
    paddingBottom: '1rem'
  },
  card: {
    flex: '0 0 auto'
  },
  title: {
    textAlign: 'center'
  },
  description: {
    marginTop: '0.25rem',
    fontWeight: 450
  },
  eventName: {
    marginTop: '0.25rem',
    fontWeight: 450
  },
  targetsContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  fieldsTitle: { marginTop: '1rem', marginBottom: '0.5rem', fontWeight: '450' }
}));

const Note = ({ note }) => {
  const dateCreated = useMemo(
    () => moment(_.get(note, 'dateCreated')).format('lll'),
    [note]
  );
  const userName = _.get(note, 'createdBy.userName');

  return (
    <Box borderBottom="#D5D5D5 1px solid" marginBottom="1rem">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        marginBottom="0.25rem"
      >
        <Typography style={{ fontWeight: 450 }}>{dateCreated}</Typography>
        <Typography>{userName}</Typography>
      </Box>
      <Box marginBottom="0.25rem">
        <Typography>{_.get(note, 'note')}</Typography>
      </Box>
    </Box>
  );
};

const Row = ({ title, value }) => {
  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <Typography style={{ marginRight: '0.5rem' }}>{title}:</Typography>
      <Typography>
        <b>{value}</b>
      </Typography>
    </Box>
  );
};

const FarmWorkOrderDetails = ({ workOrder }) => {
  const styles = useStyles();

  const {
    type,
    description,
    dueDate,
    dateCreated,
    createdBy,
    dateCompleted,
    completedBy,
    status,
    payloadType,
    payload,
    notes
  } = useWorkOrderParams(workOrder);

  const targets = _.chain(workOrder).get('targetsByFieldId').values().value();

  const typeName =
    type === WorkOrderType.Recommendation
      ? _.get(activityTypes, [_.get(workOrder, 'growthActivityType'), 'name'])
      : _.get(WorkOrderPayloadType, [payloadType, 'name']);

  return (
    <Box>
      <Row title="Status" value={_.get(WorkOrderStatus, [status, 'name'])} />
      {!!typeName && <Row title="Type" value={typeName} />}
      {!!dueDate && <Row title="Due Date" value={dueDate} />}
      {(payloadType === WorkOrderPayloadType.NitrogenApplication.key ||
        payloadType === WorkOrderPayloadType.HerbicideApplication.key ||
        payloadType === WorkOrderPayloadType.FungicideApplication.key) && (
        <>
          <Row title="Typography" value={_.get(payload, 'product')} />
          <Row title="Application Rate" value={_.get(payload, 'rate')} />
        </>
      )}
      {!_.isEmpty(description) && (
        <Row title="Description" value={description} />
      )}
      {!!dateCreated && <Row title="Creation Date" value={dateCreated} />}
      {!!createdBy && <Row title=" Created by" value={createdBy} />}
      {!!dateCompleted && <Row title="Completion Date" value={dateCompleted} />}
      {!!completedBy && <Row title=" Completed by" value={completedBy} />}
      <Typography className={styles.fieldsTitle}>Fields / Zones:</Typography>
      <Box className={styles.targetsContainer}>
        <FeedbackTargets targets={targets} />
      </Box>
      {!_.isEmpty(notes) && (
        <>
          <Typography className={styles.fieldsTitle}>Notes</Typography>
          <Box className={styles.targetsContainer}>
            {_.map(notes, (note) => (
              <Note key={_.get(note, 'id')} note={note} />
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export default FarmWorkOrderDetails;
