import React, { useContext, useMemo } from 'react';

import { faCloudSun } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

import { userRoles } from '../../_constants/userConstants';
import { AuthContext } from '../../_context/AuthContext';
import { FarmStructureContext } from '../../_context/FarmStructureContext';
import { PagesConfigContext } from '../../_context/PagesConfigContext';

import MenuSection from './MenuSection';

const WEATHER_STATION_URL = 'https://ambientweather.net/dashboard';

export const getWeatherStationItem = (weatherKey) => ({
  icon: faCloudSun,
  label: 'Weather Station',
  path: `${WEATHER_STATION_URL}/${weatherKey}`,
  allowedRoles: [
    userRoles.Admin,
    userRoles.Consultant,
    userRoles.User,
    userRoles.UserView
  ],
  target: '_blank'
});

const AppNav = ({ collapsedSideMenuHover }) => {
  const { hasRole } = useContext(AuthContext);

  const { selectedGrower } = useContext(FarmStructureContext);

  const weatherKey = _.get(selectedGrower, 'ambientWeatherKey');

  const { pagesConfig } = useContext(PagesConfigContext);

  const navItems = useMemo(() => {
    const items = _.isEmpty(weatherKey)
      ? pagesConfig
      : [...pagesConfig, getWeatherStationItem(weatherKey)];
    return _.filter(
      items,
      (navItem) =>
        _.some(navItem.allowedRoles, (role) => hasRole(role)) &&
        !_.get(navItem, 'hideFromMenu')
    );
  }, [hasRole, pagesConfig, weatherKey]);

  const userMenuItems = useMemo(
    () =>
      _.filter(navItems, (item) =>
        _.includes(_.get(item, 'allowedRoles'), userRoles.User)
      ),
    [navItems]
  );

  const adminMenuItems = useMemo(
    () =>
      _.filter(
        navItems,
        (item) => !_.includes(_.get(item, 'allowedRoles'), userRoles.User)
      ),
    [navItems]
  );

  return (
    <>
      <MenuSection
        title="Menu"
        menuItems={userMenuItems}
        collapsedSideMenuHover={collapsedSideMenuHover}
      />
      {!_.isEmpty(adminMenuItems) && (
        <MenuSection
          title="Admin Menu"
          menuItems={adminMenuItems}
          collapsedSideMenuHover={collapsedSideMenuHover}
        />
      )}
    </>
  );
};

export default AppNav;
