import { useMemo } from 'react';

import { faCogs } from '@fortawesome/free-solid-svg-icons';

import { userRoles } from '../../_constants/userConstants';

const GrowthModelApiAdminPageKey = 'GROWTH_MODEL_API_ADMIN_PAGE';

const baseConfig = {
  key: GrowthModelApiAdminPageKey,
  icon: faCogs,
  label: 'Growth Model API',
  path: 'growth-model-api',
  allowedRoles: [userRoles.Admin]
};

export const useGrowthModelApiAdminPageConfig = () => {
  return useMemo(() => ({ config: baseConfig }), []);
};
