import { addToken, client } from './client';

export const growerBundlesConfigService = {
  createBundleV2,
  updateBundleV2,
  deleteBundleV2,
  setDefaultBundleV2,
  resetDefaultBundleV2,
  cloneBundleV2,
  cloneGlobalBundleV2
};

async function createBundleV2(token, growerId, bundle) {
  const response = await client.post(
    `v2/growers/${growerId}/bundles`,
    bundle,
    addToken(token)
  );
  return response.data;
}

async function updateBundleV2(token, growerId, bundleId, bundle) {
  const response = await client.patch(
    `v2/growers/${growerId}/bundles/${bundleId}`,
    bundle,
    addToken(token)
  );
  return response.data;
}

async function deleteBundleV2(token, growerId, bundleId) {
  const response = await client.delete(
    `v2/growers/${growerId}/bundles/${bundleId}`,
    addToken(token)
  );
  return response.data;
}

async function setDefaultBundleV2(token, growerId, bundleId) {
  const response = await client.post(
    `v2/growers/${growerId}/bundles/${bundleId}/set-default`,
    {},
    addToken(token)
  );
  return response.data;
}

async function resetDefaultBundleV2(token, growerId) {
  const response = await client.post(
    `v2/growers/${growerId}/bundles/reset-default`,
    {},
    addToken(token)
  );
  return response.data;
}

async function cloneBundleV2(token, growerId, bundleId, bundle) {
  const response = await client.post(
    `v2/growers/${growerId}/bundles/${bundleId}/clone`,
    bundle,
    addToken(token)
  );
  return response.data;
}

async function cloneGlobalBundleV2(token, growerId, cloneBundle) {
  const response = await client.post(
    `v2/growers/${growerId}/bundles/clone-global`,
    cloneBundle,
    addToken(token)
  );
  return response.data;
}
