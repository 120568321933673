import React, { useCallback } from 'react';

import { faLaptopHouse } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

import { faObservedGrowthStageIcon } from '../Growths/faObservedGrowthStageIcon';
import { faPendingGrowthStageIcon } from '../Growths/faPendingGrowthStageIcon';

const minObservedIconMargin = 3;
const minObservedIconSize = 6;

const TimelineRectangle = ({
  rectangleRadius,
  rectangle,
  onClick = _.noop,
  onMouseOver = _.noop,
  onMouseOut = _.noop,
  disablePointerEvents = false
}) => {
  const handleClick = useCallback(() => {
    onClick(rectangle);
  }, [onClick, rectangle]);

  const handleMouseEnter = useCallback(
    (event) => {
      onMouseOver({ element: event.currentTarget, data: rectangle });
    },
    [onMouseOver, rectangle]
  );

  const handleMouseLeave = useCallback(() => {
    onMouseOut();
  }, [onMouseOut]);

  const observed = _.get(rectangle, 'observed');
  const pending = _.get(rectangle, 'pending');
  const hasWorkOrder = !!_.get(rectangle, 'workOrder');

  const rectangleWidth = _.get(rectangle, 'width');
  const rectangleHeight = _.get(rectangle, 'height');
  const canEditFeedback = _.get(rectangle, 'canEditFeedback');

  const smallerDimension = Math.min(rectangleWidth, rectangleHeight);
  const margin = Math.max(minObservedIconMargin, 0.4 * smallerDimension - 5);

  const observedIconSize = smallerDimension - 2 * margin;
  const observedIconX =
    _.get(rectangle, 'x') +
    Math.min((rectangleWidth - observedIconSize) / 2, margin);
  const observedIconY =
    _.get(rectangle, 'y') + (rectangleHeight - observedIconSize) / 2;
  const showObservedIcon =
    !!observed && !pending && observedIconSize > minObservedIconSize;
  const showPendingIcon = !!pending && observedIconSize > minObservedIconSize;
  const showWorkOrderIcon =
    !observed &&
    !pending &&
    hasWorkOrder &&
    observedIconSize > minObservedIconSize;

  return (
    <g>
      <rect
        rx={rectangleRadius}
        ry={rectangleRadius}
        x={_.get(rectangle, 'x')}
        y={_.get(rectangle, 'y')}
        width={rectangleWidth}
        height={rectangleHeight}
        fill={_.get(rectangle, 'color')}
        style={{
          cursor: canEditFeedback ? 'pointer' : 'default'
        }}
        stroke="none"
        pointerEvents={disablePointerEvents ? 'none' : 'default'}
        onMouseEnter={!disablePointerEvents ? handleMouseEnter : undefined}
        onMouseLeave={!disablePointerEvents ? handleMouseLeave : undefined}
        onClick={!disablePointerEvents ? handleClick : undefined}
      />
      {(showObservedIcon || showPendingIcon || showWorkOrderIcon) && (
        <FontAwesomeIcon
          icon={
            showPendingIcon
              ? faPendingGrowthStageIcon
              : showWorkOrderIcon
              ? faLaptopHouse
              : faObservedGrowthStageIcon
          }
          width={observedIconSize}
          height={observedIconSize}
          x={observedIconX}
          y={observedIconY}
          color={_.get(rectangle, 'textColor')}
          pointerEvents={'none'}
        />
      )}
    </g>
  );
};

export default TimelineRectangle;
