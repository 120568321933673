import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import EditIcon from '@material-ui/icons/Edit';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import {
  aquariusGrowerDevicesTypesByDeviceType,
  growerDevicesVendors
} from '../../../_constants/growerDevicesConstants';
import { AuthContext } from '../../../_context/AuthContext';
import {
  removeSeasonDeviceActions,
  useRemoveSeasonDeviceSelectors
} from '../../../_store/slices/devices/seasonDevices/removeSeasonDeviceSlice';
import { getSeasonsActions } from '../../../_store/slices/gff/seasons/getSeasonsSlice';
import { ResponsiveDataContainer } from '../../../Components/Configuration/ResponsiveDataContainer';
import WeatherStationIcon from '../../../Components/Devices/DeviceIcon/Icons/WeatherStationIcon';

const headers = ['', 'Type', 'Device ID', 'Name'];

export default function SeasonDevicesList({
  growerId,
  seasonId,
  season,
  onSelectDeviceToEdit
}) {
  const dispatch = useDispatch();

  const { isAdmin } = useContext(AuthContext);

  const [deviceToDeleteId, setDeviceToDeleteId] = useState();

  const devices = _.get(season, 'devices');

  const deviceToDelete = useMemo(() => {
    return _.find(devices, (device) => device.id === deviceToDeleteId);
  }, [deviceToDeleteId, devices]);

  const {
    inProgress: deleteInProgress,
    success: deleteSuccess,
    errorMessage: deleteError
  } = useRemoveSeasonDeviceSelectors();
  const [deleteModalText, setDeleteModalText] = useState('');

  useEffect(() => {
    if (deleteSuccess) {
      dispatch(getSeasonsActions.request(growerId));
      dispatch(removeSeasonDeviceActions.clear());
      setDeviceToDeleteId(undefined);
    }
  }, [deleteSuccess, dispatch, growerId]);

  // This is a workaround to make sure the modal content is not changed when the closing animation is being displayed
  useEffect(() => {
    if (deviceToDelete) {
      setDeleteModalText(
        `Are you sure you want to delete Device with ID ${_.get(
          deviceToDelete,
          'deviceId',
          ''
        )}?`
      );
    }
  }, [deviceToDelete]);

  const handleEditClick = useCallback(
    (id) => {
      onSelectDeviceToEdit(id);
    },
    [onSelectDeviceToEdit]
  );

  const normalizedData = useMemo(
    () =>
      _.map(devices, (device) => {
        const id = _.get(device, 'id');
        const vendor = _.get(device, 'vendor');
        const deviceType = _.get(device, 'deviceType');
        const deviceId =
          vendor === growerDevicesVendors.Ambient.type
            ? _.get(device, 'macAddress')
            : _.get(device, 'deviceId');
        return {
          id: id,
          values: [
            vendor === growerDevicesVendors.Aquarius.type ? (
              _.get(aquariusGrowerDevicesTypesByDeviceType, [
                deviceType,
                'icon'
              ])
            ) : (
              <WeatherStationIcon size={40} />
            ),
            vendor === growerDevicesVendors.Aquarius.type
              ? _.get(aquariusGrowerDevicesTypesByDeviceType, [
                  deviceType,
                  'name'
                ])
              : 'Weather Station',
            deviceId,
            _.get(device, 'name')
          ],
          customButtons: [
            {
              onClick: () => handleEditClick(id),
              icon: <EditIcon />,
              description: 'Edit Device'
            }
          ],
          deleteButtonDescription: 'Delete Device'
        };
      }),
    [devices, handleEditClick]
  );

  const handleCancelDelete = useCallback(() => {
    dispatch(removeSeasonDeviceActions.clear());
    setDeviceToDeleteId(undefined);
  }, [dispatch]);

  const handleConfirmDelete = useCallback(() => {
    dispatch(
      removeSeasonDeviceActions.request(growerId, seasonId, deviceToDeleteId)
    );
  }, [deviceToDeleteId, dispatch, growerId, seasonId]);

  const handleSelectGrowerToDelete = useCallback((deviceId) => {
    setDeviceToDeleteId(deviceId);
  }, []);

  return (
    <ResponsiveDataContainer
      isSuccess
      headers={headers}
      data={normalizedData}
      deleteConfirmationOpen={!!deviceToDeleteId}
      deleteInProgress={deleteInProgress}
      deleteError={deleteError}
      deleteModalTitle="Delete Device?"
      deleteModalText={deleteModalText}
      onDeleteCancel={handleCancelDelete}
      onDeleteConfirm={handleConfirmDelete}
      onSelectItemToDelete={isAdmin ? handleSelectGrowerToDelete : undefined}
    />
  );
}
