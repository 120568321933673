import addActivitySlice from './addActivitySlice';
import addWorkOrderSlice from './addWorkOrderSlice';
import cropDashboardSlice from './cropDashboardSlice';
import dialogsSlices from './dialogs';
import growersConfigSlice from './growersConfigSlice';
import growersDrawerSlice from './growersDrawerSlice';
import irrigationDashboardSlice from './irrigationDashboardSlice';
import managementZonesSlice from './managementZonesSlice';
import menuSlice from './menuSlice';
import pagingSlice from './pagingSlice';
import redirectSlice from './redirectSlice';
import uploadShapefileSlice from './uploadShapefileSlice';
import weatherSlices from './weather';
import workOrdersFiltersSlice from './workOrdersFiltersSlice';

const uiSlices = [
  dialogsSlices,
  growersDrawerSlice,
  menuSlice,
  redirectSlice,
  cropDashboardSlice,
  pagingSlice,
  growersConfigSlice,
  irrigationDashboardSlice,
  managementZonesSlice,
  addActivitySlice,
  uploadShapefileSlice,
  weatherSlices,
  addWorkOrderSlice,
  workOrdersFiltersSlice
];

export default uiSlices;
