import React, { useCallback, useRef, useState } from 'react';

import { ButtonGroup } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

import ChartDownloadButton from '../../../Components/Charts/ChartDownloadButton';

import HarvestYieldGroupedReportChart from './HarvestYieldGroupedReportChart';
import HarvestYieldHistogram from './HarvestYieldHistogram';
import ReportsChartContainer from './ReportsChartContainer';

const ChartTypes = {
  ByAcres: 'Acres',
  ByTechnology: 'ByTechnology',
  ByVariety: 'ByVariety'
};

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    '& > :not(:first-child)': {
      marginLeft: '0.5rem'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      '& > :not(:first-child)': {
        marginTop: '0.5rem',
        marginLeft: '0'
      }
    }
  }
}));

function HarvestYieldReportChart({ fields }) {
  const styles = useStyles();

  const [chartType, setChartType] = useState(ChartTypes.ByAcres);

  const byAcresSelected = chartType === ChartTypes.ByAcres;
  const byTechnologySelected = chartType === ChartTypes.ByTechnology;
  const byVarietySelected = chartType === ChartTypes.ByVariety;

  const chartRef = useRef(null);

  const handleByAcresClick = useCallback(() => {
    setChartType(ChartTypes.ByAcres);
  }, []);

  const handleByTechnologyClick = useCallback(() => {
    setChartType(ChartTypes.ByTechnology);
  }, []);

  const handleByVarietyClick = useCallback(() => {
    setChartType(ChartTypes.ByVariety);
  }, []);

  return (
    <ReportsChartContainer
      header={
        <Box className={styles.buttonsContainer}>
          <ChartDownloadButton
            chartRef={chartRef}
            fileName={`Yield-by-${
              byAcresSelected
                ? 'Acres'
                : byTechnologySelected
                ? 'Technology'
                : 'Variety'
            }-Chart.png`}
            size="small"
            color="primary"
            variant="contained"
            disableElevation
          />
          <ButtonGroup size="small" variant="contained" disableElevation>
            <Button
              onClick={handleByAcresClick}
              color={byAcresSelected ? 'primary' : 'default'}
            >
              By Acres
            </Button>
            <Button
              onClick={handleByTechnologyClick}
              color={byTechnologySelected ? 'primary' : 'default'}
            >
              By Technology
            </Button>
            <Button
              onClick={handleByVarietyClick}
              color={byVarietySelected ? 'primary' : 'default'}
            >
              By Variety
            </Button>
          </ButtonGroup>
        </Box>
      }
    >
      {byAcresSelected && (
        <HarvestYieldHistogram chartRef={chartRef} fields={fields} />
      )}
      {byTechnologySelected && (
        <HarvestYieldGroupedReportChart
          chartRef={chartRef}
          fields={fields}
          groupByKey="technologyName"
          groupName="Technology"
        />
      )}
      {byVarietySelected && (
        <HarvestYieldGroupedReportChart
          chartRef={chartRef}
          fields={fields}
          groupByKey="varietyName"
          groupName="Variety"
        />
      )}
    </ReportsChartContainer>
  );
}

export default HarvestYieldReportChart;
