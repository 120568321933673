import { useMemo } from 'react';

import { bundlesConfigService } from '../../../../services/bundlesConfig.service';
import { createApiSlice } from '../../utils/createApiSlice';

const deleteBundleSlice = createApiSlice({
  reducerPath: ['newConfig', 'bundles', 'deleteBundle'],
  apiFunction: bundlesConfigService.deleteBundleV2,
  apiArgumentsFn: ({ bundleId }) => [bundleId]
});

const { actions, useApiSelectors } = deleteBundleSlice;

export const deleteBundleActions = actions;

export const useDeleteBundleSelectors = () => {
  const { inProgress, success, errorMessage, response, deletedAt } =
    useApiSelectors();

  return useMemo(
    () => ({ inProgress, success, errorMessage, response, deletedAt }),
    [errorMessage, inProgress, response, success, deletedAt]
  );
};

export default deleteBundleSlice;
