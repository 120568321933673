import React, { useCallback, useEffect } from 'react';

import MomentUtils from '@date-io/moment';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import _ from 'lodash';
import { useForm } from 'react-hook-form';

import { useVarietiesConfig } from '../../../_store/selectors/configSelectors';
import VarietyDropdown from '../../../Components/Growths/VarietyDropdown';
import { growthConfig } from '../../../config';

const useStyles = makeStyles(() => ({
  form: {
    width: '100%'
  }
}));

const SeasonGrowthForm = React.forwardRef(function SeasonGrowthForm(
  { season, onFormSubmit },
  ref
) {
  const styles = useStyles();

  const { varieties } = useVarietiesConfig();

  const seasonStartDate = _.get(season, 'startDate');
  const seasonEndDate = _.get(season, 'endDate');

  const { register, unregister, handleSubmit, errors, watch, setValue } =
    useForm({
      defaultValues: {
        plantingDate: null,
        varietyId: _.chain(varieties).last().get('id').value(),
        targetGrainMoisture: growthConfig.defaultTargetGrainMoisture
      }
    });

  const plantingDateError = _.get(errors, 'plantingDate.message');
  const varietyId = watch('varietyId');
  const plantingDate = watch('plantingDate');

  useEffect(() => {
    register({ name: 'plantingDate' });
  }, [register]);

  useEffect(() => {
    register({
      name: 'varietyId',
      required: !!plantingDate ? 'Seed Product is required' : false
    });
    return () => {
      unregister('varietyId');
    };
  }, [plantingDate, register, unregister]);

  const handlePlantingDateChange = useCallback(
    (date) => {
      setValue('plantingDate', date);
    },
    [setValue]
  );

  const handleSeedProductChange = useCallback(
    (e) => {
      setValue('varietyId', e.target.value);
    },
    [setValue]
  );

  const handleFormSubmit = useCallback(
    (formData) => {
      if (!_.get(formData, 'plantingDate')) {
        onFormSubmit();
        return;
      }
      const growth = {
        ...formData,
        plantingDate: formData.plantingDate.toISOString()
      };

      onFormSubmit(growth);
    },
    [onFormSubmit]
  );

  return (
    <form
      noValidate
      onSubmit={handleSubmit(handleFormSubmit)}
      ref={ref}
      className={styles.form}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={3}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              disableToolbar
              variant="dialog"
              format="L"
              id="plantingDate"
              name="plantingDate"
              label="Planting Date"
              value={watch('plantingDate')}
              onChange={handlePlantingDateChange}
              clearable
              minDate={seasonStartDate}
              maxDate={seasonEndDate}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <VarietyDropdown
            value={varietyId}
            onChange={handleSeedProductChange}
            variant="standard"
            required
            fullWidth
            select
            id="varietyId"
            label="Seed Product"
            name="varietyId"
            error={!!errors.varietyId}
            helperText={_.get(errors, 'varietyId.message')}
            disabled={!plantingDate}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <TextField
            inputRef={register()}
            variant="standard"
            fullWidth
            id="lotNumber"
            label="Lot Number"
            name="lotNumber"
            error={!!errors.lotNumber}
            helperText={_.get(errors, 'lotNumber.message')}
            disabled={!plantingDate}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <TextField
            inputRef={register({
              min: {
                value: 0.00000001,
                message: 'Target Grain Moisture Content must be greater than 0'
              },
              max: {
                value: 100,
                message: 'Target Grain Moisture Content must be less than 100'
              },
              required: !!plantingDate
                ? 'Target Grain Moisture Content is required'
                : false,
              valueAsNumber: true
            })}
            required
            inputProps={{ min: 0, max: 100 }}
            fullWidth
            variant="standard"
            type="number"
            id="targetGrainMoisture"
            label="Target Grain Moisture Content"
            name="targetGrainMoisture"
            error={!!_.get(errors, 'targetGrainMoisture')}
            helperText={_.get(errors, 'targetGrainMoisture.message')}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
            disabled={!plantingDate}
          />
        </Grid>

        <Grid item xs={12}>
          {plantingDateError && (
            <FormHelperText error>{plantingDateError}</FormHelperText>
          )}
        </Grid>
      </Grid>
    </form>
  );
});

export default SeasonGrowthForm;
