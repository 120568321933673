import { useMemo } from 'react';

import _ from 'lodash';
import moment from 'moment/moment';

import { FeedbackTargetType } from '../../../../_constants/activitiesConstants';

export const useWorkOrderParams = (workOrder) => {
  const name = _.get(workOrder, 'name');
  const type = _.get(workOrder, 'type');
  const description = _.get(workOrder, 'description');
  const status = _.get(workOrder, 'status');
  const createdBy = _.get(workOrder, 'createdBy.userName');
  const completedBy = _.get(workOrder, 'completedBy.userName');
  const payloadType = _.get(workOrder, 'payload.type');
  const payload = _.get(workOrder, 'payload');
  const notes = _.get(workOrder, 'notes');

  const fieldsCount = useMemo(() => {
    return _.values(_.get(workOrder, 'targetsByFieldId')).length;
  }, [workOrder]);

  const zonesCount = useMemo(() => {
    return _.chain(workOrder)
      .get('targetsByFieldId')
      .values()
      .filter((field) => _.get(field, 'type') === FeedbackTargetType.Zones)
      .flatMap((field) => _.get(field, 'zonesIds'))
      .get('length')
      .value();
  }, [workOrder]);

  const dateCreated = useMemo(() => {
    const dateCreated = _.get(workOrder, 'dateCreated');
    return !!dateCreated ? moment(dateCreated).format('lll') : null;
  }, [workOrder]);

  const dateCompleted = useMemo(() => {
    const dateCompleted = _.get(workOrder, 'dateCompleted');
    return !!dateCompleted ? moment(dateCompleted).format('lll') : null;
  }, [workOrder]);

  const dueDate = useMemo(() => {
    const dueDate = _.get(workOrder, 'dueDate');
    return !!dueDate ? moment(dueDate).format('lll') : null;
  }, [workOrder]);

  return useMemo(
    () => ({
      name,
      description,
      type,
      fieldsCount,
      zonesCount,
      dateCreated,
      createdBy,
      dateCompleted,
      completedBy,
      dueDate,
      status,
      payloadType,
      payload,
      notes
    }),
    [
      name,
      description,
      type,
      fieldsCount,
      zonesCount,
      dateCreated,
      createdBy,
      dateCompleted,
      completedBy,
      dueDate,
      status,
      payloadType,
      payload,
      notes
    ]
  );
};
