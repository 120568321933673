import { useMemo } from 'react';

import { faClipboardList } from '@fortawesome/free-solid-svg-icons';

import { userRoles } from '../../_constants/userConstants';

import { Reports } from './ReportsPage';

const ReportsPageKey = 'REPORTS_PAGE';

const ReportsPageSubpageKeys = {
  FullSeason: `${ReportsPageKey}_FULL_SEASON`,
  MidToFullSeason: `${ReportsPageKey}_MID_TO_FULL_SEASON`,
  CropProgress: `${ReportsPageKey}_CROP_PROGRESS`,
  Harvest: `${ReportsPageKey}_HARVEST`,
  HarvestedYield: `${ReportsPageKey}_HARVESTED_YIELD`,
  GrowthStage: `${ReportsPageKey}_GROWTH_STAGE`,
  Herbicide: `${ReportsPageKey}_HERBICIDE`,
  Nitrogen: `${ReportsPageKey}_NITROGEN`
};

const baseConfig = {
  key: ReportsPageKey,
  icon: faClipboardList,
  label: 'Reports',
  path: 'reports',
  allowedRoles: [
    userRoles.Admin,
    userRoles.Consultant,
    userRoles.User,
    userRoles.UserView
  ],
  subPages: [
    {
      key: ReportsPageSubpageKeys.FullSeason,
      label: 'Full Season',
      path: Reports['full-season'].id
    },
    {
      key: ReportsPageSubpageKeys.MidToFullSeason,
      label: 'Mid to Full Season',
      path: Reports['mid-to-full-season'].id
    },
    {
      key: ReportsPageSubpageKeys.CropProgress,
      label: 'Crop Progress',
      path: Reports['crop-progress'].id
    },
    {
      key: ReportsPageSubpageKeys.Harvest,
      label: 'Harvest',
      path: Reports.harvest.id
    },
    {
      key: ReportsPageSubpageKeys.HarvestedYield,
      label: 'Harvested Yield',
      path: Reports['harvested-yield'].id
    },
    {
      key: ReportsPageSubpageKeys.GrowthStage,
      label: 'Growth Stage',
      path: Reports['growth-stage'].id
    },
    {
      key: ReportsPageSubpageKeys.Herbicide,
      label: 'Herbicide',
      path: Reports.herbicide.id
    },
    {
      key: ReportsPageSubpageKeys.Nitrogen,
      label: 'Nitrogen',
      path: Reports.nitrogen.id
    }
  ]
};

export const useReportsPageConfig = () => {
  return useMemo(() => ({ config: baseConfig }), []);
};
