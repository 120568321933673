import { useMemo } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';

import { createDataSlice } from '../../utils/createDataSlice';

const activityFeedbackDialogSlice = createDataSlice({
  reducerPath: ['ui', 'dialogs', 'activityFeedback'],
  initialState: { selected: null },
  reducers: {
    open(state, action) {
      const { growerId, seasonId, farmId, fieldId, activity } = action.payload;

      state.selected = { growerId, seasonId, farmId, fieldId, activity };
    },
    close(state) {
      state.selected = null;
    }
  }
});

const { actions, baseSelector: activityFeedbackDialogSelector } =
  activityFeedbackDialogSlice;

export const activityFeedbackDialogActions = actions;

export const useActivityFeedbackDialogSelectors = () => {
  const { selected } = useSelector(activityFeedbackDialogSelector);

  const growerId = _.get(selected, 'growerId');
  const seasonId = _.get(selected, 'seasonId');
  const farmId = _.get(selected, 'farmId');
  const fieldId = _.get(selected, 'fieldId');
  const activity = _.get(selected, 'activity');

  const open = !!selected;

  return useMemo(
    () => ({
      open,
      growerId,
      seasonId,
      farmId,
      fieldId,
      activity
    }),
    [activity, farmId, fieldId, growerId, open, seasonId]
  );
};

export default activityFeedbackDialogSlice;
