// 1 fl oz (us) = 29.5735295625 g
// 1g = 0.033814023 fl oz
// 1 oz = 28.349523125 g
// 1 oz = 28.349523125 / 29.5735295625 fl oz = 0.958611419

export const TankMixUnit = {
  FlOzPerAc: {
    key: 'FlOzPerAc',
    name: 'fl oz',
    namePerAc: 'fl oz/ac',
    toFlOzPerAcMultiplier: 1
  },
  GramPerAc: {
    key: 'GramPerAc',
    name: 'g',
    namePerAc: 'g/ac',
    toFlOzPerAcMultiplier: 0.033814023
  },
  OzPerAc: {
    key: 'OzPerAc',
    name: 'oz',
    namePerAc: 'oz/ac',
    toFlOzPerAcMultiplier: 0.958611419
  }
};
