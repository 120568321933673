import React from 'react';

import Collapse from '@material-ui/core/Collapse';

import FieldActivitiesDetails from './FieldActivitiesDetails';

const ActivitiesCollapse = ({ field, open, size }) => {
  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <FieldActivitiesDetails field={field} size={size} />
    </Collapse>
  );
};

export default ActivitiesCollapse;
