export const GrowthConditionTypes = {
  IrrigationType: 'IrrigationType',
  CropTechnology: 'CropTechnology',
  PlantingDate: 'PlantingDate',
  Susceptibility: 'Susceptibility',
  Resistance: 'Resistance'
};

export const ActivityConditionTypes = {
  And: 'And',
  Or: 'Or',
  Not: 'Not',
  Weather: 'Weather',
  GrowthStage: 'GrowthStage',
  Growth: 'Growth',
  DatesRange: 'DatesRange',
  AddDays: 'AddDays',
  AddGdds: 'AddGdds',
  RelatedActivity: 'RelatedActivity',
  CustomAttribute: 'CustomAttribute'
};

export const GrowthStageConditionTypes = {
  Single: 'Single',
  Range: 'Range',
  RangeExclusiveEnd: 'RangeExclusiveEnd',
  StartDate: 'StartDate',
  EndDate: 'EndDate'
};

export const WeatherConditionTypes = {
  MinTemperature: 'MinTemperature',
  MaxTemperature: 'MaxTemperature',
  AverageTemperature: 'AverageTemperature',
  AccumulatedPrecipitation: 'AccumulatedPrecipitation',
  ShortWaveRadiation: 'ShortWaveRadiation'
};

export const RelatedActivityConditionTypes = {
  ActivityCompleted: 'ActivityCompleted'
};

export const RelatedActivityConditionTypeNames = {
  ActivityCompleted: 'Activity Completed'
};

export const RelatedActivityFormFieldConditionType = {
  Boolean: 'Boolean',
  Numerical: 'Numerical'
};

export const CustomAttributeConditionType = {
  Text: 'Text',
  Number: 'Number',
  Checkbox: 'Checkbox',
  Select: 'Select',
  Date: 'Date',
  DateTime: 'DateTime'
};

export const RelatedAttributeEntityType = {
  Grower: 'Grower',
  Farm: 'Farm',
  Field: 'Field',
  SeasonField: 'SeasonField',
  Growth: 'Growth'
};

export const NumericalConditionTypes = {
  GreaterThanEqual: {
    type: 'GreaterThanEqual',
    operator: '>='
  },
  GreaterThan: {
    type: 'GreaterThan',
    operator: '>'
  },
  LessThanEqual: {
    type: 'LessThanEqual',
    operator: '<='
  },
  LessThan: {
    type: 'LessThan',
    operator: '<'
  }
};

export const TextConditionTypes = {
  NotEmpty: {
    type: 'NotEmpty',
    name: 'Not Empty'
  },
  Equals: {
    type: 'Equals',
    name: 'Equals'
  },
  Contains: {
    type: 'Contains',
    name: 'Contains'
  }
};

export const ZonedActivityTypes = {
  GrowthStage: 'GrowthStage',
  RecommendationCompletion: 'RecommendationCompletion',
  CustomNote: 'CustomNote',
  ZonedWorkOrderCompletion: 'ZonedWorkOrderCompletion'
};

export const FeedbackTargetType = {
  Field: 'Field',
  Zones: 'Zones'
};
