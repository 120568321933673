import { useContext, useMemo } from 'react';

import _ from 'lodash';

import { FarmStructureContext } from '../../../../_context/FarmStructureContext';

export const useGrowerBundles = () => {
  const { selectedGrower } = useContext(FarmStructureContext);

  const protocolBundles = _.get(
    selectedGrower,
    'activitiesConfig.protocolBundles'
  );
  const defaultBundleId = _.get(
    selectedGrower,
    'activitiesConfig.defaultBundleId'
  );

  const bundles = useMemo(
    () =>
      _.map(protocolBundles, (bundle) => ({
        ...bundle,
        isDefault: _.get(bundle, 'id') === defaultBundleId
      })),
    [defaultBundleId, protocolBundles]
  );

  return useMemo(
    () => ({
      defaultBundleId,
      bundles
    }),
    [bundles, defaultBundleId]
  );
};
