import difference from '@turf/difference';
import union from '@turf/union';
import _ from 'lodash';

function featuresUnion(features) {
  const firstFeature = _.first(features);
  if (!firstFeature) {
    return null;
  }
  return _.chain(features)
    .tail()
    .reduce(
      (result, exterior) => union(result, exterior),
      _.cloneDeep(firstFeature)
    )
    .value();
}

function toMultiPolygon(feature) {
  if (_.isEmpty(_.get(feature, 'geometry.coordinates'))) {
    return null;
  }
  if (_.get(feature, 'geometry.type') === 'MultiPolygon') {
    return feature;
  }
  return {
    type: 'Feature',
    geometry: {
      type: 'MultiPolygon',
      coordinates: [_.get(feature, 'geometry.coordinates')]
    }
  };
}

export function buildMultiPolygon(exteriorsFeatures, holesFeatures) {
  const combinedExteriors = featuresUnion(exteriorsFeatures);
  if (!combinedExteriors) {
    return null;
  }
  const combinedHoles = featuresUnion(holesFeatures);
  if (!combinedHoles) {
    return toMultiPolygon(combinedExteriors);
  }

  return toMultiPolygon(difference(combinedExteriors, combinedHoles));
}
