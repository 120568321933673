import { useMemo } from 'react';

import { farmWorkOrdersService } from '../../../../services/farmWorkOrdersService';
import { createApiSlice } from '../../utils/createApiSlice';

const deleteFarmWorkOrderSlice = createApiSlice({
  reducerPath: ['newGrowths', 'farmWorkOrders', 'deleteFarmWorkOrder'],
  apiFunction: farmWorkOrdersService.deleteFarmWorkOrderV2,
  apiArgumentsFn: ({ growerId, seasonId, farmId, workOrderId }) => [
    growerId,
    seasonId,
    farmId,
    workOrderId
  ]
});

const { actions, useApiSelectors } = deleteFarmWorkOrderSlice;

export const deleteFarmWorkOrderActions = actions;

export const useDeleteFarmWorkOrderSelectors = () => {
  const { inProgress, errorMessage, success, updatedAt } = useApiSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      updatedAt
    }),
    [errorMessage, inProgress, success, updatedAt]
  );
};

export default deleteFarmWorkOrderSlice;
