import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { IconButton, Tooltip } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CheckIcon from '@material-ui/icons/Check';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { useBundlesConfig } from '../../../../_store/selectors/configSelectors';
import {
  deleteBundleActions,
  useDeleteBundleSelectors
} from '../../../../_store/slices/config/bundles/deleteBundleSlice';
import {
  setDefaultBundleActions,
  useSetDefaultBundleSelectors
} from '../../../../_store/slices/config/bundles/setDefaultBundleSlice';
import {
  toaster,
  toastError,
  toastSuccess
} from '../../../../_utilities/toast-utils';
import ConfirmIconButtons from '../../../../Components/ConfirmIconButtons';
import { ApplicationConfigurationTabsIds } from '../../ApplicationConfigurationPage';
import DeleteButton from '../DeleteButton';

const SetDefaultButton = ({ bundleId, disabled, onClick }) => {
  const handleClick = useCallback(() => {
    onClick(bundleId);
  }, [bundleId, onClick]);

  return (
    <Tooltip title="Set Default" placement="top">
      <IconButton onClick={handleClick} disabled={disabled}>
        <DoneOutlineIcon />
      </IconButton>
    </Tooltip>
  );
};

const BundlesList = () => {
  const dispatch = useDispatch();

  const [bundleIdToDelete, setBundleIdToDelete] = useState();

  const { bundles } = useBundlesConfig();

  const bundleToDelete = useMemo(
    () => _.find(bundles, (bundle) => _.get(bundle, 'id') === bundleIdToDelete),
    [bundleIdToDelete, bundles]
  );

  const {
    inProgress: deleteInProgress,
    success: deleteSuccess,
    errorMessage: deleteErrorMessage
  } = useDeleteBundleSelectors();

  const {
    inProgress: setDefaultInProgress,
    success: setDefaultSuccess,
    errorMessage: setDefaultErrorMessage
  } = useSetDefaultBundleSelectors();

  const inProgress = deleteInProgress || setDefaultInProgress;

  useEffect(() => {
    if (deleteSuccess) {
      toaster('Bundle deleted', toastSuccess);
      dispatch(deleteBundleActions.clear());
    }
  }, [dispatch, deleteSuccess]);

  useEffect(() => {
    if (deleteErrorMessage) {
      toaster(`Error deleting bundle: ${deleteErrorMessage}`, toastError);
      dispatch(deleteBundleActions.clear());
    }
  }, [dispatch, deleteErrorMessage]);

  useEffect(() => {
    if (setDefaultSuccess) {
      toaster('Default Bundle changed', toastSuccess);
      dispatch(setDefaultBundleActions.clear());
    }
  }, [dispatch, setDefaultSuccess]);

  useEffect(() => {
    if (setDefaultErrorMessage) {
      toaster(
        `Error setting default bundle: ${setDefaultErrorMessage}`,
        toastError
      );
      dispatch(setDefaultBundleActions.clear());
    }
  }, [dispatch, setDefaultErrorMessage]);

  const handleDeleteClick = useCallback((protocolId) => {
    setBundleIdToDelete(protocolId);
  }, []);

  const handleCancelDeleteClick = useCallback(() => {
    setBundleIdToDelete(undefined);
  }, []);

  const handleConfirmDeleteClick = useCallback(() => {
    dispatch(deleteBundleActions.submit({ bundleId: bundleIdToDelete }));
    setBundleIdToDelete(undefined);
  }, [bundleIdToDelete, dispatch]);

  const handleSetDefaultClick = useCallback(
    (bundleId) => {
      dispatch(setDefaultBundleActions.submit({ bundleId }));
    },
    [dispatch]
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Description</TableCell>
          <TableCell align="right">Default</TableCell>
          <TableCell align="right">Activities</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {_.map(bundles, (bundle) => (
          <TableRow key={_.get(bundle, 'id')}>
            <TableCell>{_.get(bundle, 'description')}</TableCell>
            <TableCell align="right">
              {_.get(bundle, 'isDefault') && <CheckIcon />}
            </TableCell>
            <TableCell align="right">
              {_.get(bundle, 'protocols.length')}
            </TableCell>
            <TableCell align="right">
              {(!bundleToDelete ||
                bundleIdToDelete !== _.get(bundle, 'id')) && (
                <>
                  <Tooltip title="Bundle Details" placement="top">
                    <IconButton
                      component={RouterLink}
                      to={`/application-config/${
                        ApplicationConfigurationTabsIds['protocol-bundles']
                      }/${_.get(bundle, 'id')}`}
                      disabled={inProgress}
                    >
                      <AssignmentIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Clone Bundle" placement="top">
                    <IconButton
                      component={RouterLink}
                      to={`/application-config/${
                        ApplicationConfigurationTabsIds['protocol-bundles']
                      }/${_.get(bundle, 'id')}/clone`}
                      disabled={inProgress}
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </Tooltip>
                  {!_.get(bundle, 'isDefault') && (
                    <>
                      <SetDefaultButton
                        bundleId={_.get(bundle, 'id')}
                        onClick={handleSetDefaultClick}
                        disabled={inProgress}
                      />
                      <DeleteButton
                        title="Delete Bundle"
                        protocolId={_.get(bundle, 'id')}
                        onClick={handleDeleteClick}
                        disabled={inProgress}
                      />
                    </>
                  )}
                </>
              )}
              {!!bundleToDelete && bundleIdToDelete === _.get(bundle, 'id') && (
                <ConfirmIconButtons
                  onCancel={handleCancelDeleteClick}
                  onConfirm={handleConfirmDeleteClick}
                />
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default BundlesList;
