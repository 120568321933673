import { useMemo } from 'react';

import { growthService } from '../../../services';
import { createApiSlice } from '../utils/createApiSlice';

const bulkUpdateGrowthModelsSlice = createApiSlice({
  reducerPath: ['newGrowths', 'farmFeedback', 'bulkUpdateGrowthModels'],
  apiFunction: growthService.bulkUpdateGrowthModelsV2,
  apiArgumentsFn: ({ growerId, seasonId, data }) => [growerId, seasonId, data]
});

const { actions, useApiSelectors } = bulkUpdateGrowthModelsSlice;

export const bulkUpdateGrowthModelsActions = actions;

export const useBulkUpdateGrowthModelsSelectors = () => {
  const { inProgress, errorMessage, success, updatedAt, response } =
    useApiSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      updatedAt,
      response
    }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default bulkUpdateGrowthModelsSlice;
