import React, { useMemo } from 'react';

import _ from 'lodash';

import { useSubPage } from '../../_hooks/Pages/useSubpage';
import AppPage from '../../Layout/AppPage';
import PageTitle from '../../Layout/PageTitle';

import FarmsStatistics from './Components/FarmsStatistics';
import FieldsStatistics from './Components/FieldsStatistics';
import GrowersStatistics from './Components/GrowersStatistics';
import PageViewsStatistics from './Components/PageViewsStatistics';
import SeasonFeedbackStatistics from './Components/SeasonFeedbackStatistics';
import SeasonFieldsStatistics from './Components/SeasonFieldsStatistics';
import UsersPageViewsStatistics from './Components/UsersPageViewsStatistics';

export const UsageStatisticsTabs = {
  growers: {
    order: 0,
    id: 'growers',
    name: 'Growers',
    component: <GrowersStatistics />
  },
  farms: {
    order: 1,
    id: 'farms',
    name: 'Farms',
    component: <FarmsStatistics />
  },
  fields: {
    order: 2,
    id: 'fields',
    name: 'Fields',
    component: <FieldsStatistics />
  },
  'season-fields': {
    order: 3,
    id: 'season-fields',
    name: 'Season Fields',
    component: <SeasonFieldsStatistics />
  },
  'season-feedback': {
    order: 4,
    id: 'season-feedback',
    name: 'Season Feedback',
    component: <SeasonFeedbackStatistics />
  },
  'users-page-views': {
    order: 5,
    id: 'users-page-views',
    name: 'Users Page Views',
    component: <UsersPageViewsStatistics />
  },
  'page-views': {
    order: 6,
    id: 'page-views',
    name: 'Page Views',
    component: <PageViewsStatistics />
  }
};

const UsageStatisticsPage = () => {
  const { subPage } = useSubPage();

  const path = _.get(subPage, 'path');

  const selectedTab = useMemo(() => _.get(UsageStatisticsTabs, [path]), [path]);

  const component = useMemo(
    () => _.get(selectedTab, 'component'),
    [selectedTab]
  );

  return <AppPage header={<PageTitle />}>{component}</AppPage>;
};

export default UsageStatisticsPage;
