import React from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Alert from '@material-ui/lab/Alert';

import { DeleteDialog } from '../../Layout/Content';
import LoadingIndicator from '../LoadingIndicator';

import { DataAccordion } from './DataAccordion';
import { DataTable } from './DataTable';

export function ResponsiveDataContainer({
  isLoading,
  isSuccess,
  error,
  headers,
  responsiveTableHeaders,
  data,
  responsiveTableData,
  deleteConfirmationOpen,
  deleteInProgress,
  deleteError,
  deleteModalTitle,
  deleteModalText,
  onDeleteCancel,
  onDeleteConfirm,
  onSelectItemToDelete,
  enablePagination = false,
  paginationKey
}) {
  const showTable = useMediaQuery((theme) => theme.breakpoints.up('md'), {
    noSsr: true
  });

  return (
    <>
      {isLoading && <LoadingIndicator />}
      {!isLoading && isSuccess && showTable && (
        <DataTable
          headers={responsiveTableHeaders || headers}
          data={responsiveTableData || data}
          onSelectItemToDelete={onSelectItemToDelete}
          enablePagination={enablePagination}
          paginationKey={paginationKey}
        />
      )}
      {!isLoading && isSuccess && !showTable && (
        <DataAccordion
          headers={headers}
          data={data}
          onSelectItemToDelete={onSelectItemToDelete}
          enablePagination={enablePagination}
          paginationKey={paginationKey}
        />
      )}
      {!isLoading && error && (
        <Alert severity="error" title="Error">
          {error}
        </Alert>
      )}
      <DeleteDialog
        open={deleteConfirmationOpen || false}
        inProgress={deleteInProgress}
        error={deleteError}
        title={deleteModalTitle}
        text={deleteModalText}
        onCancel={onDeleteCancel}
        onDelete={onDeleteConfirm}
      />
    </>
  );
}
