import { useMemo } from 'react';

import { growerBundlesConfigService } from '../../../../../services/growerBundlesConfig.service';
import { createApiSlice } from '../../../utils/createApiSlice';

const setDefaultGrowerBundleSlice = createApiSlice({
  reducerPath: ['gff', 'growers', 'bundles', 'setDefaultGrowerBundle'],
  apiFunction: growerBundlesConfigService.setDefaultBundleV2,
  apiArgumentsFn: ({ growerId, bundleId }) => [growerId, bundleId]
});

const { actions, useApiSelectors } = setDefaultGrowerBundleSlice;

export const setDefaultGrowerBundleActions = actions;

export const useSetDefaultGrowerBundleSelectors = () => {
  const { inProgress, success, errorMessage, response, setDefaultdAt } =
    useApiSelectors();

  return useMemo(
    () => ({ inProgress, success, errorMessage, response, setDefaultdAt }),
    [errorMessage, inProgress, response, success, setDefaultdAt]
  );
};

export default setDefaultGrowerBundleSlice;
