import React from 'react';

import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';

import FormField from '../../../Components/DynamicForms/FormFields/FormField';

const useStyles = makeStyles(() => ({
  customAttributes: {
    marginTop: '1rem',
    marginBottom: '0.5rem',
    marginLeft: '0.75rem',
    fontSize: '0.88rem',
    fontWeight: 'bold'
  }
}));

const CustomAttributesForm = ({ customAttributes }) => {
  const styles = useStyles();

  return (
    <>
      {!_.isEmpty(customAttributes) && (
        <Typography className={styles.customAttributes}>
          Custom Attributes
        </Typography>
      )}
      {_.map(customAttributes, (attribute) => (
        <Grid item xs={12} key={_.get(attribute, 'id')}>
          <FormField
            field={attribute}
            prefix="customAttributes"
            variant="outlined"
            fullWidth
          />
        </Grid>
      ))}
    </>
  );
};

export default CustomAttributesForm;
