import React, { useCallback } from 'react';

import {
  faExternalLinkAlt,
  faChevronUp,
  faChevronDown
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import _ from 'lodash';

import { buildPath } from '../../../_helpers/buildPath';
import { usePage } from '../../../_hooks/Pages/usePage';
import { useMenuState } from '../../../_hooks/ui/useMenuState';
import { useMenuItemState } from '../../../_store/slices/ui/menuSlice';

import MenuSubItem from './MenuSubItem';

const useStyles = makeStyles(() => ({
  externalIcon: {
    marginLeft: '0.5rem',
    verticalAlign: 'super',
    fontSize: '0.7rem'
  },
  item: {
    width: '100%'
  },
  link: {
    width: '100%',
    cursor: 'pointer',
    transition:
      'transform .2s, height 300ms, color 300ms, background-color 300ms',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    lineHeight: '2.5rem',
    height: '2.5rem',
    position: 'relative',
    borderRadius: '0.25rem',
    whiteSpace: 'nowrap',
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover': {
      color: 'white',
      background: 'rgba(255, 255, 255, 0.1)',
      textDecoration: 'none'
    }
  },
  activeLink: {
    background: 'rgba(255, 255, 255, 0.1)',
    fontWeight: 'bold'
  },
  icon: {
    textAlign: 'center',
    width: '34px',
    height: '34px',
    lineHeight: '34px',
    fontSize: '1.25rem',
    opacity: '0.5',
    transition: 'color 150ms'
  },
  iconContainer: {
    display: 'flex',
    padding: '0 3px',
    width: '40px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  label: {
    marginLeft: '5px',
    transition: 'color 150ms, opacity 300ms',
    fontSize: '0.88rem',
    flexGrow: 1,
    fontWeight: 500
  },
  hideLabel: {
    opacity: '0'
  },
  expandIcon: {
    marginLeft: '0.5rem',
    verticalAlign: 'super',
    fontSize: '0.7rem',
    color: 'rgba(255, 255, 255, 0.7)',
    justifySelf: 'right',
    marginRight: '0.5rem'
  }
}));

const LinkItem = ({ item, collapsedSideMenuHover }) => {
  const styles = useStyles();

  const { page } = usePage();

  const { toggleMobileSideMenu, showMobileSideMenu, collapsedSideMenu } =
    useMenuState();

  const path = buildPath('#', _.get(item, 'path'));
  const active = _.get(page, 'key') === _.get(item, 'key');

  const handleClick = useCallback(() => {
    // Close menu on phone layouts when a link is clicked
    if (showMobileSideMenu) {
      toggleMobileSideMenu();
    }
  }, [showMobileSideMenu, toggleMobileSideMenu]);

  return (
    <li className={styles.item}>
      <a
        onClick={handleClick}
        className={cx(styles.link, {
          [styles.activeLink]: active
        })}
        href={path}
        target={item.target || '_self'}
      >
        <div className={cx(styles.iconContainer)}>
          <FontAwesomeIcon icon={item.icon} className={styles.icon} />
        </div>
        <span
          className={cx(styles.label, {
            [styles.hideLabel]: collapsedSideMenu && !collapsedSideMenuHover
          })}
        >
          {item.label}
          {item.target === '_blank' && (
            <FontAwesomeIcon
              className={styles.externalIcon}
              icon={faExternalLinkAlt}
            />
          )}
        </span>
      </a>
    </li>
  );
};

const CollapseItem = ({ item, collapsedSideMenuHover }) => {
  const styles = useStyles();

  const { collapsedSideMenu, toggleMenuItem } = useMenuState();

  const { page } = usePage();

  const key = _.get(item, 'key');
  const active = _.get(page, 'key') === _.get(item, 'key');

  const { isExpanded } = useMenuItemState(key);

  const handleClick = useCallback(() => {
    toggleMenuItem(key);
  }, [key, toggleMenuItem]);

  return (
    <>
      <li className={styles.item}>
        <div
          onClick={handleClick}
          className={cx(styles.link, {
            [styles.activeLink]: active
          })}
        >
          <div className={cx(styles.iconContainer)}>
            <FontAwesomeIcon icon={item.icon} className={styles.icon} />
          </div>
          <div
            className={cx(styles.label, {
              [styles.hideLabel]: collapsedSideMenu && !collapsedSideMenuHover
            })}
          >
            {item.label}
          </div>
          <FontAwesomeIcon
            className={styles.expandIcon}
            icon={isExpanded ? faChevronUp : faChevronDown}
          />
        </div>
      </li>
      <Collapse in={isExpanded} timeout="auto">
        {_.map(_.get(item, 'subPages'), (subPage) => (
          <MenuSubItem
            key={`menuitem-${_.get(item, 'key')}-${_.get(subPage, 'key')}`}
            subItem={subPage}
            collapsedSideMenuHover={collapsedSideMenuHover}
          />
        ))}
      </Collapse>
    </>
  );
};

const MenuItem = ({ item, collapsedSideMenuHover }) => {
  const subPages = _.get(item, 'subPages');
  const hasSubPages = !_.isEmpty(subPages);

  return (
    <>
      {!hasSubPages && (
        <LinkItem item={item} collapsedSideMenuHover={collapsedSideMenuHover} />
      )}
      {!!hasSubPages && (
        <CollapseItem
          item={item}
          collapsedSideMenuHover={collapsedSideMenuHover}
        />
      )}
    </>
  );
};

export default MenuItem;
