import { useMemo } from 'react';

import { fieldGeospatialDataService } from '../../../services/fieldGeospatialData.service';
import { createApiSlice } from '../utils/createApiSlice';

const deleteGeospatialDataSlice = createApiSlice({
  reducerPath: ['newGrowths', 'geospatialData', 'deleteGeospatialData'],
  apiFunction: fieldGeospatialDataService.deleteGeospatialDataV2,
  apiArgumentsFn: ({ growerId, seasonId, farmId, fieldId, fileId }) => [
    growerId,
    seasonId,
    farmId,
    fieldId,
    fileId
  ]
});

const { actions, useApiSelectors } = deleteGeospatialDataSlice;

export const deleteGeospatialDataActions = actions;

export const useDeleteGeospatialDataSelectors = () => {
  const { inProgress, errorMessage, success, updatedAt, response } =
    useApiSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      updatedAt,
      response
    }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default deleteGeospatialDataSlice;
