import { useMemo } from 'react';

import { farmFeedbackService } from '../../../../services/farmFeedbackService';
import { createApiSlice } from '../../utils/createApiSlice';

const createFarmFeedbackSlice = createApiSlice({
  reducerPath: ['newGrowths', 'farmFeedback', 'createFarmFeedback'],
  apiFunction: farmFeedbackService.createFarmFeedbackV2,
  apiArgumentsFn: ({ growerId, seasonId, farmId, data }) => [
    growerId,
    seasonId,
    farmId,
    data
  ]
});

const { actions, useApiSelectors } = createFarmFeedbackSlice;

export const createFarmFeedbackActions = actions;

export const useCreateFarmFeedbackSelectors = () => {
  const { inProgress, errorMessage, success, updatedAt } = useApiSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      updatedAt
    }),
    [errorMessage, inProgress, success, updatedAt]
  );
};

export default createFarmFeedbackSlice;
