import { useCallback, useEffect, useState } from 'react';

import { IconButton, TableCell } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import {
  deleteTankMixProductActions,
  useDeleteTankMixProductSelectors
} from '../../../../_store/slices/config/tankMixes/deleteTankMixProductSlice';
import { getTankMixesConfigActions } from '../../../../_store/slices/config/tankMixes/getTankMixesConfigSlice';
import { toaster, toastSuccess } from '../../../../_utilities/toast-utils';
import ConfirmIconButtons from '../../../../Components/ConfirmIconButtons';
import LoadingIndicator from '../../../../Components/LoadingIndicator';
import { TankMixProductType } from '../../../TaknMixDemo/Components/TankMixProductType';
import { TankMixUnit } from '../../../TaknMixDemo/Components/TankMixUnit';

const TankMixProductsList = ({ products, onEditClick }) => {
  const dispatch = useDispatch();

  const [productToDeleteId, setProductToDeleteId] = useState();

  const {
    inProgress,
    success: deleteSuccess,
    errorMessage: deleteErrorMessage
  } = useDeleteTankMixProductSelectors();

  useEffect(() => {
    if (deleteSuccess) {
      toaster('Product deleted successfully', toastSuccess);
      dispatch(deleteTankMixProductActions.clear());
      dispatch(getTankMixesConfigActions.submit({}));
    }
  }, [deleteSuccess, dispatch]);

  useEffect(() => {
    if (deleteErrorMessage) {
      toaster(`Error deleting product: ${deleteErrorMessage}`, toastSuccess);
      dispatch(deleteTankMixProductActions.clear());
    }
  }, [deleteErrorMessage, dispatch]);

  const handleDeleteButtonClick = useCallback((productId) => {
    setProductToDeleteId(productId);
  }, []);

  const handleDeleteCancel = useCallback(() => {
    setProductToDeleteId(undefined);
  }, []);

  const handleEditProductClick = useCallback(
    (product) => {
      onEditClick(product);
    },
    [onEditClick]
  );

  const handleDeleteConfirm = useCallback(() => {
    setProductToDeleteId(undefined);
    dispatch(
      deleteTankMixProductActions.submit({ productId: productToDeleteId })
    );
  }, [dispatch, productToDeleteId]);

  return (
    <>
      {inProgress && <LoadingIndicator />}
      {!inProgress && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="right">#</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Manufacturer</TableCell>
              <TableCell>Active Ingredient</TableCell>
              <TableCell>Type</TableCell>
              <TableCell align="right">Min Rate</TableCell>
              <TableCell align="right">Max Rate</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(products, (product, idx) => (
              <TableRow key={_.get(product, 'id')}>
                <TableCell align="right">{idx + 1}</TableCell>
                <TableCell>{_.get(product, 'name')}</TableCell>
                <TableCell>{_.get(product, 'manufacturer')}</TableCell>
                <TableCell>{_.get(product, 'activeConstituent')}</TableCell>
                <TableCell>
                  {_.get(TankMixProductType, [_.get(product, 'type'), 'name'])}
                </TableCell>
                <TableCell align="right">
                  {_.get(product, 'minRate')}{' '}
                  {_.get(TankMixUnit, [_.get(product, 'unit'), 'namePerAc'])}
                </TableCell>
                <TableCell align="right">
                  {_.get(product, 'maxRate')}{' '}
                  {_.get(TankMixUnit, [_.get(product, 'unit'), 'namePerAc'])}
                </TableCell>
                <TableCell align="right" width="100rem">
                  {productToDeleteId !== _.get(product, 'id') && (
                    <>
                      <IconButton
                        size="small"
                        onClick={() => handleEditProductClick(product)}
                        disabled={!!productToDeleteId}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() =>
                          handleDeleteButtonClick(_.get(product, 'id'))
                        }
                        disabled={!!productToDeleteId}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                  {productToDeleteId === _.get(product, 'id') && (
                    <ConfirmIconButtons
                      size="small"
                      onCancel={handleDeleteCancel}
                      onConfirm={handleDeleteConfirm}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default TankMixProductsList;
