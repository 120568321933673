import { userService } from '../../services';
import {
  getNotificationsConfigActionTypes,
  notificationsConfigActionTypes,
  saveNotificationsConfigActionTypes
} from '../actionTypes/notificationsConfigActionTypes';

import { buildApiActions } from './apiActions';

export const getNotificationsConfigActions = buildApiActions(
  getNotificationsConfigActionTypes,
  userService.getNotificationsConfig
);

export const saveNotificationsConfigActions = buildApiActions(
  saveNotificationsConfigActionTypes,
  userService.saveNotificationsConfig
);

export const updateGrowthStagesNotificationConfig = (
  growerId,
  channel,
  selected
) => ({
  type: notificationsConfigActionTypes.UPDATE_GROWTH_STAGES_NOTIFICATIONS_CONFIG,
  payload: {
    growerId,
    channel,
    selected
  }
});

export const updateActivitiesNotificationConfig = (
  growerId,
  channel,
  selected
) => ({
  type: notificationsConfigActionTypes.UPDATE_ACTIVITIES_NOTIFICATIONS_CONFIG,
  payload: {
    growerId,
    channel,
    selected
  }
});

export const updatePumpSystemNotificationConfig = (
  growerId,
  seasonId,
  pumpId,
  channel,
  selected
) => ({
  type: notificationsConfigActionTypes.UPDATE_PUMP_SYSTEM_NOTIFICATIONS_CONFIG,
  payload: {
    growerId,
    seasonId,
    pumpId,
    channel,
    selected
  }
});
