import { useContext, useMemo } from 'react';

import { AuthContext } from '../../../../_context/AuthContext';
import { useGrowersDrawerSelectors } from '../../../../_store/slices/ui/growersDrawerSlice';

export function useCommonReportsColumns() {
  const { isAdmin } = useContext(AuthContext);
  const { showReportsRegions } = useGrowersDrawerSelectors();

  const commonColumns = useMemo(
    () => [
      ...(isAdmin && showReportsRegions
        ? [
            {
              field: 'regionName',
              headerName: 'Region',
              width: 150,
              editable: false
            },
            {
              field: 'districtName',
              headerName: 'District',
              width: 150,
              editable: false
            }
          ]
        : []),
      {
        field: 'growerName',
        headerName: 'Grower',
        width: 150,
        editable: false
      },
      {
        field: 'farmName',
        headerName: 'Farm',
        width: 150,
        editable: false
      },
      {
        field: 'fieldName',
        headerName: 'Field',
        width: 150,
        editable: false
      },
      {
        field: 'acres',
        headerName: 'Acres',
        width: 120,
        type: 'number',
        editable: false
      },
      {
        field: 'technologyName',
        headerName: 'Technology',
        width: 150,
        editable: false
      },
      {
        field: 'varietyName',
        headerName: 'Variety',
        width: 150,
        editable: false
      }
    ],
    [isAdmin, showReportsRegions]
  );

  return useMemo(
    () => ({
      commonColumns
    }),
    [commonColumns]
  );
}
