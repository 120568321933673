import React from 'react';

import { IconButton, Tooltip } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
  cancelButton: {
    color: 'red'
  },
  confirmButton: {
    color: 'green'
  }
}));

export default function ConfirmIconButtons({
  onCancel = _.noop,
  onConfirm = _.noop,
  ...props
}) {
  const styles = useStyles();

  return (
    <>
      <Tooltip title="Cancel" placement="top">
        <IconButton
          className={styles.cancelButton}
          onClick={onCancel}
          {...props}
        >
          <CloseIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Confirm" placement="top">
        <IconButton
          className={styles.confirmButton}
          onClick={onConfirm}
          {...props}
        >
          <CheckIcon />
        </IconButton>
      </Tooltip>
    </>
  );
}
