import { useContext, useMemo } from 'react';

import _ from 'lodash';

import { AuthContext } from '../../../../_context/AuthContext';
import { useGetGrowthModelReportSelectors } from '../../../../_store/slices/reports/getGrowthModelReportSlice';

import { useReportsGrowersWithSelection } from './useReportsGrowersWithSelection';
import { useReportsRegionsWithSelection } from './useReportsRegionsWithSelection';

export function useGrowthModelReportWithDistricts() {
  const { isAdmin } = useContext(AuthContext);

  const {
    inProgress,
    errorMessage,
    response: reportResponse
  } = useGetGrowthModelReportSelectors();

  const { growers } = useReportsGrowersWithSelection();

  const { regions } = useReportsRegionsWithSelection(growers);

  const growersWithDistricts = useMemo(
    () =>
      _.chain(regions)
        .flatMap((region) =>
          _.chain(region)
            .get('districts')
            .flatMap((district) =>
              _.chain(district)
                .get('growers')
                .map((grower) => ({
                  ...grower,
                  districtName: _.get(district, 'name'),
                  regionName: _.get(region, 'name')
                }))
                .value()
            )
            .value()
        )
        .keyBy('id')
        .value(),
    [regions]
  );

  const response = useMemo(() => {
    if (!isAdmin) {
      return reportResponse;
    }

    return {
      fields: _.chain(reportResponse)
        .get('fields')
        .map((item) => {
          const growerWithDistrict = _.get(growersWithDistricts, [
            _.get(item, 'field.growerId')
          ]);
          return {
            ...item,
            field: {
              ..._.get(item, 'field'),
              regionName: _.get(growerWithDistrict, 'regionName'),
              districtName: _.get(growerWithDistrict, 'districtName')
            }
          };
        })
        .value()
    };
  }, [growersWithDistricts, isAdmin, reportResponse]);

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      response
    }),
    [errorMessage, inProgress, response]
  );
}
