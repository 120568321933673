import { useMemo } from 'react';

import { protocolsConfigService } from '../../../../services/protocolsConfig.service';
import { createApiSlice } from '../../utils/createApiSlice';

const deleteProtocolSlice = createApiSlice({
  reducerPath: ['newConfig', 'protocols', 'deleteProtocol'],
  apiFunction: protocolsConfigService.deleteProtocolV2,
  apiArgumentsFn: ({ bundleId, protocolId }) => [bundleId, protocolId]
});

const { actions, useApiSelectors } = deleteProtocolSlice;

export const deleteProtocolActions = actions;

export const useDeleteProtocolSelectors = () => {
  const { inProgress, success, errorMessage, response, updatedAt } =
    useApiSelectors();

  return useMemo(
    () => ({ inProgress, success, errorMessage, response, updatedAt }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default deleteProtocolSlice;
