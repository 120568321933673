import React from 'react';

import { faTemperatureHigh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import makeStyles from '@material-ui/core/styles/makeStyles';
import cx from 'classnames';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
  icon: (props) => ({
    fontSize: `${props.size}px`,
    color: props.color
  })
}));

export default function WeatherStationIcon({
  size = 20,
  style = {},
  color = '#1e88e5',
  onClick = _.noop
}) {
  const styles = useStyles({ size, color });

  return (
    <FontAwesomeIcon
      className={cx(styles.icon)}
      icon={faTemperatureHigh}
      style={{ ...style }}
      onClick={onClick}
    />
  );
}
