import { useMemo } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';

import { requestStatus } from '../helpers/requestStatus';

export const useGrowthStagesConfig = () => {
  const growthStagesConfig = useSelector((state) =>
    _.get(state, 'config.growthModel.growthStages')
  );

  return useMemo(() => ({ growthStagesConfig }), [growthStagesConfig]);
};

export const useVarietiesConfig = () => {
  const varieties = useSelector((state) =>
    _.get(state, 'config.growthModel.varieties')
  );

  return useMemo(() => ({ varieties }), [varieties]);
};

export const useTechnologiesConfig = () => {
  const technologies = useSelector((state) =>
    _.get(state, 'config.growthModel.technologies')
  );

  return useMemo(() => ({ technologies }), [technologies]);
};

export const usePestsAndDiseasesConfig = () => {
  const pestsAndDiseases = useSelector((state) =>
    _.get(state, 'config.growthModel.pestsAndDiseases')
  );

  return useMemo(() => ({ pestsAndDiseases }), [pestsAndDiseases]);
};

export const useSeasonsConfig = () => {
  const seasons = useSelector((state) =>
    _.get(state, 'config.seasons.seasons')
  );

  return useMemo(() => ({ seasons }), [seasons]);
};

export const useRegionsConfig = () => {
  const regionsConfig = useSelector((state) =>
    _.get(state, 'config.regions.regions')
  );

  return useMemo(() => ({ regionsConfig }), [regionsConfig]);
};

export const useSaveGrowthStageConfig = () => {
  const status = useSelector((state) =>
    _.get(state, 'config.saveStageConfig.status')
  );
  const inProgress = status === requestStatus.IN_PROGRESS;
  const success = status === requestStatus.SUCCESS;
  const error = useSelector((state) =>
    _.get(state, 'config.saveStageConfig.error.message')
  );

  return useMemo(
    () => ({
      inProgress,
      success,
      error
    }),
    [error, inProgress, success]
  );
};

export const activityTypes = {
  Scouting: {
    type: 'Scouting',
    color: '#009688',
    name: 'Scouting'
  },
  NitrogenApplication: {
    type: 'NitrogenApplication',
    color: '#303f9f',
    name: 'Nitrogen Application'
  },
  HerbicideApplication: {
    type: 'HerbicideApplication',
    color: '#43a047',
    name: 'Herbicide Application'
  },
  FungicideApplication: {
    type: 'FungicideApplication',
    color: '#5d4037',
    name: 'Fungicide Application'
  },
  Irrigation: {
    type: 'Irrigation',
    color: '#2196f3',
    name: 'Irrigation'
  },
  Drydown: {
    type: 'Drydown',
    color: '#ff7232',
    name: 'Drydown'
  },
  MultipleAwd: {
    type: 'MultipleAwd',
    color: '#ffd632',
    name: 'AWD'
  },
  Alert: {
    type: 'Alert',
    color: '#ff4130',
    name: 'Alert'
  }
};

export const useActivitiesConfig = () => {
  return useMemo(() => ({ activityTypes }), []);
};

export const useBundlesConfig = () => {
  const protocolBundles = useSelector((state) =>
    _.get(state, 'config.activities.protocolBundles')
  );

  const defaultBundleId = useSelector((state) =>
    _.get(state, 'config.activities.defaultBundleId')
  );

  const bundles = useMemo(
    () =>
      _.map(protocolBundles, (bundle) => ({
        ...bundle,
        isDefault: _.get(bundle, 'id') === defaultBundleId
      })),
    [defaultBundleId, protocolBundles]
  );

  return useMemo(
    () => ({ defaultBundleId, bundles }),
    [bundles, defaultBundleId]
  );
};

export const useLayersConfig = () => {
  const categories = useSelector((state) =>
    _.get(state, 'config.layers.categories')
  );

  return useMemo(() => ({ categories }), [categories]);
};
