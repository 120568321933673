import React, { useEffect, useMemo } from 'react';

import Alert from '@material-ui/lab/Alert';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { useGlobalSeasonSelectors } from '../../../_store/slices/gff/seasons/globalSeasonSlice';
import {
  getSeasonFieldsStatisticsActions,
  useGetSeasonFieldsStatisticsSelectors
} from '../../../_store/slices/statistics/getSeasonFieldsStatisticsSlice';
import CommonDataGrid from '../../../Components/DataGrid/CommonDataGrid';
import LoadingIndicator from '../../../Components/LoadingIndicator';
import {
  ContentBody,
  ContentContainer,
  ContentHeader
} from '../../../Layout/Content';

const columns = [
  {
    field: 'growerName',
    headerName: 'Grower',
    width: 250,
    editable: false
  },
  {
    field: 'farmName',
    headerName: 'Farm',
    width: 250,
    editable: false
  },
  {
    field: 'name',
    headerName: 'Field',
    width: 250,
    editable: false
  },
  {
    field: 'dateCreated',
    headerName: 'Date Created',
    width: 250,
    editable: false,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: (params) => {
      return params.value ? moment(params.value).format('lll') : '';
    }
  },
  {
    field: 'dateModified',
    headerName: 'Date Modified',
    width: 250,
    editable: false,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: (params) => {
      return params.value ? moment(params.value).format('lll') : '';
    }
  },
  {
    field: 'dateAdded',
    headerName: 'Date Added to Season',
    width: 250,
    editable: false,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: (params) => {
      return params.value ? moment(params.value).format('lll') : '';
    }
  }
];

const SeasonFieldsStatistics = () => {
  const dispatch = useDispatch();

  const { selectedSeason: seasonYear } = useGlobalSeasonSelectors();

  const { inProgress, errorMessage, response } =
    useGetSeasonFieldsStatisticsSelectors(seasonYear);

  const rows = useMemo(() => _.map(response, (item) => item), [response]);

  useEffect(() => {
    dispatch(getSeasonFieldsStatisticsActions.submit({ seasonYear }));
  }, [dispatch, seasonYear]);

  return (
    <ContentContainer>
      <ContentHeader>
        <span>{`Season ${seasonYear} Fields`}</span>
      </ContentHeader>
      <ContentBody>
        {inProgress && <LoadingIndicator />}
        {!inProgress && errorMessage && (
          <Alert severity="error">{errorMessage}</Alert>
        )}
        {!inProgress && !errorMessage && (
          <CommonDataGrid rows={rows} columns={columns} />
        )}
      </ContentBody>
    </ContentContainer>
  );
};

export default SeasonFieldsStatistics;
