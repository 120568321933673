import React, { useCallback, useState } from 'react';

import { TableCell } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import _ from 'lodash';

import { ZonedActivityTypes } from '../../_constants/activitiesConstants';
import { useFeedbackParams } from '../../Pages/Map/Components/hooks/useFeedbackParams';
import GrowthStageBadge from '../Growths/GrowthStageBadge';

import FeedbackTargets from './FeedbackTargets';
import GoToZonedActivityButton from './GoToZonedActivityButton';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const FeedbackCollapse = ({ feedback, open }) => {
  const styles = useStyles();

  const targets = _.chain(feedback).get('targetsByFieldId').values().value();

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Box margin={2} className={styles.container}>
        <FeedbackTargets targets={targets} />
      </Box>
    </Collapse>
  );
};

const FeedbackRow = ({ feedback }) => {
  const [open, setOpen] = useState(false);

  const { type, growthStage, fieldsCount, zonesCount, date, dateCreated } =
    useFeedbackParams(feedback);

  const handleCollapseButtonClick = useCallback(() => {
    setOpen((open) => !open);
  }, []);

  return (
    <>
      <TableRow>
        {(type === ZonedActivityTypes.CustomNote ||
          type === ZonedActivityTypes.ZonedWorkOrderCompletion) && (
          <TableCell>{_.get(feedback, 'name')}</TableCell>
        )}
        {type === ZonedActivityTypes.GrowthStage && (
          <TableCell>
            <GrowthStageBadge growthStage={growthStage} small />
          </TableCell>
        )}
        <TableCell>{_.get(feedback, 'user.userName')}</TableCell>
        <TableCell align="right">{dateCreated}</TableCell>
        <TableCell align="right">{date}</TableCell>
        <TableCell>{_.get(feedback, 'description')}</TableCell>
        <TableCell align="right">{`${fieldsCount} ${
          fieldsCount === 1 ? 'Field' : 'Fields'
        } / ${zonesCount} ${zonesCount === 1 ? 'Zone' : 'Zones'}`}</TableCell>
        <TableCell align="right">{_.get(feedback, 'photos.length')}</TableCell>
        <TableCell width="100rem">
          <GoToZonedActivityButton activity={feedback} />
          <IconButton size="small" onClick={handleCollapseButtonClick}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <FeedbackCollapse open={open} feedback={feedback} />
        </TableCell>
      </TableRow>
    </>
  );
};

const ZonedActivitiesTable = ({ feedbackItems }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name / Stage</TableCell>
          <TableCell>Created by</TableCell>
          <TableCell align="right">Date Created</TableCell>
          <TableCell align="right">Date</TableCell>
          <TableCell>Description</TableCell>
          <TableCell align="right">Fields / Zones</TableCell>
          <TableCell align="right">Photos</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>

      <TableBody>
        {_.map(feedbackItems, (feedback) => (
          <FeedbackRow key={_.get(feedback, 'id')} feedback={feedback} />
        ))}
      </TableBody>
    </Table>
  );
};

export default ZonedActivitiesTable;
