import { useMemo } from 'react';

import _ from 'lodash';

import { farmFeedbackService } from '../../../../services/farmFeedbackService';
import { createApiSlice } from '../../utils/createApiSlice';

const getFarmFeedbackPhotoSlice = createApiSlice({
  reducerPath: ['newGrowths', 'farmFeedback', 'getFarmFeedbackPhoto'],
  apiFunction: farmFeedbackService.getFeedbackPhotoV2,
  apiArgumentsFn: ({ growerId, seasonId, farmId, feedbackId, photoId }) => [
    growerId,
    seasonId,
    farmId,
    feedbackId,
    photoId
  ],
  getStateUpdatePath: (action) => {
    const photoId = _.last(_.get(action, 'payload.apiArguments'));
    return [photoId];
  },
  getSelectorPath: (photoId) => {
    return [photoId];
  }
});

const { actions, useApiSelectors } = getFarmFeedbackPhotoSlice;

export const getFarmFeedbackPhotoActions = actions;

export const useGetFarmFeedbackPhotoSelectors = (photoId) => {
  const {
    inProgress,
    errorMessage,
    success,
    updatedAt,
    response: photo
  } = useApiSelectors(photoId);

  return useMemo(
    () => ({ photo, inProgress, errorMessage, success, updatedAt }),
    [errorMessage, inProgress, photo, success, updatedAt]
  );
};

export default getFarmFeedbackPhotoSlice;
