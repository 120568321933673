import { useMemo } from 'react';

import { faCalendarWeek } from '@fortawesome/free-solid-svg-icons';

import { userRoles } from '../../_constants/userConstants';

import { SeasonConfigTabIds } from './SeasonConfigPage';

const SeasonsConfigPageKey = 'SEASONS_CONFIG_DEMO_PAGE';

export const SeasonsConfigSubpageKey = {
  Fields: `${SeasonsConfigPageKey}_FIELDS`,
  Devices: `${SeasonsConfigPageKey}_DEVICES`
};

const baseConfig = {
  key: SeasonsConfigPageKey,
  icon: faCalendarWeek,
  label: 'Seasons Configuration',
  path: 'seasonsConfig',
  allowedRoles: [userRoles.Admin, userRoles.Consultant],
  subPages: [
    {
      key: SeasonsConfigSubpageKey.Fields,
      label: 'Fields',
      path: SeasonConfigTabIds.FIELDS
    },
    {
      key: SeasonsConfigSubpageKey.Devices,
      label: 'Devices',
      path: SeasonConfigTabIds.DEVICES
    }
  ]
};

export const useSeasonsConfigPageConfig = () => {
  return useMemo(() => ({ config: baseConfig }), []);
};
