import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import _ from 'lodash';

import FeedbackTarget from './FeedbackTarget';

const FeedbackTargets = ({ targets, light }) => {
  return (
    <Table size="small">
      <TableBody>
        {_.map(targets, (target) => (
          <FeedbackTarget
            key={_.get(target, 'fieldId')}
            target={target}
            light={light}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default FeedbackTargets;
