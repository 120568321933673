import generateActivitiesSlice from './generateActivitiesSlice';
import generateDemoActivitiesSlice from './generateDemoActivitiesSlice';
import selectedActivitiesConfigsSlice from './selectedActivitiesConfigsSlice';

const sandboxSlices = [
  generateActivitiesSlice,
  generateDemoActivitiesSlice,
  selectedActivitiesConfigsSlice
];

export default sandboxSlices;
