import { useMemo } from 'react';

import _ from 'lodash';

import { useLayersConfig } from '../../../../../_store/selectors/configSelectors';

export const useLayerCategory = (layer) => {
  const { categories } = useLayersConfig();

  const categoryId = _.get(layer, 'categoryId');

  return useMemo(
    () => _.find(categories, (c) => _.get(c, 'id') === categoryId),
    [categories, categoryId]
  );
};
