import { useMemo } from 'react';

import { protocolsConfigService } from '../../../../services/protocolsConfig.service';
import { createApiSlice } from '../../utils/createApiSlice';

const updateActivitySlice = createApiSlice({
  reducerPath: ['newConfig', 'protocols', 'updateActivity'],
  apiFunction: protocolsConfigService.updateActivityV2,
  apiArgumentsFn: ({ bundleId, protocolId, activityId, activity }) => [
    bundleId,
    protocolId,
    activityId,
    activity
  ]
});

const { actions, useApiSelectors } = updateActivitySlice;

export const updateActivityActions = actions;

export const useUpdateActivitySelectors = () => {
  const { inProgress, success, errorMessage, response, updatedAt } =
    useApiSelectors();

  return useMemo(
    () => ({ inProgress, success, errorMessage, response, updatedAt }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default updateActivitySlice;
