import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { DrawPolygonMode, ModifyMode, ViewMode } from '@nebula.gl/edit-modes';
import difference from '@turf/difference';
import intersect from '@turf/intersect';
import union from '@turf/union';
import _ from 'lodash';
import { EditingMode } from 'react-map-gl-draw';

import { buildMultiPolygon } from '../../../../../../_utilities/geojson/polygonUtils';

export const MODES = {
  DISABLED: 'DISABLED',
  VIEW: 'VIEW',
  EDIT: 'EDIT',
  DRAW: 'DRAW',
  DRAW_HOLE: 'DRAW_HOLE',
  DRAW_EXTERIOR: 'DRAW_EXTERIOR',
  MODIFY: 'MODIFY'
};

export function useZonesPolygonEditor(
  initialPolygon,
  boundingFeature,
  shapesToExclude
) {
  const editorRef = useRef(null);
  const [mode, setMode] = useState(MODES.DISABLED);
  const [editorFeatures, setEditorFeatures] = useState([]);
  const [selected, setSelected] = useState();

  const selectedFeature = _.get(selected, 'selectedFeature');
  const selectedFeatureIndex = _.get(selected, 'selectedFeatureIndex');

  const isEditorActive =
    mode === MODES.EDIT ||
    mode === MODES.DRAW_HOLE ||
    mode === MODES.DRAW_EXTERIOR ||
    mode === MODES.MODIFY;
  const isEditorInViewMode = mode === MODES.VIEW;

  const featureToExclude = useMemo(() => {
    if (_.isEmpty(shapesToExclude)) {
      return null;
    }
    return _.reduce(
      shapesToExclude,
      (f, shape) => union(f, shape),
      _.first(shapesToExclude)
    );
  }, [shapesToExclude]);

  const editorMode = useMemo(() => {
    switch (mode) {
      case MODES.VIEW:
        return new ViewMode();
      case MODES.EDIT:
        return new EditingMode();
      case MODES.DRAW_HOLE:
      case MODES.DRAW_EXTERIOR:
        return new DrawPolygonMode();
      case MODES.MODIFY:
        return new ModifyMode();
      case MODES.DISABLED:
      default:
        return null;
    }
  }, [mode]);

  const shapeToSave = useMemo(() => {
    if (isEditorInViewMode && !_.isEmpty(editorFeatures)) {
      const exteriors = _.filter(
        editorFeatures,
        (feature) => !_.get(feature, 'properties.hole')
      );
      const holes = _.filter(editorFeatures, (feature) =>
        _.get(feature, 'properties.hole')
      );

      const multiPolygonFeature = buildMultiPolygon(exteriors, holes);
      return _.get(multiPolygonFeature, 'geometry');
    }
  }, [editorFeatures, isEditorInViewMode]);

  const clearEditor = useCallback(() => {
    setEditorFeatures([]);
    setSelected(null);
    setMode(MODES.VIEW);
  }, []);

  const convertMultipolygonToFeatures = useCallback((multiPolygon) => {
    return _.chain(multiPolygon)
      .get('coordinates')
      .flatMap((polygonCoordinates) => {
        const exterior = _.head(polygonCoordinates);
        const holes = _.tail(polygonCoordinates);
        return [
          {
            type: 'Feature',
            properties: { hole: false },
            geometry: {
              type: 'Polygon',
              coordinates: [exterior]
            }
          },
          ...holes.map((hole) => ({
            type: 'Feature',
            properties: { hole: true },
            geometry: {
              type: 'Polygon',
              coordinates: [hole]
            }
          }))
        ];
      })
      .value();
  }, []);

  const loadFeatures = useCallback(
    (multiPolygon) => {
      const features = convertMultipolygonToFeatures(multiPolygon);

      clearEditor();
      setMode(MODES.VIEW);
      setEditorFeatures(features);
    },
    [clearEditor, convertMultipolygonToFeatures]
  );

  useEffect(() => {
    clearEditor();
    if (initialPolygon) {
      loadFeatures(initialPolygon);
    }
  }, [clearEditor, initialPolygon, loadFeatures]);

  const handleFeatureSelect = useCallback((featureDescriptor) => {
    setSelected(featureDescriptor);
  }, []);

  const restrictToBounding = useCallback(
    (feature) => {
      let restrictedFeature = feature;
      if (boundingFeature) {
        restrictedFeature = intersect(restrictedFeature, boundingFeature);
      }
      if (featureToExclude) {
        restrictedFeature = difference(restrictedFeature, featureToExclude);
      }
      return restrictedFeature;
    },
    [boundingFeature, featureToExclude]
  );

  const handleFeatureUpdate = useCallback(
    (event) => {
      const { editType } = event;
      const features = _.get(event, 'data', []);
      if (
        editType === 'movePosition' ||
        editType === 'removePosition' ||
        editType === 'addPosition'
      ) {
        setEditorFeatures([...features]);
      } else if (editType === 'addFeature') {
        const existingFeatures = _.initial(features);
        const newFeature = _.last(features);
        const restrictedNewFeature = restrictToBounding(newFeature);

        const flattenedNewFeature =
          _.get(restrictedNewFeature, 'geometry.type') === 'MultiPolygon'
            ? _.map(
                convertMultipolygonToFeatures(
                  _.get(restrictedNewFeature, 'geometry')
                ),
                (feature) => ({
                  ...feature,
                  properties: {
                    hole: mode === MODES.DRAW_HOLE
                  }
                })
              )
            : [
                {
                  ...restrictedNewFeature,
                  properties: {
                    hole: mode === MODES.DRAW_HOLE
                  }
                }
              ];

        const featuresToSet = [...existingFeatures, ...flattenedNewFeature];

        setEditorFeatures(
          _.filter(
            featuresToSet,
            (feature) => !_.isEmpty(_.get(feature, 'geometry.coordinates'))
          )
        );
        setMode(MODES.VIEW);
      }
    },
    [convertMultipolygonToFeatures, mode, restrictToBounding]
  );

  const handleClear = useCallback(() => {
    clearEditor();
  }, [clearEditor]);

  const handleFeatureDelete = useCallback(() => {
    if (selected) {
      setEditorFeatures((features) => {
        const newFeatures = [...features];
        newFeatures.splice(selectedFeatureIndex, 1);
        return newFeatures;
      });
      setSelected(null);
    }
  }, [selected, selectedFeatureIndex]);

  const handleBringForward = useCallback(() => {
    if (selected) {
      if (selectedFeatureIndex < editorFeatures.length - 1) {
        setEditorFeatures((features) => {
          const newFeatures = [...features];
          const next = features[selectedFeatureIndex + 1];
          newFeatures[selectedFeatureIndex + 1] =
            newFeatures[selectedFeatureIndex];
          newFeatures[selectedFeatureIndex] = next;
          return newFeatures;
        });
        setSelected({
          ...selected,
          selectedFeatureIndex: selectedFeatureIndex + 1
        });
      }
    }
  }, [editorFeatures, selected, selectedFeatureIndex]);

  const handleSendBack = useCallback(() => {
    if (selected) {
      if (
        selectedFeatureIndex > 0 &&
        selectedFeatureIndex < editorFeatures.length
      ) {
        setEditorFeatures((features) => {
          const newFeatures = [...features];
          const previous = features[selectedFeatureIndex - 1];
          newFeatures[selectedFeatureIndex - 1] =
            newFeatures[selectedFeatureIndex];
          newFeatures[selectedFeatureIndex] = previous;
          return newFeatures;
        });
        setSelected({
          ...selected,
          selectedFeatureIndex: selectedFeatureIndex - 1
        });
      }
    }
  }, [editorFeatures, selected, selectedFeatureIndex]);

  const setViewMode = useCallback(() => {
    setMode(MODES.VIEW);
    setEditorFeatures((features) =>
      _.chain(features)
        .map(restrictToBounding)
        .filter((feature) => !_.isEmpty(_.get(feature, 'geometry.coordinates')))
        .value()
    );
  }, [restrictToBounding]);

  const toggleModifyMode = useCallback(() => {
    if (mode === MODES.MODIFY) {
      setViewMode();
      return;
    }
    setMode(MODES.MODIFY);
  }, [mode, setViewMode]);

  const toggleEditMode = useCallback(() => {
    if (mode === MODES.EDIT) {
      setViewMode();
    } else {
      setMode(MODES.EDIT);
    }
  }, [mode, setViewMode]);

  const toggleDrawHoleMode = useCallback(() => {
    if (mode === MODES.DRAW_HOLE) {
      setViewMode();
    } else {
      setMode(MODES.DRAW_HOLE);
    }
  }, [mode, setViewMode]);

  const toggleDrawExteriorMode = useCallback(() => {
    if (mode === MODES.DRAW_EXTERIOR) {
      setViewMode();
    } else {
      setMode(MODES.DRAW_EXTERIOR);
    }
  }, [mode, setViewMode]);

  return useMemo(
    () => ({
      editorRef,
      editorMode,
      isEditorActive,
      isEditorInViewMode,
      handleFeatureSelect,
      handleFeatureUpdate,
      handleFeatureDelete,
      handleClear,
      handleBringForward,
      handleSendBack,
      toggleEditMode,
      toggleModifyMode,
      toggleDrawHoleMode,
      toggleDrawExteriorMode,
      setViewMode,
      mode,
      editorFeatures,
      selectedFeature,
      selectedFeatureIndex,
      loadFeatures,
      shapeToSave
    }),
    [
      editorMode,
      handleFeatureDelete,
      handleClear,
      handleFeatureSelect,
      handleFeatureUpdate,
      handleBringForward,
      handleSendBack,
      isEditorActive,
      isEditorInViewMode,
      mode,
      toggleEditMode,
      toggleModifyMode,
      toggleDrawHoleMode,
      toggleDrawExteriorMode,
      setViewMode,
      editorFeatures,
      selectedFeature,
      selectedFeatureIndex,
      loadFeatures,
      shapeToSave
    ]
  );
}
