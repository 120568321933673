import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import {
  getTankMixesConfigActions,
  useGetTankMixesConfigSelectors
} from '../../../../_store/slices/config/tankMixes/getTankMixesConfigSlice';

import TankMixesContent from './TankMixesContent';
import TankMixesProductsContent from './TankMixesProductsContent';

const TankMixesConfigContent = () => {
  const dispatch = useDispatch();
  const [productManagementMode, setProductManagementMode] = useState(false);

  const { success } = useGetTankMixesConfigSelectors();

  useEffect(() => {
    if (!success) {
      dispatch(getTankMixesConfigActions.submit({}));
    }
  }, [dispatch, success]);

  const handleMenageProductsClick = useCallback(() => {
    setProductManagementMode(true);
  }, []);

  const handleManageProductsBackClick = useCallback(() => {
    setProductManagementMode(false);
  }, []);

  return (
    <>
      {productManagementMode && (
        <TankMixesProductsContent onBack={handleManageProductsBackClick} />
      )}
      {!productManagementMode && (
        <TankMixesContent onManageProductsClick={handleMenageProductsClick} />
      )}
    </>
  );
};

export default TankMixesConfigContent;
