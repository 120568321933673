import { useMemo } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';

import { createDataSlice } from '../utils/createDataSlice';

const managementZonesSlice = createDataSlice({
  reducerPath: ['ui', 'managementZones'],
  initialState: { selectedLayersByFieldId: {} },
  reducers: {
    setSelectedLayer: (state, action) => {
      const { layer, fieldId } = action.payload;
      _.set(state, ['selectedLayersByFieldId', fieldId], layer);
    }
  }
});

const { actions, baseSelector: managementZonesSelector } = managementZonesSlice;

export const managementZonesActions = actions;

export const useManagementZonesSelectors = (selectedFieldId) => {
  const { selectedLayersByFieldId } = useSelector(managementZonesSelector);

  const selectedLayer = useMemo(
    () => _.get(selectedLayersByFieldId, [selectedFieldId]),
    [selectedFieldId, selectedLayersByFieldId]
  );

  return useMemo(
    () => ({ selectedLayer, selectedLayersByFieldId }),
    [selectedLayer, selectedLayersByFieldId]
  );
};

export default managementZonesSlice;
