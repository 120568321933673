import { addToken, client } from './client';

export const seasonService = {
  getSeasonWithGrowthsV2,
  getSeasonsV2,
  createSeasonV2,
  addFieldV2,
  updateFieldV2,
  removeFieldV2,
  addDeviceV2,
  updateDeviceV2,
  removeDeviceV2,
  addFieldDeviceV2,
  updateFieldDeviceV2,
  removeFieldDeviceV2,
  createManagementZoneV2,
  updateManagementZoneV2,
  deleteManagementZoneV2
};

async function getSeasonWithGrowthsV2(token, growerId, seasonId) {
  const response = await client.get(
    `/v2/growers/${growerId}/seasons/${seasonId}/with-growths`,
    addToken(token)
  );
  return response.data;
}

async function getSeasonsV2(token, growerId) {
  const response = await client.get(
    `/v2/growers/${growerId}/seasons`,
    addToken(token)
  );
  return response.data;
}

async function createSeasonV2(token, growerId, seasonYear) {
  const response = await client.post(
    `/v2/growers/${growerId}/seasons`,
    { seasonYear },
    addToken(token)
  );
  return response.data;
}

async function addFieldV2(token, growerId, seasonId, farmId, fieldId, growth) {
  const response = await client.post(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/fields/${fieldId}`,
    { growth },
    addToken(token)
  );
  return response.data;
}

async function updateFieldV2(
  token,
  growerId,
  seasonId,
  farmId,
  fieldId,
  updatedField
) {
  const response = await client.put(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/fields/${fieldId}`,
    updatedField,
    addToken(token)
  );
  return response.data;
}

async function removeFieldV2(token, growerId, seasonId, farmId, fieldId) {
  const response = await client.delete(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/fields/${fieldId}`,
    addToken(token)
  );
  return response.data;
}

async function addDeviceV2(token, growerId, seasonId, device) {
  const response = await client.post(
    `/v2/growers/${growerId}/seasons/${seasonId}/devices`,
    device,
    addToken(token)
  );
  return response.data;
}

async function updateDeviceV2(token, growerId, seasonId, deviceId, device) {
  const response = await client.put(
    `/v2/growers/${growerId}/seasons/${seasonId}/devices/${deviceId}`,
    device,
    addToken(token)
  );
  return response.data;
}

async function removeDeviceV2(token, growerId, seasonId, deviceId) {
  const response = await client.delete(
    `/v2/growers/${growerId}/seasons/${seasonId}/devices/${deviceId}`,
    addToken(token)
  );
  return response.data;
}

async function addFieldDeviceV2(
  token,
  growerId,
  seasonId,
  farmId,
  fieldId,
  device
) {
  const response = await client.post(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/fields/${fieldId}/devices`,
    device,
    addToken(token)
  );
  return response.data;
}

async function updateFieldDeviceV2(
  token,
  growerId,
  seasonId,
  farmId,
  fieldId,
  deviceId,
  device
) {
  const response = await client.put(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/fields/${fieldId}/devices/${deviceId}`,
    device,
    addToken(token)
  );
  return response.data;
}

async function removeFieldDeviceV2(
  token,
  growerId,
  seasonId,
  farmId,
  fieldId,
  deviceId
) {
  const response = await client.delete(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/fields/${fieldId}/devices/${deviceId}`,
    addToken(token)
  );
  return response.data;
}

async function createManagementZoneV2(
  token,
  growerId,
  seasonId,
  farmId,
  fieldId,
  managementZone
) {
  const response = await client.post(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/fields/${fieldId}/management-zones`,
    managementZone,
    addToken(token)
  );
  return response.data;
}

async function updateManagementZoneV2(
  token,
  growerId,
  seasonId,
  farmId,
  fieldId,
  zoneId,
  managementZone
) {
  const response = await client.put(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/fields/${fieldId}/management-zones/${zoneId}`,
    managementZone,
    addToken(token)
  );
  return response.data;
}

async function deleteManagementZoneV2(
  token,
  growerId,
  seasonId,
  farmId,
  fieldId,
  zoneId
) {
  const response = await client.delete(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/fields/${fieldId}/management-zones/${zoneId}`,
    addToken(token)
  );
  return response.data;
}
