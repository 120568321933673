import { useMemo } from 'react';

import { growerProtocolsConfigService } from '../../../../../services/growerProtocolsConfig.service';
import { createApiSlice } from '../../../utils/createApiSlice';

const updateGrowerActivitySlice = createApiSlice({
  reducerPath: ['gff', 'growers', 'protocols', 'updateGrowerActivity'],
  apiFunction: growerProtocolsConfigService.updateActivityV2,
  apiArgumentsFn: ({
    growerId,
    bundleId,
    protocolId,
    activityId,
    activity
  }) => [growerId, bundleId, protocolId, activityId, activity]
});

const { actions, useApiSelectors } = updateGrowerActivitySlice;

export const updateGrowerActivityActions = actions;

export const useUpdateGrowerActivitySelectors = () => {
  const { inProgress, success, errorMessage, response, updatedAt } =
    useApiSelectors();

  return useMemo(
    () => ({ inProgress, success, errorMessage, response, updatedAt }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default updateGrowerActivitySlice;
