import { useMemo } from 'react';

import { tankMixesConfigService } from '../../../../services/tankMixesConfig.service';
import { createApiSlice } from '../../utils/createApiSlice';

const createTankMixSlice = createApiSlice({
  reducerPath: ['newConfig', 'tankMixes', 'createTankMix'],
  apiFunction: tankMixesConfigService.createTankMixV2,
  apiArgumentsFn: ({ tankMix }) => [tankMix]
});

const { actions, useApiSelectors } = createTankMixSlice;

export const createTankMixActions = actions;

export const useCreateTankMixSelectors = () => {
  const { inProgress, success, errorMessage, response, updatedAt } =
    useApiSelectors();

  return useMemo(
    () => ({ inProgress, success, errorMessage, response, updatedAt }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default createTankMixSlice;
