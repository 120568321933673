import { useMemo } from 'react';

import { faCompass } from '@fortawesome/free-regular-svg-icons';

import { userRoles } from '../../_constants/userConstants';

const MapPageKey = 'MAP_PAGE';

const baseConfig = {
  key: MapPageKey,
  icon: faCompass,
  label: 'Farm Map',
  path: 'map',
  allowedRoles: [
    userRoles.Admin,
    userRoles.Consultant,
    userRoles.User,
    userRoles.UserView
  ]
};
export const useMapPageConfig = () => {
  return useMemo(() => ({ config: baseConfig }), []);
};
