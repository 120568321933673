import { useMemo } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';

import { createDataSlice } from '../../utils/createDataSlice';

export const LocationSources = {
  Field: 'Field',
  BrowserLocation: 'BrowserLocation',
  WeatherStation: 'WeatherStation'
};

const weatherDashboardSlice = createDataSlice({
  reducerPath: ['ui', 'weatherDashboard'],
  initialState: { locationSource: LocationSources.Field },
  reducers: {
    setLocationSource: (state, action) => {
      const locationSource = action.payload;
      _.set(state, 'locationSource', locationSource);
    }
  }
});

const { actions, baseSelector: weatherDashboardSelector } =
  weatherDashboardSlice;

export const weatherDashboardActions = actions;

export const useWeatherDashboardSelectors = () => {
  const { locationSource } = useSelector(weatherDashboardSelector);

  return useMemo(
    () => ({
      locationSource
    }),
    [locationSource]
  );
};

export default weatherDashboardSlice;
