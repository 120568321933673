import React, { useCallback, useEffect, useState } from 'react';

import { FormHelperText, Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PublishIcon from '@material-ui/icons/Publish';
import { Alert } from '@material-ui/lab';
import cx from 'classnames';
import _ from 'lodash';

import { useSavedReportsFilters } from '../../_hooks/Reports/useSavedReportsFilters';
import { useReportsFieldsSelectionSelectors } from '../../_store/slices/reports/reportsFieldsSelectionSlice';
import { useReportsFiltersSelectors } from '../../_store/slices/reports/reportsFiltersSlice';
import ConfirmIconButtons from '../../Components/ConfirmIconButtons';
import LoadingIndicator from '../../Components/LoadingIndicator';

const useStyles = makeStyles({
  container: {
    padding: '0.5rem',
    color: '#D1D1D1'
  },
  buttonLabel: {
    color: '#D1D1D1'
  },
  buttonDisabledLabel: {
    color: 'rgba(209,209,209,0.41)'
  },
  buttonsContainer: {
    marginBottom: '0.5rem'
  },
  input: {
    marginTop: '0.5rem',
    backgroundColor: '#FFFFFF'
  },
  title: { textAlign: 'center', fontWeight: '500', marginBottom: '0.25rem' },
  filterItems: {
    marginTop: '0.5rem',
    width: '100%'
  },
  filterItem: {
    marginLeft: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  iconButton: {
    color: '#D1D1D1'
  },
  formError: {
    padding: '0.25rem'
  },
  selectedFilter: {
    fontWeight: 'bold',
    color: '#FFFFFF'
  },
  iconButtonsContainer: {
    display: 'flex',
    flexDirection: 'row'
  }
});

const SaveDialog = ({
  filterToEdit,
  savedFilters,
  fieldsChecked,
  onSave,
  onEditSave,
  onCancel
}) => {
  const styles = useStyles();

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState();
  const [duplicatedError, setDuplicatedError] = useState();

  const isEdit = !!filterToEdit;

  useEffect(() => {
    return () => {
      setDuplicatedError(undefined);
    };
  }, []);

  useEffect(() => {
    if (!!filterToEdit) {
      setName(_.get(filterToEdit, 'name'));
    }
  }, [filterToEdit]);

  const handleSave = useCallback(() => {
    if (_.isEmpty(name)) {
      setNameError('Name cannot be empty');
      return;
    }

    const existingFilterByName = _.find(
      savedFilters,
      (filter) =>
        _.get(filter, 'name', '').trim().toLowerCase() ===
          name.trim().toLowerCase() &&
        (!isEdit || _.get(filter, 'id') !== _.get(filterToEdit, 'id'))
    );

    if (existingFilterByName) {
      setNameError(
        `Filter with name "${_.get(
          existingFilterByName,
          'name'
        )}" already exists`
      );
      return;
    }

    const existingFilterByStructure = _.find(savedFilters, (filter) =>
      _.isEqual(_.get(filter, 'fieldsChecked'), fieldsChecked)
    );

    if (!isEdit && existingFilterByStructure) {
      setDuplicatedError(
        `Filter "${_.get(
          existingFilterByStructure,
          'name'
        )}" with the same selection  already exists`
      );
      return;
    }

    if (isEdit) {
      onEditSave(_.get(filterToEdit, 'id'), name);
    } else {
      onSave(name, fieldsChecked);
    }
  }, [
    fieldsChecked,
    filterToEdit,
    isEdit,
    name,
    onEditSave,
    onSave,
    savedFilters
  ]);

  const handleNameChange = useCallback((e) => {
    setName(e.target.value);
    setNameError(undefined);
  }, []);

  return (
    <Grid container className={styles.buttonsContainer}>
      <Grid item xs={12}>
        <Typography className={styles.title}>Saved Filters</Typography>
      </Grid>
      <Grid item xs={6}>
        <Button
          size="small"
          classes={{ label: styles.buttonLabel }}
          fullWidth
          onClick={onCancel}
        >
          Cancel
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          size="small"
          classes={{ label: styles.buttonLabel }}
          fullWidth
          onClick={handleSave}
        >
          Save
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={styles.input}
          value={name}
          onChange={handleNameChange}
          variant="filled"
          size="small"
          fullWidth
          required
          id="name"
          label="Name"
          name="name"
          error={!!nameError}
          helperText={nameError}
        ></TextField>
      </Grid>
      {!!duplicatedError && (
        <Grid item xs={12}>
          <FormHelperText error className={styles.formError}>
            {duplicatedError}
          </FormHelperText>
        </Grid>
      )}
    </Grid>
  );
};

const FilterItem = ({ filter, onDelete, onLoad, onEdit }) => {
  const styles = useStyles();

  const { selectedFilter } = useReportsFiltersSelectors();

  const selectedFilterId = _.get(selectedFilter, 'id');
  const isSelected = _.get(filter, 'id') === selectedFilterId;

  const [showDeleteConfirmButtons, setShowDeleteConfirmButtons] =
    useState(false);

  const handleEdit = useCallback(() => {
    onEdit(filter);
  }, [filter, onEdit]);

  const handleDelete = useCallback(() => {
    setShowDeleteConfirmButtons(true);
  }, []);

  const handleLoad = useCallback(() => {
    onLoad(filter);
  }, [filter, onLoad]);

  const handleDeleteConfirm = useCallback(() => {
    onDelete(_.get(filter, 'id'));
  }, [filter, onDelete]);

  const handleDeleteCancel = useCallback(() => {
    setShowDeleteConfirmButtons(false);
  }, []);

  return (
    <Box className={styles.filterItem}>
      <Typography className={cx({ [styles.selectedFilter]: isSelected })}>
        {_.get(filter, 'name')}
      </Typography>
      <Box className={styles.iconButtonsContainer}>
        {!showDeleteConfirmButtons && (
          <>
            <Tooltip title="Edit Name" placement="bottom">
              <IconButton
                size="small"
                className={styles.iconButton}
                onClick={handleEdit}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Filter" placement="bottom">
              <IconButton
                size="small"
                className={styles.iconButton}
                onClick={handleDelete}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Load Filter" placement="bottom">
              <IconButton
                size="small"
                className={styles.iconButton}
                onClick={handleLoad}
              >
                <PublishIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
        {showDeleteConfirmButtons && (
          <ConfirmIconButtons
            onCancel={handleDeleteCancel}
            onConfirm={handleDeleteConfirm}
            size="small"
          />
        )}
      </Box>
    </Box>
  );
};

const SavedFilters = ({
  onSave,
  onEdit,
  onBack,
  savedFilters,
  onDelete,
  onLoad,
  errorMessage
}) => {
  const styles = useStyles();

  return (
    <>
      <Grid container className={styles.buttonsContainer}>
        <Grid item xs={12}>
          <Typography className={styles.title}>Saved Filters</Typography>
        </Grid>
        <Grid item xs={6}>
          <Button
            size="small"
            classes={{ label: styles.buttonLabel }}
            fullWidth
            onClick={onBack}
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            size="small"
            classes={{ label: styles.buttonLabel }}
            fullWidth
            onClick={onSave}
          >
            Save Selection
          </Button>
        </Grid>
        <Box className={styles.filterItems}>
          {_.map(savedFilters, (filter) => (
            <FilterItem
              key={_.get(filter, 'id')}
              filter={filter}
              onDelete={onDelete}
              onLoad={onLoad}
              onEdit={onEdit}
            />
          ))}
        </Box>
      </Grid>
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
    </>
  );
};

const ReportsSavedFilters = ({ onHideSavedFilters }) => {
  const styles = useStyles();

  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [filterToEdit, setFilterToEdit] = useState();

  const showDialog = showSaveDialog || !!filterToEdit;

  const { fieldsChecked } = useReportsFieldsSelectionSelectors();

  const {
    inProgress,
    errorMessage,
    savedFilters,
    saveFilters,
    saveEditFilters,
    deleteFilters,
    loadFilters
  } = useSavedReportsFilters();

  const handleShowSaveDialog = useCallback(() => {
    setShowSaveDialog(true);
  }, []);

  const handleHideSaveDialog = useCallback(() => {
    setShowSaveDialog(false);
    setFilterToEdit(undefined);
  }, []);

  const handleSave = useCallback(
    (name, fields) => {
      saveFilters(name, fields);
      handleHideSaveDialog();
    },
    [handleHideSaveDialog, saveFilters]
  );

  const handleEditSave = useCallback(
    (filterId, name) => {
      saveEditFilters(filterId, name);
      handleHideSaveDialog();
    },
    [handleHideSaveDialog, saveEditFilters]
  );

  const handleLoad = useCallback(
    (filter) => {
      loadFilters(filter);
      onHideSavedFilters();
    },
    [loadFilters, onHideSavedFilters]
  );

  const handleEdit = useCallback((filter) => {
    setFilterToEdit(filter);
  }, []);

  return (
    <Box className={styles.container}>
      {!inProgress && (
        <>
          {showDialog && (
            <SaveDialog
              filterToEdit={filterToEdit}
              savedFilters={savedFilters}
              fieldsChecked={fieldsChecked}
              onCancel={handleHideSaveDialog}
              onSave={handleSave}
              onEditSave={handleEditSave}
            />
          )}
          {!showDialog && (
            <SavedFilters
              onBack={onHideSavedFilters}
              onSave={handleShowSaveDialog}
              onEdit={handleEdit}
              savedFilters={savedFilters}
              onDelete={deleteFilters}
              onLoad={handleLoad}
              errorMessage={errorMessage}
            />
          )}
        </>
      )}
      {inProgress && <LoadingIndicator />}
    </Box>
  );
};

export default ReportsSavedFilters;
