import { useMemo } from 'react';

import { growerBundlesConfigService } from '../../../../../services/growerBundlesConfig.service';
import { createApiSlice } from '../../../utils/createApiSlice';

const createGrowerBundleSlice = createApiSlice({
  reducerPath: ['gff', 'growers', 'bundles', 'createGrowerBundle'],
  apiFunction: growerBundlesConfigService.createBundleV2,
  apiArgumentsFn: ({ growerId, bundle }) => [growerId, bundle]
});

const { actions, useApiSelectors } = createGrowerBundleSlice;

export const createGrowerBundleActions = actions;

export const useCreateGrowerBundleSelectors = () => {
  const { inProgress, success, errorMessage, response, updatedAt } =
    useApiSelectors();

  return useMemo(
    () => ({ inProgress, success, errorMessage, response, updatedAt }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default createGrowerBundleSlice;
