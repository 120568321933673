import _ from 'lodash';
import moment from 'moment';
import { REHYDRATE } from 'redux-persist';
import {
  all,
  fork,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';

import { apiUpdateIntervals } from '../../config';
import {
  selectFarmAction,
  selectFieldAction,
  selectGrowerAction,
  selectSeasonAction
} from '../actions/farmStructureActions';
import { getGrowersActions } from '../actions/growersAction';
import { deleteFarmActionTypes } from '../actionTypes/farmsActionTypes';
import { farmStructureActionTypes } from '../actionTypes/farmStructureActionTypes';
import { deleteFieldActionTypes } from '../actionTypes/fieldsActionTypes';
import {
  deleteGrowerActionTypes,
  getGrowersActionTypes
} from '../actionTypes/growersActionTypes';
import {
  createGrowthActionTypes,
  deleteGrowthActionTypes,
  modifyGrowthActionTypes
} from '../actionTypes/growthsActionTypes';
import { requestStatus } from '../helpers/requestStatus';
import {
  farmIdSelector,
  fieldIdSelector,
  growerIdSelector,
  seasonIdSelector
} from '../selectors/farmStructureSelectors';
import {
  growersSelector,
  growersStatusSelector,
  growersUpdatedAtSelector
} from '../selectors/growersSelectors';
import { addFieldToSeasonActions } from '../slices/gff/seasons/addFieldToSeasonSlice';
import { createGrowerSeasonActions } from '../slices/gff/seasons/createGrowerSeasonSlice';
import {
  getSeasonsActions,
  getSeasonsSelectors
} from '../slices/gff/seasons/getSeasonsSlice';
import {
  getSeasonWithGrowthsActions,
  getSeasonWithGrowthsSelectors
} from '../slices/gff/seasons/getSeasonsWithGrowthsSlice';
import {
  globalSeasonActions,
  globalSeasonSelector
} from '../slices/gff/seasons/globalSeasonSlice';
import { removeFieldFromSeasonActions } from '../slices/gff/seasons/removeFieldFromSeasonSlice';
import { authActions, authSelectors } from '../slices/user/authSlice';
import { tokenRefreshActions } from '../slices/user/tokenRefreshSlice';

function* updateGrowers() {
  const growersStatus = yield select(growersStatusSelector);
  const updatedAt = yield select(growersUpdatedAtSelector);
  const shouldUpdate =
    !updatedAt ||
    moment().diff(moment(updatedAt), 'seconds') >
      apiUpdateIntervals.growersUpdateIntervalSeconds ||
    growersStatus === requestStatus.FAILURE;
  if (shouldUpdate) {
    yield forceUpdateGrowers();
  }
}

function* forceUpdateGrowers() {
  const growersStatus = yield select(growersStatusSelector);
  if (growersStatus !== requestStatus.IN_PROGRESS) {
    yield put(getGrowersActions.request());
  }
}

function* forceUpdateSeasons(growerId) {
  const seasonsStatus = yield select(getSeasonsSelectors.status(growerId));

  if (seasonsStatus !== requestStatus.IN_PROGRESS) {
    yield put(getSeasonsActions.request(growerId));
  }
}

function* updateSeasons(growerId) {
  const seasonsStatus = yield select(getSeasonsSelectors.status(growerId));
  const updatedAt = yield select(getSeasonsSelectors.updatedAt(growerId));

  const shouldUpdate =
    !updatedAt ||
    moment().diff(moment(updatedAt), 'seconds') >
      apiUpdateIntervals.growersUpdateIntervalSeconds ||
    seasonsStatus === requestStatus.FAILURE;
  if (shouldUpdate) {
    yield forceUpdateSeasons(growerId);
  }
}

function* forceUpdateSeasonWithGrowths(growerId, seasonId) {
  const seasonsStatus = yield select(
    getSeasonWithGrowthsSelectors.status(growerId)
  );

  if (seasonsStatus !== requestStatus.IN_PROGRESS) {
    yield put(getSeasonWithGrowthsActions.request(growerId, seasonId));
  }
}

function* updateSeasonWithGrowths(growerId, seasonId, force = false) {
  const seasonsStatus = yield select(
    getSeasonWithGrowthsSelectors.status(growerId, seasonId)
  );
  const updatedAt = yield select(
    getSeasonWithGrowthsSelectors.updatedAt(growerId, seasonId)
  );

  const shouldUpdate =
    !updatedAt ||
    moment().diff(moment(updatedAt), 'seconds') >
      apiUpdateIntervals.growersUpdateIntervalSeconds ||
    seasonsStatus === requestStatus.FAILURE ||
    force;
  if (shouldUpdate) {
    yield forceUpdateSeasonWithGrowths(growerId, seasonId);
  }
}

function* updateSeasonsWithGrowths(growerId, force = false) {
  const growers = yield select(growersSelector);
  const grower = _.find(growers, (grower) => grower.id === growerId);

  const selectedSeasonId = yield select(seasonIdSelector);

  const seasonsToUpdate = _.chain(grower)
    .get('seasons')
    .filter(
      (season) =>
        !_.get(season, 'isHistorical') ||
        (!!selectedSeasonId && _.get(season, 'id') === selectedSeasonId)
    )
    .value();

  for (let season of seasonsToUpdate) {
    yield updateSeasonWithGrowths(growerId, _.get(season, 'id'), force);
  }
}

function* handleGrowersFetched(action) {
  const growers = _.get(action, 'payload.response');
  const selectedGrowerId = yield select(growerIdSelector);
  const selectedGrower = _.find(
    growers,
    (grower) => _.get(grower, 'id') === selectedGrowerId
  );
  if (!!growers && selectedGrower) {
    yield forceUpdateSeasons(selectedGrowerId);
    yield updateSeasonsWithGrowths(selectedGrowerId, true);
  }

  // Select a grower if there is only one
  if (!!growers && growers.length === 1 && !selectedGrower) {
    const growerId = _.get(growers, '0.id');
    yield put(selectGrowerAction(growerId));
  }
}

function* handleSeasonWithGrowthsSuccess(action) {
  const growerId = _.get(action, 'payload.apiArguments.0');

  const season = _.get(action, 'payload.response');
  const farms = _.get(season, 'farms');

  const selectedGrowerId = yield select(growerIdSelector);
  const seasonId = _.get(season, 'id');

  const growers = yield select(growersSelector);
  if (
    _.get(growers, 'length', 0) === 1 &&
    _.get(farms, 'length', 0) === 1 &&
    growerId === selectedGrowerId
  ) {
    yield put(selectFarmAction(growerId, seasonId, _.get(farms, '0.id')));
  }
}

function* selectSeason(year) {
  const selectedGrowerId = yield select(growerIdSelector);
  if (!selectedGrowerId) {
    return;
  }
  const seasons = yield select(getSeasonsSelectors.response(selectedGrowerId));

  const { selectedSeason: selectedSeasonYear } = yield select(
    globalSeasonSelector
  );
  const seasonYear = year || selectedSeasonYear;

  const selectedSeason = _.find(
    seasons,
    (season) => _.get(season, 'seasonYear') === seasonYear
  );

  if (!selectedSeason) {
    yield put(selectSeasonAction(selectedGrowerId, null));
    return;
  }
  const selectedFieldId = yield select(fieldIdSelector);

  const activeSeasonId = _.get(selectedSeason, 'id');

  yield updateSeasonWithGrowths(selectedGrowerId, activeSeasonId);

  const farms = _.chain(selectedSeason).get('farms').values().value();

  const farm = _.find(farms, (farm) => {
    return _.chain(farm).get('fields').has(selectedFieldId).value();
  });

  const selectedFarmId = _.get(farm, 'id');

  if (!farm) {
    yield put(selectSeasonAction(selectedGrowerId, activeSeasonId));
    return;
  }
  const field = selectedFieldId
    ? _.get(farm, ['fields', selectedFieldId])
    : undefined;

  if (!field) {
    yield put(
      selectFarmAction(selectedGrowerId, activeSeasonId, selectedFarmId)
    );
    return;
  }
  yield put(
    selectFieldAction(
      selectedGrowerId,
      activeSeasonId,
      selectedFarmId,
      selectedFieldId
    )
  );
}

function* handleGetSeasonsSuccess() {
  yield selectSeason();
}

function* updateSelectedGrowerSeasons() {
  const selectedGrowerId = yield select(growerIdSelector);
  if (!!selectedGrowerId) {
    yield updateSeasons(selectedGrowerId);
    yield updateSeasonsWithGrowths(selectedGrowerId);
  }
}

function* handleUpdateFarmStructure() {
  const currentUser = yield select(authSelectors.getCurrentUser);
  if (currentUser) {
    yield updateGrowers();
    yield updateSelectedGrowerSeasons();
  }
}

function* handleForceUpdateFarmStructure() {
  yield forceUpdateGrowers();
}

function* handleGrowerSelected() {
  yield updateSelectedGrowerSeasons();
  yield selectSeason();
}

function* handleGrowthUpdated(action) {
  const growerId = _.get(action, 'payload.apiArguments.0');
  const seasonId = _.get(action, 'payload.apiArguments.1');
  yield forceUpdateSeasonWithGrowths(growerId, seasonId);
}

function* handleGrowerDeleted(action) {
  const growerId = _.get(action, 'payload.apiArguments.0');
  const selectedGrowerId = yield select(growerIdSelector);
  if (growerId === selectedGrowerId) {
    yield put(selectGrowerAction(null));
  }
}

function* handleFarmDeleted(action) {
  const growerId = _.get(action, 'payload.apiArguments.0');
  const farmId = _.get(action, 'payload.apiArguments.1');
  const selectedGrowerId = yield select(growerIdSelector);
  const selectedFarmId = yield select(farmIdSelector);
  if (growerId === selectedGrowerId && farmId === selectedFarmId) {
    yield put(selectFarmAction(growerId, null));
  }
}

function* handleFieldDeleted(action) {
  const growerId = _.get(action, 'payload.apiArguments.0');
  const farmId = _.get(action, 'payload.apiArguments.1');
  const fieldId = _.get(action, 'payload.apiArguments.2');
  const selectedGrowerId = yield select(growerIdSelector);
  const selectedFarmId = yield select(farmIdSelector);
  const selectedFieldId = yield select(fieldIdSelector);
  if (
    growerId === selectedGrowerId &&
    farmId === selectedFarmId &&
    fieldId === selectedFieldId
  ) {
    yield put(selectFieldAction(growerId, farmId, null));
  }
}

function* handleForceUpdateSeasonWithGrowths(action) {
  const { growerId, seasonId } = action.payload;
  if (!growerId || !seasonId) {
    return;
  }
  yield forceUpdateSeasonWithGrowths(growerId, seasonId);
}

function* handleGlobalSeasonSelected(action) {
  const { season } = action.payload;
  yield selectSeason(season);
}

function* handleGrowerSeasonCreated(action) {
  const growerId = _.get(action, 'payload.apiArguments.0');
  yield forceUpdateGrowers();
  yield forceUpdateSeasons(growerId);
}

function* handleGrowerSeasonModified(action) {
  const growerId = _.get(action, 'payload.apiArguments.0');
  yield forceUpdateSeasons(growerId);
  yield updateSeasonsWithGrowths(growerId, true);
}

function* watchRehydrate() {
  // REHYDRATE is dispatched by redux-persist every time the state is rehydrated
  yield takeEvery(REHYDRATE, handleUpdateFarmStructure);
}

function* watchLogin() {
  yield takeEvery(authActions.success.type, handleUpdateFarmStructure);
}

function* watchTokenRefresh() {
  yield takeEvery(tokenRefreshActions.success.type, handleUpdateFarmStructure);
}

function* watchSelectGrower() {
  yield takeEvery(farmStructureActionTypes.SELECT_GROWER, handleGrowerSelected);
}

function* watchGrowersFetched() {
  yield takeEvery(getGrowersActionTypes.SUCCEEDED, handleGrowersFetched);
}

function* watchForceUpdateFarmStructure() {
  yield takeLatest(
    farmStructureActionTypes.FORCE_UPDATE_FARM_STRUCTURE,
    handleForceUpdateFarmStructure
  );
}

function* watchGrowthCreated() {
  yield takeLatest(createGrowthActionTypes.SUCCEEDED, handleGrowthUpdated);
}

function* watchGrowthModified() {
  yield takeLatest(modifyGrowthActionTypes.SUCCEEDED, handleGrowthUpdated);
}

function* watchGrowthDeleted() {
  yield takeLatest(deleteGrowthActionTypes.SUCCEEDED, handleGrowthUpdated);
}

function* watchGrowerDeleted() {
  yield takeLatest(deleteGrowerActionTypes.SUCCEEDED, handleGrowerDeleted);
}

function* watchFarmDeleted() {
  yield takeLatest(deleteFarmActionTypes.SUCCEEDED, handleFarmDeleted);
}

function* watchFieldDeleted() {
  yield takeLatest(deleteFieldActionTypes.SUCCEEDED, handleFieldDeleted);
}

function* watchGetSeasonsSuccess() {
  yield takeLatest(getSeasonsActions.success.type, handleGetSeasonsSuccess);
}

function* watchForceUpdateSeasonWithGrowths() {
  yield takeLatest(
    getSeasonWithGrowthsActions.forceUpdate.type,
    handleForceUpdateSeasonWithGrowths
  );
}

function* watchSeasonWithGrowthsSuccess() {
  yield takeLatest(
    getSeasonWithGrowthsActions.success.type,
    handleSeasonWithGrowthsSuccess
  );
}

function* watchGlobalSeasonSelected() {
  yield takeLatest(
    globalSeasonActions.selectSeason.type,
    handleGlobalSeasonSelected
  );
}

function* watchGrowerSeasonCreated() {
  yield takeLatest(
    createGrowerSeasonActions.success.type,
    handleGrowerSeasonCreated
  );
}

function* watchGrowerSeasonFieldAdded() {
  yield takeLatest(
    addFieldToSeasonActions.success.type,
    handleGrowerSeasonModified
  );
}

function* watchGrowerSeasonFieldRemoved() {
  yield takeLatest(
    removeFieldFromSeasonActions.success.type,
    handleGrowerSeasonModified
  );
}

function* farmStructureSaga() {
  yield all([
    fork(watchRehydrate),
    fork(watchLogin),
    fork(watchTokenRefresh),
    fork(watchSelectGrower),
    fork(watchGrowersFetched),
    fork(watchForceUpdateFarmStructure),
    fork(watchGrowthCreated),
    fork(watchGrowthModified),
    fork(watchGrowthDeleted),
    fork(watchGrowerDeleted),
    fork(watchFarmDeleted),
    fork(watchFieldDeleted),
    fork(watchGetSeasonsSuccess),
    fork(watchForceUpdateSeasonWithGrowths),
    fork(watchSeasonWithGrowthsSuccess),
    fork(watchGlobalSeasonSelected),
    fork(watchGrowerSeasonCreated),
    fork(watchGrowerSeasonFieldAdded),
    fork(watchGrowerSeasonFieldRemoved)
  ]);
}

export default farmStructureSaga;
