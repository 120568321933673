import React, { useCallback } from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Pagination } from '@material-ui/lab';

export default function CommonPagination({ paginationControls }) {
  const { page, onPageChange, pagesCount } = paginationControls;

  const showLongPagination = useMediaQuery((theme) =>
    theme.breakpoints.up('sm')
  );

  const handlePageChange = useCallback(
    (event, newPage) => {
      onPageChange(newPage - 1);
    },
    [onPageChange]
  );

  return (
    <Pagination
      count={pagesCount + 1}
      page={page + 1}
      onChange={handlePageChange}
      color="primary"
      siblingCount={showLongPagination ? 3 : 0}
    />
  );
}
