import React from 'react';

import { growthStagesTypes } from '../../../_constants/growthsConstants';

import GrowthModelReport from './GrowthModelReport';

const reportGrowthStages = [
  growthStagesTypes.Planting,
  growthStagesTypes.Emergence,
  growthStagesTypes.Leaf2,
  growthStagesTypes.Leaf4,
  growthStagesTypes.GreenRing,
  growthStagesTypes.Pd,
  growthStagesTypes.EarlyBoot,
  growthStagesTypes.LateBoot,
  growthStagesTypes.Heading50X,
  growthStagesTypes.PhysiologicalMaturity,
  growthStagesTypes.Harvest,
  growthStagesTypes.Historical
];

const FullSeasonGrowthModelReport = () => (
  <GrowthModelReport reportGrowthStages={reportGrowthStages} />
);

export default FullSeasonGrowthModelReport;
