export const layerStyle = {
  id: 'fields',
  type: 'fill',
  paint: {
    'fill-color': {
      stops: [
        [true, '#ff6347'],
        [false, '#3cb371']
      ],
      property: 'selected',
      type: 'categorical'
    },
    'fill-outline-color': 'red',
    'fill-opacity': 0.6
  }
};

export const outlineLayerStyle = {
  id: 'fields-outline',
  type: 'line',
  paint: {
    'line-color': {
      stops: [
        [true, '#a23f2d'],
        [false, '#76ff03']
      ],
      property: 'selected',
      type: 'categorical'
    },
    'line-width': 3,
    'line-opacity': 1,
    'line-blur': 1.25
  }
};
