import React, { useContext } from 'react';

import _ from 'lodash';

import {
  aquariusGrowerDevicesTypes,
  growerDevicesVendors
} from '../../../_constants/growerDevicesConstants';
import { FarmStructureContext } from '../../../_context/FarmStructureContext';
import { useGetCurrentDeviceStateSelectors } from '../../../_store/slices/devices/state/getCurrentDeviceStateSlice';

import FlowMeterIcon from './Icons/FlowMeterIcon';
import LevelIcon from './Icons/LevelIcon';
import MoistureIcon from './Icons/MoistureIcon';
import PumpIcon from './Icons/PumpIcon';
import TelemetryIcon from './Icons/TelemetryIcon';
import WeatherStationIcon from './Icons/WeatherStationIcon';

const AmbientDeviceIcon = ({ size, onClick, style }) => {
  return <WeatherStationIcon size={size} style={style} onClick={onClick} />;
};

const AquariusPumpIcon = ({ device, size, onClick, style }) => {
  const { selectedGrowerId, selectedSeasonId } =
    useContext(FarmStructureContext);

  const pumpId = _.get(device, 'id');

  const { state } = useGetCurrentDeviceStateSelectors(
    selectedGrowerId,
    selectedSeasonId,
    pumpId
  );

  const pumpState = _.get(state, 'currentState.state');

  return (
    <PumpIcon size={size} onClick={onClick} style={style} state={pumpState} />
  );
};

const AquariusDeviceIcon = ({ device, size, onClick, style }) => {
  switch (device.deviceType) {
    case aquariusGrowerDevicesTypes.MoistureSensor.deviceType:
      return <MoistureIcon size={size} onClick={onClick} style={style} />;
    case aquariusGrowerDevicesTypes.WaterLevelSensor.deviceType:
      return <LevelIcon size={size} onClick={onClick} style={style} />;
    case aquariusGrowerDevicesTypes.FlowMeter.deviceType:
      return <FlowMeterIcon size={size} onClick={onClick} style={style} />;
    case aquariusGrowerDevicesTypes.TelemetryHub.deviceType:
      return <TelemetryIcon size={size} onClick={onClick} style={style} />;
    case aquariusGrowerDevicesTypes.PumpController.deviceType:
      return (
        <AquariusPumpIcon
          device={device}
          size={size}
          onClick={onClick}
          style={style}
        />
      );
    default:
      return <></>;
  }
};

const DeviceIcon = ({ device, size, onClick = _.noop, style }) => {
  switch (device.vendor) {
    case growerDevicesVendors.Aquarius.type:
      return (
        <AquariusDeviceIcon
          device={device}
          size={size}
          onClick={onClick}
          style={style}
        />
      );
    case growerDevicesVendors.Ambient.type:
      return <AmbientDeviceIcon size={size} onClick={onClick} style={style} />;
    default:
      return <></>;
  }
};

export default DeviceIcon;
