import React, { useMemo, useRef } from 'react';

import _ from 'lodash';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';

import { growthStagesTypes } from '../../../_constants/growthsConstants';
import { useGrowthStagesConfig } from '../../../_store/selectors/configSelectors';
import { commonLineDataSetParams } from '../../WeatherDashboard/components/weatherChartUtils';

import { useChartDataType } from './_hooks/useChartDataType';
import ReportChartButtons from './ReportChartButtons';
import ReportsChartContainer from './ReportsChartContainer';

function GrowthStageReportChart({
  fields,
  growthStage = growthStagesTypes.Harvest
}) {
  const chartDataType = useChartDataType();
  const { dataType, showAcres } = chartDataType;

  const { growthStagesConfig } = useGrowthStagesConfig();

  const growthStageName = _.get(growthStagesConfig, [growthStage, 'name']);

  const chartRef = useRef(null);

  const fieldsByHarvestDates = useMemo(() => {
    return _.chain(fields)
      .filter((field) => !!_.get(field, ['growthStages', growthStage]))
      .map((field) => ({
        ...field,
        stageObserved: _.get(field, ['growthStages', growthStage, 'observed'])
      }))
      .groupBy((field) => _.get(field, ['growthStages', growthStage, 'date']))
      .value();
  }, [fields, growthStage]);

  const harvestStatistics = useMemo(() => {
    if (_.isEmpty(fieldsByHarvestDates)) {
      return {};
    }
    const firstDate = moment(
      _.chain(fieldsByHarvestDates).keys().sortBy().first().value()
    );
    const lastDate = moment(
      _.chain(fieldsByHarvestDates).keys().sortBy().last().value()
    );
    const diff = lastDate.diff(firstDate, 'days');

    const dates = [];
    for (let i = 0; i <= diff; i++) {
      dates.push(moment(firstDate).add(i, 'days').format('yyyy-MM-DD'));
    }

    return _.chain(dates)
      .map((date) => {
        const group = _.get(fieldsByHarvestDates, [date]);
        const observed = _.filter(
          group,
          (item) => !!_.get(item, 'stageObserved')
        );
        const estimated = _.filter(
          group,
          (item) => !_.get(item, 'stageObserved')
        );
        return {
          date,
          observed: {
            acres: _.sumBy(observed, 'acres'),
            count: _.get(observed, 'length', 0)
          },
          estimated: {
            acres: _.sumBy(estimated, 'acres'),
            count: _.get(estimated, 'length', 0)
          }
        };
      })
      .value();
  }, [fieldsByHarvestDates]);

  const percentages = useMemo(() => {
    let totalObservedAcres = 0;
    let totalObservedCount = 0;
    let totalEstimatedAcres = 0;
    let totalEstimatedCount = 0;
    return _.map(harvestStatistics, (stats) => {
      const observedAcres = _.get(stats, 'observed.acres', 0);
      const observedCount = _.get(stats, 'observed.count', 0);
      const estimatedAcres = _.get(stats, 'estimated.acres', 0);
      const estimatedCount = _.get(stats, 'estimated.count', 0);
      totalObservedAcres += observedAcres;
      totalObservedCount += observedCount;
      totalEstimatedAcres += estimatedAcres;
      totalEstimatedCount += estimatedCount;

      return {
        date: _.get(stats, 'date'),
        observed: {
          acres: Math.round(observedAcres * 10) / 10,
          count: Math.round(observedCount * 10) / 10
        },
        estimated: {
          acres: Math.round(estimatedAcres * 10) / 10,
          count: Math.round(estimatedCount * 10) / 10
        },
        total: {
          observed: {
            acres: Math.round(totalObservedAcres * 10) / 10,
            count: Math.round(totalObservedCount * 10) / 10
          },
          estimated: {
            acres: Math.round(totalEstimatedAcres * 10) / 10,
            count: Math.round(totalEstimatedCount * 10) / 10
          },
          total: {
            acres:
              Math.round((totalEstimatedAcres + totalObservedAcres) * 10) / 10,
            count:
              Math.round((totalEstimatedCount + totalObservedCount) * 10) / 10
          }
        }
      };
    });
  }, [harvestStatistics]);

  const chartData = useMemo(() => {
    const data = _.chain(percentages).orderBy('date').value();

    const labels = _.map(data, (item) => _.get(item, 'date'));

    const datasets = [
      {
        ...commonLineDataSetParams('#ffae00'),
        label: 'Predicted',
        data: _.map(data, (item) =>
          _.get(item, ['total', 'estimated', dataType])
        )
      },
      {
        ...commonLineDataSetParams('#59c05e'),
        label: 'Actual',
        data: _.map(data, (item) =>
          _.get(item, ['total', 'observed', dataType])
        )
      },
      {
        ...commonLineDataSetParams('#039107'),
        label: 'Total',
        data: _.map(data, (item) => _.get(item, ['total', 'total', dataType]))
      },
      {
        label: 'Predicted (By Day)',
        data: _.map(data, (item) => _.get(item, ['estimated', dataType])),
        type: 'bar',
        backgroundColor: '#ffdd4a',
        borderColor: '#ffdd4a'
      },
      {
        label: 'Actual (By Day)',
        data: _.map(data, (item) => _.get(item, ['observed', dataType])),
        type: 'bar',
        backgroundColor: '#66bb6a',
        borderColor: '#66bb6a'
      }
    ].filter((dataset) => !_.isEmpty(dataset.data));

    return {
      labels,
      datasets
    };
  }, [dataType, percentages]);

  const options = useMemo(
    () => ({
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          text: `${growthStageName} Dates by ${
            showAcres ? 'Acres' : 'Number of Fields'
          }`,
          font: {
            size: 16
          }
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              return ` ${context.dataset.label}: ${context.formattedValue} ${
                showAcres ? 'Acres' : 'Fields'
              } `;
            }
          }
        }
      },
      scales: {
        'x-axis': {
          type: 'time',
          time: {
            minUnit: 'day',
            tooltipFormat: 'LL',
            displayFormats: {
              day: 'MMM DD'
            }
          },
          display: true,
          gridLines: {
            display: true
          },
          ticks: {
            autoSkip: true
          }
        },
        'y-axis': {
          ticks: {
            precision: 0,
            beginAtZero: true
          }
        }
      }
    }),
    [growthStageName, showAcres]
  );

  return (
    <ReportsChartContainer
      header={
        <ReportChartButtons
          chartRef={chartRef}
          chartFilename={`${growthStageName}-dates-by-${
            showAcres ? 'Acres' : 'Number-of-Fields'
          }-Chart.png`}
          chartDataType={chartDataType}
        />
      }
    >
      <Bar ref={chartRef} type="bar" data={chartData} options={options} />
    </ReportsChartContainer>
  );
}

export default GrowthStageReportChart;
