import bundlesSlices from './bundles';
import environmentSlice from './environmentSlice';
import protocolsSlices from './protocols';
import tankMixesSlices from './tankMixes';

const configSlices = [
  environmentSlice,
  protocolsSlices,
  bundlesSlices,
  tankMixesSlices
];

export default configSlices;
