import React, { useCallback } from 'react';

import { IconButton, Tooltip } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ListIcon from '@material-ui/icons/List';
import _ from 'lodash';
import { Link as RouterLink } from 'react-router-dom';

export function UtilityButtons({ dataRow, onSelectItemToDelete }) {
  const showDeleteButton = !!onSelectItemToDelete;

  const handleSelectItemToDelete = useCallback(
    (id) => {
      onSelectItemToDelete(id);
    },
    [onSelectItemToDelete]
  );

  const detailsLink = _.get(dataRow, 'detailsButton.link');
  const listLink = _.get(dataRow, 'listButton.link');
  const editLink = _.get(dataRow, 'editButton.link');

  return (
    <>
      {_.map(_.get(dataRow, 'customButtons'), (customButton, idx) => (
        <React.Fragment key={`custom-button-${dataRow.id}-${idx}`}>
          {customButton.onClick && (
            <Tooltip title={customButton.description || ''} placement="top">
              <IconButton
                onClick={customButton.onClick}
                id={`custom-button-${dataRow.id}-${idx}`}
              >
                {customButton.icon}
              </IconButton>
            </Tooltip>
          )}
          {!customButton.onClick && (
            <Tooltip title={customButton.description || ''} placement="top">
              <IconButton
                component={RouterLink}
                to={customButton.link}
                id={`custom-button-${dataRow.id}-${idx}`}
              >
                {customButton.icon}
              </IconButton>
            </Tooltip>
          )}
        </React.Fragment>
      ))}
      {detailsLink && (
        <Tooltip
          title={_.get(dataRow, 'detailsButton.description') || ''}
          placement="top"
        >
          <IconButton component={RouterLink} to={detailsLink}>
            <AssignmentIcon />
          </IconButton>
        </Tooltip>
      )}
      {listLink && (
        <Tooltip
          title={_.get(dataRow, 'listButton.description') || ''}
          placement="top"
        >
          <IconButton component={RouterLink} to={listLink}>
            <ListIcon />
          </IconButton>
        </Tooltip>
      )}
      {dataRow.downloadButton}
      {editLink && (
        <Tooltip
          title={_.get(dataRow, 'editButton.description') || ''}
          placement="top"
        >
          <IconButton component={RouterLink} to={editLink}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
      {showDeleteButton && (
        <Tooltip
          title={_.get(dataRow, 'deleteButtonDescription') || ''}
          placement="top"
        >
          <IconButton onClick={() => handleSelectItemToDelete(dataRow.id)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}
