import { useMemo } from 'react';

import { protocolsConfigService } from '../../../../services/protocolsConfig.service';
import { createApiSlice } from '../../utils/createApiSlice';

const createProtocolSlice = createApiSlice({
  reducerPath: ['newConfig', 'protocols', 'createProtocol'],
  apiFunction: protocolsConfigService.createProtocolV2,
  apiArgumentsFn: ({ bundleId, protocol }) => [bundleId, protocol]
});

const { actions, useApiSelectors } = createProtocolSlice;

export const createProtocolActions = actions;

export const useCreateProtocolSelectors = () => {
  const { inProgress, success, errorMessage, response, updatedAt } =
    useApiSelectors();

  return useMemo(
    () => ({ inProgress, success, errorMessage, response, updatedAt }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default createProtocolSlice;
