import React, { useMemo } from 'react';

import { Tooltip } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';

import CommonDataGrid from '../../../Components/DataGrid/CommonDataGrid';

import { useCommonReportsColumns } from './_hooks/useCommonReportsColumns';

const useStyles = makeStyles(() => ({
  description: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  }
}));

const ActivitiesReportTable = ({
  activityType,
  growthModelReportResponse,
  onVisibleRowIdsChange = _.noop
}) => {
  const styles = useStyles();

  const fieldsWithActivities = useMemo(
    () =>
      _.map(_.get(growthModelReportResponse, 'fields'), (field) => ({
        ...field,
        selectedActivities: _.chain(field)
          .get('activitiesV2')
          .filter((activity) => _.get(activity, 'type') === activityType)
          .value()
      })),
    [activityType, growthModelReportResponse]
  );

  const maxActivitiesCount = useMemo(
    () =>
      _.chain(fieldsWithActivities)
        .map((field) => _.get(field, 'selectedActivities.length'))
        .max()
        .value(),
    [fieldsWithActivities]
  );

  const { commonColumns } = useCommonReportsColumns();

  const columns = useMemo(
    () => [
      ...commonColumns,
      ..._.flatMap(_.range(maxActivitiesCount), (idx) => [
        {
          field: `activity-${idx}-description`,
          headerName: `#${idx + 1} Description`,
          width: 200,
          editable: false,
          valueGetter: (params) =>
            _.get(params, ['row', 'activities', idx, 'description']),
          renderCell: (params) => (
            <Tooltip title={_.get(params, 'value')}>
              <span className={styles.description}>
                {_.get(params, 'value')}
              </span>
            </Tooltip>
          )
        },
        {
          field: `activity-${idx}-startDate`,
          headerName: `#${idx + 1} Start Date`,
          width: 150,
          editable: false,
          align: 'right',
          headerAlign: 'right',
          valueGetter: (params) =>
            _.get(params, ['row', 'activities', idx, 'startDate'])
        },
        {
          field: `activity-${idx}-endDate`,
          headerName: `#${idx + 1} End Date`,
          width: 150,
          editable: false,
          align: 'right',
          headerAlign: 'right',
          valueGetter: (params) =>
            _.get(params, ['row', 'activities', idx, 'endDate'])
        }
      ])
    ],
    [commonColumns, maxActivitiesCount, styles.description]
  );

  const rows = useMemo(
    () =>
      _.chain(fieldsWithActivities)
        .map((field) => {
          return {
            id: _.get(field, 'field.fieldId'),
            ..._.get(field, 'field', {}),
            ..._.get(field, 'variety', {}),
            activities: _.get(field, 'selectedActivities')
          };
        })
        .value(),
    [fieldsWithActivities]
  );

  return (
    <CommonDataGrid
      rows={rows}
      columns={columns}
      onVisibleRowIdsChange={onVisibleRowIdsChange}
    />
  );
};

export default ActivitiesReportTable;
