import { useMemo } from 'react';

import { growerBundlesConfigService } from '../../../../../services/growerBundlesConfig.service';
import { createApiSlice } from '../../../utils/createApiSlice';

const resetDefaultGrowerBundleSlice = createApiSlice({
  reducerPath: ['gff', 'growers', 'bundles', 'resetDefaultGrowerBundle'],
  apiFunction: growerBundlesConfigService.resetDefaultBundleV2,
  apiArgumentsFn: ({ growerId }) => [growerId]
});

const { actions, useApiSelectors } = resetDefaultGrowerBundleSlice;

export const resetDefaultGrowerBundleActions = actions;

export const useResetDefaultGrowerBundleSelectors = () => {
  const { inProgress, success, errorMessage, response } = useApiSelectors();

  return useMemo(
    () => ({ inProgress, success, errorMessage, response }),
    [errorMessage, inProgress, response, success]
  );
};

export default resetDefaultGrowerBundleSlice;
