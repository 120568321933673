import React, { createContext, useCallback, useContext, useMemo } from 'react';

import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

import { apiErrors } from '../services';

import { AuthContext } from './AuthContext';

export const ApiContext = createContext({});

export const ApiProvider = ({ children }) => {
  const navigate = useNavigate();
  const { token, logOut } = useContext(AuthContext);

  const shouldLogout = useCallback((e) => {
    return apiErrors.isUnauthorized(e);
  }, []);

  const callApi = useCallback(
    async (fn) => {
      try {
        const response = await fn(token);
        if (_.get(response, 'isError')) {
          // noinspection ExceptionCaughtLocallyJS
          throw new Error(_.get(response, 'message'));
        }
        return response;
      } catch (e) {
        if (shouldLogout(e)) {
          logOut();
          navigate('/');
        } else {
          throw e;
        }
      }
    },
    [shouldLogout, logOut, token, navigate]
  );
  const context = useMemo(() => ({ callApi }), [callApi]);

  return <ApiContext.Provider value={context}>{children}</ApiContext.Provider>;
};
