import { useMemo } from 'react';

import { protocolsConfigService } from '../../../../services/protocolsConfig.service';
import { createApiSlice } from '../../utils/createApiSlice';

const addActivitySlice = createApiSlice({
  reducerPath: ['newConfig', 'protocols', 'addActivity'],
  apiFunction: protocolsConfigService.addActivityV2,
  apiArgumentsFn: ({ bundleId, protocolId, activity }) => [
    bundleId,
    protocolId,
    activity
  ]
});

const { actions, useApiSelectors } = addActivitySlice;

export const addActivityActions = actions;

export const useAddActivitySelectors = () => {
  const { inProgress, success, errorMessage, response, updatedAt } =
    useApiSelectors();

  return useMemo(
    () => ({ inProgress, success, errorMessage, response, updatedAt }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default addActivitySlice;
