import React, { useMemo } from 'react';

import _ from 'lodash';
import tinycolor from 'tinycolor2';

import { WorkOrderType } from '../../_constants/workOrderConstants';
import { useFarmWorkOrderItems } from '../../_hooks/FarmWorkOrders/useFarmWorkOrderItems';
import TimelineRectangle from '../Charts/TimelineRectangle';
import { parseDate } from '../Growths/GrowthTimeline/_utils/growthTimelineUtils';

const GrowthActivitiesTimeline = ({
  svgWidth,
  barHeight,
  barGap,
  growthModelTimelineBarHeight,
  growthModelTimelineGap,
  activitiesTimelineBarHeight,
  activitiesTimelineGap,
  rectangleRadius,
  timelineActivities,
  selectedTimeScale,
  onMouseOver = _.noop,
  onMouseOut = _.noop,
  onClick = _.noop,
  completedActivityHoverParams
}) => {
  const { workOrders } = useFarmWorkOrderItems();

  const hoveredGrowthId = _.get(completedActivityHoverParams, 'data.growthId');

  const hoveredCompletedActivities = _.get(
    completedActivityHoverParams,
    'data.activities'
  );

  const isCompletedActivityHovered = !!_.get(
    completedActivityHoverParams,
    'element'
  );

  const growthRectangles = useMemo(() => {
    return _.map(timelineActivities, (growth) => {
      const growthId = _.get(growth, 'id');
      const startY = _.get(growth, 'startY');
      const rectangles = _.chain(growth)
        .get('activitiesByType')
        .values()
        .flatMap((activities, rowIdx) => {
          return _.map(activities, (activity) => {
            const activityConfigId = _.get(activity, 'activityConfigId');
            const fieldId = _.get(growth, 'fieldId');

            const workOrder = _.find(
              workOrders,
              (it) =>
                _.get(it, 'type') === WorkOrderType.Recommendation &&
                _.get(it, 'activityConfigId') === activityConfigId &&
                _.has(_.get(it, 'targetsByFieldId'), fieldId)
            );

            const isHovered =
              !!_.find(
                hoveredCompletedActivities,
                (item) =>
                  _.get(item, 'activityConfigId') ===
                  _.get(activity, 'activityConfigId')
              ) && hoveredGrowthId === growthId;

            const x0 = selectedTimeScale(parseDate(activity.date));
            const rectWidth =
              selectedTimeScale(parseDate(activity.endDate)) -
              selectedTimeScale(parseDate(activity.date));
            const x1 = x0 + rectWidth;

            const rangeX0 = selectedTimeScale.range()[0];
            const rangeX1 = selectedTimeScale.range()[1];

            const visibleX0 = x0 < rangeX0 ? rangeX0 : x0;
            const visibleX1 = x1 > rangeX1 ? rangeX1 : x1;
            const visibleMidX =
              x0 >= rangeX0
                ? (visibleX1 - visibleX0) / 2
                : rangeX0 - x0 + (visibleX1 - visibleX0) / 2;

            const y =
              startY +
              rowIdx * (barHeight + barGap) +
              growthModelTimelineBarHeight +
              growthModelTimelineGap +
              activitiesTimelineBarHeight +
              activitiesTimelineGap;

            const height = barHeight;

            let color = activity.backgroundColor;

            if (isCompletedActivityHovered && !isHovered) {
              const fillTinycolor = tinycolor(color);
              fillTinycolor.setAlpha(0.2);
              color = fillTinycolor.toRgbString();
            }

            return {
              growthId,
              id: `${growthId}-${activity.id}`,
              x: x0,
              y,
              width: rectWidth,
              height,
              color,
              textColor: '#FFFFFF',
              startDate: activity.date,
              endDate: activity.endDate,
              growthStage: activity.growthStage,
              visibleMidX,
              dateCompleted: _.get(activity, 'dateCompleted'),
              observed: _.get(activity, 'isCompleted'),
              isCompleted: _.get(activity, 'isCompleted'),
              fieldName: _.get(growth, 'fieldName'),
              description: _.get(activity, 'description'),
              longDescription: _.get(activity, 'longDescription'),
              activity,
              workOrder
            };
          });
        })
        .filter((rectangle) => rectangle.width > 0)
        .value();
      return { growthId, rectangles };
    });
  }, [
    activitiesTimelineBarHeight,
    activitiesTimelineGap,
    barGap,
    barHeight,
    growthModelTimelineBarHeight,
    growthModelTimelineGap,
    hoveredCompletedActivities,
    hoveredGrowthId,
    isCompletedActivityHovered,
    selectedTimeScale,
    timelineActivities,
    workOrders
  ]);

  return (
    <g>
      {svgWidth > 0 && (
        <>
          {_.map(growthRectangles, (growth) => (
            <g key={_.get(growth, 'growthId')}>
              {_.map(_.get(growth, 'rectangles'), (rectangle) => (
                <TimelineRectangle
                  key={_.get(rectangle, 'id')}
                  rectangleRadius={rectangleRadius}
                  rectangle={rectangle}
                  onMouseOver={onMouseOver}
                  onMouseOut={onMouseOut}
                  onClick={onClick}
                />
              ))}
            </g>
          ))}
        </>
      )}
    </g>
  );
};

export default GrowthActivitiesTimeline;
