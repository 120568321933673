import React from 'react';

import { Popover, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';
import moment from 'moment';

import { ZonedActivityTypes } from '../../_constants/activitiesConstants';
import GrowthStageBadge from '../Growths/GrowthStageBadge';

import { ActivityTypes } from './CompletedActivitiesTimeline';

const useStyles = makeStyles(() => ({
  tooltip: {
    padding: '1rem',
    maxWidth: '20rem'
  },
  popover: {
    pointerEvents: 'none'
  },
  completed: {
    marginTop: '0.5rem',
    paddingBottom: '0.5rem',
    marginBottom: '0.5rem',
    borderBottom: '2px solid #00000050',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  activity: {
    paddingBottom: '0.5rem',
    marginBottom: '0.5rem',
    borderBottom: '1px solid #00000050'
  },
  description: {
    marginTop: '0.25rem',
    fontWeight: 450
  },
  eventName: {
    marginTop: '0.25rem',
    fontWeight: 450
  }
}));

export const ZonedActivityRow = ({ activity }) => {
  const styles = useStyles();

  const description = _.get(activity, 'description');
  const type = _.get(activity, 'type');
  const growthStage = _.get(activity, 'stage');

  return (
    <>
      <Typography>Zoned Activity</Typography>
      {type === ZonedActivityTypes.CustomNote && (
        <>
          <Typography>Note:</Typography>
          <Typography className={styles.eventName}>
            {_.get(activity, 'name')}
          </Typography>
        </>
      )}
      {type === ZonedActivityTypes.GrowthStage && (
        <>
          <Typography style={{ marginBottom: '0.25rem' }}>
            Growth Stage:
          </Typography>
          <GrowthStageBadge growthStage={growthStage} small />
        </>
      )}
      {!_.isEmpty(description) && (
        <>
          <Typography style={{ marginTop: '0.5rem' }}>Description:</Typography>
          <Typography className={styles.description}>{description}</Typography>
        </>
      )}
    </>
  );
};

const CompletedRecommendationRow = ({ activity }) => {
  const styles = useStyles();

  const description = _.get(activity, 'description');

  return (
    <>
      <Typography>Completed Recommendation:</Typography>
      <Typography className={styles.description}>{description}</Typography>
    </>
  );
};

const ActivityRow = ({ activity }) => {
  const styles = useStyles();

  const type = _.get(activity, 'activityType');

  return (
    <Box className={styles.activity}>
      {type === ActivityTypes.ZonedActivity && (
        <ZonedActivityRow activity={activity} />
      )}
      {type === ActivityTypes.CompletedRecommendation && (
        <CompletedRecommendationRow activity={activity} />
      )}
    </Box>
  );
};

const CompletedActivityPopover = ({ hoverParams }) => {
  const styles = useStyles();

  const anchorEl = _.get(hoverParams, 'element');
  const dateCompleted = _.get(hoverParams, 'data.dateCompleted');
  const activities = _.get(hoverParams, 'data.activities');
  const displayTooltip = !!anchorEl;

  return (
    <Popover
      className={styles.popover}
      classes={{ paper: styles.tooltip }}
      open={displayTooltip}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: _.get(hoverParams, 'data.visibleMidX', 0)
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      disableAutoFocus
      disableRestoreFocus
    >
      <Box className={styles.completed}>
        <Typography style={{ marginRight: '0.5rem' }}>Completed:</Typography>
        <Typography>
          <b>{moment(dateCompleted).format('ll')}</b>
        </Typography>
      </Box>
      {_.map(activities, (activity) => (
        <ActivityRow key={_.get(activity, 'id')} activity={activity} />
      ))}
    </Popover>
  );
};

export default CompletedActivityPopover;
