import { useMemo } from 'react';

import { growthService } from '../../../services';
import { createApiSlice } from '../utils/createApiSlice';

const bulkUpdateGrowthsSlice = createApiSlice({
  reducerPath: ['newGrowths', 'farmFeedback', 'bulkUpdateGrowths'],
  apiFunction: growthService.bulkUpdateV2,
  apiArgumentsFn: ({ growerId, seasonId, data }) => [growerId, seasonId, data]
});

const { actions, useApiSelectors } = bulkUpdateGrowthsSlice;

export const bulkUpdateGrowthsActions = actions;

export const useBulkUpdateGrowthsSelectors = () => {
  const { inProgress, errorMessage, success, updatedAt, response } =
    useApiSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      updatedAt,
      response
    }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default bulkUpdateGrowthsSlice;
