import React, { useEffect, useMemo } from 'react';

import Alert from '@material-ui/lab/Alert';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import {
  getFieldsStatisticsActions,
  useGetFieldsStatisticsSelectors
} from '../../../_store/slices/statistics/getFieldsStatisticsSlice';
import CommonDataGrid from '../../../Components/DataGrid/CommonDataGrid';
import LoadingIndicator from '../../../Components/LoadingIndicator';
import {
  ContentBody,
  ContentContainer,
  ContentHeader
} from '../../../Layout/Content';

const columns = [
  {
    field: 'growerName',
    headerName: 'Grower',
    width: 250,
    editable: false
  },
  {
    field: 'farmName',
    headerName: 'Farm',
    width: 250,
    editable: false
  },
  {
    field: 'name',
    headerName: 'Field',
    width: 250,
    editable: false
  },
  {
    field: 'dateCreated',
    headerName: 'Date Created',
    width: 250,
    editable: false,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: (params) => {
      return params.value ? moment(params.value).format('lll') : '';
    }
  },
  {
    field: 'dateModified',
    headerName: 'Date Modified',
    width: 250,
    editable: false,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: (params) => {
      return params.value ? moment(params.value).format('lll') : '';
    }
  }
];

const FieldsStatistics = () => {
  const dispatch = useDispatch();

  const { inProgress, errorMessage, response } =
    useGetFieldsStatisticsSelectors();

  const rows = useMemo(() => _.map(response, (item) => item), [response]);

  useEffect(() => {
    dispatch(getFieldsStatisticsActions.submit());
  }, [dispatch]);

  return (
    <ContentContainer>
      <ContentHeader>
        <span>Fields</span>
      </ContentHeader>
      <ContentBody>
        {inProgress && <LoadingIndicator />}
        {!inProgress && errorMessage && (
          <Alert severity="error">{errorMessage}</Alert>
        )}
        {!inProgress && !errorMessage && (
          <CommonDataGrid rows={rows} columns={columns} />
        )}
      </ContentBody>
    </ContentContainer>
  );
};

export default FieldsStatistics;
