import { useContext, useMemo } from 'react';

import _ from 'lodash';

import { FarmStructureContext } from '../../_context/FarmStructureContext';
import { useFarmWithGrowthsSelectors } from '../../_store/selectors/farmsSelectors';

export const useFarmFeedbackItems = () => {
  const {
    selectedGrowerId: growerId,
    selectedSeasonId: seasonId,
    selectedFarmId: farmId,
    selectedFieldId: fieldId
  } = useContext(FarmStructureContext);

  const { farm } = useFarmWithGrowthsSelectors(growerId, seasonId, farmId);

  const feedbackItems = useMemo(() => {
    const feedback = _.get(farm, 'feedback');
    if (!fieldId) {
      return feedback;
    }

    return _.filter(feedback, (f) =>
      _.chain(f).get('targetsByFieldId').has(fieldId).value()
    );
  }, [farm, fieldId]);

  return useMemo(
    () => ({
      feedbackItems
    }),
    [feedbackItems]
  );
};
