import React, { useCallback, useMemo, useState } from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { saveGrowthStageConfigRequestAction } from '../../../../_store/actions/configActions';
import { useGrowthStagesConfig } from '../../../../_store/selectors/configSelectors';
import MapBadge from '../../../Map/Components/MapBadge';

import ColorPickerDialog from './ColorPickerDialog';
import FieldRectangle from './FieldRectangle';

const rectangleWidth = 200;
const rectangleHeight = 37;

const GrowthStageRow = ({ stage, onEdit }) => {
  const handleEditClick = useCallback(() => {
    onEdit(_.get(stage, 'type'));
  }, [onEdit, stage]);

  return (
    <TableRow key={_.get(stage, 'order')}>
      <TableCell>{_.get(stage, 'name')}</TableCell>
      <TableCell align="center">
        <FieldRectangle
          width={rectangleWidth}
          height={rectangleHeight}
          borderColor={_.get(stage, 'outlineColor')}
          fillColor={_.get(stage, 'fillColor')}
        />
      </TableCell>
      <TableCell align="center">
        <MapBadge
          textColor={_.get(stage, 'textColor')}
          backgroundColor={_.get(stage, 'outlineColor')}
          onClick={_.noop}
          text={_.get(stage, 'name')}
        />
      </TableCell>
      <TableCell align="center">
        <IconButton onClick={handleEditClick}>
          <EditIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const useAccordionStyles = makeStyles(() => ({
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

const GrowthStageAccordion = ({ stage, onEdit }) => {
  const styles = useAccordionStyles();

  const handleEditClick = useCallback(() => {
    onEdit(_.get(stage, 'type'));
  }, [onEdit, stage]);

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id={`${_.get(stage, 'order')}-header`}
      >
        <Typography>{_.get(stage, 'name')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} className={styles.buttonsContainer}>
            <IconButton onClick={handleEditClick}>
              <EditIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} className={styles.contentContainer}>
            <Typography>Field Shape</Typography>
            <FieldRectangle
              width={rectangleWidth}
              height={rectangleHeight}
              borderColor={_.get(stage, 'outlineColor')}
              fillColor={_.get(stage, 'fillColor')}
            />
          </Grid>
          <Grid item xs={12} className={styles.contentContainer}>
            <Typography>Badge</Typography>
            <MapBadge
              textColor={_.get(stage, 'textColor')}
              backgroundColor={_.get(stage, 'outlineColor')}
              onClick={_.noop}
              text={_.get(stage, 'name')}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const GrowthStagesColorsConfig = () => {
  const dispatch = useDispatch();

  const showTable = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const { growthStagesConfig } = useGrowthStagesConfig();

  const [selectedStage, setSelectedStage] = useState(null);

  const orderedStages = useMemo(
    () => _.chain(growthStagesConfig).values().orderBy('order').value(),
    [growthStagesConfig]
  );

  const handleClose = useCallback(() => {
    setSelectedStage(null);
    dispatch(saveGrowthStageConfigRequestAction.clear());
  }, [dispatch]);

  const handleEditClick = useCallback((stageType) => {
    setSelectedStage(stageType);
  }, []);

  return (
    <>
      {showTable && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Growth Stage</TableCell>
                <TableCell align="center">Field Shape</TableCell>
                <TableCell align="center">Badge</TableCell>
                <TableCell align="center">Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(orderedStages, (stage) => (
                <GrowthStageRow
                  key={_.get(stage, 'order')}
                  stage={stage}
                  onEdit={handleEditClick}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!showTable && (
        <>
          {_.map(orderedStages, (stage) => (
            <GrowthStageAccordion
              key={_.get(stage, 'order')}
              stage={stage}
              onEdit={handleEditClick}
            />
          ))}
        </>
      )}
      <ColorPickerDialog selectedStage={selectedStage} onClose={handleClose} />
    </>
  );
};

export default GrowthStagesColorsConfig;
