import { useMemo } from 'react';

import { faCompass } from '@fortawesome/free-regular-svg-icons';

import { userRoles } from '../../_constants/userConstants';

const YieldMapPageKey = 'YIELD_MAP_PAGE';

const baseConfig = {
  key: YieldMapPageKey,
  icon: faCompass,
  label: 'Yield Maps Demo',
  path: 'yieldmaps',
  allowedRoles: [userRoles.Admin]
};

export const useYieldMapPageConfig = () => {
  return useMemo(() => ({ config: baseConfig }), []);
};
