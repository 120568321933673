import { useMemo } from 'react';

import { faCalculator } from '@fortawesome/free-solid-svg-icons';

import { userRoles } from '../../_constants/userConstants';

const TankMixPageKey = 'TANK_MIX_DEMO_PAGE';

const baseConfig = {
  key: TankMixPageKey,
  icon: faCalculator,
  label: 'Tank Mix Demo',
  path: 'tank-mix-demo',
  allowedRoles: [userRoles.Admin]
};

export const useTankMixPageConfig = () => {
  return useMemo(() => ({ config: baseConfig }), []);
};
