import { useMemo } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';

export const useCreateGrowthDialogSelectors = () => {
  const createGrowthDialog = useSelector((state) =>
    _.get(state, 'uiLegacy.createGrowthDialog')
  );

  const growerId = _.get(createGrowthDialog, 'growerId');
  const seasonId = _.get(createGrowthDialog, 'seasonId');
  const farmId = _.get(createGrowthDialog, 'farmId');
  const fieldId = _.get(createGrowthDialog, 'fieldId');
  const growthId = _.get(createGrowthDialog, 'growthId');

  const open = !!createGrowthDialog;

  return useMemo(
    () => ({ growerId, seasonId, farmId, fieldId, growthId, open }),
    [farmId, fieldId, growerId, growthId, open, seasonId]
  );
};

export const useGrowthFeedbackDialogSelectors = () => {
  const growthFeedbackDialog = useSelector((state) =>
    _.get(state, 'uiLegacy.growthFeedbackDialog')
  );

  const growerId = _.get(growthFeedbackDialog, 'growerId');
  const seasonId = _.get(growthFeedbackDialog, 'seasonId');
  const farmId = _.get(growthFeedbackDialog, 'farmId');
  const fieldId = _.get(growthFeedbackDialog, 'fieldId');
  const growthStage = _.get(growthFeedbackDialog, 'growthStage');

  const open = !!growthFeedbackDialog;

  return useMemo(
    () => ({
      growerId,
      seasonId,
      farmId,
      fieldId,
      growthStage,
      open
    }),
    [growerId, seasonId, farmId, fieldId, growthStage, open]
  );
};

export const useGrowthModelDisplaySelectors = () => {
  const tabId = useSelector((state) =>
    _.get(state, 'uiLegacy.growthModelDisplay.tabId', 0)
  );

  return useMemo(() => ({ tabId }), [tabId]);
};

export const useAddFieldAccessibilityFeedbackDialogSelectors = () => {
  const addFieldAccessibilityFeedbackDialog = useSelector((state) =>
    _.get(state, 'uiLegacy.addFieldAccessibilityFeedbackDialog')
  );

  const growerId = _.get(addFieldAccessibilityFeedbackDialog, 'growerId');
  const seasonId = _.get(addFieldAccessibilityFeedbackDialog, 'seasonId');

  const open = !!addFieldAccessibilityFeedbackDialog;

  return useMemo(
    () => ({ growerId, seasonId, open }),
    [growerId, open, seasonId]
  );
};

export const useDisableScrollSelectors = () => {
  const disableScroll = useSelector((state) =>
    _.get(state, 'uiLegacy.disableScroll', false)
  );

  return useMemo(() => ({ disableScroll }), [disableScroll]);
};

export const useMapLayersSelectors = () => {
  const layerId = useSelector((state) =>
    _.get(state, 'uiLegacy.mapLayers.layerId')
  );
  const showDetails = useSelector((state) =>
    _.get(state, 'uiLegacy.mapLayers.showDetails')
  );

  return useMemo(() => ({ layerId, showDetails }), [layerId, showDetails]);
};

export const useSetTargetMoistureDialogSelectors = () => {
  const openSetTargetMoistureDialog = useSelector((state) =>
    _.get(state, 'uiLegacy.openSetTargetMoistureDialog')
  );

  const open = !!openSetTargetMoistureDialog;
  const growerId = _.get(openSetTargetMoistureDialog, 'growerId');
  const seasonId = _.get(openSetTargetMoistureDialog, 'seasonId');
  const farmId = _.get(openSetTargetMoistureDialog, 'farmId');
  const fieldId = _.get(openSetTargetMoistureDialog, 'fieldId');
  const growthId = _.get(openSetTargetMoistureDialog, 'growthId');

  return useMemo(
    () => ({ open, growerId, seasonId, farmId, fieldId, growthId }),
    [farmId, fieldId, growerId, growthId, open, seasonId]
  );
};

export const useAddMoistureSampleDialogSelectors = () => {
  const addMoistureSampleDialog = useSelector((state) =>
    _.get(state, 'uiLegacy.addMoistureSampleDialog')
  );

  const open = !!addMoistureSampleDialog;
  const growerId = _.get(addMoistureSampleDialog, 'growerId');
  const seasonId = _.get(addMoistureSampleDialog, 'seasonId');
  const farmId = _.get(addMoistureSampleDialog, 'farmId');
  const fieldId = _.get(addMoistureSampleDialog, 'fieldId');
  const growthId = _.get(addMoistureSampleDialog, 'growthId');
  const sampleId = _.get(addMoistureSampleDialog, 'sampleId');

  return useMemo(
    () => ({ open, growerId, seasonId, farmId, fieldId, growthId, sampleId }),
    [open, growerId, seasonId, farmId, fieldId, growthId, sampleId]
  );
};

export const useWeatherParametersSelectors = () => {
  const weatherParameters = useSelector((state) =>
    _.get(state, 'uiLegacy.weatherParameters')
  );

  return useMemo(() => ({ weatherParameters }), [weatherParameters]);
};

export const useSelectedActivityTypesSelectors = () => {
  const selectedActivityTypes = useSelector((state) =>
    _.get(state, 'uiLegacy.selectedActivityTypes')
  );

  return useMemo(() => ({ selectedActivityTypes }), [selectedActivityTypes]);
};

export const useSelectedUserFeedbackStatusesSelectors = () => {
  const userFeedbackStatuses = useSelector((state) =>
    _.get(state, 'uiLegacy.userFeedbackStatuses')
  );

  return useMemo(() => ({ userFeedbackStatuses }), [userFeedbackStatuses]);
};

export const useSelectedMapActivityTypeSelectors = () => {
  const selectedType = useSelector((state) =>
    _.get(state, 'uiLegacy.selectedMapActivityType')
  );

  return useMemo(() => ({ selectedType }), [selectedType]);
};

export const useZoomToFieldSelectors = () => {
  const zoomToField = useSelector((state) =>
    _.get(state, 'uiLegacy.zoomToField')
  );

  return useMemo(() => ({ zoomToField }), [zoomToField]);
};

export const useGrowthModelSelectors = () => {
  const growthModel = useSelector((state) =>
    _.get(state, 'uiLegacy.growthModel')
  );

  return useMemo(() => ({ selectedGrowthModel: growthModel }), [growthModel]);
};
