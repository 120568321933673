import React, { useCallback, useMemo, useState } from 'react';

import { MenuItem, Select } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Alert from '@material-ui/lab/Alert';
import _ from 'lodash';

import { growthStagesTypes } from '../../../_constants/growthsConstants';
import { useGrowthStagesConfig } from '../../../_store/selectors/configSelectors';
import LoadingIndicator from '../../../Components/LoadingIndicator';
import {
  ContentBody,
  ContentContainer,
  ContentHeader,
  ResponsiveHeaderButtonsContainer
} from '../../../Layout/Content';

import { useGrowthModelReportWithDistricts } from './_hooks/useGrowthModelReportWithDistricts';
import { useReportsChartsControls } from './_hooks/useReportsChartsControls';
import GrowthModelReportTable from './GrowthModelReportTable';
import GrowthStageReportChart from './GrowthStageReportChart';
import ReportsDataGridWrapper from './ReportsDataGridWrapper';
import TableChartToggle from './TableChartToggle';

const availableGrowthStages = [
  growthStagesTypes.Planting,
  growthStagesTypes.Emergence,
  growthStagesTypes.Leaf2,
  growthStagesTypes.Leaf4,
  growthStagesTypes.GreenRing,
  growthStagesTypes.Pd,
  growthStagesTypes.EarlyBoot,
  growthStagesTypes.LateBoot,
  growthStagesTypes.Heading50X,
  growthStagesTypes.PhysiologicalMaturity,
  growthStagesTypes.Harvest
];

const useStyles = makeStyles(() => ({
  growthStageSelector: {
    marginRight: '1rem',
    fontSize: '0.875rem'
  }
}));

const GrowthStageReport = () => {
  const styles = useStyles();

  const [selectedGrowthStage, setSelectedGrowthStage] = useState(
    growthStagesTypes.GreenRing
  );

  const { inProgress, errorMessage, response } =
    useGrowthModelReportWithDistricts();

  const { growthStagesConfig } = useGrowthStagesConfig();

  const { showChart, toggleChart, visibleRows, handleVisibleRowIdsChange } =
    useReportsChartsControls(response);

  const reportGrowthStages = useMemo(
    () => [selectedGrowthStage],
    [selectedGrowthStage]
  );

  const handleGrowthStageSelect = useCallback((e) => {
    setSelectedGrowthStage(e.target.value);
  }, []);

  return (
    <ContentContainer>
      <ContentHeader>
        <span>Growth Stage Report</span>
        <ResponsiveHeaderButtonsContainer>
          <Select
            className={styles.growthStageSelector}
            value={selectedGrowthStage}
            onChange={handleGrowthStageSelect}
          >
            {_.map(availableGrowthStages, (growthStage) => (
              <MenuItem key={growthStage} value={growthStage}>
                {_.get(growthStagesConfig, [growthStage, 'name'])}
              </MenuItem>
            ))}
          </Select>
          <TableChartToggle showChart={showChart} onToggle={toggleChart} />
        </ResponsiveHeaderButtonsContainer>
      </ContentHeader>
      <ContentBody>
        {inProgress && <LoadingIndicator />}
        {!inProgress && errorMessage && (
          <Alert severity="error">{errorMessage}</Alert>
        )}
        {!inProgress && !errorMessage && (
          <>
            <ReportsDataGridWrapper hide={showChart}>
              <GrowthModelReportTable
                reportGrowthStages={reportGrowthStages}
                growthModelReportResponse={response}
                onVisibleRowIdsChange={handleVisibleRowIdsChange}
              />
            </ReportsDataGridWrapper>
            {showChart && (
              <GrowthStageReportChart
                growthStage={selectedGrowthStage}
                fields={visibleRows}
              />
            )}
          </>
        )}
      </ContentBody>
    </ContentContainer>
  );
};

export default GrowthStageReport;
