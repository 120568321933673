import _ from 'lodash';
import moment from 'moment';
import { REHYDRATE } from 'redux-persist';
import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';

import { apiUpdateIntervals } from '../../config';
import { forceUpdateFarmStructureAction } from '../actions/farmStructureActions';
import {
  forceUpdateActiveGrowthAction,
  getActiveGrowthsActions
} from '../actions/growthsActions';
import { farmStructureActionTypes } from '../actionTypes/farmStructureActionTypes';
import {
  createGrowthActionTypes,
  deleteGrowthActionTypes,
  growthsActionTypes,
  modifyGrowthActionTypes,
  setTargetMoistureActionTypes
} from '../actionTypes/growthsActionTypes';
import { requestStatus } from '../helpers/requestStatus';
import { farmSelector, farmsSelector } from '../selectors/farmsSelectors';
import {
  growthErrorStatusSelector,
  growthStatusSelector,
  growthUpdatedAtSelector
} from '../selectors/growthsSelectors';
import { getSeasonWithGrowthsActions } from '../slices/gff/seasons/getSeasonsWithGrowthsSlice';
import { bulkUpdateGrowthModelsActions } from '../slices/growths/bulkUpdateGrowthModelsSlice';
import { authActions } from '../slices/user/authSlice';
import { tokenRefreshActions } from '../slices/user/tokenRefreshSlice';

import { getFarmStructureSelection } from './sagaHelpers';

function* updateGrowth(growerId, seasonId, farmId, fieldId, forceUpdate) {
  const growthStatus = yield select(growthStatusSelector(seasonId, fieldId));
  const updatedAt = yield select(growthUpdatedAtSelector(seasonId, fieldId));
  const errorStatus = yield select(
    growthErrorStatusSelector(seasonId, fieldId)
  );
  const shouldUpdate =
    !updatedAt ||
    moment().diff(moment(updatedAt), 'seconds') >
      apiUpdateIntervals.growthUpdateIntervalSeconds ||
    (growthStatus === requestStatus.FAILURE && errorStatus !== 404);
  if (
    growthStatus !== requestStatus.IN_PROGRESS &&
    (forceUpdate || shouldUpdate)
  ) {
    yield put(
      getActiveGrowthsActions.request(growerId, seasonId, farmId, fieldId)
    );
  }
}

function* handleSelectField(action) {
  const { growerId, seasonId, farmId, fieldId } = action.payload;
  if (growerId && seasonId && farmId && fieldId) {
    yield updateGrowth(growerId, seasonId, farmId, fieldId);
  }
}

function* handleUpdateGrowth(action) {
  const { growerId, seasonId, farmId, fieldId } = action.payload;
  if (growerId && seasonId && farmId && fieldId) {
    yield updateGrowth(growerId, seasonId, farmId, fieldId);
  }
}

function* handleUpdateAllActiveGrowthsForGrower(action) {
  const { growerId, seasonId } = action.payload;
  if (growerId && seasonId) {
    const farmsById = yield select(farmsSelector(growerId, seasonId));
    const fields = _.chain(farmsById)
      .values()
      .flatMap((farm) => _.chain(farm).get('fields').values().value())
      .filter((field) => !!field)
      .map((field) => ({ fieldId: field.id, farmId: field.farmId }))
      .value();
    yield all(
      fields.map((field) =>
        call(updateGrowth, growerId, seasonId, field.farmId, field.fieldId)
      )
    );
  }
}

function* handleUpdateAllActiveGrowthsForFarm(action) {
  const { growerId, seasonId, farmId } = action.payload;
  if (growerId && seasonId && farmId) {
    const farm = yield select(farmSelector(growerId, seasonId, farmId));
    const fields = _.chain(farm)
      .get('fields')
      .values()
      .filter((field) => !!field)
      .map((field) => ({ fieldId: field.id, farmId: field.farmId }))
      .value();
    yield all(
      fields.map((field) =>
        call(updateGrowth, growerId, seasonId, field.farmId, field.fieldId)
      )
    );
  }
}

function* handleUpdateIfSelected() {
  const { growerId, seasonId, farmId, fieldId } = yield call(
    getFarmStructureSelection
  );
  if (growerId && seasonId && farmId && fieldId) {
    yield updateGrowth(growerId, seasonId, farmId, fieldId);
  }
}

function* handleForceUpdate(action) {
  const { growerId, seasonId, farmId, fieldId } = action.payload;
  if (growerId && seasonId && farmId && fieldId) {
    yield updateGrowth(growerId, seasonId, farmId, fieldId, true);
  }
}

function* handleGrowthUpdated(action) {
  const growerId = _.get(action, 'payload.apiArguments.0');
  const seasonId = _.get(action, 'payload.apiArguments.1');
  const farmId = _.get(action, 'payload.apiArguments.2');
  const fieldId = _.get(action, 'payload.apiArguments.3');

  if (growerId && seasonId && farmId && fieldId) {
    yield updateGrowth(growerId, seasonId, farmId, fieldId, true);
  }
}

function* handleTargetMoistureSet(action) {
  yield handleGrowthUpdated(action);
  yield put(forceUpdateFarmStructureAction());
}

function* handleBulkUpdateGrowthModelsSuccess(action) {
  const growerId = _.get(action, 'payload.apiArguments.0');
  const seasonId = _.get(action, 'payload.apiArguments.1');
  const response = _.get(action, 'payload.response');

  yield put(bulkUpdateGrowthModelsActions.clear());
  yield put(getSeasonWithGrowthsActions.forceUpdate({ growerId, seasonId }));
  yield all(
    _.map(response, (item) =>
      put(
        forceUpdateActiveGrowthAction(
          _.get(item, 'growerId'),
          _.get(item, 'seasonId'),
          _.get(item, 'farmId'),
          _.get(item, 'fieldId')
        )
      )
    )
  );
}

function* watchRehydrate() {
  // REHYDRATE is dispatched by redux-persist every time the state is rehydrated
  yield takeEvery(REHYDRATE, handleUpdateIfSelected);
}

function* watchLogin() {
  yield takeEvery(authActions.success.type, handleUpdateIfSelected);
}

function* watchTokenRefresh() {
  yield takeEvery(tokenRefreshActions.success.type, handleUpdateIfSelected);
}

function* watchSelectField() {
  yield takeEvery(farmStructureActionTypes.SELECT_FIELD, handleSelectField);
}

function* watchUpdateActiveGrowth() {
  yield takeLatest(growthsActionTypes.UPDATE_ACTIVE_GROWTH, handleUpdateGrowth);
}

function* watchUpdateAllActiveGrowthForGrower() {
  yield takeLatest(
    growthsActionTypes.UPDATE_ALL_ACTIVE_GROWTH_FOR_GROWER,
    handleUpdateAllActiveGrowthsForGrower
  );
}

function* watchUpdateAllActiveGrowthForFarm() {
  yield takeLatest(
    growthsActionTypes.UPDATE_ALL_ACTIVE_GROWTH_FOR_FARM,
    handleUpdateAllActiveGrowthsForFarm
  );
}

function* watchForceUpdateActiveGrowth() {
  yield takeLatest(
    growthsActionTypes.FORCE_UPDATE_ACTIVE_GROWTH,
    handleForceUpdate
  );
}

function* watchGrowthCreated() {
  yield takeLatest(createGrowthActionTypes.SUCCEEDED, handleGrowthUpdated);
}

function* watchGrowthModified() {
  yield takeLatest(modifyGrowthActionTypes.SUCCEEDED, handleGrowthUpdated);
}

function* watchGrowthDeleted() {
  yield takeLatest(deleteGrowthActionTypes.SUCCEEDED, handleGrowthUpdated);
}

function* watchTargetMoistureSet() {
  yield takeLatest(
    setTargetMoistureActionTypes.SUCCEEDED,
    handleTargetMoistureSet
  );
}

function* watchBulkUpdateGrowthModels() {
  yield takeLatest(
    bulkUpdateGrowthModelsActions.success.type,
    handleBulkUpdateGrowthModelsSuccess
  );
}

function* farmStructureSaga() {
  yield all([
    fork(watchRehydrate),
    fork(watchLogin),
    fork(watchTokenRefresh),
    fork(watchSelectField),
    fork(watchUpdateActiveGrowth),
    fork(watchUpdateAllActiveGrowthForGrower),
    fork(watchUpdateAllActiveGrowthForFarm),
    fork(watchForceUpdateActiveGrowth),
    fork(watchGrowthCreated),
    fork(watchGrowthModified),
    fork(watchGrowthDeleted),
    fork(watchTargetMoistureSet),
    fork(watchBulkUpdateGrowthModels)
  ]);
}

export default farmStructureSaga;
