import cloneBundleSlice from './cloneBundleSlice';
import createBundleSlice from './createBundleSlice';
import deleteBundleSlice from './deleteBundleSlice';
import setDefaultBundleSlice from './setDefaultBundleSlice';
import updateBundleSlice from './updateBundleSlice';

const bundlesSlices = [
  createBundleSlice,
  updateBundleSlice,
  deleteBundleSlice,
  setDefaultBundleSlice,
  cloneBundleSlice
];

export default bundlesSlices;
