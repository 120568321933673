import _ from 'lodash';

export const growthModels = {
  RiceTec: {
    type: 'RiceTec',
    name: 'RiceTec',
    order: 0
  },
  ClearAg: {
    type: 'ClearAg',
    name: 'ClearAg',
    order: 1
  }
};

export const orderedGrowthModels = _.chain(growthModels)
  .values()
  .orderBy('order')
  .value();

export const defaultGrowthModel = _.chain(growthModels)
  .values()
  .minBy('order')
  .value();

export const growthFeedbackType = {
  GrowthStage: {
    type: 'GrowthStage',
    name: 'Growth Stage',
    order: 0
  }
};

export const growthStagesTypes = {
  Unknown: 'Unknown',
  NotPlanted: 'NotPlanted',
  Preplanting: 'Preplanting',
  Planting: 'Planting',
  Emergence: 'Emergence',
  Leaf2: 'Leaf2',
  Leaf4: 'Leaf4',
  GreenRing: 'GreenRingPi',
  Pd: 'Pd',
  EarlyBoot: 'EarlyBoot',
  MidBoot: 'MidBoot',
  LateBoot: 'LateBoot',
  Heading50X: 'Heading50X',
  PhysiologicalMaturity: 'PhysiologicalMaturity',
  Harvest: 'Harvest',
  Historical: 'Historical'
};

export const growthFeedbackTypesList = _.chain(growthFeedbackType)
  .values()
  .orderBy((type) => type.order)
  .value();

export const activitiesGrowthStagesTypes = {
  V1: 'V1',
  V2: 'V2',
  V3: 'V3',
  V4: 'V4',
  V5: 'V5',
  V6: 'V6',
  V7: 'V7',
  V8: 'V8',
  V9: 'V9',
  V10: 'V10',
  V11: 'V11',
  V12: 'V12',
  V13: 'V13',
  R0: 'R0',
  R1: 'R1',
  R2: 'R2',
  R3: 'R3',
  R4: 'R4',
  R5: 'R5',
  R6: 'R6',
  R7: 'R7',
  R8: 'R8',
  R9: 'R9',
  Planting: 'Planting',
  Emergence: 'Emergence',
  Leaf2: 'Leaf2',
  Leaf4: 'Leaf4',
  GreenRingPi: 'GreenRingPi',
  Pd: 'Pd',
  EarlyBoot: 'EarlyBoot',
  MidBoot: 'MidBoot',
  LateBoot: 'LateBoot',
  Heading50X: 'Heading50X',
  PhysiologicalMaturity: 'PhysiologicalMaturity',
  Harvest: 'Harvest'
};
