import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { IconButton, Tooltip } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { FarmStructureContext } from '../../../_context/FarmStructureContext';
import { forceUpdateFarmStructureAction } from '../../../_store/actions/farmStructureActions';
import {
  deleteGrowerBundleActions,
  useDeleteGrowerBundleSelectors
} from '../../../_store/slices/gff/growers/bundles/deleteGrowerBundleSlice';
import {
  resetDefaultGrowerBundleActions,
  useResetDefaultGrowerBundleSelectors
} from '../../../_store/slices/gff/growers/bundles/resetDefaultGrowerBundleSlice';
import {
  setDefaultGrowerBundleActions,
  useSetDefaultGrowerBundleSelectors
} from '../../../_store/slices/gff/growers/bundles/setDefaultGrowerBundleSlice';
import {
  toaster,
  toastError,
  toastSuccess
} from '../../../_utilities/toast-utils';
import ConfirmIconButtons from '../../../Components/ConfirmIconButtons';
import DeleteButton from '../../ApplicationConfiguration/Components/DeleteButton';
import { CropManagementTabsIds } from '../CropManagementPage';

import { useGrowerBundles } from './_hooks/useGrowerBundles';

const ResetDefaultButton = ({ disabled, onClick }) => {
  const handleClick = useCallback(() => {
    onClick();
  }, [onClick]);

  return (
    <Tooltip title="Clear Default" placement="top">
      <IconButton onClick={handleClick} disabled={disabled}>
        <ClearIcon />
      </IconButton>
    </Tooltip>
  );
};

const SetDefaultButton = ({ bundleId, disabled, onClick }) => {
  const handleClick = useCallback(() => {
    onClick(bundleId);
  }, [bundleId, onClick]);

  return (
    <Tooltip title="Set Default" placement="top">
      <IconButton onClick={handleClick} disabled={disabled}>
        <DoneOutlineIcon />
      </IconButton>
    </Tooltip>
  );
};

const GrowerBundlesList = () => {
  const dispatch = useDispatch();

  const [bundleIdToDelete, setBundleIdToDelete] = useState();

  const { selectedGrowerId: growerId } = useContext(FarmStructureContext);

  const { bundles } = useGrowerBundles();

  const bundleToDelete = useMemo(
    () => _.find(bundles, (bundle) => _.get(bundle, 'id') === bundleIdToDelete),
    [bundleIdToDelete, bundles]
  );

  const {
    inProgress: deleteInProgress,
    success: deleteSuccess,
    errorMessage: deleteErrorMessage
  } = useDeleteGrowerBundleSelectors();

  const {
    inProgress: setDefaultInProgress,
    success: setDefaultSuccess,
    errorMessage: setDefaultErrorMessage
  } = useSetDefaultGrowerBundleSelectors();

  const {
    inProgress: resetDefaultInProgress,
    success: resetDefaultSuccess,
    errorMessage: resetDefaultErrorMessage
  } = useResetDefaultGrowerBundleSelectors();

  const inProgress =
    deleteInProgress || setDefaultInProgress || resetDefaultInProgress;

  useEffect(() => {
    if (deleteSuccess) {
      toaster('Bundle deleted', toastSuccess);
      dispatch(deleteGrowerBundleActions.clear());
      dispatch(forceUpdateFarmStructureAction());
    }
  }, [dispatch, deleteSuccess]);

  useEffect(() => {
    if (deleteErrorMessage) {
      toaster(`Error deleting bundle: ${deleteErrorMessage}`, toastError);
      dispatch(deleteGrowerBundleActions.clear());
    }
  }, [dispatch, deleteErrorMessage]);

  useEffect(() => {
    if (setDefaultSuccess) {
      toaster('Default Bundle changed', toastSuccess);
      dispatch(setDefaultGrowerBundleActions.clear());
      dispatch(forceUpdateFarmStructureAction());
    }
  }, [dispatch, setDefaultSuccess]);

  useEffect(() => {
    if (setDefaultErrorMessage) {
      toaster(
        `Error setting default bundle: ${setDefaultErrorMessage}`,
        toastError
      );
      dispatch(setDefaultGrowerBundleActions.clear());
    }
  }, [dispatch, setDefaultErrorMessage]);

  useEffect(() => {
    if (resetDefaultSuccess) {
      toaster('Default Bundle changed', toastSuccess);
      dispatch(resetDefaultGrowerBundleActions.clear());
      dispatch(forceUpdateFarmStructureAction());
    }
  }, [dispatch, resetDefaultSuccess]);

  useEffect(() => {
    if (resetDefaultErrorMessage) {
      toaster(
        `Error resetting default bundle: ${resetDefaultErrorMessage}`,
        toastError
      );
      dispatch(resetDefaultGrowerBundleActions.clear());
    }
  }, [dispatch, resetDefaultErrorMessage]);

  const handleDeleteClick = useCallback((protocolId) => {
    setBundleIdToDelete(protocolId);
  }, []);

  const handleCancelDeleteClick = useCallback(() => {
    setBundleIdToDelete(undefined);
  }, []);

  const handleConfirmDeleteClick = useCallback(() => {
    dispatch(
      deleteGrowerBundleActions.submit({ growerId, bundleId: bundleIdToDelete })
    );
    setBundleIdToDelete(undefined);
  }, [bundleIdToDelete, dispatch, growerId]);

  const handleSetDefaultClick = useCallback(
    (bundleId) => {
      dispatch(setDefaultGrowerBundleActions.submit({ growerId, bundleId }));
    },
    [dispatch, growerId]
  );

  const handleResetDefaultClick = useCallback(() => {
    dispatch(resetDefaultGrowerBundleActions.submit({ growerId }));
  }, [dispatch, growerId]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Description</TableCell>
          <TableCell align="right">Default</TableCell>
          <TableCell align="right">Activities</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {_.map(bundles, (bundle) => (
          <TableRow key={_.get(bundle, 'id')}>
            <TableCell>{_.get(bundle, 'description')}</TableCell>
            <TableCell align="right">
              {_.get(bundle, 'isDefault') && <CheckIcon />}
            </TableCell>
            <TableCell align="right">
              {_.get(bundle, 'protocols.length')}
            </TableCell>
            <TableCell align="right">
              {(!bundleToDelete ||
                bundleIdToDelete !== _.get(bundle, 'id')) && (
                <>
                  <Tooltip title="Bundle Details" placement="top">
                    <IconButton
                      component={RouterLink}
                      to={`/crop-management/${
                        CropManagementTabsIds['grower-bundles']
                      }/${_.get(bundle, 'id')}`}
                      disabled={inProgress}
                    >
                      <AssignmentIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Clone Bundle" placement="top">
                    <IconButton
                      component={RouterLink}
                      to={`/crop-management/${
                        CropManagementTabsIds['grower-bundles']
                      }/${_.get(bundle, 'id')}/clone`}
                      disabled={inProgress}
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </Tooltip>
                  {!!_.get(bundle, 'isDefault') && (
                    <ResetDefaultButton
                      onClick={handleResetDefaultClick}
                      disabled={inProgress}
                    />
                  )}
                  {!_.get(bundle, 'isDefault') && (
                    <>
                      <SetDefaultButton
                        bundleId={_.get(bundle, 'id')}
                        onClick={handleSetDefaultClick}
                        disabled={inProgress}
                      />
                      <DeleteButton
                        title="Delete Bundle"
                        protocolId={_.get(bundle, 'id')}
                        onClick={handleDeleteClick}
                        disabled={inProgress}
                      />
                    </>
                  )}
                </>
              )}
              {!!bundleToDelete && bundleIdToDelete === _.get(bundle, 'id') && (
                <ConfirmIconButtons
                  onCancel={handleCancelDeleteClick}
                  onConfirm={handleConfirmDeleteClick}
                />
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default GrowerBundlesList;
