import { useContext, useMemo } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';

import { FarmStructureContext } from '../../_context/FarmStructureContext';
import { getSeasonWithGrowthsSelectors } from '../../_store/slices/gff/seasons/getSeasonsWithGrowthsSlice';
import { useCropDashboardSelectors } from '../../_store/slices/ui/cropDashboardSlice';

export const useAllGrowerFarmsFeedbackItems = () => {
  const {
    selectedGrowerId: growerId,
    selectedSeasonId: seasonId,
    selectedFarmId: farmId
  } = useContext(FarmStructureContext);

  const farmsWithGrowthsById = useSelector(
    getSeasonWithGrowthsSelectors.farms(growerId, seasonId)
  );

  const { showAllForGrower } = useCropDashboardSelectors();

  const feedbackItems = useMemo(
    () =>
      _.chain(farmsWithGrowthsById)
        .filter((farm, id) => showAllForGrower || id === farmId)
        .mapValues((farm) =>
          _.chain(farm)
            .get('feedback')
            .map((feedback) => ({
              ...feedback,
              farmName: _.get(farm, 'name'),
              farmId: _.get(farm, 'id')
            }))
            .value()
        )
        .flatMap((farm) => farm)
        .value(),
    [farmId, farmsWithGrowthsById, showAllForGrower]
  );

  return useMemo(
    () => ({
      feedbackItems
    }),
    [feedbackItems]
  );
};
