import { useMemo } from 'react';

import { activitiesSandboxService } from '../../../../services/activitiesSandbox.service';
import { createApiSlice } from '../../utils/createApiSlice';

const generateDemoActivitiesSlice = createApiSlice({
  reducerPath: ['activities', 'sandbox', 'generateDemoActivities'],
  apiFunction: activitiesSandboxService.generateActivities
});

const { actions, useApiSelectors } = generateDemoActivitiesSlice;

export const generateDemoActivitiesActions = actions;

export const generateDemoActivitiesSelectors = useApiSelectors;

export const useGenerateDemoActivitiesSelectors = () => {
  const { inProgress, errorMessage, success, response } =
    generateDemoActivitiesSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      response
    }),
    [errorMessage, inProgress, response, success]
  );
};

export default generateDemoActivitiesSlice;
