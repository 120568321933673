import { useMemo } from 'react';

import _ from 'lodash';

import { useFarmWorkOrderItems } from '../../../_hooks/FarmWorkOrders/useFarmWorkOrderItems';
import { useWorkOrdersFiltersSelectors } from '../../../_store/slices/ui/workOrdersFiltersSlice';

export const useFilteredWorkOrders = () => {
  const { workOrders } = useFarmWorkOrderItems();

  const { selectedStatuses } = useWorkOrdersFiltersSelectors();

  const filteredWorkOrders = useMemo(
    () =>
      _.filter(workOrders, (workOrder) => {
        return !!_.get(selectedStatuses, [_.get(workOrder, 'status')]);
      }),
    [selectedStatuses, workOrders]
  );

  return useMemo(
    () => ({
      workOrders: filteredWorkOrders
    }),
    [filteredWorkOrders]
  );
};
