import { useContext, useMemo } from 'react';

import _ from 'lodash';
import moment from 'moment/moment';

import { FarmStructureContext } from '../../../_context/FarmStructureContext';
import { useAllGrowerFarmsFeedbackItems } from '../../../_hooks/FarmFeedback/useAllGrowerFarmsFeedbackItems';
import { getActiveGrowth } from '../../../_hooks/Growths/useActiveGrowth';
import { useAllGrowerFieldsSelector } from '../../../_store/selectors/fieldsSelectors';
import { useGrowthModelSelectors } from '../../../_store/selectors/uiSelectors';
import { ActivityTypes } from '../CompletedActivitiesTimeline';

export const useAllActivities = () => {
  const { fields } = useAllGrowerFieldsSelector();

  const { selectedGrowthModel } = useGrowthModelSelectors();

  const { feedbackItems } = useAllGrowerFarmsFeedbackItems();

  const { selectedFieldId } = useContext(FarmStructureContext);

  const completedActivities = useMemo(
    () =>
      _.chain(fields)
        .filter(
          (field) => !selectedFieldId || _.get(field, 'id') === selectedFieldId
        )
        .flatMap((field) => {
          const activeGrowth = getActiveGrowth(field, selectedGrowthModel);
          return _.chain(activeGrowth)
            .get('activitiesV2')
            .filter((activity) => _.get(activity, 'isCompleted'))
            .map((item) => ({
              ...item,
              activityDate: moment(_.get(item, 'dateCompleted')).format(
                'YYYY-MM-DD'
              ),
              activityType: ActivityTypes.CompletedRecommendation,
              fieldId: _.get(field, 'id'),
              farmId: _.get(field, 'farmId'),
              fieldName: _.get(field, 'name')
            }))
            .value();
        })
        .value(),
    [fields, selectedFieldId, selectedGrowthModel]
  );

  const zonedActivities = useMemo(
    () =>
      _.chain(feedbackItems)
        .filter(
          (item) =>
            !selectedFieldId ||
            _.has(_.get(item, 'targetsByFieldId'), selectedFieldId)
        )
        .map((item) => ({
          ...item,
          activityDate: moment(_.get(item, 'date')).format('YYYY-MM-DD'),
          activityType: ActivityTypes.ZonedActivity
        }))
        .value(),
    [feedbackItems, selectedFieldId]
  );

  const allActivities = useMemo(
    () =>
      _.orderBy([...completedActivities, ...zonedActivities], 'activityDate'),
    [completedActivities, zonedActivities]
  );

  return useMemo(
    () => ({
      allActivities
    }),
    [allActivities]
  );
};
