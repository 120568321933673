import React, { useCallback, useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { ChromePicker } from 'react-color';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

const ColorPicker = ({ title, initialColor, onChange }) => {
  const styles = useStyles();
  const [color, setColor] = useState('#FFFFFF');

  useEffect(() => {
    if (!initialColor || typeof initialColor !== 'string') {
      return;
    }
    if (initialColor.startsWith('rgba')) {
      const rgbaArray = initialColor
        .replace('rgba(', '')
        .replace(')', '')
        .split(',')
        .map((val) => parseFloat(val.trim()));
      setColor({
        r: rgbaArray[0],
        g: rgbaArray[1],
        b: rgbaArray[2],
        a: rgbaArray[3]
      });
    } else if (initialColor.startsWith('#')) {
      setColor(initialColor);
    }
  }, [initialColor]);

  const handleChangeComplete = useCallback(() => {
    if (!color) {
      return;
    }
    const rgba = `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
    onChange(rgba);
  }, [color, onChange]);

  const handleChange = useCallback((color) => {
    setColor(color.rgb);
  }, []);

  return (
    <Box className={styles.container}>
      <Typography>{title}</Typography>
      <ChromePicker
        color={color}
        onChangeComplete={handleChangeComplete}
        onChange={handleChange}
        disableAlpha
      />
    </Box>
  );
};

export default ColorPicker;
