import React, { useCallback } from 'react';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
  channels: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end'
  },
  channelLabel: {
    marginBottom: 0
  }
}));

const NotificationChannel = ({ name, enabled, onChange }) => {
  const styles = useStyles();

  const handleChange = useCallback(
    (e) => {
      onChange(e.target.checked);
    },
    [onChange]
  );

  return (
    <FormControlLabel
      className={styles.channelLabel}
      control={
        <Checkbox
          id="channel"
          name="channel"
          checked={enabled || false}
          onChange={handleChange}
        />
      }
      label={name}
    />
  );
};

const NotificationChannels = ({ channels, onChange }) => {
  const styles = useStyles();

  const handleEmailChange = useCallback(
    (checked) => {
      onChange('email', checked);
    },
    [onChange]
  );

  const handleSmsChange = useCallback(
    (checked) => {
      onChange('sms', checked);
    },
    [onChange]
  );

  return (
    <Box className={styles.channels}>
      <NotificationChannel
        name="Email"
        enabled={_.get(channels, 'email')}
        onChange={handleEmailChange}
      />
      <NotificationChannel
        name="SMS"
        enabled={_.get(channels, 'sms')}
        onChange={handleSmsChange}
      />
    </Box>
  );
};

export default NotificationChannels;
