import WebMercatorViewport from '@math.gl/web-mercator';
import bbox from '@turf/bbox';
import { lineString } from '@turf/helpers';
import { easeQuad } from 'd3-ease';
import { FlyToInterpolator } from 'react-map-gl';

import { mapTransitionDuration } from '../config';

const MIN_ZOOM = 18;
const MAP_PADDING = 100;

export const BuildViewPort = (
  coordinates,
  width,
  height,
  padding = MAP_PADDING
) => {
  const [minLng, minLat, maxLng, maxLat] = bbox(lineString(coordinates));

  const { longitude, latitude, zoom } = new WebMercatorViewport({
    width,
    height
  }).fitBounds(
    [
      [minLng, minLat],
      [maxLng, maxLat]
    ],
    { padding }
  );

  return {
    longitude,
    latitude,
    zoom: Math.min(zoom, MIN_ZOOM)
  };
};

export const BuildFlyViewPort = (
  coordinates,
  width,
  height,
  padding = MAP_PADDING
) => {
  return {
    ...BuildViewPort(coordinates, width, height, padding),
    transitionDuration: mapTransitionDuration,
    transitionInterpolator: new FlyToInterpolator({ curve: 0.7 }),
    transitionEasing: easeQuad
  };
};
