import { useMemo } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';

import { FeedbackTargetType } from '../../../_constants/activitiesConstants';
import { createDataSlice } from '../utils/createDataSlice';

const addWorkOrderSlice = createDataSlice({
  reducerPath: ['ui', 'addWorkOrder'],
  initialState: {
    showAddWorkOrderForm: false,
    selectShapeMode: false,
    selectedShapes: {},
    workOrderToEdit: undefined
  },
  reducers: {
    setShowAddWorkOrderForm: (state, action) => {
      const show = action.payload;
      _.set(state, 'showAddWorkOrderForm', show);
    },
    setSelectShapeMode: (state, action) => {
      const mode = action.payload;
      _.set(state, 'selectShapeMode', mode);
    },
    toggleSelectShapeMode: (state) => {
      _.set(state, 'selectShapeMode', !_.get(state, 'selectShapeMode'));
    },
    addFieldShape: (state, action) => {
      const { growerId, farmId, fieldId } = action.payload;
      const selectedShapes = _.get(state, 'selectedShapes', {});
      _.set(state, 'selectedShapes', {
        ...selectedShapes,
        [fieldId]: { type: FeedbackTargetType.Field, growerId, farmId, fieldId }
      });
    },
    addZoneShape: (state, action) => {
      const { growerId, farmId, fieldId, layerId, zoneId } = action.payload;
      const selectedShapes = _.get(state, 'selectedShapes', {});
      const currentLayerId = _.get(selectedShapes, [fieldId, 'layerId']);

      if (currentLayerId !== layerId) {
        _.set(state, ['selectedShapes', fieldId], {
          type: FeedbackTargetType.Zones,
          growerId,
          farmId,
          fieldId,
          layerId,
          zones: {
            [zoneId]: { layerId, zoneId }
          }
        });
      } else {
        _.set(state, ['selectedShapes', fieldId, 'zones', zoneId], {
          layerId,
          zoneId
        });
      }
    },
    removeFieldShape: (state, action) => {
      const { fieldId } = action.payload;
      const selectedShapes = _.get(state, 'selectedShapes', {});
      _.set(state, 'selectedShapes', _.omit(selectedShapes, [fieldId]));
      _.set(state, 'selectShapeMode', false);
    },
    removeZoneShape: (state, action) => {
      const { fieldId, zoneId } = action.payload;
      const selectedShapes = _.get(state, 'selectedShapes', {});
      const zones = _.get(selectedShapes, [fieldId, 'zones']);
      const newZones = _.omit(zones, [zoneId]);
      if (_.isEmpty(newZones)) {
        _.set(state, 'selectedShapes', _.omit(selectedShapes, [fieldId]));
      } else {
        _.set(state, ['selectedShapes', fieldId, 'zones'], newZones);
      }
      _.set(state, 'selectShapeMode', false);
    },
    clearSelectedShapes: (state) => {
      _.set(state, 'selectedShapes', {});
    },
    setWorkOrderToEdit: (state, action) => {
      const workOrderToEdit = action.payload;
      _.set(state, 'workOrderToEdit', workOrderToEdit);
    }
  }
});

const { actions, baseSelector: addWorkOrderSelector } = addWorkOrderSlice;

export const addWorkOrderActions = actions;

export const useAddWorkOrderSelectors = () => {
  const {
    showAddWorkOrderForm,
    selectShapeMode,
    selectedShapes,
    workOrderToEdit
  } = useSelector(addWorkOrderSelector);

  return useMemo(
    () => ({
      showAddWorkOrderForm,
      selectShapeMode,
      selectedShapes,
      workOrderToEdit
    }),
    [selectShapeMode, selectedShapes, showAddWorkOrderForm, workOrderToEdit]
  );
};

export default addWorkOrderSlice;
