import React, { lazy, Suspense, useContext } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import { AuthContext } from '../../../_context/AuthContext';
import { ActivitiesEngineSubpagePaths } from '../../../Pages/ActivitiesEngine/useActivitiesEnginePageConfig';
import { ApplicationConfigurationTabsIds } from '../../../Pages/ApplicationConfiguration/ApplicationConfigurationPage';
import { CropManagementTabsIds } from '../../../Pages/CropManagement/CropManagementPage';
import { dashboardTabsIds } from '../../../Pages/Dashboard/DashboardPage';
import { NotificationsConfigTabIds } from '../../../Pages/NotificationsConfig/NotificationsConfigPage';
import { Reports } from '../../../Pages/Reports/ReportsPage';
import { SeasonConfigTabIds } from '../../../Pages/SeasonsConfig/SeasonConfigPage';
import { UsageStatisticsTabs } from '../../../Pages/Statistics/UsageStatisticsPage';
import { DASHBOARD_TABS } from '../../../Pages/WeatherDashboard/useWeatherDashboardPageConfig';

import LoadingOverlay from './LoadingOverlay';
import RouteWrapper from './RouteWrapper';

const UserAdminPage = lazy(() => import('../../../Pages/UserAdmin'));
const YieldMapPage = lazy(() => import('../../../Pages/YieldMap'));
const ShapefileDemoPage = lazy(() =>
  import('../../../Pages/ShapefileDemo/ShapefileDemoPage')
);
const TankMixDemoPage = lazy(() =>
  import('../../../Pages/TaknMixDemo/TankMixDemoPage')
);
const LoginPage = lazy(() => import('../../../Pages/Login/LoginPage'));
const ForgotPasswordPage = lazy(() =>
  import('../../../Pages/Login/ForgotPasswordPage')
);
const ResetPasswordPage = lazy(() =>
  import('../../../Pages/Login/ResetPasswordPage')
);
const TokenRefreshPage = lazy(() =>
  import('../../../Pages/Login/TokenRefreshPage')
);
const Aquarius = lazy(() => import('../../../Pages/Aquarius'));
const NotificationsConfig = lazy(() =>
  import('../../../Pages/NotificationsConfig')
);
const GrowersConfigListPage = lazy(() =>
  import('../../../Pages/GrowersConfig/GrowersListPage')
);
const GrowersDevicesListPage = lazy(() =>
  import('../../../Pages/GrowersConfig/GrowersDevicesListPage')
);
const EditGrowerDevicePage = lazy(() =>
  import('../../../Pages/GrowersConfig/EditGrowerDevicePage')
);
const EditGrowerPage = lazy(() =>
  import('../../../Pages/GrowersConfig/EditGrowerPage')
);
const GrowerDetailsPage = lazy(() =>
  import('../../../Pages/GrowersConfig/GrowerDetailsPage')
);
const GrowerFarmsPage = lazy(() =>
  import('../../../Pages/GrowersConfig/GrowerFarmsPage')
);
const EditFarmPage = lazy(() =>
  import('../../../Pages/GrowersConfig/EditFarmPage')
);
const FarmDetailsPage = lazy(() =>
  import('../../../Pages/GrowersConfig/FarmDetailsPage')
);
const FarmFieldsPage = lazy(() =>
  import('../../../Pages/GrowersConfig/FarmFieldsPage')
);
const FieldDetailsPage = lazy(() =>
  import('../../../Pages/GrowersConfig/FieldDetailsPage')
);
const EditFieldPage = lazy(() =>
  import('../../../Pages/GrowersConfig/EditFieldPage')
);
const ApplicationConfigurationPage = lazy(() =>
  import('../../../Pages/ApplicationConfiguration/ApplicationConfigurationPage')
);
const MapPage = lazy(() => import('../../../Pages/Map'));
const DashboardPage = lazy(() => import('../../../Pages/Dashboard'));
const EditUserPage = lazy(() =>
  import('../../../Pages/UserAdmin/EditUserPage')
);
const UserDetailsPage = lazy(() =>
  import('../../../Pages/UserAdmin/UserDetailsPage')
);

const AccountInactivePage = lazy(() =>
  import('../../../Pages/AccountInactive')
);

const AcceptTermsOfServicePage = lazy(() =>
  import('../../../Pages/TermsOfService/AcceptTermsOfServicePage')
);
const ViewTermsOfServicePage = lazy(() =>
  import('../../../Pages/TermsOfService/ViewTermsOfServicePage')
);
const RedirectHandlerPage = lazy(() =>
  import('../../../Pages/RedirectHandler')
);
const SeasonConfigPage = lazy(() =>
  import('../../../Pages/SeasonsConfig/SeasonConfigPage')
);
const EditSeasonFieldPage = lazy(() =>
  import('../../../Pages/SeasonsConfig/EditSeasonFieldPage')
);
const SeasonFieldSensorConfigPage = lazy(() =>
  import('../../../Pages/SeasonsConfig/SeasonFieldSensorConfigPage')
);

const IrrigationDashboardPage = lazy(() =>
  import('../../../Pages/IrrigationDashboard')
);

const AddUserFeedbackPage = lazy(() =>
  import('../../../Pages/Feedback/AddUserFeedbackPage')
);

const UsersFeedbackPage = lazy(() =>
  import('../../../Pages/Feedback/UsersFeedbackPage')
);

const AboutPage = lazy(() => import('../../../Pages/About/AboutPage'));

const ReportsPage = lazy(() => import('../../../Pages/Reports/ReportsPage'));
const UsageStatisticsPage = lazy(() =>
  import('../../../Pages/Statistics/UsageStatisticsPage')
);
const ActivitiesEnginePage = lazy(() =>
  import('../../../Pages/ActivitiesEngine/ActivitiesEnginePage')
);

const ActivityConfigPage = lazy(() =>
  import('../../../Pages/ApplicationConfiguration/ActivityConfigPage')
);

const WeatherDashboard = lazy(() => import('../../../Pages/WeatherDashboard'));

const CreateBundlePage = lazy(() =>
  import('../../../Pages/ApplicationConfiguration/CreateBundlePage')
);
const BundleDetailsPage = lazy(() =>
  import('../../../Pages/ApplicationConfiguration/BundleDetailsPage')
);
const CloneBundlePage = lazy(() =>
  import('../../../Pages/ApplicationConfiguration/CloneBundlePage')
);

const CreateProtocolPage = lazy(() =>
  import('../../../Pages/ApplicationConfiguration/CreateProtocolPage')
);

const ProtocolDetailsPage = lazy(() =>
  import('../../../Pages/ApplicationConfiguration/ProtocolDetailsPage')
);

const CropManagementPage = lazy(() =>
  import('../../../Pages/CropManagement/CropManagementPage')
);
const CreateGrowerBundlePage = lazy(() =>
  import('../../../Pages/CropManagement/CreateGrowerBundlePage')
);
const GrowerBundleDetailsPage = lazy(() =>
  import('../../../Pages/CropManagement/GrowerBundleDetailsPage')
);
const CloneGrowerBundlePage = lazy(() =>
  import('../../../Pages/CropManagement/CloneGrowerBundlePage')
);
const CloneGlobalBundlePage = lazy(() =>
  import('../../../Pages/CropManagement/CloneGlobalBundlePage')
);

const CreateGrowerProtocolPage = lazy(() =>
  import('../../../Pages/CropManagement/CreateGrowerProtocolPage')
);
const GrowerProtocolDetailsPage = lazy(() =>
  import('../../../Pages/CropManagement/GrowerProtocolDetailsPage')
);
const GrowerActivityConfigPage = lazy(() =>
  import('../../../Pages/CropManagement/GrowerActivityConfigPage')
);
const GrowthModelApiAdminPage = lazy(() =>
  import('../../../Pages/GrowthModelApiAdmin/GrowthModelApiAdminPage')
);

const GrowthModelApiFieldPage = lazy(() =>
  import('../../../Pages/GrowthModelApiAdmin/GrowthModelApiFieldPage')
);

export const PageTabPathKey = ':tabId';

const AppRoutes = () => {
  const { isLoggedIn, isActive, tosAccepted } = useContext(AuthContext);

  return (
    <Suspense fallback={<LoadingOverlay />}>
      <Routes>
        {!isLoggedIn && (
          <Route
            exact
            path="/login"
            element={
              <RouteWrapper>
                <LoginPage />
              </RouteWrapper>
            }
          />
        )}
        {!isLoggedIn && (
          <Route
            exact
            path="/forgot-password"
            element={
              <RouteWrapper>
                <ForgotPasswordPage />
              </RouteWrapper>
            }
          />
        )}
        {!isLoggedIn && (
          <Route
            exact
            path="/reset-password"
            element={
              <RouteWrapper>
                <ResetPasswordPage />
              </RouteWrapper>
            }
          />
        )}
        <Route
          exact
          path="/auth"
          element={
            <RouteWrapper>
              <TokenRefreshPage />
            </RouteWrapper>
          }
        />
        {isLoggedIn && !isActive && (
          <Route
            exact
            path="/inactive"
            element={
              <RouteWrapper>
                <AccountInactivePage />
              </RouteWrapper>
            }
          />
        )}
        {isLoggedIn && isActive && !tosAccepted && (
          <Route
            exact
            path="/acceptTermsOfService"
            element={
              <RouteWrapper>
                <AcceptTermsOfServicePage />
              </RouteWrapper>
            }
          />
        )}
        {isLoggedIn && isActive && tosAccepted && (
          <Route
            exact
            path="/termsOfService"
            element={
              <RouteWrapper>
                <ViewTermsOfServicePage />
              </RouteWrapper>
            }
          />
        )}
        {(!isLoggedIn || (isLoggedIn && isActive && tosAccepted)) && (
          <Route
            exact
            path="/redirect"
            element={
              <RouteWrapper>
                <RedirectHandlerPage />
              </RouteWrapper>
            }
          />
        )}
        <Route
          exact
          path="/yieldmaps"
          element={
            <RouteWrapper>
              <YieldMapPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/shapefile-demo"
          element={
            <RouteWrapper>
              <ShapefileDemoPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/tank-mix-demo"
          element={
            <RouteWrapper>
              <TankMixDemoPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/usersConfig"
          element={
            <RouteWrapper>
              <UserAdminPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/weather"
          element={
            <Navigate to={`/weather/${DASHBOARD_TABS.CURRENT_CONDITIONS}`} />
          }
        />
        <Route
          exact
          path={`/weather/${PageTabPathKey}`}
          element={
            <RouteWrapper>
              <WeatherDashboard />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/irrigationDashboard"
          element={
            <RouteWrapper>
              <IrrigationDashboardPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/aquarius"
          element={
            <RouteWrapper>
              <Aquarius />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/notificationsConfig"
          element={
            <Navigate
              to={`/notificationsConfig/${NotificationsConfigTabIds.GROWTH_MODEL}`}
            />
          }
        />
        <Route
          exact
          path={`/notificationsConfig/${PageTabPathKey}`}
          element={
            <RouteWrapper>
              <NotificationsConfig />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/growersConfig"
          element={
            <RouteWrapper>
              <GrowersConfigListPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/growersConfig/:growerId/devices"
          element={
            <RouteWrapper>
              <GrowersDevicesListPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/growersConfig/:growerId/devices/new"
          element={
            <RouteWrapper>
              <EditGrowerDevicePage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/growersConfig/:growerId/devices/:deviceId/edit"
          element={
            <RouteWrapper>
              <EditGrowerDevicePage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/growersConfig/new"
          element={
            <RouteWrapper>
              <EditGrowerPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/growersConfig/:growerId"
          element={
            <RouteWrapper>
              <GrowerDetailsPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/growersConfig/:growerId/edit"
          element={
            <RouteWrapper>
              <EditGrowerPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/growersConfig/:growerId/farms"
          element={
            <RouteWrapper>
              <GrowerFarmsPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/growersConfig/:growerId/farms/new"
          element={
            <RouteWrapper>
              <EditFarmPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/growersConfig/:growerId/farms/:farmId/edit"
          element={
            <RouteWrapper>
              <EditFarmPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/growersConfig/:growerId/farms/:farmId"
          element={
            <RouteWrapper>
              <FarmDetailsPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/growersConfig/:growerId/farms/:farmId/fields"
          element={
            <RouteWrapper>
              <FarmFieldsPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/growersConfig/:growerId/farms/:farmId/fields/new"
          element={
            <RouteWrapper>
              <EditFieldPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/growersConfig/:growerId/farms/:farmId/fields/:fieldId"
          element={
            <RouteWrapper>
              <FieldDetailsPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/growersConfig/:growerId/farms/:farmId/fields/:fieldId/edit"
          element={
            <RouteWrapper>
              <EditFieldPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/seasonsConfig"
          element={
            <Navigate to={`/seasonsConfig/${SeasonConfigTabIds.FIELDS}`} />
          }
        />
        <Route
          exact
          path={`/seasonsConfig/${PageTabPathKey}`}
          element={
            <RouteWrapper>
              <SeasonConfigPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/seasonsConfig/fields/:fieldId/edit"
          element={
            <RouteWrapper>
              <EditSeasonFieldPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/seasonsConfig/fields/:fieldId/devices"
          element={
            <RouteWrapper>
              <SeasonFieldSensorConfigPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path={`/crop-management/${PageTabPathKey}`}
          element={
            <RouteWrapper>
              <CropManagementPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/crop-management"
          element={
            <Navigate
              to={`/crop-management/${CropManagementTabsIds['growths']}`}
            />
          }
        />
        <Route
          exact
          path={`/crop-management/${CropManagementTabsIds['grower-bundles']}/new`}
          element={
            <RouteWrapper>
              <CreateGrowerBundlePage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path={`/crop-management/${CropManagementTabsIds['grower-bundles']}/clone-global`}
          element={
            <RouteWrapper>
              <CloneGlobalBundlePage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path={`/crop-management/${CropManagementTabsIds['grower-bundles']}/:bundleId`}
          element={
            <RouteWrapper>
              <GrowerBundleDetailsPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path={`/crop-management/${CropManagementTabsIds['grower-bundles']}/:bundleId/clone`}
          element={
            <RouteWrapper>
              <CloneGrowerBundlePage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path={`/crop-management/${CropManagementTabsIds['grower-bundles']}/:bundleId/protocols/new`}
          element={
            <RouteWrapper>
              <CreateGrowerProtocolPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path={`/crop-management/${CropManagementTabsIds['grower-bundles']}/:bundleId/protocols/:protocolId`}
          element={
            <RouteWrapper>
              <GrowerProtocolDetailsPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path={`/crop-management/${CropManagementTabsIds['grower-bundles']}/:bundleId/protocols/:protocolId/activities/new`}
          element={
            <RouteWrapper>
              <GrowerActivityConfigPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path={`/crop-management/${CropManagementTabsIds['grower-bundles']}/:bundleId/protocols/:protocolId/activities/:activityId/edit`}
          element={
            <RouteWrapper>
              <GrowerActivityConfigPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path={`/application-config/${PageTabPathKey}`}
          element={
            <RouteWrapper>
              <ApplicationConfigurationPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/application-config"
          element={
            <Navigate
              to={`/application-config/${ApplicationConfigurationTabsIds['growth-stages']}`}
            />
          }
        />
        <Route
          exact
          path={`/application-config/${ApplicationConfigurationTabsIds['protocol-bundles']}/new`}
          element={
            <RouteWrapper>
              <CreateBundlePage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path={`/application-config/${ApplicationConfigurationTabsIds['protocol-bundles']}/:bundleId`}
          element={
            <RouteWrapper>
              <BundleDetailsPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path={`/application-config/${ApplicationConfigurationTabsIds['protocol-bundles']}/:bundleId/clone`}
          element={
            <RouteWrapper>
              <CloneBundlePage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path={`/application-config/${ApplicationConfigurationTabsIds['protocol-bundles']}/:bundleId/protocols/new`}
          element={
            <RouteWrapper>
              <CreateProtocolPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path={`/application-config/${ApplicationConfigurationTabsIds['protocol-bundles']}/:bundleId/protocols/:protocolId`}
          element={
            <RouteWrapper>
              <ProtocolDetailsPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path={`/application-config/${ApplicationConfigurationTabsIds['protocol-bundles']}/:bundleId/protocols/:protocolId/activities/new`}
          element={
            <RouteWrapper>
              <ActivityConfigPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path={`/application-config/${ApplicationConfigurationTabsIds['protocol-bundles']}/:bundleId/protocols/:protocolId/activities/:activityId/edit`}
          element={
            <RouteWrapper>
              <ActivityConfigPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/usersConfig/new"
          element={
            <RouteWrapper>
              <EditUserPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/usersConfig/:userId/edit"
          element={
            <RouteWrapper>
              <EditUserPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/usersConfig/:userId/details"
          element={
            <RouteWrapper>
              <UserDetailsPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/map"
          element={
            <RouteWrapper>
              <MapPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/dashboard"
          element={
            <Navigate to={`/dashboard/${dashboardTabsIds.GROWTH_MODEL}`} />
          }
        />
        <Route
          exact
          path={`/dashboard/${PageTabPathKey}`}
          element={
            <RouteWrapper>
              <DashboardPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/share-feedback"
          element={
            <RouteWrapper>
              <AddUserFeedbackPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/users-feedback"
          element={
            <RouteWrapper>
              <UsersFeedbackPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/about"
          element={
            <RouteWrapper>
              <AboutPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/reports"
          element={<Navigate to={`/reports/${Reports['full-season'].id}`} />}
        />
        <Route
          exact
          path={`/reports/${PageTabPathKey}`}
          element={
            <RouteWrapper>
              <ReportsPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/statistics"
          element={
            <Navigate to={`/statistics/${UsageStatisticsTabs.growers.id}`} />
          }
        />
        <Route
          exact
          path={`/statistics/${PageTabPathKey}`}
          element={
            <RouteWrapper>
              <UsageStatisticsPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/activities-engine"
          element={
            <Navigate
              to={`/activities-engine/${ActivitiesEngineSubpagePaths.Engine}`}
            />
          }
        />
        <Route
          exact
          path={`/activities-engine/${PageTabPathKey}`}
          element={
            <RouteWrapper>
              <ActivitiesEnginePage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path={`/growth-model-api`}
          element={
            <RouteWrapper>
              <GrowthModelApiAdminPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path={`/growth-model-api/:season/fields/:fieldId`}
          element={
            <RouteWrapper>
              <GrowthModelApiFieldPage />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/"
          element={
            <>
              {!isLoggedIn && <Navigate to="/login" />}
              {!!isLoggedIn && !isActive && <Navigate to="/inactive" />}
              {!!isLoggedIn && !!isActive && !tosAccepted && (
                <Navigate to="/acceptTermsOfService" />
              )}
              {!!isLoggedIn && !!isActive && !!tosAccepted && (
                <Navigate to="/redirect" />
              )}
            </>
          }
        />
        <Route path="*" exact element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
