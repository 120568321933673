import React, { useCallback, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { useSubPage } from '../../_hooks/Pages/useSubpage';
import {
  getNotificationsConfigActions,
  saveNotificationsConfigActions,
  updateActivitiesNotificationConfig,
  updateGrowthStagesNotificationConfig
} from '../../_store/actions/notificationsConfigActions';
import { useGrowersSelectors } from '../../_store/selectors/growersSelectors';
import {
  useNotificationsConfigSelector,
  useSaveNotificationsConfigSelector
} from '../../_store/selectors/notificationsConfigSelectors';
import LoadingIndicator from '../../Components/LoadingIndicator';
import AppPage from '../../Layout/AppPage';
import { ContentBody, ContentContainer } from '../../Layout/Content';
import PageTitle from '../../Layout/PageTitle';

import { useGrowersWithPumps } from './Components/_hooks/useGrowersWithPumps';
import PerGrowerNotificationsConfig from './Components/PerGrowerNotificationsConfig';
import PumpSystemsNotificationsConfig from './Components/PumpSystemsNotificationsConfig';
import { NotificationsConfigPageSubpageKeys } from './useNotificationsConfigPageConfig';

const useStyles = makeStyles(() => ({
  addButton: {
    '&:hover': {
      textDecoration: 'none',
      color: '#fff'
    }
  }
}));

export const NotificationsConfigTabIds = {
  GROWTH_MODEL: 'growth-model',
  ACTIVITIES: 'activities',
  PUMP_SYSTEMS: 'pump-systems'
};

const NotificationsConfigPage = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const { subPageKey } = useSubPage();

  const {
    notificationsConfig,
    inProgress: getConfigProgress,
    error: getConfigError
  } = useNotificationsConfigSelector();

  const {
    inProgress: saveConfigProgress,
    success: saveConfigSuccess,
    error: saveConfigError
  } = useSaveNotificationsConfigSelector();

  const {
    growers,
    growersFetchError,
    inProgress: getGrowersProgress
  } = useGrowersSelectors();

  const inProgress =
    getConfigProgress || getGrowersProgress || saveConfigProgress;
  const error = getConfigError || growersFetchError || saveConfigError;

  const growersGrowthStagesConfig = _.get(
    notificationsConfig,
    'growersGrowthStages'
  );

  const growersActivitiesConfig = _.get(
    notificationsConfig,
    'growersActivities'
  );

  const pumpSystemsNotificationsConfig = _.get(
    notificationsConfig,
    'growersPumpSystems'
  );

  const { growersWithPumps } = useGrowersWithPumps(growers);

  useEffect(() => {
    dispatch(getNotificationsConfigActions.request());
    return () => {
      dispatch(saveNotificationsConfigActions.clear());
    };
  }, [dispatch]);

  useEffect(() => {
    if (saveConfigSuccess) {
      dispatch(saveNotificationsConfigActions.clear());
    }
  }, [dispatch, saveConfigSuccess]);

  const handleSave = useCallback(() => {
    dispatch(saveNotificationsConfigActions.request(notificationsConfig));
  }, [dispatch, notificationsConfig]);

  const handleGrowthModelChange = useCallback(
    (growerId, channel, checked) => {
      dispatch(
        updateGrowthStagesNotificationConfig(growerId, channel, checked)
      );
    },
    [dispatch]
  );

  const handleActivitiesChange = useCallback(
    (growerId, channel, checked) => {
      dispatch(updateActivitiesNotificationConfig(growerId, channel, checked));
    },
    [dispatch]
  );

  return (
    <AppPage
      header={
        <PageTitle>
          <Button
            size="small"
            variant="contained"
            color="primary"
            className={styles.addButton}
            onClick={handleSave}
            disabled={inProgress || error}
          >
            Save
          </Button>
        </PageTitle>
      }
    >
      {!inProgress && !error && growers && notificationsConfig && (
        <>
          {subPageKey === NotificationsConfigPageSubpageKeys.GrowthModel && (
            <PerGrowerNotificationsConfig
              title="Growth Model"
              notificationsConfig={growersGrowthStagesConfig}
              growers={growers}
              onChange={handleGrowthModelChange}
            />
          )}
          {subPageKey === NotificationsConfigPageSubpageKeys.Activities && (
            <PerGrowerNotificationsConfig
              title="Activities"
              notificationsConfig={growersActivitiesConfig}
              growers={growers}
              onChange={handleActivitiesChange}
            />
          )}
          {subPageKey === NotificationsConfigPageSubpageKeys.PumpSystems && (
            <PumpSystemsNotificationsConfig
              notificationsConfig={pumpSystemsNotificationsConfig}
              growers={growersWithPumps}
            />
          )}
        </>
      )}
      {inProgress && <LoadingIndicator />}
      {!inProgress && error && (
        <ContentContainer>
          <ContentBody>
            <Alert severity="error">{error}</Alert>
          </ContentBody>
        </ContentContainer>
      )}
    </AppPage>
  );
};

export default NotificationsConfigPage;
