import React, { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { useActiveGrowthSelectors } from '../../../_store/selectors/growthsSelectors';
import {
  activityFeedbackDialogActions,
  useActivityFeedbackDialogSelectors
} from '../../../_store/slices/ui/dialogs/activityFeedbackDialogSlice';
import ResponsiveDialog from '../../ResponsiveDialog';

import ActivityFeedbackDialogContent from './ActivityFeedbackDialogContent';

const ActivityFeedbackDialog = () => {
  const dispatch = useDispatch();

  const { open, growerId, seasonId, farmId, fieldId, activity } =
    useActivityFeedbackDialogSelectors();

  const { growth } = useActiveGrowthSelectors(seasonId, fieldId);

  const handleClose = useCallback(() => {
    dispatch(activityFeedbackDialogActions.close());
  }, [dispatch]);

  return (
    <>
      <ResponsiveDialog open={open}>
        {open && (
          <ActivityFeedbackDialogContent
            activity={activity}
            onClose={handleClose}
            growth={growth}
            growerId={growerId}
            seasonId={seasonId}
            farmId={farmId}
            fieldId={fieldId}
          />
        )}
      </ResponsiveDialog>
    </>
  );
};

export default ActivityFeedbackDialog;
