import React, { useMemo, useRef } from 'react';

import _ from 'lodash';
import { Pie } from 'react-chartjs-2';

import { growthStagesTypes } from '../../../_constants/growthsConstants';
import { useGrowthStagesConfig } from '../../../_store/selectors/configSelectors';

import { useChartDataType } from './_hooks/useChartDataType';
import ReportChartButtons from './ReportChartButtons';
import ReportsChartContainer from './ReportsChartContainer';

function GrowthModelReportChart({ fields, reportGrowthStages }) {
  const chartDataType = useChartDataType();
  const { dataType, showAcres } = chartDataType;

  const chartRef = useRef(null);

  const { growthStagesConfig } = useGrowthStagesConfig();

  const fieldsWithCurrentGrowthStage = useMemo(
    () =>
      _.map(fields, (field) => {
        const selectedGrowthStages = _.chain(reportGrowthStages)
          .map((stage) => ({ stage }))
          .keyBy('stage')
          .value();

        const currentGrowthStage = _.get(field, 'estimatedCurrentGrowthStage');

        const plantingEnabled = _.has(
          selectedGrowthStages,
          growthStagesTypes.Planting
        );

        return {
          ...field,
          currentGrowthStage:
            currentGrowthStage === growthStagesTypes.NotPlanted ||
            (plantingEnabled &&
              currentGrowthStage === growthStagesTypes.Preplanting) ||
            _.has(selectedGrowthStages, currentGrowthStage)
              ? currentGrowthStage
              : growthStagesTypes.Unknown
        };
      }),
    [fields, reportGrowthStages]
  );

  const growthStagesStatistics = useMemo(() => {
    const fieldsByStages = _.groupBy(
      fieldsWithCurrentGrowthStage,
      'currentGrowthStage'
    );
    return _.chain(fieldsByStages)
      .mapValues((group) => ({
        acres: _.sumBy(group, 'acres'),
        count: _.get(group, 'length', 0)
      }))
      .value();
  }, [fieldsWithCurrentGrowthStage]);

  const percentages = useMemo(() => {
    const totalAcres = _.chain(growthStagesStatistics)
      .values()
      .sumBy('acres')
      .value();
    const totalCount = _.chain(growthStagesStatistics)
      .values()
      .sumBy('count')
      .value();
    return _.mapValues(growthStagesStatistics, (stage) => {
      const acres = (_.get(stage, 'acres') / totalAcres) * 100;
      const count = (_.get(stage, 'count') / totalCount) * 100;
      return {
        percentages: {
          acres: Math.round(acres * 10) / 10,
          count: Math.round(count * 10) / 10
        },
        values: {
          acres: Math.round(_.get(stage, 'acres') * 10) / 10,
          count: Math.round(_.get(stage, 'count'))
        }
      };
    });
  }, [growthStagesStatistics]);

  const data = useMemo(
    () =>
      _.chain(percentages)
        .map((item, stage) => {
          let stageConfig = _.get(growthStagesConfig, [stage]);
          if (_.get(stageConfig, 'type') === growthStagesTypes.Unknown) {
            stageConfig = { ...stageConfig, name: 'Other' };
          }
          return {
            ...item,
            stage,
            stageConfig
          };
        })
        .orderBy((item) =>
          _.get(growthStagesConfig, [_.get(item, 'stage'), 'order'])
        )
        .value(),
    [growthStagesConfig, percentages]
  );

  const chartData = useMemo(() => {
    const labels = _.map(data, (item) =>
      _.get(item, 'stageConfig.name', 'Other')
    );

    const datasets = [
      {
        data: _.map(data, (item) => _.get(item, ['percentages', dataType])),
        backgroundColor: _.map(data, (item) =>
          _.get(item, 'stageConfig.outlineColor')
        )
      }
    ].filter((dataset) => !_.isEmpty(dataset.data));

    return {
      labels,
      datasets
    };
  }, [data, dataType]);

  const options = useMemo(
    () => ({
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          text: `Current Growth Stages by ${
            showAcres ? 'Acres' : 'Number of Fields'
          }`,
          font: {
            size: 16
          }
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              const idx = context.dataIndex;

              return ` ${context.label}: ${_.get(data, [
                idx,
                'values',
                dataType
              ])} ${showAcres ? 'Acres' : 'Fields'} (${context.parsed}%)`;
            }
          }
        }
      }
    }),
    [data, dataType, showAcres]
  );

  return (
    <ReportsChartContainer
      header={
        <ReportChartButtons
          chartRef={chartRef}
          chartFilename={`Current-Growth-Stages-by-${
            showAcres ? 'Acres' : 'Number-of-Fields'
          }-Chart.png`}
          chartDataType={chartDataType}
        />
      }
    >
      <Pie ref={chartRef} type="pie" data={chartData} options={options} />
    </ReportsChartContainer>
  );
}

export default GrowthModelReportChart;
