import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { Card, CardContent, IconButton, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import _ from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { mapLayerIds } from '../../_constants/mapLayersConstants';
import {
  WorkOrderPayloadType,
  WorkOrderStatus,
  WorkOrderType
} from '../../_constants/workOrderConstants';
import { AuthContext } from '../../_context/AuthContext';
import { FarmStructureContext } from '../../_context/FarmStructureContext';
import { useQuery } from '../../_hooks/useQuery';
import { forceUpdateFarmStructureAction } from '../../_store/actions/farmStructureActions';
import { selectMapLayer } from '../../_store/actions/uiActions';
import { activityTypes } from '../../_store/selectors/configSelectors';
import {
  deleteFarmWorkOrderActions,
  useDeleteFarmWorkOrderSelectors
} from '../../_store/slices/growths/farmWorkOrders/deleteFarmWorkOrderSlice';
import {
  toaster,
  toastError,
  toastSuccess
} from '../../_utilities/toast-utils';
import { useWorkOrderParams } from '../../Pages/Map/Components/hooks/useWorkOrderParams';
import ConfirmIconButtons from '../ConfirmIconButtons';
import LoadingIndicator from '../LoadingIndicator';

import AddWorkOrderNoteDialog from './AddWorkOrderNoteDialog';
import EditFarmWorkOrderDialog from './EditFarmWorkOrderDialog';
import FarmWorkOrderDetailsDialog from './FarmWorkOrderDetailsDialog';
import GoToWorkOrderMapButton from './GoToWorkOrderMapButton';

const useStyles = makeStyles(() => ({
  container: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    flexWrap: 'wrap',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    paddingTop: '0.5rem',
    paddingBottom: '1rem'
  },
  card: {
    marginLeft: '0.75rem',
    marginTop: '0.75rem',
    flex: '0 0 auto'
  },
  title: {
    textAlign: 'center'
  },
  fieldsTitle: { marginTop: '1rem' }
}));

const Row = ({ title, value }) => {
  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <Typography style={{ marginRight: '0.5rem' }}>{title}:</Typography>
      <Typography
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap'
        }}
      >
        <b>{value}</b>
      </Typography>
    </Box>
  );
};

const WorkOrder = ({
  workOrder,
  onEditClick,
  onDetailsClick,
  onAddNoteClick
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [confirmDelete, setConfirmDelete] = useState(false);

  const { selectedGrowerId, selectedSeasonId, selectedFarmId, isActiveSeason } =
    useContext(FarmStructureContext);
  const { isUserView } = useContext(AuthContext);

  const {
    type,
    name,
    description,
    dueDate,
    dateCreated,
    dateCompleted,
    status,
    payloadType,
    fieldsCount,
    zonesCount
  } = useWorkOrderParams(workOrder);

  const typeName =
    type === WorkOrderType.Recommendation
      ? _.get(activityTypes, [_.get(workOrder, 'growthActivityType'), 'name'])
      : _.get(WorkOrderPayloadType, [payloadType, 'name']);

  const handleDetailsClick = useCallback(() => {
    onDetailsClick(workOrder);
  }, [onDetailsClick, workOrder]);

  const handleEditClick = useCallback(() => {
    if (type === WorkOrderType.Recommendation) {
      onEditClick(workOrder);
    } else {
      dispatch(selectMapLayer(mapLayerIds.WorkOrders));
      navigate(`/map?workOrderId=${_.get(workOrder, 'id')}&edit=true`);
    }
  }, [dispatch, navigate, onEditClick, type, workOrder]);

  const handleAddNoteClick = useCallback(() => {
    onAddNoteClick(workOrder);
  }, [onAddNoteClick, workOrder]);

  const handleDeleteClick = useCallback(() => {
    setConfirmDelete(true);
  }, []);

  const handleCancelDelete = useCallback(() => {
    setConfirmDelete(false);
  }, []);

  const handleConfirmDelete = useCallback(() => {
    setConfirmDelete(false);
    dispatch(
      deleteFarmWorkOrderActions.submit({
        growerId: selectedGrowerId,
        seasonId: selectedSeasonId,
        farmId: selectedFarmId,
        workOrderId: _.get(workOrder, 'id')
      })
    );
  }, [dispatch, selectedFarmId, selectedGrowerId, selectedSeasonId, workOrder]);

  return (
    <Card elevation={3} className={styles.card}>
      <CardContent>
        <Box width="20rem">
          <Box marginBottom="0.5rem">
            <Typography className={styles.title}>
              <b>{name}</b>
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            marginBottom="0.5rem"
          >
            {!confirmDelete && (
              <>
                <Tooltip title="Work Order Details" placement="top">
                  <IconButton
                    onClick={handleDetailsClick}
                    size="small"
                    color="primary"
                  >
                    <AssignmentIcon />
                  </IconButton>
                </Tooltip>
                {isActiveSeason && !isUserView && (
                  <>
                    <Tooltip title="Add Note" placement="top">
                      <IconButton
                        onClick={handleAddNoteClick}
                        size="small"
                        color="primary"
                      >
                        <NoteAddIcon />
                      </IconButton>
                    </Tooltip>
                    {!(
                      type === WorkOrderType.Recommendation &&
                      status === WorkOrderStatus.Complete.key
                    ) && (
                      <Tooltip title="Edit Work Order" placement="top">
                        <IconButton
                          onClick={handleEditClick}
                          size="small"
                          color="primary"
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Delete Work Order" placement="top">
                      <IconButton
                        onClick={handleDeleteClick}
                        size="small"
                        color="primary"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                <GoToWorkOrderMapButton workOrder={workOrder} />
              </>
            )}
            {!!confirmDelete && (
              <ConfirmIconButtons
                size="small"
                onCancel={handleCancelDelete}
                onConfirm={handleConfirmDelete}
              />
            )}
          </Box>
          <Row
            title="Status"
            value={_.get(WorkOrderStatus, [status, 'name'])}
          />
          {!!typeName && <Row title="Type" value={typeName} />}
          {!!dueDate && <Row title="Due Date" value={dueDate} />}
          {!_.isEmpty(description) && (
            <Row title="Description" value={description} />
          )}
          {!!dateCreated && <Row title="Creation Date" value={dateCreated} />}
          {!!dateCompleted && (
            <Row title="Completion Date" value={dateCompleted} />
          )}
          <Typography className={styles.fieldsTitle}>
            <b>{fieldsCount}</b>
            {` ${fieldsCount === 1 ? 'Field' : 'Fields'} / `}
            <b>{zonesCount}</b>
            {` ${zonesCount === 1 ? 'Zone' : 'Zones'}`}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

const FarmWorkOrders = ({ workOrders }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const query = useQuery();
  const navigate = useNavigate();

  const [workOrderToEdit, setWorkOrderToEdit] = useState();
  const [workOrderToShowDetails, setWorkOrderToShowDetails] = useState();
  const [workOrderToAddNote, setWorkOrderToAddNote] = useState();

  const { inProgress, errorMessage, success } =
    useDeleteFarmWorkOrderSelectors();

  const queryWorkOrderId = useMemo(() => {
    return query.get('workOrderId');
  }, [query]);

  useEffect(() => {
    if (queryWorkOrderId) {
      const workOrder = _.find(
        workOrders,
        (item) => _.get(item, 'id') === queryWorkOrderId
      );
      if (workOrder) {
        setWorkOrderToEdit(workOrder);
        setWorkOrderToShowDetails(undefined);
        setWorkOrderToAddNote(undefined);
      }
      navigate('/dashboard/work-orders');
    }
  }, [dispatch, navigate, queryWorkOrderId, workOrders]);

  useEffect(() => {
    if (success) {
      toaster('Work Order Deleted', toastSuccess);
      dispatch(deleteFarmWorkOrderActions.clear());
      dispatch(forceUpdateFarmStructureAction());
    }
  }, [dispatch, success]);

  useEffect(() => {
    if (errorMessage) {
      toaster(`Error Deleting Work Order: ${errorMessage}`, toastError);
      dispatch(deleteFarmWorkOrderActions.clear());
    }
  }, [dispatch, errorMessage]);

  const handleEditClick = useCallback((workOrder) => {
    setWorkOrderToEdit(workOrder);
    setWorkOrderToShowDetails(undefined);
    setWorkOrderToAddNote(undefined);
  }, []);

  const handleDetailsClick = useCallback((workOrder) => {
    setWorkOrderToShowDetails(workOrder);
    setWorkOrderToEdit(undefined);
    setWorkOrderToAddNote(undefined);
  }, []);

  const handleAddNoteClick = useCallback((workOrder) => {
    setWorkOrderToShowDetails(undefined);
    setWorkOrderToEdit(undefined);
    setWorkOrderToAddNote(workOrder);
  }, []);

  const handleDetailsClose = useCallback(() => {
    setWorkOrderToShowDetails(undefined);
  }, []);

  const handleEditClose = useCallback(() => {
    setWorkOrderToEdit(undefined);
  }, []);

  const handleAddNoteClose = useCallback(() => {
    setWorkOrderToAddNote(undefined);
  }, []);

  return (
    <>
      {inProgress && <LoadingIndicator />}
      {!inProgress && (
        <PerfectScrollbar className={styles.container}>
          {_.map(workOrders, (workOrder) => (
            <WorkOrder
              key={_.get(workOrder, 'id')}
              workOrder={workOrder}
              onEditClick={handleEditClick}
              onDetailsClick={handleDetailsClick}
              onAddNoteClick={handleAddNoteClick}
            />
          ))}
        </PerfectScrollbar>
      )}
      <FarmWorkOrderDetailsDialog
        workOrder={workOrderToShowDetails}
        onClose={handleDetailsClose}
      />
      <EditFarmWorkOrderDialog
        workOrder={workOrderToEdit}
        onClose={handleEditClose}
      />
      <AddWorkOrderNoteDialog
        workOrder={workOrderToAddNote}
        onClose={handleAddNoteClose}
      />
    </>
  );
};

export default FarmWorkOrders;
