import { useContext, useMemo } from 'react';

import _ from 'lodash';

import { PagesConfigContext } from '../../_context/PagesConfigContext';

export const useSubPage = () => {
  const { currentSubPage } = useContext(PagesConfigContext);

  const subPageKey = _.get(currentSubPage, 'key');

  return useMemo(
    () => ({ subPage: currentSubPage, subPageKey }),
    [currentSubPage, subPageKey]
  );
};
