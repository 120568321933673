import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import _ from 'lodash';
import MapGL, { Layer, Marker, Source } from 'react-map-gl';

import { aquariusGrowerDevicesTypes } from '../../../_constants/growerDevicesConstants';
import { BuildFlyViewPort } from '../../../_utilities/mappingUtils';
import { layerStyle, outlineLayerStyle } from '../../../_utilities/mapStyles';
import DeviceIcon from '../../../Components/Devices/DeviceIcon/DeviceIcon';
import { mapboxToken } from '../../../config';

import EditSensorForm from './EditSensorForm';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%'
  },
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: '#00000080',
    color: '#D5D5D5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const initialCoordinates = [-94.0, 33.0];

const buildFeature = (field) => {
  return {
    type: 'Feature',
    properties: {
      selected: false
    },
    geometry: _.get(field, 'shape')
  };
};

const EditSensorMap = ({
  growerId,
  seasonId,
  farmId,
  fieldId,
  field,
  fields,
  deviceToEdit,
  onCancel
}) => {
  const styles = useStyles();

  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  const [vendor, setVendor] = useState(null);
  const [sensorType, setSensorType] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const sensorCenterFeature = useMemo(() => {
    if (
      !_.isNumber(latitude) ||
      !_.isNumber(longitude) ||
      _.isNaN(latitude) ||
      _.isNaN(longitude) ||
      latitude > 90 ||
      latitude < -90 ||
      longitude > 180 ||
      longitude < -180
    ) {
      return null;
    }

    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [longitude, latitude]
      }
    };
  }, [latitude, longitude]);

  const fieldFeature = useMemo(() => {
    if (field) {
      return buildFeature(field);
    }
  }, [field]);

  const fieldsFeatures = useMemo(() => {
    if (fields) {
      return _.map(fields, (field) => buildFeature(field));
    }
  }, [fields]);

  const fieldsFeatureCollection = useMemo(() => {
    const features = !!fieldFeature
      ? [fieldFeature]
      : !!fieldsFeatures
      ? fieldsFeatures
      : [];
    return {
      type: 'FeatureCollection',
      features: features
    };
  }, [fieldFeature, fieldsFeatures]);

  const [viewport, setViewport] = useState({
    latitude: initialCoordinates[1],
    longitude: initialCoordinates[0],
    zoom: 4.0
  });

  const sensorInField = useMemo(() => {
    if (!sensorType && sensorType !== 0) {
      return true;
    }
    if (
      sensorType === aquariusGrowerDevicesTypes.FlowMeter.deviceType ||
      sensorType === aquariusGrowerDevicesTypes.TelemetryHub.deviceType ||
      sensorType === aquariusGrowerDevicesTypes.PumpController.deviceType
    ) {
      return true;
    }
    if (!sensorCenterFeature || !fieldFeature) {
      return false;
    }
    return booleanPointInPolygon(sensorCenterFeature, fieldFeature);
  }, [fieldFeature, sensorCenterFeature, sensorType]);

  const center = useMemo(() => {
    if (field) {
      return _.get(field, 'center');
    }
    if (fields) {
      const latitudes = _.map(fields, (field) =>
        _.get(field, 'center.coordinates.1')
      );
      const longitudes = _.map(fields, (field) =>
        _.get(field, 'center.coordinates.0')
      );
      const minLat = _.min(latitudes);
      const minLon = _.min(longitudes);
      const maxLat = _.max(latitudes);
      const maxLon = _.max(longitudes);
      const avgLat = (minLat + maxLat) / 2;
      const avgLon = (minLon + maxLon) / 2;
      return {
        coordinates: [avgLon, avgLat]
      };
    }
  }, [field, fields]);

  useEffect(() => {
    if (deviceToEdit) {
      const lon = _.get(deviceToEdit, 'location.coordinates.0');
      const lat = _.get(deviceToEdit, 'location.coordinates.1');
      setLatitude(lat);
      setLongitude(lon);
    } else if (center) {
      const lon = _.get(center, 'coordinates.0');
      const lat = _.get(center, 'coordinates.1');
      setLatitude(lat);
      setLongitude(lon);
    }
  }, [center, deviceToEdit, field]);

  const updateViewport = useCallback((coordinates) => {
    const { width, height } = mapContainerRef.current.getBoundingClientRect();
    const normalizedCoordinates = _.isEmpty(coordinates)
      ? [initialCoordinates, initialCoordinates]
      : coordinates.length === 1
      ? [coordinates[0], coordinates[0]]
      : coordinates;

    const flyViewport = BuildFlyViewPort(normalizedCoordinates, width, height);
    setViewport(flyViewport);
  }, []);

  const calculateViewport = useCallback(
    (collection) => {
      const bboxCoordinates = _.chain(collection)
        .filter((item) => !_.isEmpty(item.boundingBox))
        .flatMap((item) => {
          const bbox = item.boundingBox;
          return [
            [bbox[0], bbox[1]],
            [bbox[2], bbox[3]]
          ];
        })
        .value();
      if (!_.isEmpty(bboxCoordinates)) {
        updateViewport(bboxCoordinates);
      }
    },
    [updateViewport]
  );

  useEffect(() => {
    if (!mapLoaded) {
      return;
    }
    if (field) {
      calculateViewport([field]);
    } else if (fields) {
      calculateViewport(fields);
    }
  }, [calculateViewport, mapLoaded, field, fields]);

  const handleViewportChange = useCallback((viewport) => {
    setViewport(viewport);
  }, []);

  const handleMapLoaded = useCallback(() => {
    setMapLoaded(true);
  }, []);

  const handleDeviceDragEnd = useCallback((event) => {
    const lat = event.lngLat[1];
    const lon = event.lngLat[0];
    setLatitude(lat);
    setLongitude(lon);
  }, []);

  const handleVendorChange = useCallback((vendor) => {
    setVendor(vendor);
  }, []);

  const handleSensorTypeChange = useCallback((type) => {
    setSensorType(type);
  }, []);

  const handleLatitudeChange = useCallback((lat) => {
    setLatitude(lat);
  }, []);

  const handleLongitudeChange = useCallback((lon) => {
    setLongitude(lon);
  }, []);

  return (
    <Box className={styles.container} ref={mapContainerRef}>
      <MapGL
        {...viewport}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/mapbox/satellite-streets-v11"
        onViewportChange={handleViewportChange}
        onLoad={handleMapLoaded}
        mapboxApiAccessToken={mapboxToken}
        ref={mapRef}
      >
        <Source type="geojson" data={fieldsFeatureCollection}>
          <Layer {...layerStyle} />
          <Layer {...outlineLayerStyle} />
        </Source>
        {sensorCenterFeature && (
          <Marker
            latitude={_.get(sensorCenterFeature, 'geometry.coordinates.1')}
            longitude={_.get(sensorCenterFeature, 'geometry.coordinates.0')}
            draggable={true}
            onDragEnd={handleDeviceDragEnd}
          >
            <DeviceIcon device={{ deviceType: sensorType, vendor }} size={50} />
          </Marker>
        )}
      </MapGL>
      <EditSensorForm
        growerId={growerId}
        seasonId={seasonId}
        farmId={farmId}
        fieldId={fieldId}
        deviceToEdit={deviceToEdit}
        sensorType={sensorType}
        onSensorTypeChange={handleSensorTypeChange}
        onVendorChange={handleVendorChange}
        latitude={latitude}
        longitude={longitude}
        sensorInField={sensorInField}
        onLatitudeChange={handleLatitudeChange}
        onLongitudeChange={handleLongitudeChange}
        onCancel={onCancel}
      />
    </Box>
  );
};

export default EditSensorMap;
