import React, { useCallback, useEffect } from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';

const SelectFormField = ({ field, path, ...props }) => {
  const { register, unregister, errors, watch, setValue } = useFormContext();

  const label = _.get(field, 'label');
  const required = _.get(field, 'validation.required', false);

  const values = _.get(field, 'values');

  const value = watch(path);

  useEffect(() => {
    register(
      { name: path },
      {
        required: required ? `${label} is required` : undefined
      }
    );
    return () => unregister(path);
  }, [label, path, register, required, unregister]);

  const handleChange = useCallback(
    (e) => {
      setValue(path, e.target.value, { shouldValidate: true });
    },
    [path, setValue]
  );

  return (
    <TextField
      {...props}
      value={value || ''}
      onChange={handleChange}
      required={required}
      select
      id={path}
      label={label}
      name={path}
      error={!!_.get(errors, path)}
      helperText={_.get(errors, `${path}.message`)}
    >
      {!required && <MenuItem value={null}>clear selection</MenuItem>}
      {_.map(values, (item) => (
        <MenuItem key={_.get(item, 'id')} value={_.get(item, 'id')}>
          {_.get(item, 'label')}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SelectFormField;
