import createTankMixProductSlice from './createTankMixProductSlice';
import createTankMixSlice from './createTankMixSlice';
import deleteTankMixProductSlice from './deleteTankMixProductSlice';
import deleteTankMixSlice from './deleteTankMixSlice';
import getTankMixesConfigSlice from './getTankMixesConfigSlice';
import updateTankMixProductSlice from './updateTankMixProductSlice';
import updateTankMixSlice from './updateTankMixSlice';

const tankMixesSlices = [
  getTankMixesConfigSlice,
  createTankMixProductSlice,
  updateTankMixProductSlice,
  deleteTankMixProductSlice,
  createTankMixSlice,
  updateTankMixSlice,
  deleteTankMixSlice
];

export default tankMixesSlices;
