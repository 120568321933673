import React from 'react';

import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';

const NumberFormField = ({ field, path, ...props }) => {
  const { register, errors } = useFormContext();

  const label = _.get(field, 'label');
  const required = _.get(field, 'validation.required', false);
  const min = _.get(field, 'validation.min');
  const max = _.get(field, 'validation.max');

  return (
    <TextField
      {...props}
      inputRef={register({
        required: required ? `${label} is required` : undefined,
        valueAsNumber: true,
        min:
          !!min || min === 0
            ? {
                value: min,
                message: `${label} must be greater than ${min}`
              }
            : undefined,
        max:
          !!max || max === 0
            ? {
                value: max,
                message: `${label} must be less than ${max}`
              }
            : undefined
      })}
      type="number"
      required={required}
      id={path}
      label={label}
      name={path}
      error={!!_.get(errors, path)}
      helperText={_.get(errors, `${path}.message`)}
    />
  );
};

export default NumberFormField;
