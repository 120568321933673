import { useMemo } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';

import { WorkOrderStatus } from '../../../_constants/workOrderConstants';
import { createDataSlice } from '../utils/createDataSlice';

const workOrdersFiltersSlice = createDataSlice({
  reducerPath: ['ui', 'workOrdersFilters'],
  initialState: {
    selectedStatuses: {
      [WorkOrderStatus.New.key]: true,
      [WorkOrderStatus.InProgress.key]: true,
      [WorkOrderStatus.Complete.key]: false,
      [WorkOrderStatus.Canceled.key]: false
    }
  },
  reducers: {
    toggleStatusFilter(state, action) {
      const key = action.payload;
      _.set(
        state,
        ['selectedStatuses', key],
        !_.get(state, ['selectedStatuses', key])
      );
    }
  }
});

const { actions, baseSelector: workOrdersFiltersSelector } =
  workOrdersFiltersSlice;

export const workOrdersFiltersActions = actions;

export const useWorkOrdersFiltersSelectors = () => {
  const { selectedStatuses } = useSelector(workOrdersFiltersSelector);

  return useMemo(
    () => ({
      selectedStatuses
    }),
    [selectedStatuses]
  );
};

export default workOrdersFiltersSlice;
