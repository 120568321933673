import React, { useCallback } from 'react';

import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MapIcon from '@material-ui/icons/Map';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { mapLayerIds } from '../../_constants/mapLayersConstants';
import { selectMapLayer } from '../../_store/actions/uiActions';

const GoToZonedActivityButton = ({ activity }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(selectMapLayer(mapLayerIds.ZonedActivities));
    navigate(`/map?activityId=${_.get(activity, 'id')}`);
  }, [activity, dispatch, navigate]);

  return (
    <Tooltip title="Show on Map">
      <IconButton onClick={handleClick} size="small">
        <MapIcon />
      </IconButton>
    </Tooltip>
  );
};

export default GoToZonedActivityButton;
