import React, { useCallback } from 'react';

import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  marker: (props) => ({
    backgroundColor: props.backgroundColor,
    padding: '0.5rem',
    maxWidth: '11rem',
    borderRadius: '1rem'
  }),
  label: (props) => ({
    fontSize: '0.875rem',
    fontWeight: '500',
    color: props.textColor,
    textAlign: 'center'
  })
}));

const MapBadge = ({ backgroundColor, textColor, text, onClick }) => {
  const styles = useStyles({ backgroundColor, textColor });

  const handleClick = useCallback(() => {
    onClick();
  }, [onClick]);

  return (
    <Paper elevation={3} className={styles.marker} onClick={handleClick}>
      <Typography className={styles.label}>{text}</Typography>
    </Paper>
  );
};

export default MapBadge;
