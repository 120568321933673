import React, { useContext, useMemo } from 'react';

import _ from 'lodash';

import { ZonedActivityTypes } from '../../_constants/activitiesConstants';
import { FarmStructureContext } from '../../_context/FarmStructureContext';
import { useGrowthStagesConfig } from '../../_store/selectors/configSelectors';
import { useAllGrowerFieldsSelector } from '../../_store/selectors/fieldsSelectors';
import { useCropDashboardSelectors } from '../../_store/slices/ui/cropDashboardSlice';
import CsvDownloadButton from '../CsvDownloadButton';

import { ActivityTypes } from './CompletedActivitiesTimeline';

const CompletedActivitiesCsvDownload = ({ allActivities }) => {
  const { selectedGrower, selectedFieldId } = useContext(FarmStructureContext);

  const { showAllForGrower } = useCropDashboardSelectors();

  const { fields } = useAllGrowerFieldsSelector();

  const { growthStagesConfig } = useGrowthStagesConfig();

  const filename = `Completed Activities - ${_.get(selectedGrower, 'name')}`;

  const fieldsToDownload = useMemo(
    () =>
      _.filter(
        fields,
        (field) => !selectedFieldId || _.get(field, 'id') === selectedFieldId
      ),
    [fields, selectedFieldId]
  );

  const header = useMemo(
    () => [
      'Date',
      ..._.chain(fieldsToDownload)
        .map((field) =>
          showAllForGrower
            ? `${_.get(field, 'farmName')} - ${_.get(field, 'name')}`
            : _.get(field, 'name')
        )
        .value()
    ],
    [fieldsToDownload, showAllForGrower]
  );

  const allActivitiesByDate = useMemo(
    () => _.groupBy(allActivities, 'activityDate'),
    [allActivities]
  );

  const csvData = useMemo(
    () =>
      _.chain(allActivitiesByDate)
        .flatMap((activities, date) => {
          const activitiesByField = _.map(fieldsToDownload, (field) => {
            const fieldId = _.get(field, 'id');
            return _.filter(
              activities,
              (activity) =>
                _.has(_.get(activity, 'targetsByFieldId'), fieldId) ||
                _.get(activity, 'fieldId') === fieldId
            );
          });

          const maxLength = _.chain(activitiesByField)
            .map((item) => _.get(item, 'length'))
            .max()
            .value();

          return _.chain(_.range(maxLength))
            .map((rowIdx) => {
              const fieldsData = _.map(activitiesByField, (activities) => {
                const activity = _.get(activities, [rowIdx]);
                const type = _.get(activity, 'activityType');
                if (type === ActivityTypes.CompletedRecommendation) {
                  return `Completed ${_.get(activity, 'description')}`;
                } else if (type === ActivityTypes.ZonedActivity) {
                  const zonedActivityType = _.get(activity, 'type');
                  if (zonedActivityType === ZonedActivityTypes.GrowthStage) {
                    return _.get(growthStagesConfig, [
                      _.get(activity, 'stage'),
                      'name'
                    ]);
                  } else if (
                    zonedActivityType === ZonedActivityTypes.CustomNote
                  ) {
                    return _.get(activity, 'name');
                  }
                }
                return undefined;
              });
              return [date, ...fieldsData];
            })
            .value();
        })
        .value(),
    [allActivitiesByDate, fieldsToDownload, growthStagesConfig]
  );

  return (
    <>
      <CsvDownloadButton
        filename={filename}
        header={header}
        dataArray={csvData}
      />
    </>
  );
};

export default CompletedActivitiesCsvDownload;
