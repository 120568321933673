import { useMemo } from 'react';

import { bundlesConfigService } from '../../../../services/bundlesConfig.service';
import { createApiSlice } from '../../utils/createApiSlice';

const updateBundleSlice = createApiSlice({
  reducerPath: ['newConfig', 'bundles', 'updateBundle'],
  apiFunction: bundlesConfigService.updateBundleV2,
  apiArgumentsFn: ({ bundleId, bundle }) => [bundleId, bundle]
});

const { actions, useApiSelectors } = updateBundleSlice;

export const updateBundleActions = actions;

export const useUpdateBundleSelectors = () => {
  const { inProgress, success, errorMessage, response, updatedAt } =
    useApiSelectors();

  return useMemo(
    () => ({ inProgress, success, errorMessage, response, updatedAt }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default updateBundleSlice;
