import React, { Fragment, useCallback, useContext, useMemo } from 'react';

import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import CloseIcon from '@material-ui/icons/Close';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import _ from 'lodash';

import { FarmStructureContext } from '../../../_context/FarmStructureContext';
import { useGrowersDrawerState } from '../../../_hooks/ui/useGrowersDrawerState';
import { usePathname } from '../../../_hooks/usePathname';
import { useReportsGrowersWithSelection } from '../../../Pages/Reports/Components/_hooks/useReportsGrowersWithSelection';

const useStyles = makeStyles(() => ({
  container: {
    borderLeft: '1px solid #ffffff50',
    paddingLeft: '0.75rem',
    paddingRight: '0.75rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    height: '100%',
    maxWidth: '16rem',
    flexGrow: 1,
    flexShrink: 1,
    minWidth: 0
  },
  titlesContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minWidth: 0
  },
  subtitlesContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  icon: {
    marginRight: '0.75rem'
  },
  title: {
    fontWeight: '500',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  subtitle: {
    fontSize: '0.75rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  divider: {
    marginRight: '0.25rem',
    marginLeft: '0.25rem',
    fontSize: '0.5rem'
  }
}));

const ReportsContent = () => {
  const styles = useStyles();

  const { growers } = useReportsGrowersWithSelection();

  const growersCount = _.get(growers, 'length', 0);
  const checkedGrowersCount = useMemo(
    () =>
      _.chain(growers)
        .filter(
          (grower) => _.get(grower, 'checked') || _.get(grower, 'indeterminate')
        )
        .get('length', 0)
        .value(),
    [growers]
  );

  const allFarms = useMemo(
    () => _.flatMap(growers, (grower) => _.get(grower, 'farms')),
    [growers]
  );
  const farmsCount = _.get(allFarms, 'length', 0);
  const checkedFarmsCount = useMemo(
    () =>
      _.chain(allFarms)
        .filter(
          (farm) => _.get(farm, 'checked') || _.get(farm, 'indeterminate')
        )
        .get('length', 0)
        .value(),
    [allFarms]
  );

  const fieldsCount = useMemo(() => _.sumBy(growers, 'fieldsCount'), [growers]);
  const checkedFieldsCount = useMemo(
    () => _.sumBy(growers, 'checkedFieldsCount'),
    [growers]
  );

  const subtitles = useMemo(
    () => [
      `${checkedFarmsCount}/${farmsCount} Farms`,
      `${checkedGrowersCount}/${growersCount} Growers`
    ],
    [checkedFarmsCount, checkedGrowersCount, farmsCount, growersCount]
  );

  return (
    <Box className={styles.titlesContainer}>
      <Typography
        className={styles.title}
      >{`${checkedFieldsCount}/${fieldsCount} Fields`}</Typography>
      <Box className={styles.subtitlesContainer}>
        {_.map(subtitles, (subtitle, idx) => (
          <Fragment key={subtitle}>
            <Typography className={styles.subtitle}>{subtitle}</Typography>
            {idx !== subtitles.length - 1 && (
              <FiberManualRecordIcon className={styles.divider} />
            )}
          </Fragment>
        ))}
      </Box>
    </Box>
  );
};

const GffContent = () => {
  const styles = useStyles();
  const { selectedGrower, selectedFarm, selectedField } =
    useContext(FarmStructureContext);

  const growerName = _.get(selectedGrower, 'name');
  const farmName = _.get(selectedFarm, 'name');
  const fieldName = _.get(selectedField, 'name');

  const titles = useMemo(
    () => _.filter([fieldName, farmName, growerName], (name) => !!name),
    [farmName, fieldName, growerName]
  );

  const subtitles = useMemo(() => _.tail(titles), [titles]);

  const title = useMemo(() => _.first(titles) || 'Select Grower', [titles]);
  return (
    <Box className={styles.titlesContainer}>
      <Typography className={styles.title}>{title}</Typography>
      <Box className={styles.subtitlesContainer}>
        {_.map(subtitles, (subtitle, idx) => (
          <Fragment key={subtitle}>
            <Typography className={styles.subtitle}>{subtitle}</Typography>
            {idx !== subtitles.length - 1 && (
              <FiberManualRecordIcon className={styles.divider} />
            )}
          </Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default function GrowerFarmFieldSelector() {
  const styles = useStyles();
  const { pathname } = usePathname();

  const { growersDrawerOpen, toggleGrowersDrawer } = useGrowersDrawerState();

  const isReportsPath = pathname.startsWith('/reports');

  const handleClick = useCallback(() => {
    toggleGrowersDrawer();
  }, [toggleGrowersDrawer]);

  return (
    <Box className={styles.container} onClick={handleClick}>
      {!growersDrawerOpen && <AccountTreeIcon className={styles.icon} />}
      {growersDrawerOpen && <CloseIcon className={styles.icon} />}
      {!isReportsPath && <GffContent />}
      {isReportsPath && <ReportsContent />}
    </Box>
  );
}
