import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react';

import { IconButton, Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import LeakAdd from '@material-ui/icons/LeakAdd';
import RemoveIcon from '@material-ui/icons/Remove';
import cx from 'classnames';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import {
  aquariusFieldDevicesTypes,
  aquariusGrowerDevicesTypes
} from '../../../_constants/growerDevicesConstants';
import { FarmStructureContext } from '../../../_context/FarmStructureContext';
import { addFieldToSeasonActions } from '../../../_store/slices/gff/seasons/addFieldToSeasonSlice';
import { removeFieldFromSeasonActions } from '../../../_store/slices/gff/seasons/removeFieldFromSeasonSlice';
import ConfirmIconButtons from '../../../Components/ConfirmIconButtons';
import DrawerThumbnail from '../../../Layout/GrowersDrawer/DrawerThumbnail';

import SeasonGrowthForm from './SeasonGrowthForm';

const useStyles = makeStyles(() => ({
  fieldLine: {
    margin: '1rem',
    padding: '0.5rem',
    borderBottom: '#D5D5D5 1px solid',
    display: 'flex',
    flexDirection: 'row'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '1rem',
    width: '100%'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    minHeight: '3rem'
  },
  growthParams: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  fieldDetails: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  devicesCount: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: '2rem'
  },
  deviceIcon: {
    marginRight: '0.5rem'
  },
  deviceCountText: { fontWeight: 'bold' },
  title: {
    fontWeight: 500
  },
  inactiveTitle: {
    fontWeight: 400,
    color: '#747474'
  }
}));

const FieldRow = ({
  field,
  season,
  hideButtons,
  fieldToAdd,
  fieldToRemove,
  onAddClick,
  onRemoveClick,
  onAddConfirm,
  onAddCancel,
  onRemoveConfirm,
  onRemoveCancel
}) => {
  const styles = useStyles();

  const { isHistoricalSeason } = useContext(FarmStructureContext);

  const formRef = useRef();

  const active = _.get(field, 'active');
  const fieldId = _.get(field, 'id');
  const fieldToAddId = _.get(fieldToAdd, 'id');
  const fieldToRemoveId = _.get(fieldToRemove, 'id');

  const shapes = useMemo(() => [_.get(field, 'shape')], [field]);

  const devicesCountByType = useMemo(
    () =>
      _.chain(field)
        .get('devices')
        .groupBy('deviceType')
        .mapValues((d) => _.get(d, 'length', 0))
        .value(),
    [field]
  );

  const handleAddClick = useCallback(() => {
    onAddClick(field);
  }, [field, onAddClick]);

  const handleRemoveClick = useCallback(() => {
    onRemoveClick(field);
  }, [field, onRemoveClick]);

  const handleAddConfirm = useCallback(() => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true })
      );
    }
  }, []);

  const handleFormSubmit = useCallback(
    (growth) => {
      onAddConfirm(growth);
    },
    [onAddConfirm]
  );

  return (
    <Box className={styles.fieldLine} key={_.get(field, 'id')}>
      <DrawerThumbnail
        geometries={shapes}
        width={100}
        height={100}
        fillColor={active ? 'rgba(46,64,87,0.7)' : '#D1D1D170'}
        strokeColor={active ? '#1F2737' : '#909090'}
        hoverFillColor={active ? 'rgba(46,64,87,0.7)' : '#D1D1D170'}
        hoverStrokeColor={active ? '#1F2737' : '#909090'}
      />
      <Box className={styles.content}>
        <Box className={styles.header}>
          <Typography
            className={cx(styles.title, {
              [styles.inactiveTitle]: !active
            })}
          >{`${_.get(field, 'farmName')} - ${_.get(
            field,
            'name'
          )}`}</Typography>
          {!isHistoricalSeason && (
            <Box>
              {!active && !hideButtons && (
                <Tooltip title="Add to Season" placement="top">
                  <Button onClick={handleAddClick} startIcon={<AddIcon />}>
                    Add
                  </Button>
                </Tooltip>
              )}
              {active && !hideButtons && (
                <>
                  <Tooltip title="Configure Devices" placement="top">
                    <IconButton
                      component={RouterLink}
                      to={`seasonsConfig/fields/${fieldId}/devices`}
                    >
                      <LeakAdd />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit Field" placement="top">
                    <Button
                      component={RouterLink}
                      to={`/seasonsConfig/fields/${fieldId}/edit`}
                      startIcon={<EditIcon />}
                    >
                      Edit
                    </Button>
                  </Tooltip>
                  <Tooltip title="Remove from Season" placement="top">
                    <Button
                      onClick={handleRemoveClick}
                      startIcon={<RemoveIcon />}
                    >
                      Remove
                    </Button>
                  </Tooltip>
                </>
              )}
              {hideButtons && fieldId === fieldToAddId && (
                <ConfirmIconButtons
                  onCancel={onAddCancel}
                  onConfirm={handleAddConfirm}
                />
              )}
              {hideButtons && fieldId === fieldToRemoveId && (
                <ConfirmIconButtons
                  onCancel={onRemoveCancel}
                  onConfirm={onRemoveConfirm}
                />
              )}
            </Box>
          )}
        </Box>
        {hideButtons && fieldId === fieldToAddId && (
          <Box className={styles.growthParams}>
            <SeasonGrowthForm
              ref={formRef}
              season={season}
              onFormSubmit={handleFormSubmit}
            />
          </Box>
        )}
        {!(hideButtons && fieldId === fieldToAddId) && (
          <Box className={styles.fieldDetails}>
            {_.map(
              _.filter(aquariusGrowerDevicesTypes, (d) =>
                _.has(aquariusFieldDevicesTypes, _.get(d, 'deviceType'))
              ),
              (deviceType) => (
                <Box
                  className={styles.devicesCount}
                  key={_.get(deviceType, 'deviceType')}
                >
                  <Tooltip title={_.get(deviceType, 'name')} placement="top">
                    <Box className={styles.deviceIcon}>
                      {_.get(deviceType, 'icon')}
                    </Box>
                  </Tooltip>
                  <Typography className={styles.deviceCountText}>
                    {_.get(
                      devicesCountByType,
                      [_.get(deviceType, 'deviceType')],
                      0
                    )}
                  </Typography>
                </Box>
              )
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

const SeasonFieldsList = ({ season, farms }) => {
  const dispatch = useDispatch();

  const { selectedGrowerId } = useContext(FarmStructureContext);

  const [fieldToAdd, setFieldToAdd] = useState();
  const [fieldToRemove, setFieldToRemove] = useState();

  const seasonId = _.get(season, 'id');

  const activeFields = useMemo(
    () =>
      _.chain(season)
        .get('farms')
        .values()
        .flatMap((farm) =>
          _.chain(farm)
            .get('fields')
            .values()
            .map((field) => ({
              ...field,
              farmName: _.get(farm, 'name'),
              farmId: _.get(farm, 'id'),
              active: true
            }))
            .value()
        )
        .keyBy('id')
        .value(),
    [season]
  );

  const inactiveFields = useMemo(
    () =>
      _.chain(farms)
        .flatMap((farm) =>
          _.chain(farm)
            .get('fields')
            .map((field) => ({
              ...field,
              farmName: _.get(farm, 'name'),
              farmId: _.get(farm, 'id'),
              active: false
            }))
            .value()
        )
        .filter((field) => !_.get(activeFields, [_.get(field, 'id')]))
        .value(),
    [activeFields, farms]
  );

  const hideButtons = !!fieldToAdd || !!fieldToRemove;

  const handleAddClick = useCallback((field) => {
    setFieldToAdd(field);
  }, []);

  const handleRemoveClick = useCallback((field) => {
    setFieldToRemove(field);
  }, []);

  const handleAddConfirm = useCallback(
    (growth) => {
      dispatch(
        addFieldToSeasonActions.request(
          selectedGrowerId,
          seasonId,
          _.get(fieldToAdd, 'farmId'),
          _.get(fieldToAdd, 'id'),
          growth
        )
      );
      setFieldToAdd(null);
    },
    [dispatch, fieldToAdd, seasonId, selectedGrowerId]
  );

  const handleAddCancel = useCallback(() => {
    setFieldToAdd(null);
  }, []);

  const handleRemoveConfirm = useCallback(() => {
    dispatch(
      removeFieldFromSeasonActions.request(
        selectedGrowerId,
        seasonId,
        _.get(fieldToRemove, 'farmId'),
        _.get(fieldToRemove, 'id')
      )
    );
    setFieldToRemove(null);
  }, [dispatch, fieldToRemove, seasonId, selectedGrowerId]);

  const handleRemoveCancel = useCallback(() => {
    setFieldToRemove(null);
  }, []);

  return (
    <>
      <Typography>
        <b>Fields added to the Season:</b>
      </Typography>
      {_.map(_.values(activeFields), (field) => (
        <FieldRow
          field={field}
          season={season}
          key={_.get(field, 'id')}
          hideButtons={hideButtons}
          fieldToAdd={fieldToAdd}
          fieldToRemove={fieldToRemove}
          onAddClick={handleAddClick}
          onRemoveClick={handleRemoveClick}
          onAddConfirm={handleAddConfirm}
          onAddCancel={handleAddCancel}
          onRemoveConfirm={handleRemoveConfirm}
          onRemoveCancel={handleRemoveCancel}
        />
      ))}
      <Typography>
        <b>Fields not added to the Season:</b>
      </Typography>
      {_.map(inactiveFields, (field) => (
        <FieldRow
          field={field}
          season={season}
          key={_.get(field, 'id')}
          hideButtons={hideButtons}
          fieldToAdd={fieldToAdd}
          fieldToRemove={fieldToRemove}
          onAddClick={handleAddClick}
          onRemoveClick={handleRemoveClick}
          onAddConfirm={handleAddConfirm}
          onAddCancel={handleAddCancel}
          onRemoveConfirm={handleRemoveConfirm}
          onRemoveCancel={handleRemoveCancel}
        />
      ))}
    </>
  );
};

export default SeasonFieldsList;
