import React, { useCallback, useState } from 'react';

import { IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Alert from '@material-ui/lab/Alert';
import _ from 'lodash';

import { useGetTankMixesConfigSelectors } from '../../../../_store/slices/config/tankMixes/getTankMixesConfigSlice';
import LoadingIndicator from '../../../../Components/LoadingIndicator';
import {
  ContentBody,
  ContentContainer,
  ContentHeader,
  FormContainer
} from '../../../../Layout/Content';

import EditTankMixProductForm from './EditTankMixProductForm';
import TankMixProductsList from './TankMixProductsList';

const TankMixesProductsContent = ({ onBack }) => {
  const [addProductMode, setAddProductMode] = useState(false);
  const [productToEdit, setProductToEdit] = useState();

  const editProductMode = !!productToEdit;

  const {
    inProgress,
    errorMessage,
    response: tankMixes
  } = useGetTankMixesConfigSelectors();

  const handleAddProductClick = useCallback(() => {
    setAddProductMode(true);
  }, []);

  const handleEditProductClick = useCallback((product) => {
    setProductToEdit(product);
  }, []);

  const handleEditCancel = useCallback(() => {
    setProductToEdit(undefined);
    setAddProductMode(false);
  }, []);

  return (
    <>
      {(inProgress || (!addProductMode && !editProductMode)) && (
        <ContentContainer>
          <ContentHeader>
            <span>
              <IconButton
                style={{ marginRight: '0.5rem' }}
                onClick={onBack}
                size="small"
              >
                <ArrowBackIcon />
              </IconButton>
              <span>Tank Mixes - Products</span>
            </span>
            {!inProgress && (
              <Button
                size="small"
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleAddProductClick}
              >
                Add Product
              </Button>
            )}
          </ContentHeader>
          <ContentBody>
            {inProgress && <LoadingIndicator />}
            {!inProgress && errorMessage && (
              <Alert severity="error">{errorMessage}</Alert>
            )}
            {!inProgress && !errorMessage && (
              <TankMixProductsList
                products={_.get(tankMixes, 'products')}
                onEditClick={handleEditProductClick}
              />
            )}
          </ContentBody>
        </ContentContainer>
      )}
      {!inProgress && (addProductMode || editProductMode) && (
        <FormContainer>
          <ContentHeader>
            {addProductMode && <span>Tank Mixes - Add Product</span>}
            {editProductMode && (
              <span>
                Tank Mixes - Edit {_.get(productToEdit, 'name')} Product
              </span>
            )}
          </ContentHeader>
          <ContentBody>
            <EditTankMixProductForm
              product={productToEdit}
              onCancel={handleEditCancel}
            />
          </ContentBody>
        </FormContainer>
      )}
    </>
  );
};

export default TankMixesProductsContent;
