import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { FarmStructureContext } from '../../_context/FarmStructureContext';
import { useSubPage } from '../../_hooks/Pages/useSubpage';
import { updateGffConfig } from '../../_store/actions/gffConfigActions';
import { useConfigFarmsSelector } from '../../_store/selectors/configFarmsSelectors';
import { useConfigGrowerSelectors } from '../../_store/selectors/growersSelectors';
import { useSeasonSelector } from '../../_store/selectors/seasonsSelectors';
import {
  addFieldToSeasonActions,
  useAddFieldToSeasonSelectors
} from '../../_store/slices/gff/seasons/addFieldToSeasonSlice';
import {
  createGrowerSeasonActions,
  useCreateGrowerSeasonSelectors
} from '../../_store/slices/gff/seasons/createGrowerSeasonSlice';
import { useGetSeasonsSelectors } from '../../_store/slices/gff/seasons/getSeasonsSlice';
import { useGlobalSeasonSelectors } from '../../_store/slices/gff/seasons/globalSeasonSlice';
import {
  removeFieldFromSeasonActions,
  useRemoveFieldFromSeasonSelectors
} from '../../_store/slices/gff/seasons/removeFieldFromSeasonSlice';
import { toaster, toastError } from '../../_utilities/toast-utils';
import ConfirmIconButtons from '../../Components/ConfirmIconButtons';
import LoadingIndicator from '../../Components/LoadingIndicator';
import AppPage from '../../Layout/AppPage';
import CommonPage from '../../Layout/CommonPage';
import {
  ContentBody,
  ContentContainer,
  ContentHeader
} from '../../Layout/Content';
import PageTitle from '../../Layout/PageTitle';

import EditSensorMap from './Components/EditSensorMap';
import SeasonDevicesList from './Components/SeasonDevicesList';
import SeasonFieldsList from './Components/SeasonFieldsList';
import { SeasonsConfigSubpageKey } from './useSeasonsConfigPageConfig';

export const SeasonConfigTabIds = {
  FIELDS: 'fields',
  DEVICES: 'devices'
};

const useStyles = makeStyles(() => ({
  notConfiguredBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  notConfiguredText: {
    marginBottom: '1rem'
  }
}));

const SeasonsConfigPage = () => {
  const styles = useStyles();

  const dispatch = useDispatch();

  const [addMode, setAddMode] = useState(false);
  const [editDeviceId, setEditDeviceId] = useState(null);
  const [confirmCreateSeason, setConfirmCreateSeason] = useState(false);

  const { subPageKey } = useSubPage();

  const {
    selectedSeason: selectedSeasonYear,
    isHistorical: isSelectedSeasonHistorical
  } = useGlobalSeasonSelectors();

  const { selectedGrowerId, selectedSeasonId } =
    useContext(FarmStructureContext);

  const { season } = useSeasonSelector(selectedGrowerId, selectedSeasonId);

  const { grower, inProgress: growerInProgress } =
    useConfigGrowerSelectors(selectedGrowerId);

  const { farms, isLoading: farmsInProgress } =
    useConfigFarmsSelector(selectedGrowerId);

  const { inProgress: seasonsInProgress } =
    useGetSeasonsSelectors(selectedGrowerId);

  const {
    inProgress: addInProgress,
    errorMessage: addErrorMessage,
    response: addResponse
  } = useAddFieldToSeasonSelectors();

  const {
    inProgress: removeInProgress,
    errorMessage: removeErrorMessage,
    response: removeResponse
  } = useRemoveFieldFromSeasonSelectors();

  const {
    inProgress: createSeasonInProgress,
    errorMessage: createSeasonErrorMessage,
    response: createSeasonResponse
  } = useCreateGrowerSeasonSelectors();

  const inProgress =
    growerInProgress ||
    seasonsInProgress ||
    farmsInProgress ||
    addInProgress ||
    removeInProgress ||
    createSeasonInProgress;

  const growerName = _.get(grower, 'name');
  const title = `${growerName} - Season ${selectedSeasonYear} - ${
    subPageKey === SeasonsConfigSubpageKey.Fields ? 'Fields' : 'Devices'
  }`;
  const fields = useMemo(
    () =>
      _.chain(season)
        .get('farms')
        .flatMap((farm) => _.chain(farm).get('fields').values().value())
        .value(),
    [season]
  );

  const deviceToEdit = useMemo(
    () =>
      _.chain(season)
        .get('devices')
        .find((device) => _.get(device, 'id') === editDeviceId)
        .value(),
    [season, editDeviceId]
  );

  const showEditMap = (addMode || deviceToEdit) && !isSelectedSeasonHistorical;

  useEffect(() => {
    if (!selectedSeasonId || !selectedGrowerId || isSelectedSeasonHistorical) {
      setAddMode(false);
      setEditDeviceId(undefined);
    }
  }, [isSelectedSeasonHistorical, selectedGrowerId, selectedSeasonId]);

  useEffect(() => {
    dispatch(updateGffConfig());
    if (selectedGrowerId) {
      dispatch(updateGffConfig(selectedGrowerId));
    }
  }, [dispatch, selectedGrowerId]);

  useEffect(() => {
    if (addResponse) {
      dispatch(addFieldToSeasonActions.clear());
    }
  }, [addResponse, dispatch]);

  useEffect(() => {
    if (addErrorMessage) {
      toaster(addErrorMessage, toastError);
      dispatch(addFieldToSeasonActions.clear());
    }
  }, [addErrorMessage, dispatch]);

  useEffect(() => {
    if (removeResponse) {
      dispatch(removeFieldFromSeasonActions.clear());
    }
  }, [removeResponse, dispatch]);

  useEffect(() => {
    if (removeErrorMessage) {
      toaster(removeErrorMessage, toastError);
      dispatch(removeFieldFromSeasonActions.clear());
    }
  }, [removeErrorMessage, dispatch]);

  useEffect(() => {
    if (createSeasonResponse) {
      dispatch(createGrowerSeasonActions.clear());
    }
  }, [createSeasonResponse, dispatch]);

  useEffect(() => {
    if (createSeasonErrorMessage) {
      toaster(createSeasonErrorMessage, toastError);
      dispatch(createGrowerSeasonActions.clear());
    }
  }, [createSeasonErrorMessage, dispatch]);

  const handleCreateSeasonClick = useCallback(() => {
    setConfirmCreateSeason(true);
  }, []);

  const handleCreateSeasonCancel = useCallback(() => {
    setConfirmCreateSeason(false);
  }, []);

  const handleCreateSeasonConfirm = useCallback(() => {
    dispatch(
      createGrowerSeasonActions.request(selectedGrowerId, selectedSeasonYear)
    );
    setConfirmCreateSeason(false);
  }, [dispatch, selectedGrowerId, selectedSeasonYear]);

  const handleAddClick = useCallback(() => {
    setAddMode(true);
  }, []);

  const handleDeviceCancel = useCallback(() => {
    setAddMode(false);
    setEditDeviceId(undefined);
  }, []);

  const handleSelectDeviceToEdit = useCallback((id) => {
    setEditDeviceId(id);
  }, []);

  return (
    <>
      {!showEditMap && (
        <AppPage header={<PageTitle />}>
          {selectedGrowerId && (
            <ContentContainer>
              <ContentHeader>
                <span>{title}</span>
                {!inProgress &&
                  subPageKey === SeasonsConfigSubpageKey.Devices &&
                  !isSelectedSeasonHistorical && (
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={handleAddClick}
                    >
                      Add Device
                    </Button>
                  )}
              </ContentHeader>
              <ContentBody>
                {inProgress && <LoadingIndicator />}
                {!inProgress &&
                  season &&
                  subPageKey === SeasonsConfigSubpageKey.Fields && (
                    <SeasonFieldsList season={season} farms={farms} />
                  )}
                {!inProgress &&
                  season &&
                  subPageKey === SeasonsConfigSubpageKey.Devices && (
                    <SeasonDevicesList
                      growerId={selectedGrowerId}
                      seasonId={selectedSeasonId}
                      season={season}
                      onSelectDeviceToEdit={handleSelectDeviceToEdit}
                    />
                  )}
                {!inProgress && !season && !isSelectedSeasonHistorical && (
                  <Box className={styles.notConfiguredBox}>
                    <Typography className={styles.notConfiguredText}>
                      Season <b>{selectedSeasonYear}</b> is not configured for
                      Grower <b>{growerName}</b>.
                    </Typography>
                    {!confirmCreateSeason && (
                      <Button onClick={handleCreateSeasonClick} color="primary">
                        Create Season
                      </Button>
                    )}
                    {confirmCreateSeason && (
                      <Box>
                        <ConfirmIconButtons
                          onCancel={handleCreateSeasonCancel}
                          onConfirm={handleCreateSeasonConfirm}
                        />
                      </Box>
                    )}
                  </Box>
                )}
                {!inProgress && !season && isSelectedSeasonHistorical && (
                  <Box className={styles.notConfiguredBox}>
                    <Typography className={styles.notConfiguredText}>
                      Season <b>{selectedSeasonYear}</b> has not been configured
                      for Grower <b>{growerName}</b>.
                    </Typography>
                  </Box>
                )}
              </ContentBody>
            </ContentContainer>
          )}
          {!selectedGrowerId && (
            <ContentContainer>
              <ContentBody>
                <Alert severity="info">Please select a grower</Alert>
              </ContentBody>
            </ContentContainer>
          )}
        </AppPage>
      )}
      {showEditMap && (
        <CommonPage>
          <EditSensorMap
            growerId={selectedGrowerId}
            seasonId={selectedSeasonId}
            fields={fields}
            onCancel={handleDeviceCancel}
            deviceToEdit={deviceToEdit}
          />
        </CommonPage>
      )}
    </>
  );
};

export default SeasonsConfigPage;
