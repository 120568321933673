import React, { useCallback, useContext, useEffect, useMemo } from 'react';

import MomentUtils from '@date-io/moment';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import _ from 'lodash';
import moment from 'moment/moment';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { FeedbackTargetType } from '../../../_constants/activitiesConstants';
import {
  WorkOrderStatus,
  WorkOrderType
} from '../../../_constants/workOrderConstants';
import { FarmStructureContext } from '../../../_context/FarmStructureContext';
import { forceUpdateActiveGrowthAction } from '../../../_store/actions/growthsActions';
import { getSeasonWithGrowthsActions } from '../../../_store/slices/gff/seasons/getSeasonsWithGrowthsSlice';
import {
  createFarmWorkOrderActions,
  useCreateFarmWorkOrderSelectors
} from '../../../_store/slices/growths/farmWorkOrders/createFarmWorkOrderSlice';
import {
  deleteFarmWorkOrderActions,
  useDeleteFarmWorkOrderSelectors
} from '../../../_store/slices/growths/farmWorkOrders/deleteFarmWorkOrderSlice';
import {
  updateFarmWorkOrderActions,
  useUpdateFarmWorkOrderSelectors
} from '../../../_store/slices/growths/farmWorkOrders/updateFarmWorkOrderSlice';
import CreateUpdateDeleteDialogActions from '../../CreateUpdateDeleteDialogActions';

import ActivityFeedbackDynamicForm from './ActivityFeedbackDynamicForm';

const ActivityFeedbackWorkOrderForm = ({
  activityConfig,
  workOrder,
  onClose,
  growerId,
  seasonId,
  farmId,
  fieldId
}) => {
  const dispatch = useDispatch();

  const isEdit = !!workOrder;

  const { selectedSeason } = useContext(FarmStructureContext);

  const {
    inProgress: createInProgress,
    errorMessage: createErrorMessage,
    success: createSuccess
  } = useCreateFarmWorkOrderSelectors();

  const {
    inProgress: updateInProgress,
    errorMessage: updateErrorMessage,
    success: updateSuccess
  } = useUpdateFarmWorkOrderSelectors();

  const {
    inProgress: deleteInProgress,
    errorMessage: deleteErrorMessage,
    success: deleteSuccess
  } = useDeleteFarmWorkOrderSelectors();

  const inProgress = createInProgress || updateInProgress || deleteInProgress;
  const errorMessage =
    createErrorMessage || updateErrorMessage || deleteErrorMessage;
  const success = createSuccess || updateSuccess || deleteSuccess;

  const formMethods = useForm({
    defaultValues: isEdit
      ? workOrder
      : { name: _.get(activityConfig, 'description') }
  });

  const { register, handleSubmit, errors, control, watch } = formMethods;

  const status = watch('status');

  const formConfig = _.get(activityConfig, 'formConfig');
  const formConfigId = _.get(formConfig, 'id');

  const seasonStartDate = _.get(selectedSeason, 'startDate');
  const seasonEndDate = _.get(selectedSeason, 'endDate');

  const minDate = useMemo(
    () => moment(seasonStartDate).endOf('day'),
    [seasonStartDate]
  );

  const maxDate = useMemo(
    () => moment(seasonEndDate).endOf('day'),
    [seasonEndDate]
  );

  useEffect(() => {
    if (success) {
      dispatch(createFarmWorkOrderActions.clear());
      dispatch(updateFarmWorkOrderActions.clear());
      dispatch(deleteFarmWorkOrderActions.clear());
      dispatch(
        getSeasonWithGrowthsActions.forceUpdate({
          growerId,
          seasonId
        })
      );
      dispatch(
        forceUpdateActiveGrowthAction(growerId, seasonId, farmId, fieldId)
      );
      onClose();
    }
  }, [dispatch, farmId, fieldId, growerId, onClose, seasonId, success]);

  useEffect(() => {
    return () => {
      if (success || errorMessage) {
        dispatch(createFarmWorkOrderActions.clear());
        dispatch(updateFarmWorkOrderActions.clear());
        dispatch(deleteFarmWorkOrderActions.clear());
      }
    };
  }, [dispatch, errorMessage, success]);

  const handleConfirmDelete = useCallback(() => {
    dispatch(
      deleteFarmWorkOrderActions.submit({
        growerId,
        seasonId,
        farmId,
        workOrderId: _.get(workOrder, 'id')
      })
    );
  }, [dispatch, farmId, growerId, seasonId, workOrder]);

  const handleFormSubmit = useCallback(
    (formData) => {
      const targetsByFieldId = {
        [fieldId]: { fieldId, type: FeedbackTargetType.Field }
      };

      const date = _.get(formData, 'date');

      const data = {
        ...formData,
        date: date ? date.toISOString() : undefined,
        targetsByFieldId,
        type: WorkOrderType.Recommendation,
        activityConfigId: _.get(activityConfig, 'id'),
        growthActivityType: _.get(activityConfig, 'type')
      };

      if (isEdit) {
        if (_.get(formData, 'status') === WorkOrderStatus.Complete.key) {
          data.formConfigId = formConfigId;
        }
        dispatch(
          updateFarmWorkOrderActions.submit({
            growerId,
            seasonId,
            farmId,
            workOrderId: _.get(workOrder, 'id'),
            data
          })
        );
      } else {
        if (_.get(formData, 'status') === WorkOrderStatus.Complete.key) {
          data.formConfigId = formConfigId;
        }
        dispatch(
          createFarmWorkOrderActions.submit({
            growerId,
            seasonId,
            farmId,
            data
          })
        );
      }
    },
    [
      activityConfig,
      dispatch,
      farmId,
      fieldId,
      formConfigId,
      growerId,
      isEdit,
      seasonId,
      workOrder
    ]
  );

  const handleClose = useCallback(() => {
    dispatch(createFarmWorkOrderActions.clear());
    dispatch(updateFarmWorkOrderActions.clear());
    onClose();
  }, [dispatch, onClose]);

  const hasFormFields = !_.chain(formConfig)
    .get('formFields')
    .isEmpty()
    .value();

  return (
    <form noValidate onSubmit={handleSubmit(handleFormSubmit)}>
      <Box padding="1rem">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="dueDate"
              id="dueDate"
              render={({ onChange, value }) => (
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    disableToolbar
                    variant="dialog"
                    format="L"
                    id="date"
                    name="date"
                    label="Due Date"
                    value={value ? moment(value) : null}
                    onChange={(date) =>
                      onChange(date ? date.toISOString() : null)
                    }
                    autoOk
                    clearable
                  />
                </MuiPickersUtilsProvider>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register()}
              variant="standard"
              multiline
              fullWidth
              id="name"
              label="Name"
              name="name"
              error={!!errors.name}
              helperText={_.get(errors, 'name.message')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register()}
              variant="standard"
              multiline
              fullWidth
              id="description"
              label="Description"
              name="description"
              error={!!errors.description}
              helperText={_.get(errors, 'description.message')}
            />
          </Grid>
          {!!isEdit && (
            <Grid item xs={12}>
              <Controller
                control={control}
                name="status"
                id="status"
                defaultValue={WorkOrderStatus.New.key}
                rules={{ required: true }}
                render={({ onChange, value }) => (
                  <FormControl fullWidth>
                    <InputLabel>Status</InputLabel>
                    <Select
                      fullWidth
                      variant="standard"
                      value={value || null}
                      onChange={(e) => onChange(e.target.value)}
                    >
                      {_.map(_.values(WorkOrderStatus), (statusType) => (
                        <MenuItem
                          value={_.get(statusType, 'key')}
                          key={_.get(statusType, 'key')}
                        >
                          {_.get(statusType, 'name')}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
          )}
          {!!isEdit && status === WorkOrderStatus.Complete.key && (
            <>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="dateCompleted"
                  id="dateCompleted"
                  rules={{ required: 'Completion Date is required' }}
                  defaultValue={moment().toISOString()}
                  render={({ onChange, value }) => (
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DatePicker
                        required
                        disableToolbar
                        variant="dialog"
                        format="L"
                        label="Completion Date"
                        value={value ? moment(value) : null}
                        onChange={(date) =>
                          onChange(date ? date.toISOString() : null)
                        }
                        autoOk
                        error={!!_.get(errors, 'dateCompleted')}
                        minDate={minDate}
                        maxDate={maxDate}
                      />
                    </MuiPickersUtilsProvider>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  inputRef={register()}
                  variant="standard"
                  multiline
                  fullWidth
                  id="completionDescription"
                  label="Completion Description"
                  name="completionDescription"
                  error={!!errors.completionDescription}
                  helperText={_.get(errors, 'completionDescription.message')}
                />
              </Grid>
              {hasFormFields && (
                <Grid
                  item
                  container
                  xs={12}
                  style={{
                    marginTop: '1rem',
                    marginLeft: '1rem',
                    paddingLeft: '1rem',
                    borderLeft: '#D5D5D5 1px solid'
                  }}
                >
                  <Grid item xs={12}>
                    {_.get(activityConfig, 'description')} Activity Parameters
                  </Grid>
                  <FormProvider {...formMethods}>
                    <ActivityFeedbackDynamicForm form={formConfig} />
                  </FormProvider>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormHelperText error>
                  Warning: Completing this Work Order will also mark the
                  associated Activity Recommendation as completed. This cannot
                  be undone.
                </FormHelperText>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            {errorMessage && (
              <FormHelperText error>{errorMessage}</FormHelperText>
            )}
          </Grid>
        </Grid>
        <CreateUpdateDeleteDialogActions
          isEdit={isEdit}
          inProgress={inProgress}
          onDelete={handleConfirmDelete}
          onCancel={handleClose}
        />
      </Box>
    </form>
  );
};

export default ActivityFeedbackWorkOrderForm;
