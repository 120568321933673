import React, { useCallback } from 'react';

import { ButtonGroup, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import BarChartIcon from '@material-ui/icons/BarChart';
import TableChartIcon from '@material-ui/icons/TableChart';

const TableChartToggle = ({ showChart, onToggle }) => {
  const handleChartToggle = useCallback(() => {
    onToggle();
  }, [onToggle]);

  return (
    <ButtonGroup>
      <Tooltip title="Show Harvest Table">
        <Button
          onClick={handleChartToggle}
          startIcon={<TableChartIcon />}
          color={!showChart ? 'primary' : 'default'}
          variant="contained"
          disableElevation
          size="small"
        >
          Table
        </Button>
      </Tooltip>
      <Tooltip title="Show Harvest Chart">
        <Button
          onClick={handleChartToggle}
          startIcon={<BarChartIcon />}
          color={showChart ? 'primary' : 'default'}
          variant="contained"
          disableElevation
          size="small"
        >
          Chart
        </Button>
      </Tooltip>
    </ButtonGroup>
  );
};

export default TableChartToggle;
