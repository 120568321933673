import { useMemo } from 'react';

import _ from 'lodash';

import { useBundlesConfig } from '../../_store/selectors/configSelectors';
import { useGrowerBundles } from '../../Pages/CropManagement/Components/_hooks/useGrowerBundles';

export const useBundle = (bundleId) => {
  const { bundles: globalBundles } = useBundlesConfig();

  const { bundles: growerBundles } = useGrowerBundles();

  const growerBundle = useMemo(
    () => _.find(growerBundles, (bundle) => _.get(bundle, 'id') === bundleId),
    [bundleId, growerBundles]
  );

  const globalBundle = useMemo(
    () => _.find(globalBundles, (bundle) => _.get(bundle, 'id') === bundleId),
    [bundleId, globalBundles]
  );

  return useMemo(
    () => ({
      bundle: growerBundle || globalBundle
    }),
    [globalBundle, growerBundle]
  );
};

export const useBundleOrDefault = (bundleId) => {
  const { bundle } = useBundle(bundleId);

  const { defaultBundleId: globalDefaultBundleId, bundles: globalBundles } =
    useBundlesConfig();

  const { defaultBundleId: growerDefaultBundleId, bundles: growerBundles } =
    useGrowerBundles();

  const growerDefaultBundle = useMemo(
    () =>
      _.find(
        growerBundles,
        (bundle) => _.get(bundle, 'id') === growerDefaultBundleId
      ),
    [growerBundles, growerDefaultBundleId]
  );

  const globalDefaultBundle = useMemo(
    () =>
      _.find(
        globalBundles,
        (bundle) => _.get(bundle, 'id') === globalDefaultBundleId
      ),
    [globalBundles, globalDefaultBundleId]
  );

  return useMemo(
    () => ({
      bundle: bundle || growerDefaultBundle || globalDefaultBundle
    }),
    [bundle, globalDefaultBundle, growerDefaultBundle]
  );
};
