import React, { useCallback } from 'react';

import { ButtonGroup } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

import ChartDownloadButton from '../../../Components/Charts/ChartDownloadButton';

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    '& > :not(:first-child)': {
      marginLeft: '0.5rem'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      '& > :not(:first-child)': {
        marginTop: '0.5rem',
        marginLeft: '0'
      }
    }
  }
}));

function ReportChartButtons({
  chartRef,
  chartFilename = 'Chart.png',
  chartDataType
}) {
  const styles = useStyles();

  const { showAcres, showNumber, setShowAcres, setShowNumber } = chartDataType;

  const handleByAcresClick = useCallback(() => {
    setShowAcres();
  }, [setShowAcres]);

  const handleByNumberClick = useCallback(() => {
    setShowNumber();
  }, [setShowNumber]);

  return (
    <Box className={styles.buttonsContainer}>
      <ChartDownloadButton
        chartRef={chartRef}
        fileName={chartFilename}
        size="small"
        color="primary"
        variant="contained"
        disableElevation
      />
      <ButtonGroup size="small" variant="contained" disableElevation>
        <Button
          onClick={handleByAcresClick}
          color={showAcres ? 'primary' : 'default'}
        >
          By Acres
        </Button>
        <Button
          onClick={handleByNumberClick}
          color={showNumber ? 'primary' : 'default'}
        >
          By Number
        </Button>
      </ButtonGroup>
    </Box>
  );
}

export default ReportChartButtons;
