import { useMemo } from 'react';

import _ from 'lodash';
import moment from 'moment/moment';

import { FeedbackTargetType } from '../../../../_constants/activitiesConstants';

export const useFeedbackParams = (feedback) => {
  const name = _.get(feedback, 'name');
  const type = _.get(feedback, 'type');
  const growthStage = _.get(feedback, 'stage');
  const description = _.get(feedback, 'description');

  const fieldsCount = useMemo(() => {
    return _.values(_.get(feedback, 'targetsByFieldId')).length;
  }, [feedback]);

  const zonesCount = useMemo(() => {
    return _.chain(feedback)
      .get('targetsByFieldId')
      .values()
      .filter((field) => _.get(field, 'type') === FeedbackTargetType.Zones)
      .flatMap((field) => _.get(field, 'zonesIds'))
      .get('length')
      .value();
  }, [feedback]);

  const date = useMemo(() => {
    const date = _.get(feedback, 'date');
    return !!date ? moment(date).format('lll') : null;
  }, [feedback]);

  const dateCreated = useMemo(() => {
    const dateCreated = _.get(feedback, 'dateCreated');
    return !!dateCreated ? moment(dateCreated).format('lll') : null;
  }, [feedback]);

  return useMemo(
    () => ({
      name,
      description,
      type,
      growthStage,
      fieldsCount,
      zonesCount,
      date,
      dateCreated
    }),
    [
      date,
      dateCreated,
      description,
      fieldsCount,
      growthStage,
      name,
      type,
      zonesCount
    ]
  );
};
