import { useMemo } from 'react';

import { farmFeedbackService } from '../../../../services/farmFeedbackService';
import { createApiSlice } from '../../utils/createApiSlice';

const deleteFarmFeedbackSlice = createApiSlice({
  reducerPath: ['newGrowths', 'farmFeedback', 'deleteFarmFeedback'],
  apiFunction: farmFeedbackService.deleteFarmFeedbackV2,
  apiArgumentsFn: ({ growerId, seasonId, farmId, feedbackId }) => [
    growerId,
    seasonId,
    farmId,
    feedbackId
  ]
});

const { actions, useApiSelectors } = deleteFarmFeedbackSlice;

export const deleteFarmFeedbackActions = actions;

export const useDeleteFarmFeedbackSelectors = () => {
  const { inProgress, errorMessage, success, updatedAt } = useApiSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      updatedAt
    }),
    [errorMessage, inProgress, success, updatedAt]
  );
};

export default deleteFarmFeedbackSlice;
