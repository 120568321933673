import { addToken, client } from './client';

export const fieldService = {
  getFieldsV2,
  createFieldV2,
  updateFieldV2,
  deleteFieldV2,
  reparentFieldV2
};

async function getFieldsV2(token, growerId, farmId) {
  const response = await client.get(
    `/v2/growers/${growerId}/farms/${farmId}/fields`,
    addToken(token)
  );
  return response.data;
}

async function createFieldV2(token, growerId, farmId, field) {
  const response = await client.post(
    `/v2/growers/${growerId}/farms/${farmId}/fields`,
    field,
    addToken(token)
  );
  return response.data;
}

async function updateFieldV2(token, growerId, farmId, fieldId, field) {
  const response = await client.put(
    `/v2/growers/${growerId}/farms/${farmId}/fields/${fieldId}`,
    field,
    addToken(token)
  );
  return response.data;
}

async function deleteFieldV2(token, growerId, farmId, fieldId) {
  const response = await client.delete(
    `/v2/growers/${growerId}/farms/${farmId}/fields/${fieldId}`,
    addToken(token)
  );
  return response.data;
}

async function reparentFieldV2(token, growerId, farmId, fieldId, newFarmId) {
  const response = await client.post(
    `/v2/growers/${growerId}/farms/${farmId}/fields/${fieldId}/reparent`,
    { farmId: newFarmId },
    addToken(token)
  );
  return response.data;
}
