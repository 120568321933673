import addActivitySlice from './addActivitySlice';
import createProtocolSlice from './createProtocolSlice';
import deleteActivitySlice from './deleteActivitySlice';
import deleteProtocolSlice from './deleteProtocolSlice';
import updateActivitySlice from './updateActivitySlice';
import updateProtocolSlice from './updateProtocolSlice';

const protocolsSlices = [
  createProtocolSlice,
  updateProtocolSlice,
  deleteProtocolSlice,
  addActivitySlice,
  updateActivitySlice,
  deleteActivitySlice
];

export default protocolsSlices;
