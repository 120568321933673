import { useMemo } from 'react';

import { growthService } from '../../../../services';
import { createApiSlice } from '../../utils/createApiSlice';

const deleteActivityFeedbackSlice = createApiSlice({
  reducerPath: ['newGrowths', 'activities', 'deleteActivityFeedback'],
  apiFunction: growthService.deleteActivityFeedbackV2
});

const { actions, useApiSelectors } = deleteActivityFeedbackSlice;

export const deleteActivityFeedbackActions = actions;

export const useDeleteActivityFeedbackSelectors = () => {
  const { inProgress, errorMessage, success, updatedAt } = useApiSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      updatedAt
    }),
    [errorMessage, inProgress, success, updatedAt]
  );
};

export default deleteActivityFeedbackSlice;
