import React from 'react';

import _ from 'lodash';

import { FormFieldTypes } from '../formConstants';

import CheckboxField from './CheckboxField';
import DateFormField from './DateFormField';
import DateTimeFormField from './DateTimeFormField';
import NumberFormField from './NumberFormField';
import SelectFormField from './SelectFormField';
import TextFormField from './TextFormField';

const FormField = ({ field, prefix = null, ...props }) => {
  const type = _.get(field, 'type');
  const id = _.get(field, 'id');
  const path = !_.isEmpty(prefix) ? `${prefix.trim()}.${id}` : id;

  switch (type) {
    case FormFieldTypes.Text:
      return <TextFormField {...props} field={field} path={path} />;
    case FormFieldTypes.Number:
      return <NumberFormField {...props} field={field} path={path} />;
    case FormFieldTypes.Checkbox:
      return <CheckboxField field={field} path={path} />;
    case FormFieldTypes.Select:
      return <SelectFormField {...props} field={field} path={path} />;
    case FormFieldTypes.Date:
      return <DateFormField {...props} field={field} path={path} />;
    case FormFieldTypes.Datetime:
      return <DateTimeFormField {...props} field={field} path={path} />;
    default:
      return <></>;
  }
};

export default FormField;
