import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import _ from 'lodash';
import { Link as RouterLink } from 'react-router-dom';

import {
  BreadcrumbsText,
  ContentBody,
  ContentBreadcrumbs,
  FormContainer,
  ResponsiveContentHeader
} from '../../../Layout/Content';
import { CropManagementTabsIds } from '../CropManagementPage';

import GrowerBundlesList from './GrowerBundlesList';

const GrowerBundlesTab = ({ selectedGrower }) => {
  return (
    <FormContainer>
      <ResponsiveContentHeader>
        <ContentBreadcrumbs>
          <BreadcrumbsText>{_.get(selectedGrower, 'name')}</BreadcrumbsText>
          <BreadcrumbsText>Protocol Bundles</BreadcrumbsText>
        </ContentBreadcrumbs>
        <Box display="flex" flexDirection="row">
          {selectedGrower && (
            <Box style={{ marginRight: '0.5rem' }}>
              <Button
                size="small"
                variant="contained"
                color="primary"
                startIcon={<FileCopyIcon />}
                component={RouterLink}
                to={`/crop-management/${CropManagementTabsIds['grower-bundles']}/clone-global`}
              >
                Clone Global Bundle
              </Button>
            </Box>
          )}
          {selectedGrower && (
            <Button
              size="small"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              component={RouterLink}
              to={`/crop-management/${CropManagementTabsIds['grower-bundles']}/new`}
            >
              Add Bundle
            </Button>
          )}
        </Box>
      </ResponsiveContentHeader>
      <ContentBody>
        <GrowerBundlesList />
      </ContentBody>
    </FormContainer>
  );
};

export default GrowerBundlesTab;
