import { useMemo } from 'react';

import { protocolsConfigService } from '../../../../services/protocolsConfig.service';
import { createApiSlice } from '../../utils/createApiSlice';

const updateProtocolSlice = createApiSlice({
  reducerPath: ['newConfig', 'protocols', 'updateProtocol'],
  apiFunction: protocolsConfigService.updateProtocolV2,
  apiArgumentsFn: ({ bundleId, protocolId, protocol }) => [
    bundleId,
    protocolId,
    protocol
  ]
});

const { actions, useApiSelectors } = updateProtocolSlice;

export const updateProtocolActions = actions;

export const useUpdateProtocolSelectors = () => {
  const { inProgress, success, errorMessage, response, updatedAt } =
    useApiSelectors();

  return useMemo(
    () => ({ inProgress, success, errorMessage, response, updatedAt }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default updateProtocolSlice;
