import { useMemo } from 'react';

import { activitiesSandboxService } from '../../../../services/activitiesSandbox.service';
import { createApiSlice } from '../../utils/createApiSlice';

const generateActivitiesSlice = createApiSlice({
  reducerPath: ['activities', 'sandbox', 'generateActivities'],
  apiFunction: activitiesSandboxService.generateActivities
});

const { actions, useApiSelectors } = generateActivitiesSlice;

export const generateActivitiesActions = actions;

export const generateActivitiesSelectors = useApiSelectors;

export const useGenerateActivitiesSelectors = () => {
  const { inProgress, errorMessage, success, response } =
    generateActivitiesSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      response
    }),
    [errorMessage, inProgress, response, success]
  );
};

export default generateActivitiesSlice;
