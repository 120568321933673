import { useCallback, useEffect, useMemo, useState } from 'react';

import { IconButton, TableCell } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import {
  deleteTankMixActions,
  useDeleteTankMixSelectors
} from '../../../../_store/slices/config/tankMixes/deleteTankMixSlice';
import {
  getTankMixesConfigActions,
  useGetTankMixesConfigSelectors
} from '../../../../_store/slices/config/tankMixes/getTankMixesConfigSlice';
import { toaster, toastSuccess } from '../../../../_utilities/toast-utils';
import ConfirmIconButtons from '../../../../Components/ConfirmIconButtons';
import LoadingIndicator from '../../../../Components/LoadingIndicator';
import { TankMixUnit } from '../../../TaknMixDemo/Components/TankMixUnit';

const TankMixesList = ({ tankMixes, onEditClick }) => {
  const dispatch = useDispatch();

  const [tankMixToDeleteId, setTankMixToDeleteId] = useState();

  const { response: tankMixesConfig } = useGetTankMixesConfigSelectors();

  const carriers = _.get(tankMixesConfig, 'carriers');
  const carriersById = useMemo(() => _.keyBy(carriers, 'id'), [carriers]);

  const {
    inProgress,
    success: deleteSuccess,
    errorMessage: deleteErrorMessage
  } = useDeleteTankMixSelectors();

  useEffect(() => {
    if (deleteSuccess) {
      toaster('Tank Mix deleted successfully', toastSuccess);
      dispatch(deleteTankMixActions.clear());
      dispatch(getTankMixesConfigActions.submit({}));
    }
  }, [deleteSuccess, dispatch]);

  useEffect(() => {
    if (deleteErrorMessage) {
      toaster(`Error deleting Tank Mix: ${deleteErrorMessage}`, toastSuccess);
      dispatch(deleteTankMixActions.clear());
    }
  }, [deleteErrorMessage, dispatch]);

  const handleDeleteButtonClick = useCallback((tankMixId) => {
    setTankMixToDeleteId(tankMixId);
  }, []);

  const handleDeleteCancel = useCallback(() => {
    setTankMixToDeleteId(undefined);
  }, []);

  const handleEditTankMixClick = useCallback(
    (tankMix) => {
      onEditClick(tankMix);
    },
    [onEditClick]
  );

  const handleDeleteConfirm = useCallback(() => {
    setTankMixToDeleteId(undefined);
    dispatch(deleteTankMixActions.submit({ tankMixId: tankMixToDeleteId }));
  }, [dispatch, tankMixToDeleteId]);

  return (
    <>
      {inProgress && <LoadingIndicator />}
      {!inProgress && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="right">#</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Carrier</TableCell>
              <TableCell>Products</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(tankMixes, (tankMix, idx) => (
              <TableRow key={_.get(tankMix, 'id')}>
                <TableCell align="right">{idx + 1}</TableCell>
                <TableCell>{_.get(tankMix, 'name')}</TableCell>
                <TableCell>{_.get(tankMix, 'description')}</TableCell>
                <TableCell>
                  {_.get(carriersById, [_.get(tankMix, 'carrierId'), 'name'])}
                </TableCell>
                <TableCell>
                  {_.chain(tankMix)
                    .get('constituents')
                    .map(
                      (it) =>
                        `${_.get(it, 'name')} (${_.get(
                          it,
                          'sprayingRate'
                        )} ${_.get(TankMixUnit, [
                          _.get(it, 'unit'),
                          'namePerAc'
                        ])})`
                    )
                    .join(', ')
                    .value()}
                </TableCell>
                <TableCell align="right" width="100rem">
                  {tankMixToDeleteId !== _.get(tankMix, 'id') && (
                    <>
                      <IconButton
                        size="small"
                        onClick={() => handleEditTankMixClick(tankMix)}
                        disabled={!!tankMixToDeleteId}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() =>
                          handleDeleteButtonClick(_.get(tankMix, 'id'))
                        }
                        disabled={!!tankMixToDeleteId}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                  {tankMixToDeleteId === _.get(tankMix, 'id') && (
                    <ConfirmIconButtons
                      size="small"
                      onCancel={handleDeleteCancel}
                      onConfirm={handleDeleteConfirm}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default TankMixesList;
