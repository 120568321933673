import React, { useCallback, useEffect } from 'react';

import MomentUtils from '@date-io/moment';
import FormHelperText from '@material-ui/core/FormHelperText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles(() => ({
  formField: {
    width: '100%'
  }
}));

const DateFormField = ({ field, path, variant }) => {
  const styles = useStyles();

  const { register, unregister, errors, watch, setValue } = useFormContext();

  const label = _.get(field, 'label');
  const required = _.get(field, 'validation.required', false);

  const values = _.get(field, 'values');

  const value = watch(path) || null;

  useEffect(() => {
    register(
      { name: path },
      {
        required: required ? `${label} is required` : undefined
      }
    );
    return () => unregister(path);
  }, [path, label, register, required, setValue, unregister, values]);

  const handleChange = useCallback(
    (val) => {
      if (val) {
        setValue(path, val.format('yyyy-MM-DD'), { shouldValidate: true });
      } else {
        setValue(path, null);
      }
    },
    [path, setValue]
  );

  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          className={styles.formField}
          disableToolbar
          variant="dialog"
          inputVariant={variant}
          required={required}
          format="L"
          id={path}
          label={label}
          name={path}
          value={value}
          onChange={handleChange}
          autoOk
          clearable={!required}
          error={!!_.get(errors, path)}
        />
        {!!_.get(errors, path) && (
          <FormHelperText error>
            {_.get(errors, `${path}.message`)}
          </FormHelperText>
        )}
      </MuiPickersUtilsProvider>
    </>
  );
};

export default DateFormField;
