import React from 'react';

import { useSubPage } from '../../_hooks/Pages/useSubpage';
import AppPage from '../../Layout/AppPage';
import PageTitle from '../../Layout/PageTitle';

import GrowthStagesConfigContent from './Components/GrowthStagesConfigContent';
import ProtocolBundlesConfigContent from './Components/ProtocolBundlesConfigContent';
import TankMixesConfigContent from './Components/TankMixes/TankMixesConfigContent';
import { ApplicationConfigurationSubpageKey } from './useApplicationConfigurationPageConfig';

export const ApplicationConfigurationTabsIds = {
  'growth-stages': 'growth-stages',
  'protocol-bundles': 'protocol-bundles',
  'tank-mixes': 'tank-mixes'
};

const ApplicationConfigurationPage = () => {
  const { subPageKey } = useSubPage();

  return (
    <AppPage header={<PageTitle />}>
      {subPageKey === ApplicationConfigurationSubpageKey.GrowthStages && (
        <GrowthStagesConfigContent />
      )}
      {subPageKey === ApplicationConfigurationSubpageKey.ProtocolBundles && (
        <ProtocolBundlesConfigContent />
      )}
      {subPageKey === ApplicationConfigurationSubpageKey.TankMixes && (
        <TankMixesConfigContent />
      )}
    </AppPage>
  );
};

export default ApplicationConfigurationPage;
