import React from 'react';

import { TableFooter } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import cx from 'classnames';
import _ from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { usePagination } from '../../_hooks/usePagination';

import CommonTablePagination from './CommonTablePagination';
import { UtilityButtons } from './UtilityButtons';

const useDataTableStyles = makeStyles(() => ({
  buttonsCell: {
    whiteSpace: 'nowrap',
    textAlign: 'right'
  },
  numberCell: {
    textAlign: 'right'
  }
}));

export function DataTable({
  headers,
  data,
  onSelectItemToDelete,
  enablePagination = false,
  paginationKey
}) {
  const styles = useDataTableStyles();

  const paginationControls = usePagination({ items: data, key: paginationKey });
  const { pagedItems } = paginationControls;

  const tableData = enablePagination ? pagedItems : data;

  return (
    <TableContainer>
      <PerfectScrollbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              {_.map(headers, (header, idx) => (
                <TableCell key={idx}>{header}</TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(tableData, (dataRow, idx) => (
              <TableRow key={dataRow.id}>
                <TableCell>
                  {enablePagination ? _.get(dataRow, 'index') + 1 : idx + 1}
                </TableCell>
                {_.map(dataRow.values, (value, cellIdx) => (
                  <TableCell
                    key={cellIdx}
                    className={cx({
                      [styles.numberCell]: typeof value === 'number'
                    })}
                  >
                    {typeof value !== 'boolean' && value}
                    {typeof value === 'boolean' && <Checkbox checked={value} />}
                  </TableCell>
                ))}
                <TableCell className={styles.buttonsCell}>
                  <UtilityButtons
                    dataRow={dataRow}
                    onSelectItemToDelete={onSelectItemToDelete}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {enablePagination && (
            <TableFooter>
              <TableRow>
                <CommonTablePagination
                  paginationControls={paginationControls}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </PerfectScrollbar>
    </TableContainer>
  );
}
