import React, { useContext, useMemo } from 'react';

import { TableCell } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import _ from 'lodash';

import { FarmStructureContext } from '../../_context/FarmStructureContext';
import { useFieldWithGrowthsSelectors } from '../../_store/selectors/farmsSelectors';
import { useLayerCategory } from '../../Pages/Map/Components/Details/_hooks/useLayerCategory';

const FeedbackTarget = ({ target, light }) => {
  const {
    selectedGrowerId: growerId,
    selectedSeasonId: seasonId,
    selectedFarmId: farmId
  } = useContext(FarmStructureContext);

  const fieldId = _.get(target, 'fieldId');

  const { field } = useFieldWithGrowthsSelectors(
    growerId,
    seasonId,
    farmId,
    fieldId
  );

  return <ActivityTarget field={field} target={target} light={light} />;
};

const ActivityTarget = ({ field, target, light }) => {
  const layerId = _.get(target, 'layerId');

  const layer = useMemo(
    () =>
      _.chain(field)
        .get('managementZones.layers')
        .find((l) => _.get(l, 'id') === layerId)
        .value(),
    [field, layerId]
  );

  const category = useLayerCategory(layer);

  const zones = useMemo(() => {
    const zonesInTarget = _.chain(target)
      .get('zonesIds')
      .keyBy((z) => z)
      .value();
    return _.chain(layer)
      .get('zones')
      .filter((zone) => _.has(zonesInTarget, _.get(zone, 'id')))
      .value();
  }, [layer, target]);

  return (
    <>
      {!layer && (
        <TableRow>
          <TableCell style={light ? { color: '#D1D1D1' } : {}}>
            {_.get(field, 'name')}
          </TableCell>
          <TableCell />
          <TableCell />
        </TableRow>
      )}
      {!!layer &&
        _.map(zones, (zone) => (
          <TableRow>
            <TableCell style={light ? { color: '#D1D1D1' } : {}}>
              {_.get(field, 'name')}
            </TableCell>
            <TableCell style={light ? { color: '#D1D1D1' } : {}}>
              {_.get(layer, 'name')}
              {!!category && ` (${_.get(category, 'name')})`}
            </TableCell>
            <TableCell style={light ? { color: '#D1D1D1' } : {}}>
              {_.get(zone, 'name')}
            </TableCell>
          </TableRow>
        ))}
    </>
  );
};

export default FeedbackTarget;
