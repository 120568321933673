import { addToken, client } from './client';

export const fieldGeospatialDataService = {
  addGeospatialDataV2,
  getGeospatialDataV2,
  deleteGeospatialDataV2
};

async function addGeospatialDataV2(
  token,
  growerId,
  seasonId,
  farmId,
  fieldId,
  data
) {
  const response = await client.post(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/fields/${fieldId}/geospatial`,
    data,
    addToken(token)
  );
  return response.data;
}

async function getGeospatialDataV2(
  token,
  growerId,
  seasonId,
  farmId,
  fieldId,
  dataId
) {
  const response = await client.get(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/fields/${fieldId}/geospatial/${dataId}`,
    addToken(token)
  );
  return response.data;
}

async function deleteGeospatialDataV2(
  token,
  growerId,
  seasonId,
  farmId,
  fieldId,
  dataId
) {
  const response = await client.delete(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/fields/${fieldId}/geospatial/${dataId}`,
    addToken(token)
  );
  return response.data;
}
