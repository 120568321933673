import React, { useCallback, useContext, useEffect } from 'react';

import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { FarmStructureContext } from '../../_context/FarmStructureContext';
import { getSeasonWithGrowthsActions } from '../../_store/slices/gff/seasons/getSeasonsWithGrowthsSlice';
import {
  addFarmWorkOrderNoteActions,
  useAddFarmWorkOrderNoteSelectors
} from '../../_store/slices/growths/farmWorkOrders/addFarmWorkOrderNoteSlice';
import CreateUpdateDeleteDialogActions from '../CreateUpdateDeleteDialogActions';

const AddWorkOrderNoteForm = ({ workOrder, onClose }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();

  const { inProgress, errorMessage, success } =
    useAddFarmWorkOrderNoteSelectors();

  const {
    selectedGrowerId: growerId,
    selectedSeasonId: seasonId,
    selectedFarmId: farmId
  } = useContext(FarmStructureContext);

  useEffect(() => {
    if (success) {
      dispatch(addFarmWorkOrderNoteActions.clear());
      dispatch(
        getSeasonWithGrowthsActions.forceUpdate({
          growerId,
          seasonId
        })
      );
      onClose();
    }
  }, [dispatch, growerId, onClose, seasonId, success]);

  useEffect(() => {
    return () => {
      if (success || errorMessage) {
        dispatch(addFarmWorkOrderNoteActions.clear());
      }
    };
  }, [dispatch, errorMessage, success]);

  const handleFormSubmit = useCallback(
    (formData) => {
      dispatch(
        addFarmWorkOrderNoteActions.submit({
          growerId,
          seasonId,
          farmId,
          workOrderId: _.get(workOrder, 'id'),
          data: formData
        })
      );
    },
    [dispatch, farmId, growerId, seasonId, workOrder]
  );

  const handleClose = useCallback(() => {
    dispatch(addFarmWorkOrderNoteActions.clear());
    onClose();
  }, [dispatch, onClose]);

  return (
    <form noValidate onSubmit={handleSubmit(handleFormSubmit)}>
      <Box padding="1rem">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              inputRef={register()}
              variant="standard"
              multiline
              fullWidth
              id="note"
              label="Note"
              name="note"
              error={!!errors.note}
              helperText={_.get(errors, 'note.message')}
            />
          </Grid>
          <Grid item xs={12}>
            {errorMessage && (
              <FormHelperText error>{errorMessage}</FormHelperText>
            )}
          </Grid>
        </Grid>
        <CreateUpdateDeleteDialogActions
          inProgress={inProgress}
          onCancel={handleClose}
        />
      </Box>
    </form>
  );
};

export default AddWorkOrderNoteForm;
