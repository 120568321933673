import React, { useCallback, useMemo } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';

import DrawerItem from './DrawerItem';

const useStyles = makeStyles(() => ({
  title: {
    marginLeft: '1rem',
    fontWeight: '600',
    color: '#ececec'
  }
}));

const FarmDrawerItem = ({
  farm,
  showFieldsCount,
  even,
  selected,
  showAll,
  onClick
}) => {
  const styles = useStyles();

  const fieldsCount = _.get(farm, 'fieldsCount');

  const shapes = useMemo(
    () =>
      _.chain(farm)
        .get('fields')
        .values()
        .map((field) => _.get(field, 'shape'))
        .value(),
    [farm]
  );

  const handleClick = useCallback(() => {
    onClick(farm);
  }, [farm, onClick]);

  return (
    <DrawerItem
      item={farm}
      title={_.get(farm, 'name')}
      subtitle={
        showFieldsCount
          ? `${fieldsCount} Field${fieldsCount === 1 ? '' : 's'}`
          : undefined
      }
      center
      even={even}
      onClick={handleClick}
      selected={selected}
      shapes={shapes}
      classes={{ title: showAll ? styles.title : undefined }}
    />
  );
};

export default FarmDrawerItem;
