import { useMemo } from 'react';

import { fieldGeospatialDataService } from '../../../services/fieldGeospatialData.service';
import { createApiSlice } from '../utils/createApiSlice';

const addGeospatialDataSlice = createApiSlice({
  reducerPath: ['newGrowths', 'geospatialData', 'addGeospatialData'],
  apiFunction: fieldGeospatialDataService.addGeospatialDataV2,
  apiArgumentsFn: ({ growerId, seasonId, farmId, fieldId, data }) => [
    growerId,
    seasonId,
    farmId,
    fieldId,
    data
  ]
});

const { actions, useApiSelectors } = addGeospatialDataSlice;

export const addGeospatialDataActions = actions;

export const useAddGeospatialDataSelectors = () => {
  const { inProgress, errorMessage, success, updatedAt, response } =
    useApiSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      updatedAt,
      response
    }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default addGeospatialDataSlice;
