export const TankMixProductType = {
  Herbicide: {
    key: 'Herbicide',
    name: 'Herbicide'
  },
  Fungicide: {
    key: 'Fungicide',
    name: 'Fungicide'
  },
  Insecticide: {
    key: 'Insecticide',
    name: 'Insecticide'
  },
  GrowthRegulator: {
    key: 'GrowthRegulator',
    name: 'Growth Regulator'
  },
  Additive: {
    key: 'Additive',
    name: 'Additive'
  }
};
