import { useContext, useMemo } from 'react';

import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import _ from 'lodash';

import { userRoles } from '../../_constants/userConstants';
import { FarmStructureContext } from '../../_context/FarmStructureContext';

import { dashboardTabsIds } from './DashboardPage';

const DashboardPageKey = 'DASHBOARD_PAGE';

export const DashboardPageSubpageKeys = {
  GrowthModel: `${DashboardPageKey}_GROWTH_MODEL`,
  Activities: `${DashboardPageKey}_ACTIVITIES`,
  ZonedActivities: `${DashboardPageKey}_ZONED_ACTIVITIES`,
  HarvestAdvisor: `${DashboardPageKey}_HARVEST_ADVISOR`,
  FieldAccessibility: `${DashboardPageKey}_FIELD_ACCESSIBILITY`,
  GrowthSnapshots: `${DashboardPageKey}_GROWTH_SNAPSHOTS`,
  WorkOrders: `${DashboardPageKey}_WORK_ORDERS`
};

const baseConfig = {
  key: DashboardPageKey,
  icon: faCalendarAlt,
  label: 'Crop Dashboard',
  path: 'dashboard',
  allowedRoles: [
    userRoles.Admin,
    userRoles.Consultant,
    userRoles.User,
    userRoles.UserView
  ],
  subPages: [
    {
      key: DashboardPageSubpageKeys.GrowthModel,
      label: 'Growth Model',
      path: dashboardTabsIds.GROWTH_MODEL
    },
    {
      key: DashboardPageSubpageKeys.Activities,
      label: 'Activities',
      path: dashboardTabsIds.ACTIVITIES
    },
    {
      key: DashboardPageSubpageKeys.ZonedActivities,
      label: 'Zoned Activities',
      path: dashboardTabsIds.FARM_ACTIVITIES
    },
    {
      key: DashboardPageSubpageKeys.WorkOrders,
      label: 'Work Orders',
      path: dashboardTabsIds.WORK_ORDERS
    },
    {
      label: 'Harvest Advisor',
      key: DashboardPageSubpageKeys.HarvestAdvisor,
      path: dashboardTabsIds.HARVEST_ADVISOR
    },
    {
      key: DashboardPageSubpageKeys.FieldAccessibility,
      label: 'Field Accessibility',
      path: dashboardTabsIds.FIELD_ACCESSIBILITY
    },
    {
      key: DashboardPageSubpageKeys.GrowthSnapshots,
      label: 'Growth Snapshots',
      path: dashboardTabsIds.GROWTH_SNAPSHOTS
    }
  ]
};

export const useDashboardPageConfig = () => {
  const { selectedSeason, isHistoricalSeason } =
    useContext(FarmStructureContext);

  const config = useMemo(
    () => ({
      ...baseConfig,
      subPages: _.chain(baseConfig)
        .get('subPages')
        .filter((subPage) => {
          const key = _.get(subPage, 'key');
          if (key === DashboardPageSubpageKeys.FieldAccessibility) {
            return !!selectedSeason && !isHistoricalSeason;
          }
          return true;
        })
        .value()
    }),
    [isHistoricalSeason, selectedSeason]
  );

  return useMemo(() => ({ config }), [config]);
};
