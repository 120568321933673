import React, { useCallback, useContext, useEffect, useMemo } from 'react';

import MomentUtils from '@date-io/moment';
import { FormControl, ListSubheader, MenuItem } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SaveIcon from '@material-ui/icons/Save';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment/moment';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { FarmStructureContext } from '../../../_context/FarmStructureContext';
import { forceUpdateActiveGrowthAction } from '../../../_store/actions/growthsActions';
import {
  useBundlesConfig,
  useVarietiesConfig
} from '../../../_store/selectors/configSelectors';
import { useFieldsWithGrowthSelector } from '../../../_store/selectors/fieldsSelectors';
import { getSeasonWithGrowthsActions } from '../../../_store/slices/gff/seasons/getSeasonsWithGrowthsSlice';
import {
  bulkUpdateGrowthsActions,
  useBulkUpdateGrowthsSelectors
} from '../../../_store/slices/growths/bulkUpdateGrowthsSlice';
import {
  toaster,
  toastError,
  toastSuccess
} from '../../../_utilities/toast-utils';
import VarietyDropdown from '../../../Components/Growths/VarietyDropdown';
import LoadingIndicator from '../../../Components/LoadingIndicator';
import {
  ContentBody,
  ContentContainer,
  ResponsiveContentHeader
} from '../../../Layout/Content';

import { useGrowerBundles } from './_hooks/useGrowerBundles';

const NoBundleId = '00000000-0000-0000-0000-000000000000';

const useStyles = makeStyles(() => ({
  table: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderBottom: '1px solid rgba(224, 224, 224, 1)'
  },
  header: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem'
  },
  cell: {
    display: 'flex',
    flexDirection: 'row',
    minWidth: 0,
    flexBasis: 0,
    padding: '1rem',
    fontSize: '0.875rem',
    alignItems: 'center'
  },
  fieldCell: {
    flexGrow: 1
  },
  dateCell: {
    flexGrow: 1
  },
  varietyCell: {
    flexGrow: 1
  },
  targetGrainMoistureCell: {
    flexGrow: 1
  },
  bundleIdCell: {
    flexGrow: 1
  },
  changedDate: {
    backgroundColor: 'rgb(255, 244, 229)'
  }
}));

const useModifiedFieldClasses = makeStyles(() => ({
  root: {
    backgroundColor: 'rgb(255, 244, 229)'
  }
}));

const FieldRow = ({ idx, field, formMethods, season }) => {
  const styles = useStyles();
  const modifiedFieldClasses = useModifiedFieldClasses();

  const {
    register,
    errors,
    watch,
    control,
    formState: { dirtyFields }
  } = formMethods;

  const { bundles: globalBundles } = useBundlesConfig();
  const { bundles: growerBundles } = useGrowerBundles();

  const seasonStartDate = _.get(season, 'startDate');
  const seasonEndDate = _.get(season, 'endDate');

  const { varieties } = useVarietiesConfig();
  const defaultVarietyId = useMemo(
    () => _.chain(varieties).last().get('id').value(),

    [varieties]
  );

  const farmIdPath = `${idx}.farmId`;
  const fieldIdPath = `${idx}.fieldId`;
  const growthIdPath = `${idx}.growthId`;
  const plantingDatePath = `${idx}.plantingDate`;
  const varietyIdPath = `${idx}.varietyId`;
  const targetGrainMoisturePath = `${idx}.targetGrainMoisture`;
  const activitiesBundleIdPath = `${idx}.activitiesBundleId`;

  const plantingDate = watch(plantingDatePath);

  return (
    <Box className={styles.row}>
      <Box className={cx(styles.cell, styles.fieldCell)}>
        <span>{_.get(field, 'name')}</span>
      </Box>
      <Controller
        as={<input type="text" hidden />}
        control={control}
        defaultValue={_.get(field, 'farmId')}
        name={farmIdPath}
        id={farmIdPath}
      />
      <Controller
        as={<input type="text" hidden />}
        control={control}
        defaultValue={_.get(field, 'id')}
        name={fieldIdPath}
        id={fieldIdPath}
      />
      <Controller
        as={<input type="text" hidden />}
        control={control}
        defaultValue={_.get(field, 'activeGrowth.id')}
        name={growthIdPath}
        id={growthIdPath}
      />
      <Box className={cx(styles.cell, styles.dateCell)}>
        <Controller
          control={control}
          name={plantingDatePath}
          id={plantingDatePath}
          defaultValue={null}
          render={({ onChange, value }) => (
            <Box>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  className={cx({
                    [styles.changedDate]: !!_.get(dirtyFields, plantingDatePath)
                  })}
                  disableToolbar
                  variant="inline"
                  inputVariant="outlined"
                  format="L"
                  value={value}
                  onChange={onChange}
                  minDate={seasonStartDate}
                  maxDate={seasonEndDate}
                  autoOk
                />
              </MuiPickersUtilsProvider>
            </Box>
          )}
        />
      </Box>
      <Box className={cx(styles.cell, styles.varietyCell)}>
        {!!plantingDate && (
          <Controller
            control={control}
            name={varietyIdPath}
            id={varietyIdPath}
            defaultValue={defaultVarietyId}
            rules={{ required: true }}
            render={({ onChange, value }) => (
              <VarietyDropdown
                value={value}
                onChange={onChange}
                variant="outlined"
                required
                fullWidth
                select
                id="varietyId"
                name="varietyId"
                error={!!_.get(errors, varietyIdPath)}
                helperText={_.get(errors, `${varietyIdPath}.message`)}
                InputProps={{
                  classes: _.get(dirtyFields, varietyIdPath)
                    ? modifiedFieldClasses
                    : {}
                }}
              />
            )}
          />
        )}
      </Box>
      <Box className={cx(styles.cell, styles.targetGrainMoistureCell)}>
        {!!plantingDate && (
          <TextField
            inputRef={register({
              min: {
                value: 0.00000001,
                message: 'Target Grain Moisture Content must be greater than 0'
              },
              max: {
                value: 100,
                message: 'Target Grain Moisture Content must be less than 100'
              },
              required: 'Target Grain Moisture Content is required',
              valueAsNumber: true
            })}
            required
            inputProps={{
              min: 0,
              max: 100
            }}
            fullWidth
            variant="outlined"
            type="number"
            id={targetGrainMoisturePath}
            name={targetGrainMoisturePath}
            error={!!_.get(errors, targetGrainMoisturePath)}
            helperText={_.get(errors, `${targetGrainMoisturePath}.message`)}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              classes: _.get(dirtyFields, targetGrainMoisturePath)
                ? modifiedFieldClasses
                : {}
            }}
          />
        )}
      </Box>
      <Box className={cx(styles.cell, styles.bundleIdCell)}>
        {!!plantingDate && (
          <Controller
            control={control}
            name={activitiesBundleIdPath}
            id={activitiesBundleIdPath}
            defaultValue={NoBundleId}
            rules={{ required: true }}
            render={({ onChange, value }) => (
              <FormControl fullWidth>
                <TextField
                  select
                  variant="outlined"
                  value={value}
                  onChange={(e) => {
                    if (e.target.value) {
                      onChange(e.target.value);
                    }
                  }}
                  fullWidth
                  InputProps={{
                    classes: _.get(dirtyFields, activitiesBundleIdPath)
                      ? modifiedFieldClasses
                      : {}
                  }}
                >
                  <MenuItem value={NoBundleId}>(use default)</MenuItem>
                  <ListSubheader>Global Bundles</ListSubheader>,
                  {_.map(globalBundles, (bundle) => (
                    <MenuItem
                      key={_.get(bundle, 'id')}
                      value={_.get(bundle, 'id')}
                    >
                      {_.get(bundle, 'description')}
                    </MenuItem>
                  ))}
                  {!_.isEmpty(growerBundles) && (
                    <ListSubheader>Grower Bundles</ListSubheader>
                  )}
                  {_.map(growerBundles, (bundle) => (
                    <MenuItem
                      key={_.get(bundle, 'id')}
                      value={_.get(bundle, 'id')}
                    >
                      {_.get(bundle, 'description')}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            )}
          />
        )}
      </Box>
    </Box>
  );
};

const FarmCropManagement = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const { selectedGrowerId, selectedSeasonId, selectedFarmId, selectedSeason } =
    useContext(FarmStructureContext);

  const { inProgress, errorMessage, success, response } =
    useBulkUpdateGrowthsSelectors();

  const { fields } = useFieldsWithGrowthSelector(
    selectedGrowerId,
    selectedSeasonId,
    selectedFarmId
  );

  const defaultValues = useMemo(
    () =>
      _.map(fields, (field) => {
        const plantingDate = _.get(field, 'activeGrowth.plantingDate');

        const baseDefault = {
          id: _.get(field, 'id'),
          plantingDate: null,
          fieldId: _.get(field, 'id'),
          farmId: _.get(field, 'farmId'),
          growthId: _.get(field, 'activeGrowth.id')
        };

        if (!plantingDate) {
          return baseDefault;
        }

        return {
          ...baseDefault,
          plantingDate: moment(_.get(field, 'activeGrowth.plantingDate')),
          varietyId: _.get(field, 'activeGrowth.varietyId'),
          targetGrainMoisture: _.get(field, 'activeGrowth.targetGrainMoisture'),
          activitiesBundleId: _.get(
            field,
            'activeGrowth.activitiesBundleId',
            NoBundleId
          )
        };
      }),
    [fields]
  );

  const formMethods = useForm({ defaultValues });

  const {
    formState: { dirtyFields },
    reset,
    handleSubmit
  } = formMethods;

  const isDirty = !_.isEmpty(dirtyFields);

  useEffect(() => {
    if (success) {
      toaster('Growths updated', toastSuccess);
      dispatch(
        getSeasonWithGrowthsActions.forceUpdate({
          growerId: selectedGrowerId,
          seasonId: selectedSeasonId
        })
      );
      _.forEach(response, (item) => {
        dispatch(
          forceUpdateActiveGrowthAction(
            _.get(item, 'growerId'),
            _.get(item, 'seasonId'),
            _.get(item, 'farmId'),
            _.get(item, 'fieldId')
          )
        );
      });
      dispatch(bulkUpdateGrowthsActions.clear());
    }
  }, [
    dispatch,
    response,
    selectedFarmId,
    selectedGrowerId,
    selectedSeasonId,
    success
  ]);

  useEffect(() => {
    if (errorMessage) {
      toaster(`Error updating growths: ${errorMessage}`, toastError);
      dispatch(bulkUpdateGrowthsActions.clear());
    }
  }, [dispatch, errorMessage]);

  const handleReset = useCallback(() => {
    reset();
  }, [reset]);

  const handleFormSubmit = useCallback(
    (formData) => {
      const changedRows = _.chain(formData)
        .filter(
          (row, idx) => _.has(dirtyFields, idx) && !!_.get(row, 'plantingDate')
        )
        .map((row) => ({
          ...row,
          plantingDate: _.get(row, 'plantingDate').toISOString(),
          activitiesBundleId:
            _.get(row, 'activitiesBundleId') === NoBundleId
              ? undefined
              : _.get(row, 'activitiesBundleId')
        }))

        .value();

      dispatch(
        bulkUpdateGrowthsActions.submit({
          growerId: selectedGrowerId,
          seasonId: selectedSeasonId,
          data: changedRows
        })
      );
    },
    [dirtyFields, dispatch, selectedGrowerId, selectedSeasonId]
  );

  return (
    <form noValidate onSubmit={handleSubmit(handleFormSubmit)}>
      <ContentContainer>
        <ResponsiveContentHeader>
          <span>Growth Management</span>
          <Box display="flex" flexDirection="row">
            <Box style={{ marginRight: '0.5rem' }}>
              <Button
                size="small"
                variant="contained"
                color="secondary"
                startIcon={<RotateLeftIcon />}
                disabled={!isDirty || inProgress}
                onClick={handleReset}
              >
                Reset
              </Button>
            </Box>
            <Button
              size="small"
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              type="submit"
              disabled={!isDirty || inProgress}
            >
              Save Changes
            </Button>
          </Box>
        </ResponsiveContentHeader>
        <ContentBody>
          {inProgress && <LoadingIndicator />}
          {!inProgress && (
            <Box className={styles.table}>
              <Box className={styles.row}>
                <Box
                  className={cx(styles.cell, styles.header, styles.fieldCell)}
                >
                  Field
                </Box>
                <Box
                  className={cx(styles.cell, styles.header, styles.dateCell)}
                >
                  Planting Date
                </Box>
                <Box
                  className={cx(styles.cell, styles.header, styles.varietyCell)}
                >
                  Seed Product
                </Box>
                <Box
                  className={cx(
                    styles.cell,
                    styles.header,
                    styles.targetGrainMoistureCell
                  )}
                >
                  Target Grain Moisture Content
                </Box>
                <Box
                  className={cx(
                    styles.cell,
                    styles.header,
                    styles.bundleIdCell
                  )}
                >
                  Protocol Bundle
                </Box>
              </Box>
              {_.map(fields, (field, idx) => (
                <FieldRow
                  key={_.get(field, 'id')}
                  idx={idx}
                  field={field}
                  formMethods={formMethods}
                  season={selectedSeason}
                />
              ))}
            </Box>
          )}
        </ContentBody>
      </ContentContainer>
    </form>
  );
};

export default FarmCropManagement;
