import { addToken, client } from './client';

export const bundlesConfigService = {
  createBundleV2,
  updateBundleV2,
  deleteBundleV2,
  setDefaultBundleV2,
  cloneBundleV2
};

async function createBundleV2(token, bundle) {
  const response = await client.post(
    `/v2/config/bundles`,
    bundle,
    addToken(token)
  );
  return response.data;
}

async function updateBundleV2(token, bundleId, bundle) {
  const response = await client.patch(
    `/v2/config/bundles/${bundleId}`,
    bundle,
    addToken(token)
  );
  return response.data;
}

async function deleteBundleV2(token, bundleId) {
  const response = await client.delete(
    `/v2/config/bundles/${bundleId}`,
    addToken(token)
  );
  return response.data;
}

async function setDefaultBundleV2(token, bundleId) {
  const response = await client.post(
    `/v2/config/bundles/${bundleId}/set-default`,
    {},
    addToken(token)
  );
  return response.data;
}

async function cloneBundleV2(token, bundleId, bundle) {
  const response = await client.post(
    `/v2/config/bundles/${bundleId}/clone`,
    bundle,
    addToken(token)
  );
  return response.data;
}
