export const WorkOrderStatus = {
  New: { key: 'New', name: 'New' },
  InProgress: { key: 'InProgress', name: 'In Progress' },
  Complete: { key: 'Complete', name: 'Complete' },
  Canceled: { key: 'Canceled', name: 'Canceled' }
};

export const WorkOrderType = {
  Zoned: 'Zoned',
  Recommendation: 'Recommendation'
};

export const WorkOrderPayloadType = {
  Scouting: { key: 'Scouting', name: 'Scouting' },
  NitrogenApplication: {
    key: 'NitrogenApplication',
    name: 'Nitrogen Application'
  },
  HerbicideApplication: {
    key: 'HerbicideApplication',
    name: 'Herbicide Application'
  },
  FungicideApplication: {
    key: 'FungicideApplication',
    name: 'Fungicide Application'
  },
  Irrigation: { key: 'Irrigation', name: 'Irrigation' },
  Drydown: { key: 'Drydown', name: 'Drydown' }
};
