export const mapLayerIds = {
  GrowthModel: 0,
  Weather: 1,
  FieldAccessibility: 2,
  Crops: 3,
  Activities: 4,
  Devices: 5,
  Empty: 6,
  ManagementZones: 7,
  ZonedActivities: 8,
  GeospatialData: 9,
  WorkOrders: 10
};
