import createManagementZoneSlice from './createManagementZoneSlice';
import deleteManagementZoneSlice from './deleteManagementZoneSlice';
import updateManagementZoneSlice from './updateManagementZoneSlice';

const managementZonesSlices = [
  createManagementZoneSlice,
  updateManagementZoneSlice,
  deleteManagementZoneSlice
];

export default managementZonesSlices;
