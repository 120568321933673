import { useCallback, useContext, useMemo } from 'react';

import { ManagementZonesContext } from '../ManagementZonesContext';

export const useManagementZonesLayers = () => {
  const {
    selectedLayer,
    hoveredLayer,
    editLayerMode,
    setSelectedLayer,
    setHoveredLayer,
    setEditLayerMode,
    managementZonesLayers
  } = useContext(ManagementZonesContext);

  const selectLayer = useCallback(
    (layer, clickedFieldId) => {
      setSelectedLayer(layer, clickedFieldId);
      setHoveredLayer(undefined);
    },
    [setHoveredLayer, setSelectedLayer]
  );

  const addLayer = useCallback(() => {
    setSelectedLayer(undefined);
    setEditLayerMode(true);
  }, [setEditLayerMode, setSelectedLayer]);

  const editLayer = useCallback(
    (layer) => {
      setSelectedLayer(layer);
      setEditLayerMode(true);
    },
    [setEditLayerMode, setSelectedLayer]
  );

  const cancelLayerEdit = useCallback(() => {
    setEditLayerMode(false);
  }, [setEditLayerMode]);

  const hoverLayer = useCallback(
    (layer) => {
      setHoveredLayer(layer);
    },
    [setHoveredLayer]
  );

  return useMemo(
    () => ({
      managementZonesLayers,
      selectedLayer,
      hoveredLayer,
      editLayerMode,
      selectLayer,
      addLayer,
      editLayer,
      cancelLayerEdit,
      hoverLayer
    }),
    [
      addLayer,
      cancelLayerEdit,
      editLayer,
      editLayerMode,
      hoverLayer,
      hoveredLayer,
      managementZonesLayers,
      selectLayer,
      selectedLayer
    ]
  );
};
