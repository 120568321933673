import React, { useMemo } from 'react';

import _ from 'lodash';

import CommonDataGrid from '../../../Components/DataGrid/CommonDataGrid';

import { useCommonReportsColumns } from './_hooks/useCommonReportsColumns';

const HarvestedYieldReportTable = ({
  response,
  onVisibleRowIdsChange = _.noop
}) => {
  const { commonColumns } = useCommonReportsColumns();

  const columns = useMemo(
    () => [
      ...commonColumns,
      {
        field: 'harvestDate',
        headerName: 'Harvest Date',
        width: 150,
        editable: false,
        align: 'right',
        headerAlign: 'right'
      },
      {
        field: 'yieldBushelsPerAcre',
        headerName: 'Yield (bu/ac)',
        width: 160,
        type: 'number',
        editable: false,
        valueFormatter: (params) => {
          return params.value.toFixed(1);
        }
      },
      {
        field: 'yieldBushels',
        headerName: 'Total Yield (bu)',
        width: 180,
        type: 'number',
        editable: false,
        valueFormatter: (params) => {
          return params.value.toFixed(0);
        }
      }
    ],
    [commonColumns]
  );

  const rows = useMemo(
    () =>
      _.chain(response)
        .get('fields')
        .map((field) => {
          return {
            id: _.get(field, 'field.fieldId'),
            ..._.get(field, 'field', {}),
            ..._.get(field, 'variety', {}),
            harvestDate: _.get(field, 'harvestDate'),
            yieldBushelsPerAcre: _.get(field, 'yieldBushelsPerAcre'),
            yieldBushels:
              _.get(field, 'yieldBushelsPerAcre') * _.get(field, 'field.acres')
          };
        })
        .value(),
    [response]
  );

  return (
    <CommonDataGrid
      rows={rows}
      columns={columns}
      onVisibleRowIdsChange={onVisibleRowIdsChange}
    />
  );
};

export default HarvestedYieldReportTable;
