import { useMemo } from 'react';

import { fieldService } from '../../../../services';
import { createApiSlice } from '../../utils/createApiSlice';

const reparentFieldSlice = createApiSlice({
  reducerPath: ['gff', 'fields', 'reparentField'],
  apiFunction: fieldService.reparentFieldV2
});

const { actions, useApiSelectors } = reparentFieldSlice;

export const reparentFieldActions = actions;

export const reparentFieldSelectors = useApiSelectors;

export const useReparentFieldSelectors = () => {
  const { inProgress, errorMessage, success } = reparentFieldSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success
    }),
    [errorMessage, inProgress, success]
  );
};

export default reparentFieldSlice;
