import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import MenuItem from './MenuItem';

const useStyles = makeStyles(() => ({
  container: {
    margin: '0',
    padding: '0',
    position: 'relative',
    listStyle: 'none'
  }
}));

const Menu = ({ menuItems, collapsedSideMenuHover }) => {
  const styles = useStyles();

  return (
    <ul className={styles.container}>
      {_.map(menuItems, (page) => (
        <MenuItem
          key={`menuitem-${_.get(page, 'key')}`}
          item={page}
          collapsedSideMenuHover={collapsedSideMenuHover}
        />
      ))}
    </ul>
  );
};

export default Menu;
