import { useMemo } from 'react';

import { tankMixesConfigService } from '../../../../services/tankMixesConfig.service';
import { createApiSlice } from '../../utils/createApiSlice';

const deleteTankMixProductSlice = createApiSlice({
  reducerPath: ['newConfig', 'tankMixes', 'deleteProduct'],
  apiFunction: tankMixesConfigService.deleteTankMixProductV2,
  apiArgumentsFn: ({ productId }) => [productId]
});

const { actions, useApiSelectors } = deleteTankMixProductSlice;

export const deleteTankMixProductActions = actions;

export const useDeleteTankMixProductSelectors = () => {
  const { inProgress, success, errorMessage, response, updatedAt } =
    useApiSelectors();

  return useMemo(
    () => ({ inProgress, success, errorMessage, response, updatedAt }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default deleteTankMixProductSlice;
