import React, { useEffect, useMemo } from 'react';

import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { useSubPage } from '../../_hooks/Pages/useSubpage';
import { getGrowthModelReportActions } from '../../_store/slices/reports/getGrowthModelReportSlice';
import AppPage from '../../Layout/AppPage';
import PageTitle from '../../Layout/PageTitle';

import { useSeasonAppliedFields } from './Components/_hooks/useSeasonAppliedFields';
import CropProgressReport from './Components/CropProgressReport';
import FullSeasonGrowthModelReport from './Components/FullSeasonGrowthModelReport';
import GrowthStageReport from './Components/GrowthStageReport';
import HarvestedYieldReport from './Components/HarvestedYieldReport';
import HarvestReport from './Components/HarvestReport';
import HerbicideReport from './Components/HerbicideReport';
import MidToFullSeasonGrowthModelReport from './Components/MidToFullSeasonGrowthModelReport';
import NitrogenReport from './Components/NitrogenReport';

export const Reports = {
  'full-season': {
    order: 0,
    id: 'full-season',
    name: 'Full Season',
    component: <FullSeasonGrowthModelReport />
  },
  'mid-to-full-season': {
    order: 1,
    id: 'mid-to-full-season',
    name: 'Mid to Full Season',
    component: <MidToFullSeasonGrowthModelReport />
  },
  'crop-progress': {
    order: 2,
    id: 'crop-progress',
    name: 'Crop Progress',
    component: <CropProgressReport />
  },
  harvest: {
    order: 3,
    id: 'harvest',
    name: 'Harvest',
    component: <HarvestReport />
  },
  'harvested-yield': {
    order: 4,
    id: 'harvested-yield',
    name: 'Harvested Yield',
    component: <HarvestedYieldReport />
  },
  'growth-stage': {
    order: 5,
    id: 'growth-stage',
    name: 'Growth Stage',
    component: <GrowthStageReport />
  },
  herbicide: {
    order: 6,
    id: 'herbicide',
    name: 'Herbicide',
    component: <HerbicideReport />
  },
  nitrogen: {
    order: 7,
    id: 'nitrogen',
    name: 'Nitrogen',
    component: <NitrogenReport />
  }
};

const ReportsPage = () => {
  const dispatch = useDispatch();

  const { subPage } = useSubPage();

  const path = _.get(subPage, 'path');

  const { appliedFields } = useSeasonAppliedFields();

  const selectedTab = useMemo(() => _.get(Reports, [path]), [path]);

  const component = useMemo(
    () => _.get(selectedTab, 'component'),
    [selectedTab]
  );

  useEffect(() => {
    dispatch(getGrowthModelReportActions.submit({ fields: appliedFields }));
  }, [appliedFields, dispatch]);

  return <AppPage header={<PageTitle />}>{component}</AppPage>;
};

export default ReportsPage;
