import React, { useEffect, useMemo } from 'react';

import Alert from '@material-ui/lab/Alert';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { useGlobalSeasonSelectors } from '../../../_store/slices/gff/seasons/globalSeasonSlice';
import {
  getUsersPageViewsStatisticsActions,
  useGetUsersPageViewsStatisticsSelectors
} from '../../../_store/slices/statistics/getUsersPageViewsStatisticsSlice';
import CommonDataGrid from '../../../Components/DataGrid/CommonDataGrid';
import LoadingIndicator from '../../../Components/LoadingIndicator';
import {
  ContentBody,
  ContentContainer,
  ContentHeader
} from '../../../Layout/Content';

const columns = [
  {
    field: 'userName',
    headerName: 'User Name',
    width: 150,
    editable: false
  },
  {
    field: 'firstName',
    headerName: 'First Name',
    width: 150,
    editable: false
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
    width: 150,
    editable: false
  },
  {
    field: 'role',
    headerName: 'Role',
    width: 110,
    editable: false
  },
  {
    field: 'totalPageViews',
    headerName: 'Total',
    width: 110,
    editable: false,
    align: 'right',
    headerAlign: 'right'
  },
  ..._.map(_.range(12), (i) => ({
    field: `${i}-month-page-views`,
    valueGetter: (params) =>
      _.get(params, ['row', 'pageViewsByMonth', i + 1], 0),
    headerName: moment(`1970-${i + 1}-1`).format('MMM'),
    width: 110,
    editable: false,
    align: 'right',
    headerAlign: 'right'
  }))
];

const UsersPageViewsStatistics = () => {
  const dispatch = useDispatch();

  const { selectedSeason: seasonYear } = useGlobalSeasonSelectors();

  const { inProgress, errorMessage, response } =
    useGetUsersPageViewsStatisticsSelectors(seasonYear);

  const rows = useMemo(() => _.map(response, (item) => item), [response]);

  useEffect(() => {
    dispatch(getUsersPageViewsStatisticsActions.submit({ seasonYear }));
  }, [dispatch, seasonYear]);

  return (
    <ContentContainer>
      <ContentHeader>
        <span>{`Season ${seasonYear} Users Page Views`}</span>
      </ContentHeader>
      <ContentBody>
        {inProgress && <LoadingIndicator />}
        {!inProgress && errorMessage && (
          <Alert severity="error">{errorMessage}</Alert>
        )}
        {!inProgress && !errorMessage && (
          <CommonDataGrid rows={rows} columns={columns} />
        )}
      </ContentBody>
    </ContentContainer>
  );
};

export default UsersPageViewsStatistics;
