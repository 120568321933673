import { useMemo } from 'react';

import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';

import { userRoles } from '../../_constants/userConstants';

const ActivitiesEnginePageKey = 'ACTIVITIES_ENGINE_PAGE';

export const ActivitiesEngineSubpageKey = {
  Engine: `${ActivitiesEnginePageKey}_ENGINE`,
  Config: `${ActivitiesEnginePageKey}_CONFIG`,
  Demo: `${ActivitiesEnginePageKey}_DEMO`
};

export const ActivitiesEngineSubpagePaths = {
  Engine: 'engine',
  Demo: 'demo',
  Config: 'config'
};

const baseConfig = {
  key: ActivitiesEnginePageKey,
  icon: faCalendarDay,
  label: 'Activities Engine',
  path: 'activities-engine',
  allowedRoles: [userRoles.Admin],
  subPages: [
    {
      key: ActivitiesEngineSubpageKey.Engine,
      label: 'Engine',
      path: ActivitiesEngineSubpagePaths.Engine
    },
    {
      key: ActivitiesEngineSubpageKey.Config,
      label: 'Config',
      path: ActivitiesEngineSubpagePaths.Config
    },
    {
      key: ActivitiesEngineSubpageKey.Demo,
      label: 'Demo',
      path: ActivitiesEngineSubpagePaths.Demo
    }
  ]
};

export const useActivitiesEnginePageConfig = () => {
  return useMemo(() => ({ config: baseConfig }), []);
};
