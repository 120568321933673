import { useMemo } from 'react';

import { tankMixesConfigService } from '../../../../services/tankMixesConfig.service';
import { createApiSlice } from '../../utils/createApiSlice';

const updateTankMixProductSlice = createApiSlice({
  reducerPath: ['newConfig', 'tankMixes', 'updateProduct'],
  apiFunction: tankMixesConfigService.updateTankMixProductV2,
  apiArgumentsFn: ({ productId, product }) => [productId, product]
});

const { actions, useApiSelectors } = updateTankMixProductSlice;

export const updateTankMixProductActions = actions;

export const useUpdateTankMixProductSelectors = () => {
  const { inProgress, success, errorMessage, response, updatedAt } =
    useApiSelectors();

  return useMemo(
    () => ({ inProgress, success, errorMessage, response, updatedAt }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default updateTankMixProductSlice;
