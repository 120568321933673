import { useMemo } from 'react';

import { growerProtocolsConfigService } from '../../../../../services/growerProtocolsConfig.service';
import { createApiSlice } from '../../../utils/createApiSlice';

const createGrowerProtocolSlice = createApiSlice({
  reducerPath: ['gff', 'growers', 'protocols', 'createGrowerProtocol'],
  apiFunction: growerProtocolsConfigService.createProtocolV2,
  apiArgumentsFn: ({ growerId, bundleId, protocol }) => [
    growerId,
    bundleId,
    protocol
  ]
});

const { actions, useApiSelectors } = createGrowerProtocolSlice;

export const createGrowerProtocolActions = actions;

export const useCreateGrowerProtocolSelectors = () => {
  const { inProgress, success, errorMessage, response, updatedAt } =
    useApiSelectors();

  return useMemo(
    () => ({ inProgress, success, errorMessage, response, updatedAt }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default createGrowerProtocolSlice;
