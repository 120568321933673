import React, { useCallback } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import _ from 'lodash';

import { buildPath } from '../../../_helpers/buildPath';
import { useMenuState } from '../../../_hooks/ui/useMenuState';
import { usePathname } from '../../../_hooks/usePathname';

const useStyles = makeStyles(() => ({
  externalIcon: {
    marginLeft: '0.5rem',
    verticalAlign: 'super',
    fontSize: '0.7rem'
  },
  item: {
    width: '100%'
  },
  link: {
    width: '100%',
    transition:
      'transform .2s, height 300ms, color 300ms, background-color 300ms',
    display: 'flex',
    alignItems: 'center',
    lineHeight: '2.5rem',
    height: '2rem',
    position: 'relative',
    borderRadius: '0.25rem',
    whiteSpace: 'nowrap',
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover': {
      color: 'white',
      background: 'rgba(255, 255, 255, 0.1)',
      textDecoration: 'none'
    }
  },
  activeLink: {
    background: 'rgba(255, 255, 255, 0.1)',
    fontWeight: 'bold'
  },
  label: {
    marginLeft: '16px',
    transition: 'color 150ms, opacity 300ms',
    fontSize: '0.88rem'
  },
  hideLabel: {
    opacity: '0'
  },
  thumbnailContainer: {
    display: 'flex',
    padding: '0 3px',
    width: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.88rem',
    color: 'rgba(255, 255, 255, 0.5)'
  }
}));

const MenuSubItem = ({ subItem, collapsedSideMenuHover }) => {
  const styles = useStyles();
  const { toggleMobileSideMenu, showMobileSideMenu, collapsedSideMenu } =
    useMenuState();

  const targetPath = buildPath('#', _.get(subItem, 'absolutePath'));

  const { pathname } = usePathname();
  const active = pathname.startsWith(targetPath.replace('#/', ''));

  const handleClick = useCallback(() => {
    // Close menu on phone layouts when a link is clicked
    if (showMobileSideMenu) {
      toggleMobileSideMenu();
    }
  }, [showMobileSideMenu, toggleMobileSideMenu]);

  return (
    <li className={styles.item}>
      <a
        onClick={handleClick}
        className={cx(styles.link, {
          [styles.activeLink]: active
        })}
        href={targetPath}
        target={subItem.target || '_self'}
      >
        <div className={cx(styles.thumbnailContainer)}>
          {collapsedSideMenu &&
            !collapsedSideMenuHover &&
            _.first(_.get(subItem, 'label'))}
        </div>
        <span
          className={cx(styles.label, {
            [styles.hideLabel]: collapsedSideMenu && !collapsedSideMenuHover
          })}
        >
          {subItem.label}
        </span>
      </a>
    </li>
  );
};

export default MenuSubItem;
