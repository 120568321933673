import React from 'react';

import { ThemeProvider } from '@material-ui/core/styles';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { ApiProvider } from './_context/ApiContext';
import { AuthProvider } from './_context/AuthContext';
import { FarmStructureProvider } from './_context/FarmStructureContext';
import { PagesConfigProvider } from './_context/PagesConfigContext';
import { UserProvider } from './_context/UserContext';
import configureStore from './_store/configureStore';
import AppMain from './Layout/AppMain';
import { unregister } from './registerServiceWorker';
import { theme } from './theme';
import 'mapbox-gl/dist/mapbox-gl.css';
import './assets/base.css';

const rootElement = document.getElementById('root');
const { store, persistor } = configureStore();

const renderApp = (Component) => {
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <HashRouter>
            <AuthProvider>
              <ApiProvider>
                <UserProvider>
                  <FarmStructureProvider>
                    <PagesConfigProvider>
                      <Component />
                    </PagesConfigProvider>
                  </FarmStructureProvider>
                </UserProvider>
              </ApiProvider>
            </AuthProvider>
          </HashRouter>
        </PersistGate>
      </ReduxProvider>
    </ThemeProvider>,
    rootElement
  );
};

renderApp(AppMain);

if (module.hot) {
  module.hot.accept('./Layout/AppMain', () => {
    const NextApp = require('./Layout/AppMain').default;
    renderApp(NextApp);
  });
}
unregister();
