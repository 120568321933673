import createZonedActivityFeedbackSlice from './createFarmFeedbackSlice';
import deleteFarmFeedbackSlice from './deleteFarmFeedbackSlice';
import getFarmFeedbackPhotoSlice from './getFarmFeedbackPhotoSlice';

const farmFeedbackSlices = [
  createZonedActivityFeedbackSlice,
  deleteFarmFeedbackSlice,
  getFarmFeedbackPhotoSlice
];

export default farmFeedbackSlices;
