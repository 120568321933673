import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { FeedbackTargetType } from '../../../../../_constants/activitiesConstants';
import { FarmStructureContext } from '../../../../../_context/FarmStructureContext';
import { useFarmFeedbackItems } from '../../../../../_hooks/FarmFeedback/useFarmFeedbackItems';
import { useFarmWorkOrderItems } from '../../../../../_hooks/FarmWorkOrders/useFarmWorkOrderItems';
import { useQuery } from '../../../../../_hooks/useQuery';
import { forceUpdateFarmStructureAction } from '../../../../../_store/actions/farmStructureActions';
import {
  createManagementZoneActions,
  useCreateManagementZoneSelectors
} from '../../../../../_store/slices/growths/managementZones/createManagementZoneSlice';
import {
  deleteManagementZoneActions,
  useDeleteManagementZoneSelectors
} from '../../../../../_store/slices/growths/managementZones/deleteManagementZoneSlice';
import {
  updateManagementZoneActions,
  useUpdateManagementZoneSelectors
} from '../../../../../_store/slices/growths/managementZones/updateManagementZoneSlice';
import { addWorkOrderActions } from '../../../../../_store/slices/ui/addWorkOrderSlice';
import {
  managementZonesActions,
  useManagementZonesSelectors
} from '../../../../../_store/slices/ui/managementZonesSlice';
import {
  toaster,
  toastError,
  toastSuccess
} from '../../../../../_utilities/toast-utils';

import { useZonesPolygonEditor } from './_hooks/useZonesPolygonEditor';

export const ManagementZonesContext = createContext({});

export const ManagementZonesProvider = ({ fields, children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [hoveredLayer, setHoveredLayer] = useState();
  const [selectedZone, setSelectedZone] = useState();
  const [hoveredZone, setHoveredZone] = useState();
  const [editLayerMode, setEditLayerMode] = useState(false);
  const [editZoneMode, setEditZoneMode] = useState(false);
  const [parentZoneToConvert, setParentZoneToConvert] = useState();
  const [zonesVisibilityByLayer, setZonesVisibilityByLayer] = useState({});
  const [hoveredFeedback, setHoveredFeedback] = useState();
  const [selectedFeedback, setSelectedFeedback] = useState();
  const [hoveredWorkOrder, setHoveredWorkOrder] = useState();
  const [selectedWorkOrder, setSelectedWorkOrder] = useState();

  const {
    selectedGrowerId,
    selectedFarmId,
    selectedFieldId,
    isHistoricalSeason
  } = useContext(FarmStructureContext);

  const updateLayerStatus = useUpdateManagementZoneSelectors();
  const createLayerStatus = useCreateManagementZoneSelectors();

  const { selectedLayer, selectedLayersByFieldId } =
    useManagementZonesSelectors(selectedFieldId);

  const convertParentZoneMode = editZoneMode && !!parentZoneToConvert;

  const {
    success: updateSuccess,
    errorMessage: updateErrorMessage,
    response: updateResponse
  } = selectedLayer ? updateLayerStatus : createLayerStatus;

  const { success: deleteSuccess, errorMessage: deleteErrorMessage } =
    useDeleteManagementZoneSelectors();

  const field = useMemo(
    () => _.find(fields, (f) => _.get(f, 'id') === selectedFieldId),
    [fields, selectedFieldId]
  );

  const query = useQuery();

  const queryActivityId = useMemo(() => {
    return query.get('activityId');
  }, [query]);

  const queryWorkOrderId = useMemo(() => {
    return query.get('workOrderId');
  }, [query]);

  const queryEdit = useMemo(() => {
    return query.get('edit');
  }, [query]);

  const { feedbackItems } = useFarmFeedbackItems();
  const { workOrders } = useFarmWorkOrderItems();

  const managementZonesLayers = _.get(field, 'managementZones.layers');

  const selectedZoneShape = _.get(selectedZone, 'shape');
  const selectedFieldShape = _.get(field, 'shape');

  const shapesToExclude = useMemo(
    () =>
      _.chain(selectedLayer)
        .get('zones')
        .initial()
        .filter((zone) => _.get(zone, 'id') !== _.get(selectedZone, 'id'))
        .map((zone) => _.get(zone, 'shape'))
        .value(),
    [selectedLayer, selectedZone]
  );

  const initialEditorShape = parentZoneToConvert
    ? _.get(parentZoneToConvert, 'shape')
    : selectedZoneShape;

  const polygonEditor = useZonesPolygonEditor(
    initialEditorShape,
    selectedFieldShape,
    shapesToExclude
  );

  const { handleClear } = polygonEditor;

  const setSelectedLayer = useCallback(
    (layer, clickedFieldId) => {
      const fieldId = clickedFieldId || selectedFieldId;
      if (fieldId) {
        dispatch(managementZonesActions.setSelectedLayer({ layer, fieldId }));
      }
    },
    [dispatch, selectedFieldId]
  );

  useEffect(() => {
    if (queryActivityId) {
      const feedback = _.find(
        feedbackItems,
        (item) => _.get(item, 'id') === queryActivityId
      );
      if (feedback) {
        setSelectedFeedback(feedback);
      }
      setHoveredFeedback(undefined);
      navigate('/map');
    }
  }, [feedbackItems, navigate, queryActivityId]);

  const handleEditWorkOrder = useCallback(
    (workOrder) => {
      dispatch(addWorkOrderActions.clearSelectedShapes());
      dispatch(addWorkOrderActions.setWorkOrderToEdit(workOrder));
      const targets = _.chain(workOrder)
        .get('targetsByFieldId')
        .values()
        .value();
      for (let target of targets) {
        const fieldId = _.get(target, 'fieldId');
        const targetType = _.get(target, 'type');
        if (targetType === FeedbackTargetType.Field) {
          dispatch(
            addWorkOrderActions.addFieldShape({
              growerId: selectedGrowerId,
              farmId: selectedFarmId,
              fieldId
            })
          );
        }
        if (targetType === FeedbackTargetType.Zones) {
          const layerId = _.get(target, 'layerId');
          const zonesIds = _.get(target, 'zonesIds');

          for (let zoneId of zonesIds) {
            dispatch(
              addWorkOrderActions.addZoneShape({
                growerId: selectedGrowerId,
                farmId: selectedFarmId,
                fieldId,
                layerId,
                zoneId
              })
            );
          }
        }
      }

      dispatch(addWorkOrderActions.setShowAddWorkOrderForm(true));
    },
    [dispatch, selectedFarmId, selectedGrowerId]
  );

  useEffect(() => {
    if (queryWorkOrderId) {
      const workOrder = _.find(
        workOrders,
        (item) => _.get(item, 'id') === queryWorkOrderId
      );
      if (workOrder) {
        if (queryEdit) {
          handleEditWorkOrder(workOrder);
          setSelectedWorkOrder(undefined);
        } else {
          setSelectedWorkOrder(workOrder);
        }
      }
      setHoveredWorkOrder(undefined);
      navigate('/map');
    } else if (queryEdit) {
      setSelectedWorkOrder(undefined);
      setHoveredWorkOrder(undefined);
      dispatch(addWorkOrderActions.setShowAddWorkOrderForm(true));
      dispatch(addWorkOrderActions.setWorkOrderToEdit(undefined));
      navigate('/map');
    }
  }, [
    dispatch,
    feedbackItems,
    handleEditWorkOrder,
    navigate,
    queryEdit,
    queryWorkOrderId,
    workOrders
  ]);

  useEffect(() => {
    if (isHistoricalSeason && editZoneMode) {
      setEditZoneMode(false);
    }
  }, [editZoneMode, isHistoricalSeason]);

  useEffect(() => {
    if (isHistoricalSeason && editLayerMode) {
      setEditLayerMode(false);
    }
  }, [editLayerMode, isHistoricalSeason]);

  useEffect(() => {
    if (editZoneMode && !selectedLayer) {
      setEditZoneMode(false);
      setParentZoneToConvert(undefined);
    }
  }, [editZoneMode, selectedLayer]);

  useEffect(() => {
    if (!editZoneMode) {
      handleClear();
    }
  }, [editZoneMode, handleClear]);

  useEffect(() => {
    for (const field of fields) {
      const managementZonesLayers = _.get(field, 'managementZones.layers');
      const selectedLayer = _.get(selectedLayersByFieldId, [
        _.get(field, 'id')
      ]);
      if (
        selectedLayer &&
        !_.find(
          managementZonesLayers,
          (layer) => _.get(layer, 'id') === _.get(selectedLayer, 'id')
        )
      ) {
        setSelectedLayer(undefined, _.get(field, 'id'));
      }
    }
  }, [fields, selectedLayersByFieldId, setSelectedLayer]);

  useEffect(() => {
    if (updateSuccess) {
      toaster(
        `Management zone layer ${selectedLayer ? 'updated' : 'added'}!`,
        toastSuccess
      );
      // polygon editor handle clear
      if (selectedLayer) {
        setSelectedLayer(updateResponse);
      }
      dispatch(forceUpdateFarmStructureAction());
      dispatch(createManagementZoneActions.clear());
      dispatch(updateManagementZoneActions.clear());
      setEditLayerMode(false);
      setEditZoneMode(false);
      setSelectedZone(undefined);
      setParentZoneToConvert(undefined);
    }
  }, [
    dispatch,
    selectedLayer,
    setSelectedLayer,
    updateResponse,
    updateSuccess
  ]);

  useEffect(() => {
    if (updateErrorMessage) {
      toaster(
        `Error ${
          selectedLayer ? 'updating' : 'adding'
        } management zone layer: ` + updateErrorMessage,
        toastError
      );
      dispatch(createManagementZoneActions.clear());
      dispatch(updateManagementZoneActions.clear());
    }
  }, [dispatch, selectedLayer, updateErrorMessage]);

  useEffect(() => {
    if (deleteSuccess) {
      toaster(`Management zone layer deleted!`, toastSuccess);
      setEditLayerMode(false);
      setEditZoneMode(false);
      setParentZoneToConvert(undefined);
      setSelectedLayer(undefined);
      dispatch(forceUpdateFarmStructureAction());
      dispatch(deleteManagementZoneActions.clear());
    }
  }, [deleteSuccess, dispatch, setSelectedLayer]);

  useEffect(() => {
    if (deleteErrorMessage) {
      toaster(
        `Error deleting management zone layer: ` + deleteErrorMessage,
        toastError
      );
      dispatch(deleteManagementZoneActions.clear());
    }
  }, [deleteErrorMessage, dispatch]);

  useEffect(() => {
    if (!selectedFarmId && selectedFeedback) {
      setSelectedFeedback(undefined);
    }
  }, [selectedFarmId, selectedFeedback]);

  useEffect(() => {
    if (!selectedFarmId && selectedWorkOrder) {
      setSelectedWorkOrder(undefined);
    }
  }, [selectedFarmId, selectedWorkOrder]);

  const context = useMemo(
    () => ({
      fields,
      field,
      selectedLayer,
      hoveredLayer,
      editLayerMode,
      editZoneMode,
      managementZonesLayers,
      selectedZone,
      hoveredZone,
      zonesVisibilityByLayer,
      setZonesVisibilityByLayer,
      polygonEditor,
      hoveredFeedback,
      selectedFeedback,
      hoveredWorkOrder,
      selectedWorkOrder,
      convertParentZoneMode,
      setSelectedLayer,
      setHoveredLayer,
      setSelectedZone,
      setHoveredZone,
      setEditLayerMode,
      setEditZoneMode,
      setParentZoneToConvert,
      setHoveredFeedback,
      setSelectedFeedback,
      setHoveredWorkOrder,
      setSelectedWorkOrder,
      handleEditWorkOrder
    }),
    [
      fields,
      field,
      selectedLayer,
      hoveredLayer,
      editLayerMode,
      editZoneMode,
      managementZonesLayers,
      selectedZone,
      hoveredZone,
      zonesVisibilityByLayer,
      polygonEditor,
      hoveredFeedback,
      selectedFeedback,
      hoveredWorkOrder,
      selectedWorkOrder,
      convertParentZoneMode,
      setSelectedLayer,
      handleEditWorkOrder
    ]
  );

  return (
    <ManagementZonesContext.Provider value={context}>
      {children}
    </ManagementZonesContext.Provider>
  );
};
