import { useMemo } from 'react';

import { fieldGeospatialDataService } from '../../../services/fieldGeospatialData.service';
import { createApiSlice } from '../utils/createApiSlice';

const getGeospatialDataSlice = createApiSlice({
  reducerPath: ['newGrowths', 'geospatialData', 'getGeospatialData'],
  apiFunction: fieldGeospatialDataService.getGeospatialDataV2,
  apiArgumentsFn: ({ growerId, seasonId, farmId, fieldId, fileId }) => [
    growerId,
    seasonId,
    farmId,
    fieldId,
    fileId
  ]
});

const { actions, useApiSelectors } = getGeospatialDataSlice;

export const getGeospatialDataActions = actions;

export const useGetGeospatialDataSelectors = () => {
  const { inProgress, errorMessage, success, updatedAt, response } =
    useApiSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      updatedAt,
      response
    }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default getGeospatialDataSlice;
