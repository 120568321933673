import { addToken, client } from './client';

export const tankMixesConfigService = {
  getTankMixesConfigV2,
  createTankMixProductV2,
  updateTankMixProductV2,
  deleteTankMixProductV2,
  createTankMixV2,
  updateTankMixV2,
  deleteTankMixV2
};

async function getTankMixesConfigV2(token) {
  const response = await client.get(`/v2/config/tank-mixes`, addToken(token));
  return response.data;
}

async function createTankMixProductV2(token, product) {
  const response = await client.post(
    `/v2/config/tank-mixes/products`,
    product,
    addToken(token)
  );
  return response.data;
}

async function updateTankMixProductV2(token, productId, product) {
  const response = await client.patch(
    `/v2/config/tank-mixes/products/${productId}`,
    product,
    addToken(token)
  );
  return response.data;
}

async function deleteTankMixProductV2(token, productId) {
  const response = await client.delete(
    `/v2/config/tank-mixes/products/${productId}`,
    addToken(token)
  );
  return response.data;
}

async function createTankMixV2(token, tankMix) {
  const response = await client.post(
    `/v2/config/tank-mixes/tank-mixes`,
    tankMix,
    addToken(token)
  );
  return response.data;
}

async function updateTankMixV2(token, tankMixId, tankMix) {
  const response = await client.patch(
    `/v2/config/tank-mixes/tank-mixes/${tankMixId}`,
    tankMix,
    addToken(token)
  );
  return response.data;
}

async function deleteTankMixV2(token, tankMixId) {
  const response = await client.delete(
    `/v2/config/tank-mixes/tank-mixes/${tankMixId}`,
    addToken(token)
  );
  return response.data;
}
