import { useCallback, useMemo, useState } from 'react';

import { useDispatch } from 'react-redux';

import {
  reportsChartsActions,
  useReportsChartsSelectors
} from '../../../../_store/slices/reports/reportsChartsSlice';

import { useGrowthModelReportsRows } from './useGrowthModelReportsRows';
import { useVisibleRows } from './useVisibleRows';

export function useReportsChartsControls(response) {
  const dispatch = useDispatch();

  const { showChart } = useReportsChartsSelectors();

  const [visibleRowsIds, setVisibleRowsIds] = useState([]);

  const { rows } = useGrowthModelReportsRows(response);

  const { visibleRows } = useVisibleRows(rows, visibleRowsIds);

  const handleVisibleRowIdsChange = useCallback((rowsIds) => {
    setVisibleRowsIds(rowsIds);
  }, []);

  const handleChartToggle = useCallback(() => {
    dispatch(reportsChartsActions.toggleShowChart());
  }, [dispatch]);

  return useMemo(
    () => ({
      showChart,
      toggleChart: handleChartToggle,
      visibleRows,
      handleVisibleRowIdsChange
    }),
    [handleChartToggle, handleVisibleRowIdsChange, showChart, visibleRows]
  );
}
