import React, { useContext, useMemo } from 'react';

import _ from 'lodash';
import { Navigate } from 'react-router-dom';

import { AuthContext } from '../../../_context/AuthContext';
import { usePage } from '../../../_hooks/Pages/usePage';

const RouteWrapper = ({ children }) => {
  const { hasRole, isActive, tosAccepted } = useContext(AuthContext);

  const { page } = usePage();

  const allowedRoles = _.get(page, 'allowedRoles');

  const isAllowed = useMemo(
    () =>
      _.isEmpty(allowedRoles) ||
      (_.some(allowedRoles, (role) => hasRole(role)) &&
        isActive &&
        tosAccepted),
    [allowedRoles, hasRole, isActive, tosAccepted]
  );

  return isAllowed ? <>{children}</> : <Navigate to={{ pathname: '/' }} />;
};

export default RouteWrapper;
