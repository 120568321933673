import { useMemo } from 'react';

import { tankMixesConfigService } from '../../../../services/tankMixesConfig.service';
import { createApiSlice } from '../../utils/createApiSlice';

const getTankMixesConfigSlice = createApiSlice({
  reducerPath: ['newConfig', 'tankMixes', 'getConfig'],
  apiFunction: tankMixesConfigService.getTankMixesConfigV2,
  apiArgumentsFn: () => []
});

const { actions, useApiSelectors } = getTankMixesConfigSlice;

export const getTankMixesConfigActions = actions;

export const useGetTankMixesConfigSelectors = () => {
  const { inProgress, success, errorMessage, response, updatedAt } =
    useApiSelectors();

  return useMemo(
    () => ({ inProgress, success, errorMessage, response, updatedAt }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default getTankMixesConfigSlice;
