import { addToken, client } from './client';

export const farmWorkOrdersService = {
  createFarmWorkOrderV2,
  updateFarmWorkOrderV2,
  deleteFarmWorkOrderV2,
  addFarmWorkOrderNoteV2
};

async function createFarmWorkOrderV2(
  token,
  growerId,
  seasonId,
  farmId,
  workOrder
) {
  const response = await client.post(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/work-orders`,
    workOrder,
    addToken(token)
  );
  return response.data;
}

async function updateFarmWorkOrderV2(
  token,
  growerId,
  seasonId,
  farmId,
  workOrderId,
  workOrder
) {
  const response = await client.put(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/work-orders/${workOrderId}`,
    workOrder,
    addToken(token)
  );
  return response.data;
}

async function deleteFarmWorkOrderV2(
  token,
  growerId,
  seasonId,
  farmId,
  workOrderId
) {
  await client.delete(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/work-orders/${workOrderId}`,
    addToken(token)
  );
}

async function addFarmWorkOrderNoteV2(
  token,
  growerId,
  seasonId,
  farmId,
  workOrderId,
  note
) {
  const response = await client.post(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/work-orders/${workOrderId}/notes`,
    note,
    addToken(token)
  );
  return response.data;
}
