import React from 'react';

import { growthStagesTypes } from '../../../_constants/growthsConstants';

import GrowthStageReportChart from './GrowthStageReportChart';

function HarvestReportChart({ fields }) {
  return (
    <GrowthStageReportChart
      growthStage={growthStagesTypes.Harvest}
      fields={fields}
    />
  );
}

export default HarvestReportChart;
