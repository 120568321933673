import { useMemo } from 'react';

import _ from 'lodash';

export function useGrowthModelReportsRows(response) {
  const rows = useMemo(
    () =>
      _.chain(response)
        .get('fields')
        .map((field) => {
          const growthStages = _.chain(field)
            .get('growthStages')
            .keyBy('growthStage')
            .value();
          return {
            id: _.get(field, 'field.fieldId'),
            ..._.get(field, 'field', {}),
            ..._.get(field, 'variety', {}),
            activitiesV2: _.get(field, 'activitiesV2'),
            growthStages,
            estimatedCurrentGrowthStage: _.get(
              field,
              'estimatedCurrentGrowthStage'
            ),
            yieldBushelsPerAcre: _.get(field, 'yieldBushelsPerAcre')
          };
        })
        .value(),
    [response]
  );

  return useMemo(
    () => ({
      rows
    }),
    [rows]
  );
}
