import createActivityFeedbackSlice from './createActivityFeedbackSlice';
import deleteActivityFeedbackSlice from './deleteActivityFeedbackSlice';
import updateActivityFeedbackSlice from './updateActivityFeedbackSlice';

const growthActivitiesSlices = [
  createActivityFeedbackSlice,
  updateActivityFeedbackSlice,
  deleteActivityFeedbackSlice
];

export default growthActivitiesSlices;
