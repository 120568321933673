import React, { useCallback, useMemo } from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { updatePumpSystemNotificationConfig } from '../../../_store/actions/notificationsConfigActions';
import {
  ContentBody,
  ContentContainer,
  ResponsiveContentHeader
} from '../../../Layout/Content';

import NotificationChannels from './NotificationChannels';

const useStyles = makeStyles(() => ({
  body: {
    maxWidth: '32rem'
  },
  growerLine: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '0.5rem'
  },
  growerName: {
    fontWeight: '500',
    marginRight: '1rem',
    marginBottom: '0.5rem'
  },
  pumpName: {
    fontWeight: '500',
    marginRight: '1rem',
    marginLeft: '1.5rem',
    marginBottom: '0.5rem'
  },
  pumpLine: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

const PumpNotificationsConfig = ({ pump, onChange }) => {
  const styles = useStyles();

  const pumpId = _.get(pump, 'id');
  const seasonId = _.get(pump, 'seasonId');
  const channelsConfig = _.get(pump, 'notificationsConfig');

  const handleChange = useCallback(
    (channel, checked) => {
      onChange(seasonId, pumpId, channel, checked);
    },
    [onChange, seasonId, pumpId]
  );

  return (
    <Box className={styles.pumpLine}>
      <Typography className={styles.pumpName}>{_.get(pump, 'name')}</Typography>
      <NotificationChannels channels={channelsConfig} onChange={handleChange} />
    </Box>
  );
};

const GrowerNotificationsConfig = ({ grower }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const growerId = _.get(grower, 'id');
  const name = _.get(grower, 'name');
  const pumps = _.get(grower, 'pumps');

  const handleChange = useCallback(
    (seasonId, pumpId, channel, checked) => {
      dispatch(
        updatePumpSystemNotificationConfig(
          growerId,
          seasonId,
          pumpId,
          channel,
          checked
        )
      );
    },
    [dispatch, growerId]
  );

  return (
    <Box className={styles.growerLine}>
      <Typography className={styles.growerName}>{name}</Typography>
      {_.map(pumps, (pump) => (
        <PumpNotificationsConfig
          key={_.get(pump, 'id')}
          pump={pump}
          onChange={handleChange}
        />
      ))}
    </Box>
  );
};

export default function PumpSystemsNotificationsConfig({
  notificationsConfig,
  growers
}) {
  const styles = useStyles();

  const growersWithConfig = useMemo(
    () =>
      _.map(growers, (grower) => {
        const activeSeason = _.chain(grower)
          .get('seasons')
          .find((season) => _.get(season, 'isActive'))
          .value();
        const activeSeasonId = _.get(activeSeason, 'id');

        const growerNotificationsConfig = _.get(notificationsConfig, [
          _.get(grower, 'id'),
          activeSeasonId
        ]);
        return {
          ...grower,
          pumps: _.chain(grower)
            .get('pumps')
            .map((pump) => {
              const pumpNotificationsConfig = _.get(growerNotificationsConfig, [
                _.get(pump, 'id')
              ]);
              return {
                ...pump,
                seasonId: activeSeasonId,
                notificationsConfig: pumpNotificationsConfig
              };
            })
            .value()
        };
      }),
    [growers, notificationsConfig]
  );

  return (
    <ContentContainer className={styles.body}>
      <ResponsiveContentHeader>Pump Systems</ResponsiveContentHeader>
      <ContentBody>
        {_.map(growersWithConfig, (grower) => (
          <GrowerNotificationsConfig
            key={_.get(grower, 'id')}
            grower={grower}
          />
        ))}
      </ContentBody>
    </ContentContainer>
  );
}
