import React, { useCallback, useEffect, useMemo } from 'react';

import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import {
  createTankMixProductActions,
  useCreateTankMixProductSelectors
} from '../../../../_store/slices/config/tankMixes/createTankMixProductSlice';
import { getTankMixesConfigActions } from '../../../../_store/slices/config/tankMixes/getTankMixesConfigSlice';
import {
  updateTankMixProductActions,
  useUpdateTankMixProductSelectors
} from '../../../../_store/slices/config/tankMixes/updateTankMixProductSlice';
import { TankMixProductType } from '../../../TaknMixDemo/Components/TankMixProductType';
import { TankMixUnit } from '../../../TaknMixDemo/Components/TankMixUnit';

const EditTankMixProductForm = ({ product, onCancel }) => {
  const dispatch = useDispatch();

  const defaultUnitId = _.chain(TankMixUnit)
    .values()
    .first()
    .get('key')
    .value();

  const { control, register, errors, handleSubmit, watch } = useForm({
    defaultValues: product
      ? _.pick(product, [
          'name',
          'manufacturer',
          'activeConstituent',
          'type',
          'minRate',
          'maxRate',
          'unit'
        ])
      : {
          name: '',
          manufacturer: '',
          activeConstituent: '',
          type: 'Herbicide',
          unit: defaultUnitId
        }
  });

  const unitId = watch('unit');

  const unit = useMemo(() => _.get(TankMixUnit, [unitId]), [unitId]);
  const unitName = _.get(unit, 'namePerAc');

  const {
    errorMessage: createErrorMessage,
    inProgress: createInProgress,
    success: createSuccess
  } = useCreateTankMixProductSelectors();

  const {
    errorMessage: updateErrorMessage,
    inProgress: updateInProgress,
    success: updateSuccess
  } = useUpdateTankMixProductSelectors();

  const inProgress = createInProgress || updateInProgress;
  const errorMessage = createErrorMessage || updateErrorMessage;

  const isEdit = !!product;

  useEffect(() => {
    if (createSuccess || updateSuccess) {
      dispatch(createTankMixProductActions.clear());
      dispatch(updateTankMixProductActions.clear());
      dispatch(getTankMixesConfigActions.submit({}));
      onCancel();
    }
  }, [createSuccess, dispatch, onCancel, updateSuccess]);

  const handleFormSubmit = useCallback(
    (formData) => {
      if (isEdit) {
        dispatch(
          updateTankMixProductActions.submit({
            productId: _.get(product, 'id'),
            product: formData
          })
        );
      } else {
        dispatch(createTankMixProductActions.submit({ product: formData }));
      }
    },
    [dispatch, isEdit, product]
  );

  const handleCancel = useCallback(() => {
    dispatch(createTankMixProductActions.clear());
    dispatch(updateTankMixProductActions.clear());
    onCancel();
  }, [dispatch, onCancel]);

  return (
    <form noValidate onSubmit={handleSubmit(handleFormSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            inputRef={register({
              required: 'Name is required'
            })}
            disabled={inProgress}
            required
            fullWidth
            variant="outlined"
            id="name"
            label="Name"
            name="name"
            error={!!_.get(errors, 'name')}
            helperText={_.get(errors, `name.message`)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputRef={register({
              required: 'Manufacturer is required'
            })}
            disabled={inProgress}
            required
            fullWidth
            variant="outlined"
            id="manufacturer"
            label="Manufacturer"
            name="manufacturer"
            error={!!_.get(errors, 'manufacturer')}
            helperText={_.get(errors, `manufacturer.message`)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputRef={register({
              required: 'Active Ingredient is required'
            })}
            disabled={inProgress}
            required
            fullWidth
            variant="outlined"
            id="activeConstituent"
            label="Active Ingredient"
            name="activeConstituent"
            error={!!_.get(errors, 'activeConstituent')}
            helperText={_.get(errors, `activeConstituent.message`)}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="type"
            id="type"
            defaultValue="Herbicide"
            rules={{ required: true }}
            render={({ onChange, value }) => (
              <FormControl fullWidth>
                <InputLabel>Type</InputLabel>
                <Select
                  disabled={inProgress}
                  fullWidth
                  variant="outlined"
                  value={value || null}
                  onChange={(e) => onChange(e.target.value)}
                >
                  {_.map(_.values(TankMixProductType), (type) => (
                    <MenuItem
                      key={_.get(type, 'key')}
                      value={_.get(type, 'key')}
                    >
                      {_.get(type, 'name')}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            id="unit"
            name="unit"
            defaultValue={defaultUnitId}
            rules={{ required: true }}
            render={({ onChange, value }) => (
              <FormControl fullWidth>
                <InputLabel>Unit</InputLabel>
                <Select
                  variant="outlined"
                  disabled={inProgress}
                  value={value || null}
                  onChange={(e) => onChange(e.target.value)}
                >
                  {_.map(_.values(TankMixUnit), (unitConfig) => (
                    <MenuItem
                      key={_.get(unitConfig, 'key')}
                      value={_.get(unitConfig, 'key')}
                    >
                      {_.get(unitConfig, 'namePerAc')}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputRef={register({
              valueAsNumber: true,
              required: 'Min Rate is required'
            })}
            disabled={inProgress}
            required
            fullWidth
            variant="outlined"
            type="number"
            id="minRate"
            label="Min Rate"
            name="minRate"
            error={!!_.get(errors, 'minRate')}
            helperText={_.get(errors, `minRate.message`)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{unitName}</InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputRef={register({
              valueAsNumber: true,
              required: 'Max Rate is required'
            })}
            disabled={inProgress}
            required
            fullWidth
            variant="outlined"
            type="number"
            id="maxRate"
            label="Max Rate"
            name="maxRate"
            error={!!_.get(errors, 'maxRate')}
            helperText={_.get(errors, `maxRate.message`)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{unitName}</InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {errorMessage && (
            <FormHelperText error>{errorMessage}</FormHelperText>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          {inProgress && <CircularProgress size={30} />}
          <Button
            style={{ marginRight: '0.5rem', marginLeft: '0.5rem' }}
            variant="contained"
            color="default"
            disabled={inProgress}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={inProgress}
          >
            {isEdit ? 'Update' : 'Create'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditTankMixProductForm;
