import React, { useCallback } from 'react';

import { IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const DeleteButton = ({ title, protocolId, onClick, disabled }) => {
  const handleClick = useCallback(() => {
    onClick(protocolId);
  }, [onClick, protocolId]);

  return (
    <Tooltip title={title} placement="top">
      <IconButton onClick={handleClick} disabled={disabled}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
};

export default DeleteButton;
