import { useMemo } from 'react';

import { seasonService } from '../../../../services';
import { createApiSlice } from '../../utils/createApiSlice';

const updateManagementZoneSlice = createApiSlice({
  reducerPath: ['newGrowths', 'managementZones', 'updateManagementZone'],
  apiFunction: seasonService.updateManagementZoneV2
});

const { actions, useApiSelectors } = updateManagementZoneSlice;

export const updateManagementZoneActions = actions;

export const useUpdateManagementZoneSelectors = () => {
  const { inProgress, errorMessage, success, updatedAt, response } =
    useApiSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      updatedAt,
      response
    }),
    [errorMessage, inProgress, success, updatedAt, response]
  );
};

export default updateManagementZoneSlice;
