import { useMemo } from 'react';

import { faChartLine } from '@fortawesome/free-solid-svg-icons';

import { userRoles } from '../../_constants/userConstants';

import { UsageStatisticsTabs } from './UsageStatisticsPage';

const UsageStatisticsPageKey = 'USAGE_STATISTICS_PAGE';

const UsageStatisticsSubpageKey = {
  Growers: `${UsageStatisticsPageKey}_GROWERS`,
  Farms: `${UsageStatisticsPageKey}_FARMS`,
  Fields: `${UsageStatisticsPageKey}_FIELDS`,
  SeasonFields: `${UsageStatisticsPageKey}_SEASON_FIELDS`,
  SeasonFeedback: `${UsageStatisticsPageKey}_SEASON_FEEDBACK`,
  UsersPageViews: `${UsageStatisticsPageKey}_USERS_PAGE_VIEWS`,
  PageViews: `${UsageStatisticsPageKey}_PAGE_VIEWS`
};

const baseConfig = {
  key: UsageStatisticsPageKey,
  icon: faChartLine,
  label: 'Usage Statistics',
  path: 'statistics',
  allowedRoles: [userRoles.Admin],
  subPages: [
    {
      key: UsageStatisticsSubpageKey.Growers,
      label: 'Growers',
      path: UsageStatisticsTabs.growers.id
    },
    {
      key: UsageStatisticsSubpageKey.Farms,
      label: 'Farms',
      path: UsageStatisticsTabs.farms.id
    },
    {
      key: UsageStatisticsSubpageKey.Fields,
      label: 'Fields',
      path: UsageStatisticsTabs.fields.id
    },
    {
      key: UsageStatisticsSubpageKey.SeasonFields,
      label: 'Season Fields',
      path: UsageStatisticsTabs['season-fields'].id
    },
    {
      key: UsageStatisticsSubpageKey.SeasonFeedback,
      label: 'Season Feedback',
      path: UsageStatisticsTabs['season-feedback'].id
    },
    {
      key: UsageStatisticsSubpageKey.UsersPageViews,
      label: 'Users Page Views',
      path: UsageStatisticsTabs['users-page-views'].id
    },
    {
      key: UsageStatisticsSubpageKey.PageViews,
      label: 'Page Views',
      path: UsageStatisticsTabs['page-views'].id
    }
  ]
};

export const useUsageStatisticsPageConfig = () => {
  return useMemo(() => ({ config: baseConfig }), []);
};
