import React from 'react';

import { DialogTitle } from '@material-ui/core';
import _ from 'lodash';

import ResponsiveDialog from '../ResponsiveDialog';

import AddWorkOrderNoteForm from './AddWorkOrderNoteForm';

const AddWorkOrderNoteDialog = ({ workOrder, onClose }) => {
  const isOpen = !!workOrder;

  return (
    <ResponsiveDialog open={isOpen} onClose={onClose} maxWidth="sm">
      <DialogTitle>{_.get(workOrder, 'name')} - Add Note</DialogTitle>
      {workOrder && (
        <AddWorkOrderNoteForm workOrder={workOrder} onClose={onClose} />
      )}
    </ResponsiveDialog>
  );
};

export default AddWorkOrderNoteDialog;
