import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  outer: {
    display: 'flex',
    minHeight: '20rem',
    height: `calc(100vh - ${theme.dimensions.headerHeight} - 17rem)`
  },
  inner: {
    flexGrow: 1
  }
}));

const DataGridContainer = ({ children }) => {
  const styles = useStyles();

  return (
    <div className={styles.outer}>
      <div className={styles.inner}>{children}</div>
    </div>
  );
};

export default DataGridContainer;
