import React from 'react';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { Link as RouterLink } from 'react-router-dom';

import {
  ContentBody,
  FormContainer,
  ResponsiveContentHeader
} from '../../../Layout/Content';
import { ApplicationConfigurationTabsIds } from '../ApplicationConfigurationPage';

import BundlesList from './Bundles/BundlesList';

const ProtocolBundlesConfigContent = () => {
  return (
    <FormContainer>
      <ResponsiveContentHeader>
        <span>Protocol Bundles</span>
        <Button
          size="small"
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          component={RouterLink}
          to={`/application-config/${ApplicationConfigurationTabsIds['protocol-bundles']}/new`}
        >
          Add Bundle
        </Button>
      </ResponsiveContentHeader>
      <ContentBody>
        <BundlesList />
      </ContentBody>
    </FormContainer>
  );
};

export default ProtocolBundlesConfigContent;
