import React, { useMemo } from 'react';

import { Accordion } from '@material-ui/core';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import moment from 'moment/moment';

import { ZonedActivityTypes } from '../../_constants/activitiesConstants';
import GrowthStageBadge from '../../Components/Growths/GrowthStageBadge';
import { AccordionDataRow } from '../Configuration/AccordionDataRow';

import FeedbackTargets from './FeedbackTargets';

const useStyles = makeStyles(() => ({
  feedbackTarget: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1rem'
  },
  targetsHeader: {
    fontSize: '0.9rem',
    fontWeight: 600,
    marginBottom: '0.25rem'
  }
}));

const AccordionContent = ({ feedback }) => {
  const styles = useStyles();
  const inline = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const targets = _.chain(feedback).get('targetsByFieldId').values().value();

  const date = useMemo(() => {
    const date = _.get(feedback, 'date');
    return !!date ? moment(date).format('lll') : null;
  }, [feedback]);

  const dateCreated = useMemo(() => {
    const dateCreated = _.get(feedback, 'dateCreated');
    return !!dateCreated ? moment(dateCreated).format('lll') : null;
  }, [feedback]);

  return (
    <Grid container>
      {!_.isEmpty(_.get(feedback, 'description')) && (
        <Grid item xs={12}>
          <AccordionDataRow
            title="Description"
            text={_.get(feedback, 'description')}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <AccordionDataRow title="Date" text={date} inline={inline} />
      </Grid>
      <Grid item xs={12}>
        <AccordionDataRow
          title="Date Created"
          text={dateCreated}
          inline={inline}
        />
      </Grid>
      <Grid item xs={12}>
        <AccordionDataRow
          title="Created By"
          text={_.get(feedback, 'user.userName')}
          inline={inline}
        />
      </Grid>
      <Grid item xs={12}>
        <AccordionDataRow
          title="Photos"
          text={_.get(feedback, 'photos.length')}
          inline={inline}
        />
      </Grid>
      <Grid item xs={12} className={styles.feedbackTarget}>
        <Typography className={styles.targetsHeader}>Fields / Zones</Typography>
        <FeedbackTargets targets={targets} />
      </Grid>
    </Grid>
  );
};

const useHeaderStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

const AccordionHeader = ({ feedback }) => {
  const styles = useHeaderStyles();

  const type = _.get(feedback, 'type');
  const growthStage = _.get(feedback, 'stage');

  return (
    <Box className={styles.header}>
      {(type === ZonedActivityTypes.CustomNote ||
        type === ZonedActivityTypes.ZonedWorkOrderCompletion) && (
        <Typography>{_.get(feedback, 'name')}</Typography>
      )}
      {type === ZonedActivityTypes.GrowthStage && (
        <GrowthStageBadge growthStage={growthStage} small />
      )}
    </Box>
  );
};

const ZonedActivitiesAccordion = ({ feedbackItems }) => {
  return (
    <>
      {_.map(feedbackItems, (feedback) => (
        <Accordion key={_.get(feedback, 'id')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id={`${_.get(feedback, 'id')}-header`}
          >
            <AccordionHeader feedback={feedback} />
          </AccordionSummary>
          <AccordionDetails>
            <AccordionContent feedback={feedback} />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default ZonedActivitiesAccordion;
