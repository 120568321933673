import { useMemo } from 'react';

import { growerBundlesConfigService } from '../../../../../services/growerBundlesConfig.service';
import { createApiSlice } from '../../../utils/createApiSlice';

const updateGrowerBundleSlice = createApiSlice({
  reducerPath: ['gff', 'growers', 'bundles', 'updateGrowerBundle'],
  apiFunction: growerBundlesConfigService.updateBundleV2,
  apiArgumentsFn: ({ growerId, bundleId, bundle }) => [
    growerId,
    bundleId,
    bundle
  ]
});

const { actions, useApiSelectors } = updateGrowerBundleSlice;

export const updateGrowerBundleActions = actions;

export const useUpdateGrowerBundleSelectors = () => {
  const { inProgress, success, errorMessage, response, updatedAt } =
    useApiSelectors();

  return useMemo(
    () => ({ inProgress, success, errorMessage, response, updatedAt }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default updateGrowerBundleSlice;
