import React, { createContext, useMemo } from 'react';

import _ from 'lodash';

import { buildPath } from '../_helpers/buildPath';
import { usePathname } from '../_hooks/usePathname';
import { useActivitiesEnginePageConfig } from '../Pages/ActivitiesEngine/useActivitiesEnginePageConfig';
import { useApplicationConfigurationPageConfig } from '../Pages/ApplicationConfiguration/useApplicationConfigurationPageConfig';
import { useAquariusPageConfig } from '../Pages/Aquarius/useAquariusPageConfig';
import { useCropManagementPageConfig } from '../Pages/CropManagement/useCropManagementPageConfig';
import { useDashboardPageConfig } from '../Pages/Dashboard/useDashboardPageConfig';
import { useAddUserFeedbackPageConfig } from '../Pages/Feedback/useAddUserFeedbackPageConfig';
import { useUserFeedbackPageConfig } from '../Pages/Feedback/useUserFeedbackPageConfig';
import { useGrowersConfigPageConfig } from '../Pages/GrowersConfig/useGrowersConfigPageConfig';
import { useGrowthModelApiAdminPageConfig } from '../Pages/GrowthModelApiAdmin/useGrowthModelApiAdminPageConfig';
import { useIrrigationDashboardPageConfig } from '../Pages/IrrigationDashboard/useIrrigationDashboardPageConfig';
import { useMapPageConfig } from '../Pages/Map/useMapPageConfig';
import { useNotificationsConfigPageConfig } from '../Pages/NotificationsConfig/useNotificationsConfigPageConfig';
import { useReportsPageConfig } from '../Pages/Reports/useReportsPageConfig';
import { useSeasonsConfigPageConfig } from '../Pages/SeasonsConfig/useSeasonsConfigPageConfig';
import { useShapefilePageConfig } from '../Pages/ShapefileDemo/useShapefilePageConfig';
import { useUsageStatisticsPageConfig } from '../Pages/Statistics/useUsageStatisticsPageConfig';
import { useTankMixPageConfig } from '../Pages/TaknMixDemo/useTankMixDemoPageConfig';
import { useUserAdminPageConfig } from '../Pages/UserAdmin/useUserAdminPageConfig';
import { useWeatherDashboardPageConfig } from '../Pages/WeatherDashboard/useWeatherDashboardPageConfig';
import { useYieldMapPageConfig } from '../Pages/YieldMap/useYieldMapPageConfig';

export const PagesConfigContext = createContext({});

export const PagesConfigProvider = ({ children }) => {
  const { pathname } = usePathname();

  const { config: dashboardPageConfig } = useDashboardPageConfig();
  const { config: mapPageConfig } = useMapPageConfig();
  const { config: irrigationDashboardPageConfig } =
    useIrrigationDashboardPageConfig();
  const { config: cropManagementPageConfig } = useCropManagementPageConfig();
  const { config: weatherDashboardPageConfig } =
    useWeatherDashboardPageConfig();
  const { config: notificationsConfigPageConfig } =
    useNotificationsConfigPageConfig();
  const { config: reportsPageConfig } = useReportsPageConfig();
  const { config: yieldMapPageConfig } = useYieldMapPageConfig();
  const { config: shapefilePageConfig } = useShapefilePageConfig();
  const { config: tankMixDemoPageConfig } = useTankMixPageConfig();
  const { config: userAdminPageConfig } = useUserAdminPageConfig();
  const { config: growersConfigPageConfig } = useGrowersConfigPageConfig();
  const { config: seasonsConfigPageConfig } = useSeasonsConfigPageConfig();
  const { config: applicationConfigurationPageConfig } =
    useApplicationConfigurationPageConfig();
  const { config: userFeedbackPageConfig } = useUserFeedbackPageConfig();
  const { config: aquariusPageConfig } = useAquariusPageConfig();
  const { config: usageStatisticsPageConfig } = useUsageStatisticsPageConfig();
  const { config: activitiesEnginePageConfig } =
    useActivitiesEnginePageConfig();
  const { config: addUserFeedbackPageConfig } = useAddUserFeedbackPageConfig();
  const { config: growthModelApiAdminPageConfig } =
    useGrowthModelApiAdminPageConfig();

  const configs = useMemo(
    () => [
      mapPageConfig,
      dashboardPageConfig,
      irrigationDashboardPageConfig,
      cropManagementPageConfig,
      weatherDashboardPageConfig,
      notificationsConfigPageConfig,
      reportsPageConfig,
      yieldMapPageConfig,
      shapefilePageConfig,
      tankMixDemoPageConfig,
      userAdminPageConfig,
      growersConfigPageConfig,
      seasonsConfigPageConfig,
      applicationConfigurationPageConfig,
      userFeedbackPageConfig,
      aquariusPageConfig,
      usageStatisticsPageConfig,
      activitiesEnginePageConfig,
      addUserFeedbackPageConfig,
      growthModelApiAdminPageConfig
    ],
    [
      activitiesEnginePageConfig,
      addUserFeedbackPageConfig,
      applicationConfigurationPageConfig,
      aquariusPageConfig,
      cropManagementPageConfig,
      dashboardPageConfig,
      growersConfigPageConfig,
      growthModelApiAdminPageConfig,
      irrigationDashboardPageConfig,
      mapPageConfig,
      notificationsConfigPageConfig,
      reportsPageConfig,
      seasonsConfigPageConfig,
      shapefilePageConfig,
      tankMixDemoPageConfig,
      usageStatisticsPageConfig,
      userAdminPageConfig,
      userFeedbackPageConfig,
      weatherDashboardPageConfig,
      yieldMapPageConfig
    ]
  );

  const pagesConfig = useMemo(
    () =>
      _.map(configs, (config) => ({
        ...config,
        path: buildPath('/', _.get(config, 'path')),
        subPages: _.chain(config)
          .get('subPages')
          .map((subPage) => ({
            ...subPage,
            absolutePath: buildPath(
              '/',
              _.get(config, 'path'),
              _.get(subPage, 'path')
            )
          }))
          .value()
      })),
    [configs]
  );

  const currentPage = useMemo(
    () =>
      _.find(
        pagesConfig,
        (item) => !!pathname && pathname.startsWith(_.get(item, 'path'))
      ),
    [pagesConfig, pathname]
  );

  const currentSubPage = useMemo(
    () =>
      _.chain(currentPage)
        .get('subPages', [])
        .find(
          (subPage) =>
            !!pathname && pathname.startsWith(_.get(subPage, 'absolutePath'))
        )
        .value(),
    [currentPage, pathname]
  );

  const context = useMemo(
    () => ({ pagesConfig, currentPage, currentSubPage }),
    [currentPage, currentSubPage, pagesConfig]
  );

  return (
    <PagesConfigContext.Provider value={context}>
      {children}
    </PagesConfigContext.Provider>
  );
};
