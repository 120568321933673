import { useMemo } from 'react';

import { bundlesConfigService } from '../../../../services/bundlesConfig.service';
import { createApiSlice } from '../../utils/createApiSlice';

const setDefaultBundleSlice = createApiSlice({
  reducerPath: ['newConfig', 'bundles', 'setDefaultBundle'],
  apiFunction: bundlesConfigService.setDefaultBundleV2,
  apiArgumentsFn: ({ bundleId }) => [bundleId]
});

const { actions, useApiSelectors } = setDefaultBundleSlice;

export const setDefaultBundleActions = actions;

export const useSetDefaultBundleSelectors = () => {
  const { inProgress, success, errorMessage, response, setDefaultdAt } =
    useApiSelectors();

  return useMemo(
    () => ({ inProgress, success, errorMessage, response, setDefaultdAt }),
    [errorMessage, inProgress, response, success, setDefaultdAt]
  );
};

export default setDefaultBundleSlice;
