import { useContext, useMemo } from 'react';

import { faCog } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

import { userRoles } from '../../_constants/userConstants';
import { AuthContext } from '../../_context/AuthContext';

import { CropManagementTabsIds } from './CropManagementPage';

const CropManagementPageKey = 'CROP_MANAGEMENT_PAGE';

export const CropManagementPageSubpageKeys = {
  Growths: `${CropManagementPageKey}_GROWTHS`,
  GrowerProtocolBundles: `${CropManagementPageKey}_GROWER_PROTOCOL_BUNDLES`
};

const baseConfig = {
  key: CropManagementPageKey,
  icon: faCog,
  label: 'Crop Management',
  path: 'crop-management',
  allowedRoles: [
    userRoles.Admin,
    userRoles.Consultant,
    userRoles.User,
    userRoles.UserView
  ],
  subPages: [
    {
      key: CropManagementPageSubpageKeys.Growths,
      label: 'Growths',
      path: CropManagementTabsIds.growths
    },
    {
      key: CropManagementPageSubpageKeys.GrowerProtocolBundles,
      label: 'Grower Protocol Bundles',
      path: CropManagementTabsIds['grower-bundles']
    }
  ]
};
export const useCropManagementPageConfig = () => {
  const { isAdmin, isConsultant } = useContext(AuthContext);

  const config = useMemo(
    () => ({
      ...baseConfig,
      subPages: _.chain(baseConfig)
        .get('subPages')
        .filter((subPage) => {
          const key = _.get(subPage, 'key');
          if (key === CropManagementPageSubpageKeys.GrowerProtocolBundles) {
            return isAdmin || isConsultant;
          }
          return true;
        })
        .value()
    }),
    [isAdmin, isConsultant]
  );

  return useMemo(() => ({ config }), [config]);
};
