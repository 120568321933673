import React, { useContext } from 'react';

import _ from 'lodash';

import { FarmStructureContext } from '../../_context/FarmStructureContext';
import {
  ContentBody,
  ContentContainer,
  ResponsiveContentHeader
} from '../../Layout/Content';

import { useAllActivities } from './_hooks/useAllActivities';
import CompletedActivitiesCsvDownload from './CompletedActivitiesCsvDownload';
import FarmCompletedActivities from './FarmCompletedActivities';
import FieldCompletedActivities from './FieldCompletedActivities';

const CompletedActivitiesSection = () => {
  const { allActivities } = useAllActivities();

  const { selectedField } = useContext(FarmStructureContext);

  return (
    <ContentContainer>
      <ResponsiveContentHeader>
        {selectedField
          ? `Completed Activities - ${_.get(selectedField, 'name')}`
          : 'Completed Activities'}
        <CompletedActivitiesCsvDownload allActivities={allActivities} />
      </ResponsiveContentHeader>
      <ContentBody>
        {!selectedField && (
          <FarmCompletedActivities allActivities={allActivities} />
        )}
        {!!selectedField && (
          <FieldCompletedActivities allActivities={allActivities} />
        )}
      </ContentBody>
    </ContentContainer>
  );
};

export default CompletedActivitiesSection;
