import React, { useContext } from 'react';

import { Alert } from '@material-ui/lab';

import { FarmStructureContext } from '../../_context/FarmStructureContext';
import { useSubPage } from '../../_hooks/Pages/useSubpage';
import LoadingIndicator from '../../Components/LoadingIndicator';
import AppPage from '../../Layout/AppPage';
import { ContentBody, FormContainer } from '../../Layout/Content';
import PageTitle from '../../Layout/PageTitle';

import GrowerBundlesTab from './Components/GrowerBundlesTab';
import GrowthsTab from './Components/GrowthsTab';
import { CropManagementPageSubpageKeys } from './useCropManagementPageConfig';

export const CropManagementTabsIds = {
  growths: 'growths',
  'grower-bundles': 'grower-bundles'
};

const CropManagementPage = () => {
  const { selectedGrower, selectedGrowerId, loading } =
    useContext(FarmStructureContext);

  const { subPageKey } = useSubPage();

  return (
    <AppPage header={<PageTitle />}>
      {loading && <LoadingIndicator />}
      {!loading && (
        <>
          {!selectedGrowerId && (
            <FormContainer>
              <ContentBody>
                <Alert severity="info">Please select a grower</Alert>
              </ContentBody>
            </FormContainer>
          )}
          {selectedGrower && (
            <>
              {subPageKey ===
                CropManagementPageSubpageKeys.GrowerProtocolBundles && (
                <GrowerBundlesTab selectedGrower={selectedGrower} />
              )}
              {subPageKey === CropManagementPageSubpageKeys.Growths && (
                <GrowthsTab />
              )}
            </>
          )}
        </>
      )}
    </AppPage>
  );
};

export default CropManagementPage;
