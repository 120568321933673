import React from 'react';

import { DialogTitle } from '@material-ui/core';
import _ from 'lodash';

import ResponsiveDialog from '../ResponsiveDialog';
import ResponsiveDialogContent from '../ResponsiveDialogContent';

import EditFarmWorkOrderDialogContent from './EditFarmWorkOrderDialogContent';

const EditFarmWorkOrderDialog = ({ workOrder, onClose }) => {
  const isOpen = !!workOrder;

  return (
    <ResponsiveDialog open={isOpen} onClose={onClose} maxWidth="sm">
      <DialogTitle>Edit {_.get(workOrder, 'name')} Work Order</DialogTitle>
      <ResponsiveDialogContent>
        {workOrder && (
          <EditFarmWorkOrderDialogContent
            workOrder={workOrder}
            onClose={onClose}
          />
        )}
      </ResponsiveDialogContent>
    </ResponsiveDialog>
  );
};

export default EditFarmWorkOrderDialog;
