import React, { useMemo } from 'react';

import _ from 'lodash';
import { Bar } from 'react-chartjs-2';

function HarvestYieldGroupedReportChart({
  chartRef,
  fields,
  groupByKey,
  groupName
}) {
  const chartData = useMemo(() => {
    const grouppedFields = _.groupBy(fields, groupByKey);
    const items = _.chain(grouppedFields)
      .mapValues((fields) => _.meanBy(fields, 'yieldBushelsPerAcre'))
      .map((averageYieldBushelsPerAcre, itemName) => ({
        averageYieldBushelsPerAcre,
        [groupByKey]: itemName
      }))
      .value();

    const labels = _.map(items, (item) => _.get(item, groupByKey));

    const datasets = [
      {
        label: 'Yield',
        data: _.map(items, (item) => _.get(item, 'averageYieldBushelsPerAcre')),
        type: 'bar',
        backgroundColor: '#039107'
      }
    ].filter((dataset) => !_.isEmpty(dataset.data));

    return {
      labels,
      datasets
    };
  }, [fields, groupByKey]);

  const options = useMemo(
    () => ({
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          text: `Yield by ${groupName}`,
          font: {
            size: 16
          }
        },
        legend: {
          display: false
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              const value = context.raw;
              if (!_.isNumber(value) || _.isNaN(value)) {
                return '-';
              }

              return ` ${value.toFixed(0)} acres`;
            }
          }
        }
      },
      scales: {
        'x-axis': {
          display: true,
          gridLines: {
            display: true
          },
          ticks: {
            autoSkip: true
          }
        },
        'y-axis': {
          title: {
            display: true,
            text: 'Yield (bu/ac)'
          },
          ticks: {
            precision: 0,
            beginAtZero: true
          }
        }
      }
    }),
    [groupName]
  );

  return <Bar ref={chartRef} data={chartData} type="bar" options={options} />;
}

export default HarvestYieldGroupedReportChart;
