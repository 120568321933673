import { useCallback, useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import { useDispatch } from 'react-redux';

import {
  pagingActions,
  usePagingSelectors
} from '../_store/slices/ui/pagingSlice';

function usePagingParams(key) {
  const dispatch = useDispatch();

  const [localPage, setLocalPage] = useState(0);
  const [localRowsPerPage, setLocalRowsPerPage] = useState(10);

  const { page: persistedPage, rowsPerPage: persistedRowsPerPage } =
    usePagingSelectors(key);

  const page = !!key ? persistedPage : localPage;
  const rowsPerPage = !!key ? persistedRowsPerPage : localRowsPerPage;

  const setPage = useCallback(
    (page) => {
      if (key) {
        dispatch(pagingActions.setPage({ key, page }));
        return;
      }
      setLocalPage(page);
    },
    [dispatch, key]
  );

  const setRowsPerPage = useCallback(
    (rowsPerPage) => {
      if (key) {
        dispatch(pagingActions.setRowsPerPage({ key, rowsPerPage }));
        return;
      }
      setLocalRowsPerPage(rowsPerPage);
    },
    [dispatch, key]
  );

  return useMemo(
    () => ({
      page,
      setPage,
      rowsPerPage,
      setRowsPerPage
    }),
    [page, rowsPerPage, setPage, setRowsPerPage]
  );
}

export function usePagination({ items, key }) {
  const { page, setPage, rowsPerPage, setRowsPerPage } = usePagingParams(key);

  const offset = page * rowsPerPage;

  const count = _.get(items, 'length', 0);

  const pagesCount = Math.floor(count / rowsPerPage);

  const pagedItems = useMemo(
    () =>
      _.chain(items)
        .map((item, index) => ({ ...item, index }))
        .drop(offset)
        .take(rowsPerPage)
        .value(),
    [items, offset, rowsPerPage]
  );

  useEffect(() => {
    if (offset > count) {
      setPage(0);
    }
  }, [count, offset, setPage]);

  const onPageChange = useCallback(
    (p) => {
      setPage(p);
    },
    [setPage]
  );

  const onRowsPerPageChange = useCallback(
    (rows) => {
      setRowsPerPage(rows);
    },
    [setRowsPerPage]
  );

  return useMemo(
    () => ({
      count,
      pagedItems,
      page,
      pagesCount,
      rowsPerPage,
      onPageChange,
      onRowsPerPageChange
    }),
    [
      count,
      onPageChange,
      onRowsPerPageChange,
      page,
      pagedItems,
      pagesCount,
      rowsPerPage
    ]
  );
}
