import React, { useContext, useEffect } from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { AuthContext } from '../../_context/AuthContext';
import { FarmStructureContext } from '../../_context/FarmStructureContext';
import { useSubPage } from '../../_hooks/Pages/useSubpage';
import {
  updateAllActiveGrowthsForFarmAction,
  updateAllActiveGrowthsForGrowerAction
} from '../../_store/actions/growthsActions';
import { useSeasonWithGrowthsSelector } from '../../_store/selectors/seasonsSelectors';
import { useBulkUpdateGrowthModelsSelectors } from '../../_store/slices/growths/bulkUpdateGrowthModelsSlice';
import { useCropDashboardSelectors } from '../../_store/slices/ui/cropDashboardSlice';
import ActivitiesHeaderButtons from '../../Components/Activities/ActivitiesHeaderButtons';
import ActivitiesTabContent from '../../Components/Activities/ActivitiesTabContent';
import CompletedActivitiesSection from '../../Components/Activities/CompletedActivitiesSection';
import ResponsiveActivities from '../../Components/Activities/ResponsiveActivities';
import ResponsiveZonedActivities from '../../Components/Activities/ResponsiveZonedActivities';
import WorkOrdersSection from '../../Components/Activities/WorkOrdersSection';
import FieldAccessibility from '../../Components/FieldAccessibility/FieldAccessibility';
import FieldAccessibilityHeaderButtons from '../../Components/FieldAccessibility/FieldAccessibilityHeaderButtons';
import GrowthModelSnapshots from '../../Components/GrowthModelSnapshots/GrowthModelSnapshots';
import GrowthModelSelector from '../../Components/Growths/GrowthModelSelector';
import GrowthTimeline from '../../Components/Growths/GrowthTimeline';
import FieldHarvestAdvisor from '../../Components/HarvestAdvisor/FieldHarvestAdvisor';
import GrowerHarvestAdvisor from '../../Components/HarvestAdvisor/GrowerHarvestAdvisor';
import LoadingIndicator from '../../Components/LoadingIndicator';
import AppPage from '../../Layout/AppPage';
import {
  ContentBody,
  ContentContainer,
  ResponsiveContentHeader
} from '../../Layout/Content';
import PageTitle from '../../Layout/PageTitle';

import GrowthsToggle from './Components/GrowthsToggle';
import ResponsiveFieldsDetails from './Components/ResponsiveFieldsDetails';
import { DashboardPageSubpageKeys } from './useDashboardPageConfig';

export const dashboardTabsIds = {
  GROWTH_MODEL: 'growth-model',
  ACTIVITIES: 'activities',
  FARM_ACTIVITIES: 'farm-activities',
  HARVEST_ADVISOR: 'harvest-advisor',
  GROWTH_SNAPSHOTS: 'growth-snapshots',
  FIELD_ACCESSIBILITY: 'field-accessibility',
  WORK_ORDERS: 'work-orders'
};

const useStyles = makeStyles(() => ({
  headerControls: {
    '& > :not(:first-child)': {
      marginLeft: '0.5rem'
    }
  }
}));

const DashboardPage = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const { isAdmin } = useContext(AuthContext);

  const mobileLayout = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const { showAllForGrower } = useCropDashboardSelectors();
  const { subPageKey } = useSubPage();

  const {
    selectedGrower,
    selectedGrowerId,
    selectedSeasonId,
    selectedFarmId,
    isActiveSeason,
    farms,
    loading: farmStructureLoading,
    isHistoricalSeason,
    selectedField
  } = useContext(FarmStructureContext);

  const { inProgress: seasonInProgress } = useSeasonWithGrowthsSelector(
    selectedGrowerId,
    selectedSeasonId
  );

  const { inProgress: growthModelsUpdateInProgress } =
    useBulkUpdateGrowthModelsSelectors();

  const isGrowerFarmSelected =
    !!selectedGrower && (showAllForGrower || !!selectedFarmId);

  const loading =
    farmStructureLoading || seasonInProgress || growthModelsUpdateInProgress;

  const showGrowthModel = subPageKey === DashboardPageSubpageKeys.GrowthModel;
  const showActivities = subPageKey === DashboardPageSubpageKeys.Activities;
  const showWorkOrders = subPageKey === DashboardPageSubpageKeys.WorkOrders;
  const showFarmActivities =
    subPageKey === DashboardPageSubpageKeys.ZonedActivities;
  const showHarvestAdvisor =
    subPageKey === DashboardPageSubpageKeys.HarvestAdvisor;
  const showHistorical =
    subPageKey === DashboardPageSubpageKeys.GrowthSnapshots;
  const showFieldAccessibility =
    !isHistoricalSeason &&
    subPageKey === DashboardPageSubpageKeys.FieldAccessibility;

  useEffect(() => {
    if (
      (showGrowthModel || showActivities || showHarvestAdvisor) &&
      selectedGrowerId &&
      selectedSeasonId &&
      !_.isEmpty(farms)
    ) {
      if (showAllForGrower) {
        dispatch(
          updateAllActiveGrowthsForGrowerAction(
            selectedGrowerId,
            selectedSeasonId
          )
        );
      }
      if (!showAllForGrower && selectedFarmId) {
        dispatch(
          updateAllActiveGrowthsForFarmAction(
            selectedGrowerId,
            selectedSeasonId,
            selectedFarmId
          )
        );
      }
    }
  }, [
    dispatch,
    selectedGrowerId,
    showGrowthModel,
    farms,
    selectedSeasonId,
    isActiveSeason,
    showActivities,
    showAllForGrower,
    selectedFarmId,
    showHarvestAdvisor
  ]);

  return (
    <AppPage
      header={
        <PageTitle>
          <Box className={styles.headerControls}>
            {isAdmin && <GrowthModelSelector showLabel />} <GrowthsToggle />
          </Box>
        </PageTitle>
      }
    >
      {loading && <LoadingIndicator />}
      {!loading && (
        <>
          {isGrowerFarmSelected && showGrowthModel && (
            <>
              {!mobileLayout && (
                <ContentContainer>
                  <ResponsiveContentHeader>
                    <span>Timeline</span>
                  </ResponsiveContentHeader>
                  <ContentBody>
                    <GrowthTimeline />
                  </ContentBody>
                </ContentContainer>
              )}
              <ContentContainer>
                <ResponsiveContentHeader>Fields</ResponsiveContentHeader>
                <ContentBody>
                  <ResponsiveFieldsDetails />
                </ContentBody>
              </ContentContainer>
            </>
          )}
          {isGrowerFarmSelected && showActivities && (
            <>
              {!mobileLayout && (
                <ContentContainer>
                  <ResponsiveContentHeader>
                    <span>Timeline</span>
                    <ActivitiesHeaderButtons />
                  </ResponsiveContentHeader>
                  <ContentBody>
                    <ActivitiesTabContent />
                  </ContentBody>
                </ContentContainer>
              )}
              <CompletedActivitiesSection />
              <ContentContainer>
                <ResponsiveContentHeader>
                  Activity Recommendations
                </ResponsiveContentHeader>
                <ContentBody>
                  <ResponsiveActivities />
                </ContentBody>
              </ContentContainer>
            </>
          )}
          {isGrowerFarmSelected && showWorkOrders && <WorkOrdersSection />}
          {isGrowerFarmSelected && showFarmActivities && (
            <>
              <ContentContainer>
                <ResponsiveContentHeader>
                  {!selectedField && 'Zoned Activities'}
                  {selectedField &&
                    `Zoned Activities - ${_.get(selectedField, 'name')}`}
                </ResponsiveContentHeader>
                <ContentBody>
                  <ResponsiveZonedActivities />
                </ContentBody>
              </ContentContainer>
            </>
          )}
          {isGrowerFarmSelected && showHarvestAdvisor && (
            <>
              <GrowerHarvestAdvisor />
              <FieldHarvestAdvisor />
            </>
          )}
          {isGrowerFarmSelected && showFieldAccessibility && (
            <ContentContainer>
              <ResponsiveContentHeader>
                <span>Field Accessibility</span>
                <FieldAccessibilityHeaderButtons />
              </ResponsiveContentHeader>
              <ContentBody>
                <FieldAccessibility />
              </ContentBody>
            </ContentContainer>
          )}
          {isGrowerFarmSelected && showHistorical && (
            <GrowthModelSnapshots mobileLayout={mobileLayout} />
          )}
          {!isGrowerFarmSelected && (
            <ContentContainer>
              <ContentBody>
                {!selectedGrowerId && (
                  <Alert severity="info">Please select a grower</Alert>
                )}
                {selectedGrowerId && (
                  <Alert severity="info">Please select a farm</Alert>
                )}
              </ContentBody>
            </ContentContainer>
          )}
        </>
      )}
    </AppPage>
  );
};

export default DashboardPage;
