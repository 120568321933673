import React, { useContext, useMemo } from 'react';

import _ from 'lodash';

import { FarmStructureContext } from '../../_context/FarmStructureContext';

import { useTimelineActivities } from './_hooks/useTimelineActivities';
import ActivitiesTimeline from './ActivitiesTimeline';

const ActivitiesTabContent = () => {
  const { inProgress, timelineActivities, minDate, maxDate } =
    useTimelineActivities();

  const { selectedFieldId } = useContext(FarmStructureContext);

  const filteredActivities = useMemo(
    () =>
      selectedFieldId
        ? _.filter(
            timelineActivities,
            (item) => _.get(item, 'fieldId') === selectedFieldId
          )
        : timelineActivities,
    [selectedFieldId, timelineActivities]
  );

  return (
    <ActivitiesTimeline
      inProgress={inProgress}
      timelineActivities={filteredActivities}
      minDate={minDate}
      maxDate={maxDate}
    />
  );
};

export default ActivitiesTabContent;
