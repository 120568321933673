import React from 'react';

import { activityTypes } from '../../../_store/selectors/configSelectors';

import ActivitiesReport from './ActivitiesReport';

const HerbicideReport = () => {
  return (
    <ActivitiesReport
      title="Herbicide Report"
      activityType={activityTypes.HerbicideApplication.type}
    />
  );
};

export default HerbicideReport;
