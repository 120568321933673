import React, { useMemo } from 'react';

import _ from 'lodash';
import { Bar } from 'react-chartjs-2';

const GroupsCount = 10;

function HarvestYieldHistogram({ chartRef, fields }) {
  const chartData = useMemo(() => {
    const maxYield = _.get(
      _.maxBy(fields, 'yieldBushelsPerAcre'),
      'yieldBushelsPerAcre'
    );
    const yieldStep = Math.round(maxYield / GroupsCount);

    const bins = _.chain(_.range(GroupsCount))
      .map((i) => ({
        start: i === 0 ? 0 : i * yieldStep + 1,
        end: i === GroupsCount - 1 ? maxYield : (i + 1) * yieldStep,
        acres: 0,
        fields: 0
      }))
      .value();

    _.forEach(fields, (field) => {
      const yieldBushelsPerAcre = _.get(field, 'yieldBushelsPerAcre');
      const bin = _.find(
        bins,
        (bin) =>
          _.get(bin, 'start') <= yieldBushelsPerAcre &&
          _.get(bin, 'end') >= yieldBushelsPerAcre
      );

      if (bin) {
        bin.acres += _.get(field, 'acres');
        bin.fields++;
      }
    });

    const labels = _.map(
      bins,
      (bin) => `${_.get(bin, 'start')} - ${_.get(bin, 'end')}`
    );

    const datasets = [
      {
        label: 'Yield',
        data: _.map(bins, (bin) => _.get(bin, 'acres')),
        type: 'bar',
        backgroundColor: '#039107'
      }
    ].filter((dataset) => !_.isEmpty(dataset.data));

    return {
      labels,
      datasets
    };
  }, [fields]);

  const options = useMemo(
    () => ({
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          text: `Yield by Acres`,
          font: {
            size: 16
          }
        },
        legend: {
          display: false
        },
        tooltip: {
          callbacks: {
            title: function (context) {
              const label = _.get(context, '0.label');
              return `${label} bu/ac`;
            },
            label: function (context) {
              const value = context.raw;
              if (!_.isNumber(value) || _.isNaN(value)) {
                return '-';
              }

              return ` ${value.toFixed(0)} acres`;
            }
          }
        }
      },
      scales: {
        'x-axis': {
          title: {
            display: true,
            text: 'Yield (bu/ac)'
          },
          display: true,
          gridLines: {
            display: true
          },
          ticks: {
            autoSkip: true
          }
        },
        'y-axis': {
          title: {
            display: true,
            text: 'Acres'
          },
          ticks: {
            precision: 0,
            beginAtZero: true
          }
        }
      }
    }),
    []
  );

  return <Bar ref={chartRef} data={chartData} type="bar" options={options} />;
}

export default HarvestYieldHistogram;
