import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import cx from 'classnames';

import { useGrowersDrawerState } from '../../_hooks/ui/useGrowersDrawerState';
import { useMenuState } from '../../_hooks/ui/useMenuState';
import { usePageViewAction } from '../../_hooks/usePageViewAction';
import AppHeader from '../AppHeader';
import Dialogs from '../Dialogs';
import GrowersDrawer from '../GrowersDrawer';
import MenuDrawer from '../MenuDrawer';

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    position: 'fixed',
    top: theme.dimensions.headerHeight,
    left: 0,
    right: 0,
    bottom: 0,
    flex: 1,
    display: 'flex',
    zIndex: 8
  },
  content: {
    width: '100%',
    overflow: 'auto',
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
    zIndex: 9,
    '@media (max-width: 991.98px)': {
      paddingLeft: '0 !important'
    },
    marginRight: '0',
    transition: theme.transitions.create('margin-right', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  openGrowersDrawer: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.dimensions.gffDrawerWidth
    },
    transition: theme.transitions.create('margin-right', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  mobileOverlay: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    background: '#333',
    opacity: 0.6,
    left: 0,
    top: 0,
    zIndex: 12
  }
}));

const CommonPage = ({ children }) => {
  const styles = useStyles();
  const { growersDrawerOpen } = useGrowersDrawerState();

  usePageViewAction();

  const { toggleMobileSideMenu, showMobileSideMenu, showMobileMenu } =
    useMenuState();

  return (
    <>
      <AppHeader />
      <div className={styles.contentContainer}>
        {showMobileSideMenu && showMobileMenu && (
          <div
            className={styles.mobileOverlay}
            onClick={toggleMobileSideMenu}
          />
        )}
        <MenuDrawer />
        <div
          className={cx(styles.content, {
            [styles.openGrowersDrawer]: growersDrawerOpen
          })}
        >
          {children}
        </div>
        <GrowersDrawer />
      </div>
      <Dialogs />
    </>
  );
};

export default CommonPage;
