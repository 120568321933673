import React from 'react';

import { DialogTitle } from '@material-ui/core';
import _ from 'lodash';

import ResponsiveDialog from '../ResponsiveDialog';
import ResponsiveDialogContent from '../ResponsiveDialogContent';

import FarmWorkOrderDetails from './FarmWorkOrderDetails';

const FarmWorkOrderDetailsDialog = ({ workOrder, onClose }) => {
  const isOpen = !!workOrder;

  return (
    <ResponsiveDialog open={isOpen} onClose={onClose} maxWidth="sm">
      <DialogTitle>{_.get(workOrder, 'name')}</DialogTitle>
      <ResponsiveDialogContent>
        {workOrder && <FarmWorkOrderDetails workOrder={workOrder} />}
      </ResponsiveDialogContent>
    </ResponsiveDialog>
  );
};

export default FarmWorkOrderDetailsDialog;
