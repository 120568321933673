import addGeospatialDataSlice from './addGeospatialDataSlice';
import deleteGeospatialDataSlice from './deleteGeospatialDataSlice';
import getGeospatialDataSlice from './getGeospatialDataSlice';

const geospatialDataSlices = [
  addGeospatialDataSlice,
  deleteGeospatialDataSlice,
  getGeospatialDataSlice
];

export default geospatialDataSlices;
