import React, { useMemo } from 'react';

import _ from 'lodash';

import { useGrowthStagesConfig } from '../../../_store/selectors/configSelectors';
import CommonDataGrid from '../../../Components/DataGrid/CommonDataGrid';

import { useCommonReportsColumns } from './_hooks/useCommonReportsColumns';

const CropProgressReportTable = ({
  growthModelReportResponse,
  onVisibleRowIdsChange = _.noop
}) => {
  const { growthStagesConfig } = useGrowthStagesConfig();

  const { commonColumns } = useCommonReportsColumns();

  const columns = useMemo(
    () => [
      ...commonColumns,
      {
        field: 'estimatedCurrentGrowthStage',
        headerName: 'Current Growth Stage',
        width: 200,
        editable: false,
        valueGetter: (params) =>
          _.get(growthStagesConfig, [
            _.get(params, ['row', 'estimatedCurrentGrowthStage']),
            'name'
          ])
      }
    ],
    [commonColumns, growthStagesConfig]
  );

  const rows = useMemo(
    () =>
      _.chain(growthModelReportResponse)
        .get('fields')
        .map((field) => {
          return {
            id: _.get(field, 'field.fieldId'),
            ..._.get(field, 'field', {}),
            ..._.get(field, 'variety', {}),
            estimatedCurrentGrowthStage: _.get(
              field,
              'estimatedCurrentGrowthStage'
            )
          };
        })
        .value(),
    [growthModelReportResponse]
  );

  return (
    <CommonDataGrid
      rows={rows}
      columns={columns}
      onVisibleRowIdsChange={onVisibleRowIdsChange}
    />
  );
};

export default CropProgressReportTable;
