import { useMemo } from 'react';

import { faBroadcastTower } from '@fortawesome/free-solid-svg-icons';

import { userRoles } from '../../_constants/userConstants';

const AquariusPageKey = 'AQUARIUS_PAGE';

const baseConfig = {
  key: AquariusPageKey,
  icon: faBroadcastTower,
  label: 'Aquarius API',
  path: 'aquarius',
  allowedRoles: [userRoles.Admin]
};

export const useAquariusPageConfig = () => {
  return useMemo(() => ({ config: baseConfig }), []);
};
