import React from 'react';

import { Popover, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';
import moment from 'moment';

import {
  WorkOrderPayloadType,
  WorkOrderStatus,
  WorkOrderType
} from '../../_constants/workOrderConstants';
import { activityTypes } from '../../_store/selectors/configSelectors';
import { useWorkOrderParams } from '../../Pages/Map/Components/hooks/useWorkOrderParams';

const useStyles = makeStyles(() => ({
  tooltip: {
    padding: '1rem',
    maxWidth: '20rem'
  },
  dates: {
    marginTop: '0.5rem',
    whiteSpace: 'nowrap',
    fontWeight: '500'
  },
  fieldName: {
    marginTop: '0.5rem',
    whiteSpace: 'nowrap',
    fontWeight: '600'
  },
  description: {
    marginTop: '1rem'
  },
  popover: {
    pointerEvents: 'none'
  },
  completed: {
    marginTop: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}));

const ActivityPopover = ({ hoverParams }) => {
  const styles = useStyles();

  const anchorEl = _.get(hoverParams, 'element');
  const fieldName = _.get(hoverParams, 'data.fieldName');
  const description = _.get(hoverParams, 'data.description');
  const longDescription = _.get(hoverParams, 'data.longDescription');
  const isCompleted = _.get(hoverParams, 'data.isCompleted');
  const dateCompleted = _.get(hoverParams, 'data.dateCompleted');
  const workOrder = _.get(hoverParams, 'data.workOrder');
  const displayTooltip = !!anchorEl;

  const {
    type: workOrderType,
    dueDate,
    dateCompleted: workOrderDateCompleted,
    status: workOrderStatus,
    payloadType
  } = useWorkOrderParams(workOrder);

  return (
    <Popover
      className={styles.popover}
      classes={{ paper: styles.tooltip }}
      open={displayTooltip}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: _.get(hoverParams, 'data.visibleMidX', 0)
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      disableAutoFocus
      disableRestoreFocus
    >
      <Typography className={styles.fieldName} align="center">
        {fieldName}
      </Typography>
      <Typography className={styles.dates} align="center">
        {moment(_.get(hoverParams, 'data.startDate')).format('ll')}
        {!!_.get(hoverParams, 'data.endDate') &&
          ` - ${moment(_.get(hoverParams, 'data.endDate')).format('ll')}`}
      </Typography>
      {isCompleted && dateCompleted && (
        <Box className={styles.completed}>
          <Typography>Completed:</Typography>
          <Typography>{moment(dateCompleted).format('ll')}</Typography>
        </Box>
      )}
      <Typography className={styles.description}>{description}</Typography>
      {!_.isEmpty(longDescription) && (
        <Typography className={styles.description}>
          Description: {longDescription}
        </Typography>
      )}
      {!!workOrder && (
        <>
          <Typography style={{ marginTop: '1rem' }}>
            Work Order: <b>{_.get(workOrder, 'name')}</b>
          </Typography>
          <Typography>
            Type:{' '}
            <b>
              {workOrderType === WorkOrderType.Recommendation
                ? _.get(activityTypes, [
                    _.get(workOrder, 'growthActivityType'),
                    'name'
                  ])
                : _.get(WorkOrderPayloadType, [payloadType, 'name'])}
            </b>
          </Typography>
          {!!workOrderStatus && (
            <Typography>
              Status: <b>{_.get(WorkOrderStatus, [workOrderStatus, 'name'])}</b>
            </Typography>
          )}
          {!!dueDate && (
            <Typography>
              Due Date: <b>{dueDate}</b>
            </Typography>
          )}
          {!!workOrderDateCompleted && (
            <Typography>
              Completion Date: <b>{workOrderDateCompleted}</b>
            </Typography>
          )}
        </>
      )}
    </Popover>
  );
};

export default ActivityPopover;
