import React from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useFarmFeedbackItems } from '../../_hooks/FarmFeedback/useFarmFeedbackItems';

import ZonedActivitiesAccordion from './ZonedActivitiesAccordion';
import ZonedActivitiesTable from './ZonedActivitiesTable';

const ResponsiveZonedActivities = () => {
  const showTable = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const { feedbackItems } = useFarmFeedbackItems();

  return (
    <>
      {!showTable && <ZonedActivitiesAccordion feedbackItems={feedbackItems} />}
      {showTable && <ZonedActivitiesTable feedbackItems={feedbackItems} />}
    </>
  );
};

export default ResponsiveZonedActivities;
