import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { getSeasonsSelectors } from '../slices/gff/seasons/getSeasonsSlice';
import { getSeasonWithGrowthsSelectors } from '../slices/gff/seasons/getSeasonsWithGrowthsSlice';

export const farmsSelector = (growerId, seasonId) =>
  getSeasonsSelectors.farms(growerId, seasonId);

export const farmSelector = (growerId, seasonId, farmId) =>
  getSeasonsSelectors.farm(growerId, seasonId, farmId);

export const useFarmsSelector = (growerId, seasonId) => {
  const farms = useSelector(farmsSelector(growerId, seasonId));

  return useMemo(() => ({ farms }), [farms]);
};

export const useFarmSelectors = (growerId, seasonId, farmId) => {
  const farm = useSelector(farmSelector(growerId, seasonId, farmId));

  return useMemo(() => ({ farm }), [farm]);
};

export const farmWithGrowthsSelector = (growerId, seasonId, farmId) =>
  getSeasonWithGrowthsSelectors.farm(growerId, seasonId, farmId);

export const useFarmWithGrowthsSelectors = (growerId, seasonId, farmId) => {
  const farm = useSelector(farmWithGrowthsSelector(growerId, seasonId, farmId));

  return useMemo(() => ({ farm }), [farm]);
};

export const useFieldWithGrowthsSelectors = (
  growerId,
  seasonId,
  farmId,
  fieldId
) => {
  const field = useSelector(
    getSeasonWithGrowthsSelectors.field(growerId, seasonId, farmId, fieldId)
  );

  return useMemo(() => ({ field }), [field]);
};
