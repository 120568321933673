import React, { useCallback, useState } from 'react';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Alert from '@material-ui/lab/Alert';
import _ from 'lodash';

import { useGetTankMixesConfigSelectors } from '../../../../_store/slices/config/tankMixes/getTankMixesConfigSlice';
import LoadingIndicator from '../../../../Components/LoadingIndicator';
import {
  ContentBody,
  ContentContainer,
  ContentHeader,
  FormContainer
} from '../../../../Layout/Content';

import EditTankMixForm from './EditTankMixForm';
import TankMixesList from './TankMixesList';

const TankMixesContent = ({ onManageProductsClick }) => {
  const [addTankMixMode, setAddTankMixMode] = useState(false);
  const [tankMixToEdit, setTankMixToEdit] = useState();

  const editTankMixMode = !!tankMixToEdit;

  const {
    inProgress,
    errorMessage,
    response: tankMixes
  } = useGetTankMixesConfigSelectors();

  const handleAddTankMixClick = useCallback(() => {
    setAddTankMixMode(true);
  }, []);

  const handleEditTankMixClick = useCallback((tankMix) => {
    setTankMixToEdit(tankMix);
  }, []);

  const handleEditCancel = useCallback(() => {
    setTankMixToEdit(undefined);
    setAddTankMixMode(false);
  }, []);

  return (
    <>
      {(inProgress || (!addTankMixMode && !editTankMixMode)) && (
        <ContentContainer>
          <ContentHeader>
            <span>Tank Mixes</span>
            {!inProgress && (
              <span>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={onManageProductsClick}
                >
                  Manage Products
                </Button>
                <Button
                  style={{ marginLeft: '0.5rem' }}
                  size="small"
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleAddTankMixClick}
                >
                  Add Tank Mix
                </Button>
              </span>
            )}
          </ContentHeader>
          <ContentBody>
            {inProgress && <LoadingIndicator />}
            {!inProgress && errorMessage && (
              <Alert severity="error">{errorMessage}</Alert>
            )}
            {!inProgress && !errorMessage && (
              <TankMixesList
                tankMixes={_.get(tankMixes, 'tankMixes')}
                onEditClick={handleEditTankMixClick}
              />
            )}
          </ContentBody>
        </ContentContainer>
      )}
      {!inProgress && (addTankMixMode || editTankMixMode) && (
        <FormContainer>
          <ContentHeader>
            {addTankMixMode && <span>Add Tank Mix</span>}
            {editTankMixMode && (
              <span>Edit Tank Mix {_.get(tankMixToEdit, 'name')}</span>
            )}
          </ContentHeader>
          <ContentBody>
            <EditTankMixForm
              tankMix={tankMixToEdit}
              onCancel={handleEditCancel}
            />
          </ContentBody>
        </FormContainer>
      )}
    </>
  );
};

export default TankMixesContent;
