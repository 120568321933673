import React from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import cx from 'classnames';

const useStyles = makeStyles(() => ({
  container: {},
  thumbnail: (props) => ({
    fill: props.fillColor,
    strokeWidth: '1',
    stroke: props.borderColor
  })
}));

const rectMargin = 1;

const FieldRectangle = ({ width, height, borderColor, fillColor }) => {
  const styles = useStyles({ borderColor, fillColor });

  return (
    <Box className={styles.container}>
      <svg height={height} width={width} className={cx(styles.thumbnail)}>
        <rect
          x={rectMargin}
          y={rectMargin}
          width={width - 2 * rectMargin}
          height={height - 2 * rectMargin}
        />
      </svg>
    </Box>
  );
};

export default FieldRectangle;
