import { useMemo } from 'react';

import { faStar } from '@fortawesome/free-regular-svg-icons';

import { userRoles } from '../../_constants/userConstants';

const UserFeedbackPageKey = 'USER_FEEDBACK_PAGE';

const baseConfig = {
  key: UserFeedbackPageKey,
  icon: faStar,
  label: 'Users Feedback Management',
  path: 'users-feedback',
  allowedRoles: [userRoles.Admin]
};

export const useUserFeedbackPageConfig = () => {
  return useMemo(() => ({ config: baseConfig }), []);
};
