import React, { useCallback, useEffect, useMemo, useState } from 'react';

import DialogTitle from '@material-ui/core/DialogTitle';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import _ from 'lodash';

import { WorkOrderType } from '../../../_constants/workOrderConstants';
import { useBundleOrDefault } from '../../../_hooks/Bundles/useBundle';
import { useFarmWorkOrderItems } from '../../../_hooks/FarmWorkOrders/useFarmWorkOrderItems';
import { useFieldSelectors } from '../../../_store/selectors/fieldsSelectors';
import ResponsiveDialogContent from '../../ResponsiveDialogContent';

import ActivityFeedbackForm from './ActivityFeedbackForm';
import ActivityFeedbackWorkOrderForm from './ActivityFeedbackWorkOrderForm';

const TabsKeys = {
  WorkOrder: 'WorkOrder',
  Complete: 'Complete'
};

const ActivityFeedbackDialogContent = ({
  activity,
  growth,
  onClose,
  growerId,
  seasonId,
  farmId,
  fieldId
}) => {
  const [selectedTab, setSelectedTab] = useState(TabsKeys.Complete);

  const { bundle } = useBundleOrDefault(_.get(growth, 'activitiesBundleId'));

  const protocols = _.get(bundle, 'protocols');

  const { workOrders } = useFarmWorkOrderItems();

  const activityConfigId = _.get(activity, 'activityConfigId');

  const workOrder = useMemo(
    () =>
      _.find(
        workOrders,
        (it) =>
          _.get(it, 'type') === WorkOrderType.Recommendation &&
          _.get(it, 'activityConfigId') === activityConfigId &&
          _.has(_.get(it, 'targetsByFieldId'), fieldId)
      ),
    [activityConfigId, fieldId, workOrders]
  );

  const activityConfig = useMemo(
    () =>
      _.chain(protocols)
        .find(
          (protocol) => _.get(protocol, 'id') === _.get(activity, 'protocolId')
        )
        .get('activities')
        .find(
          (activityConfig) => _.get(activityConfig, 'id') === activityConfigId
        )
        .value(),
    [protocols, activity, activityConfigId]
  );

  const { field } = useFieldSelectors(growerId, seasonId, farmId, fieldId);
  const fieldName = _.get(field, 'name');

  const activityFeedback = useMemo(
    () =>
      _.chain(growth)
        .get('activityFeedback')
        .find((item) => _.get(item, 'activityConfigId') === activityConfigId)
        .value(),

    [activityConfigId, growth]
  );

  useEffect(() => {
    if (selectedTab === TabsKeys.WorkOrder && activityFeedback) {
      setSelectedTab(TabsKeys.Complete);
    }
  }, [activityFeedback, selectedTab]);

  const handleTabChange = useCallback((e, newTab) => {
    setSelectedTab(newTab);
  }, []);

  return (
    <>
      <DialogTitle>
        {fieldName} - {_.get(activityConfig, 'formTitle', 'Activity Feedback')}
      </DialogTitle>
      <ResponsiveDialogContent>
        {!activityFeedback && (
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Mark Complete" value={TabsKeys.Complete} />
            <Tab
              label={workOrder ? 'Edit Work Order' : 'Create Work Order'}
              value={TabsKeys.WorkOrder}
            />
          </Tabs>
        )}
        {selectedTab === TabsKeys.Complete && (
          <ActivityFeedbackForm
            activityConfig={activityConfig}
            activityFeedback={activityFeedback}
            workOrder={workOrder}
            growth={growth}
            onClose={onClose}
            growerId={growerId}
            seasonId={seasonId}
            farmId={farmId}
            fieldId={fieldId}
          />
        )}
        {selectedTab === TabsKeys.WorkOrder && (
          <ActivityFeedbackWorkOrderForm
            activityConfig={activityConfig}
            workOrder={workOrder}
            onClose={onClose}
            growerId={growerId}
            seasonId={seasonId}
            farmId={farmId}
            fieldId={fieldId}
          />
        )}
      </ResponsiveDialogContent>
    </>
  );
};

export default ActivityFeedbackDialogContent;
