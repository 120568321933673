import { useMemo } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';

import { createDataSlice } from '../utils/createDataSlice';

const uploadShapefileSlice = createDataSlice({
  reducerPath: ['ui', 'uploadShapefile'],
  initialState: {
    showUploadShapefileDialog: false
  },
  reducers: {
    setShowUploadShapefileDialog: (state, action) => {
      const show = action.payload;
      _.set(state, 'showUploadShapefileDialog', show);
    }
  }
});

const { actions, baseSelector: uploadShapefileSelector } = uploadShapefileSlice;

export const uploadShapefileActions = actions;

export const useUploadShapefileSelectors = () => {
  const { showUploadShapefileDialog } = useSelector(uploadShapefileSelector);

  return useMemo(
    () => ({ showUploadShapefileDialog }),
    [showUploadShapefileDialog]
  );
};

export default uploadShapefileSlice;
