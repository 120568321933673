import React, { useCallback, useState } from 'react';

import { IconButton, Popover, Tooltip, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FilterListIcon from '@material-ui/icons/FilterList';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { WorkOrderStatus } from '../../_constants/workOrderConstants';
import {
  useWorkOrdersFiltersSelectors,
  workOrdersFiltersActions
} from '../../_store/slices/ui/workOrdersFiltersSlice';

const StatusFilters = () => {
  const dispatch = useDispatch();

  const { selectedStatuses } = useWorkOrdersFiltersSelectors();

  const handleSelectionChange = useCallback(
    (e) => {
      dispatch(workOrdersFiltersActions.toggleStatusFilter(e.target.value));
    },
    [dispatch]
  );

  return (
    <Box display="flex" flexDirection="column">
      <Typography>
        <b>Status</b>
      </Typography>
      {_.map(_.values(WorkOrderStatus), (workOrderStatus) => (
        <FormControlLabel
          key={_.get(workOrderStatus, 'key')}
          control={
            <Checkbox
              value={_.get(workOrderStatus, 'key')}
              name={_.get(workOrderStatus, 'name')}
              checked={_.get(selectedStatuses, [_.get(workOrderStatus, 'key')])}
              onChange={handleSelectionChange}
            />
          }
          label={_.get(workOrderStatus, 'name')}
        />
      ))}
    </Box>
  );
};

const WorkOrdersFilters = ({ size, color = 'primary' }) => {
  const [anchorEl, setAnchorEl] = useState();

  const open = !!anchorEl;

  const handleFiltersClick = useCallback((e) => {
    setAnchorEl((el) => (el ? undefined : e.currentTarget));
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(undefined);
  }, []);

  return (
    <>
      <Tooltip title="Filters" placement="top">
        <IconButton
          onClick={handleFiltersClick}
          style={{ marginLeft: '1rem' }}
          color={color}
          size={size}
        >
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Box padding="1rem">
          <StatusFilters />
        </Box>
      </Popover>
    </>
  );
};

export default WorkOrdersFilters;
