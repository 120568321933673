import React, { useCallback, useContext, useEffect, useMemo } from 'react';

import MomentUtils from '@date-io/moment';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import _ from 'lodash';
import moment from 'moment';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { WorkOrderStatus } from '../../../_constants/workOrderConstants';
import { FarmStructureContext } from '../../../_context/FarmStructureContext';
import {
  createActivityFeedbackActions,
  useCreateActivityFeedbackSelectors
} from '../../../_store/slices/growths/activities/createActivityFeedbackSlice';
import {
  deleteActivityFeedbackActions,
  useDeleteActivityFeedbackSelectors
} from '../../../_store/slices/growths/activities/deleteActivityFeedbackSlice';
import {
  updateActivityFeedbackActions,
  useUpdateActivityFeedbackSelectors
} from '../../../_store/slices/growths/activities/updateActivityFeedbackSlice';
import CreateUpdateDeleteDialogActions from '../../CreateUpdateDeleteDialogActions';

import ActivityFeedbackDynamicForm from './ActivityFeedbackDynamicForm';

const ActivityFeedbackForm = ({
  activityConfig,
  growth,
  onClose,
  growerId,
  seasonId,
  farmId,
  fieldId,
  activityFeedback,
  workOrder
}) => {
  const dispatch = useDispatch();

  const { selectedSeason } = useContext(FarmStructureContext);

  const seasonStartDate = _.get(selectedSeason, 'startDate');
  const seasonEndDate = _.get(selectedSeason, 'endDate');

  const formConfig = _.get(activityConfig, 'formConfig');
  const formConfigId = _.get(formConfig, 'id');

  const isEdit = !!activityFeedback;
  const activityDateCompleted = _.get(activityFeedback, 'dateCompleted');
  const activityId = _.get(activityFeedback, 'id');

  const formMethods = useForm({
    defaultValues: isEdit
      ? {
          ..._.pick(activityFeedback, ['feedbackDescription', 'formValues']),
          dateCompleted: moment(activityDateCompleted)
        }
      : { dateCompleted: moment(), formValues: {} }
  });

  const {
    register,
    unregister,
    handleSubmit,
    errors,
    watch,
    setValue,
    control
  } = formMethods;

  const addSelectors = useCreateActivityFeedbackSelectors();
  const updateSelectors = useUpdateActivityFeedbackSelectors();

  const {
    inProgress: submitInProgress,
    success: submitSuccess,
    errorMessage: submitError
  } = isEdit ? updateSelectors : addSelectors;

  const {
    inProgress: deleteInProgress,
    success: deleteSuccess,
    errorMessage: deleteError
  } = useDeleteActivityFeedbackSelectors();

  const growthId = _.get(growth, 'id');

  const inProgress = deleteInProgress || submitInProgress;
  const success = submitSuccess || deleteSuccess;
  const apiError = submitError || deleteError;

  const minDate = useMemo(
    () => moment(seasonStartDate).endOf('day'),
    [seasonStartDate]
  );

  const maxDate = useMemo(
    () => moment(seasonEndDate).endOf('day'),
    [seasonEndDate]
  );

  const selectedDate = watch('dateCompleted');

  useEffect(() => {
    register({ name: 'dateCompleted' }, { required: 'Date is required' });
    return () => {
      unregister('dateCompleted');
    };
  }, [register, unregister]);

  useEffect(() => {
    if (maxDate && selectedDate.isAfter(maxDate)) {
      if (minDate && minDate.isAfter()) {
        setValue('dateCompleted', minDate);
      } else {
        setValue('dateCompleted', moment());
      }
    }
    if (minDate && selectedDate.isBefore(minDate)) {
      setValue('dateCompleted', minDate);
    }
  }, [maxDate, minDate, selectedDate, setValue]);

  const dateError = _.get(errors, 'date.message');
  const dateOutOfRange =
    !selectedDate ||
    selectedDate.isBefore(minDate) ||
    selectedDate.isAfter(maxDate);

  const handleClose = useCallback(() => {
    dispatch(createActivityFeedbackActions.clear());
    dispatch(updateActivityFeedbackActions.clear());
    dispatch(deleteActivityFeedbackActions.clear());
    onClose();
  }, [dispatch, onClose]);

  useEffect(() => {
    if (success) {
      handleClose();
    }
  }, [handleClose, success]);

  const handleFormSubmit = useCallback(
    (formData) => {
      const formValues = _.chain(formData)
        .get('formValues')
        .omitBy(
          (value) =>
            _.isNil(value) ||
            (_.isString(value) && _.isEmpty(value)) ||
            _.isNaN(value)
        )
        .value();

      const activityDto = {
        dateCompleted: _.get(formData, 'dateCompleted').toISOString(),
        feedbackDescription: _.get(formData, 'feedbackDescription'),
        formValues: formValues,
        activityConfigId: _.get(activityConfig, 'id'),
        formConfigId: formConfigId,
        completeWorkOrder: _.get(formData, 'completeWorkOrder', false)
      };

      if (isEdit) {
        dispatch(
          updateActivityFeedbackActions.request(
            growerId,
            seasonId,
            farmId,
            fieldId,
            growthId,
            activityId,
            activityDto
          )
        );
      } else {
        dispatch(
          createActivityFeedbackActions.request(
            growerId,
            seasonId,
            farmId,
            fieldId,
            growthId,
            activityDto
          )
        );
      }
    },
    [
      activityConfig,
      formConfigId,
      isEdit,
      dispatch,
      growerId,
      seasonId,
      farmId,
      fieldId,
      growthId,
      activityId
    ]
  );

  const handleConfirmDelete = useCallback(() => {
    dispatch(
      deleteActivityFeedbackActions.request(
        growerId,
        seasonId,
        farmId,
        fieldId,
        growthId,
        activityId
      )
    );
  }, [activityId, dispatch, farmId, fieldId, growerId, growthId, seasonId]);

  const handleDateChange = useCallback(
    (date) => {
      setValue('dateCompleted', date);
    },
    [setValue]
  );

  return (
    <form noValidate onSubmit={handleSubmit(handleFormSubmit)}>
      <Box padding="1rem">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                autoOk
                disableToolbar
                variant="inline"
                format="L"
                id="date"
                name="date"
                label="Completion Date"
                value={selectedDate}
                onChange={handleDateChange}
                minDate={minDate}
                maxDate={maxDate}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          {dateError && (
            <Grid item xs={12}>
              <FormHelperText error>{dateError}</FormHelperText>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              inputRef={register()}
              variant="standard"
              multiline
              maxRows={6}
              fullWidth
              id="feedbackDescription"
              label="Description"
              name="feedbackDescription"
              error={!!errors.feedbackDescription}
              helperText={_.get(errors, 'feedbackDescription.message')}
            />
          </Grid>
          <FormProvider {...formMethods}>
            <ActivityFeedbackDynamicForm form={formConfig} />
          </FormProvider>
          {!!workOrder &&
            _.get(workOrder, 'status') !== WorkOrderStatus.Complete.key && (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="completeWorkOrder"
                  id="completeWorkOrder"
                  render={({ onChange, value, ref }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => onChange(e.target.checked)}
                          checked={value}
                          inputRef={ref}
                        />
                      }
                      label={`Complete the associated Work Order "${_.get(
                        workOrder,
                        'name'
                      )}"`}
                    />
                  )}
                />
              </Grid>
            )}
          <Grid item xs={12}>
            {apiError && <FormHelperText error>{apiError}</FormHelperText>}
          </Grid>
        </Grid>
        <CreateUpdateDeleteDialogActions
          confirmText="Mark Complete"
          isEdit={isEdit}
          inProgress={inProgress}
          confirmDisabled={!!dateOutOfRange}
          onDelete={handleConfirmDelete}
          onCancel={handleClose}
        />
      </Box>
    </form>
  );
};

export default ActivityFeedbackForm;
