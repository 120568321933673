import React, { useMemo, useRef } from 'react';

import _ from 'lodash';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';

import { activityTypes } from '../../../_store/selectors/configSelectors';

import { useChartDataType } from './_hooks/useChartDataType';
import ReportChartButtons from './ReportChartButtons';
import ReportsChartContainer from './ReportsChartContainer';

function ActivitiesReportChart({ fields, activityType }) {
  const chartDataType = useChartDataType();
  const { dataType, showAcres } = chartDataType;

  const activityName = _.get(activityTypes, [activityType, 'name']);
  const activityColor = _.get(activityTypes, [activityType, 'color']);

  const chartRef = useRef(null);

  const activities = useMemo(
    () =>
      _.chain(fields)
        .flatMap((field) =>
          _.chain(field)
            .get('activitiesV2')
            .map((activity) => ({ ...activity, acres: _.get(field, 'acres') }))
            .filter(
              (activity) =>
                !!_.get(activity, 'startDate') && !!_.get(activity, 'endDate')
            )
            .value()
        )
        .filter((activity) => _.get(activity, 'type') === activityType)
        .value(),
    [activityType, fields]
  );

  const activitiesDates = useMemo(() => {
    const dates = {};
    _.forEach(activities, (activity) => {
      const startDate = moment(_.get(activity, 'startDate')).startOf('day');
      const endDate = moment(_.get(activity, 'endDate')).startOf('day');

      let currentDate = startDate.clone();
      while (currentDate.isSameOrBefore(endDate)) {
        const dateKey = currentDate.format('yyyy-MM-DD');
        _.set(
          dates,
          [dateKey, 'acres'],
          _.get(dates, [dateKey, 'acres'], 0) + _.get(activity, 'acres')
        );
        _.set(
          dates,
          [dateKey, 'count'],
          _.get(dates, [dateKey, 'count'], 0) + 1
        );
        currentDate = currentDate.add(1, 'day');
      }
    });

    return _.chain(dates)
      .map((val, date) => ({ ...val, date }))
      .orderBy('date')
      .value();
  }, [activities]);

  const chartData = useMemo(() => {
    const labels = _.map(activitiesDates, (item) => _.get(item, 'date'));

    const datasets = [
      {
        label: `Predicted ${activityName}`,
        data: _.map(activitiesDates, (item) => _.get(item, [dataType])),
        type: 'bar',
        backgroundColor: activityColor,
        borderColor: activityColor
      }
    ].filter((dataset) => !_.isEmpty(dataset.data));

    return {
      labels,
      datasets
    };
  }, [activitiesDates, activityColor, activityName, dataType]);

  const options = useMemo(
    () => ({
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          text: `${activityName} Dates by ${
            showAcres ? 'Acres' : 'Number of Fields'
          }`,
          font: {
            size: 16
          }
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              return ` ${context.dataset.label}: ${context.formattedValue} ${
                showAcres ? 'Acres' : 'Fields'
              } `;
            }
          }
        }
      },
      scales: {
        'x-axis': {
          type: 'time',
          time: {
            minUnit: 'day',
            tooltipFormat: 'LL',
            displayFormats: {
              day: 'MMM DD'
            }
          },
          display: true,
          gridLines: {
            display: true
          },
          ticks: {
            autoSkip: true
          }
        },
        'y-axis': {
          ticks: {
            precision: 0,
            beginAtZero: true
          }
        }
      }
    }),
    [activityName, showAcres]
  );

  return (
    <ReportsChartContainer
      header={
        <ReportChartButtons
          chartRef={chartRef}
          chartFilename={`${activityName}-dates-by-${
            showAcres ? 'Acres' : 'Number-of-Fields'
          }-Chart.png`}
          chartDataType={chartDataType}
        />
      }
    >
      <Bar ref={chartRef} type="bar" data={chartData} options={options} />
    </ReportsChartContainer>
  );
}

export default ActivitiesReportChart;
