import { useMemo } from 'react';

import _ from 'lodash';

import {
  aquariusGrowerDevicesTypes,
  growerDevicesVendors
} from '../../../../_constants/growerDevicesConstants';

export const useGrowersWithPumps = (growers) => {
  const growersWithPumps = useMemo(
    () =>
      _.chain(growers)
        .map((grower) => {
          const pumps = _.chain(grower)
            .get('seasons')
            .find((season) => _.get(season, 'isActive'))
            .get('devices')
            .filter(
              (device) =>
                _.get(device, 'vendor') ===
                  growerDevicesVendors.Aquarius.type &&
                _.get(device, 'deviceType') ===
                  aquariusGrowerDevicesTypes.PumpController.deviceType
            )
            .value();

          return { ...grower, pumps };
        })
        .filter((grower) => !_.isEmpty(_.get(grower, 'pumps')))
        .value(),
    [growers]
  );

  return useMemo(
    () => ({
      growersWithPumps
    }),
    [growersWithPumps]
  );
};
