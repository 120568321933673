import React, { useContext, useMemo } from 'react';

import _ from 'lodash';

import { FarmStructureContext } from '../../_context/FarmStructureContext';
import { useBundleOrDefault } from '../../_hooks/Bundles/useBundle';
import { useActiveGrowthSelectors } from '../../_store/selectors/growthsSelectors';
import ActivityFeedbackWorkOrderForm from '../Growths/Dialogs/ActivityFeedbackWorkOrderForm';

const EditFarmWorkOrderDialogContent = ({ workOrder, onClose }) => {
  const {
    selectedGrowerId: growerId,
    selectedSeasonId: seasonId,
    selectedFarmId: farmId
  } = useContext(FarmStructureContext);

  const fieldId = _.chain(workOrder)
    .get('targetsByFieldId')
    .keys()
    .first()
    .value();

  const activityConfigId = _.get(workOrder, 'activityConfigId');

  const { growth } = useActiveGrowthSelectors(seasonId, fieldId);

  const { bundle } = useBundleOrDefault(_.get(growth, 'activitiesBundleId'));

  const activityConfig = useMemo(
    () =>
      _.chain(bundle)
        .get('protocols')
        .flatMap((protocol) => _.get(protocol, 'activities'))
        .find(
          (activityConfig) => _.get(activityConfig, 'id') === activityConfigId
        )
        .value(),
    [activityConfigId, bundle]
  );

  return (
    <ActivityFeedbackWorkOrderForm
      activityConfig={activityConfig}
      workOrder={workOrder}
      onClose={onClose}
      growerId={growerId}
      seasonId={seasonId}
      farmId={farmId}
      fieldId={fieldId}
    />
  );
};

export default EditFarmWorkOrderDialogContent;
