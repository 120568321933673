import React from 'react';

import Alert from '@material-ui/lab/Alert';

import LoadingIndicator from '../../../Components/LoadingIndicator';
import {
  ContentBody,
  ContentContainer,
  ContentHeader,
  ResponsiveHeaderButtonsContainer
} from '../../../Layout/Content';

import { useGrowthModelReportWithDistricts } from './_hooks/useGrowthModelReportWithDistricts';
import { useReportsChartsControls } from './_hooks/useReportsChartsControls';
import ActivitiesReportChart from './ActivitiesReportChart';
import ActivitiesReportTable from './ActivitiesReportTable';
import ReportsDataGridWrapper from './ReportsDataGridWrapper';
import TableChartToggle from './TableChartToggle';

const ActivitiesReport = ({ title, activityType }) => {
  const { inProgress, errorMessage, response } =
    useGrowthModelReportWithDistricts();

  const { showChart, toggleChart, visibleRows, handleVisibleRowIdsChange } =
    useReportsChartsControls(response);

  return (
    <ContentContainer>
      <ContentHeader>
        <span>{title}</span>
        <ResponsiveHeaderButtonsContainer>
          <TableChartToggle showChart={showChart} onToggle={toggleChart} />
        </ResponsiveHeaderButtonsContainer>
      </ContentHeader>
      <ContentBody>
        {inProgress && <LoadingIndicator />}
        {!inProgress && errorMessage && (
          <Alert severity="error">{errorMessage}</Alert>
        )}
        {!inProgress && !errorMessage && (
          <>
            <ReportsDataGridWrapper hide={showChart}>
              <ActivitiesReportTable
                growthModelReportResponse={response}
                activityType={activityType}
                onVisibleRowIdsChange={handleVisibleRowIdsChange}
              />
            </ReportsDataGridWrapper>
            {showChart && (
              <ActivitiesReportChart
                fields={visibleRows}
                activityType={activityType}
              />
            )}
          </>
        )}
      </ContentBody>
    </ContentContainer>
  );
};

export default ActivitiesReport;
