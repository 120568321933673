import React, { useMemo, useRef } from 'react';

import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import _ from 'lodash';
import { Bar } from 'react-chartjs-2';

import { growthStagesTypes } from '../../../_constants/growthsConstants';
import { useGrowthStagesConfig } from '../../../_store/selectors/configSelectors';

import { DataTypes, useChartDataType } from './_hooks/useChartDataType';
import ReportChartButtons from './ReportChartButtons';
import ReportsChartContainer from './ReportsChartContainer';

Chart.register(annotationPlugin);

function CropProgressReportChart({ fields }) {
  const chartDataType = useChartDataType();
  const { dataType, showAcres } = chartDataType;

  const { growthStagesConfig } = useGrowthStagesConfig();

  const chartRef = useRef(null);

  const fieldsByGrowthStage = useMemo(
    () =>
      _.chain(fields)
        .filter(
          (field) =>
            !!_.get(field, 'estimatedCurrentGrowthStage') &&
            _.get(field, 'estimatedCurrentGrowthStage') !==
              growthStagesTypes.Unknown
        )
        .groupBy((field) => _.get(field, 'estimatedCurrentGrowthStage'))
        .value(),
    [fields]
  );

  const cropProgress = useMemo(() => {
    return _.chain(growthStagesConfig)
      .values()
      .filter((stage) => _.get(stage, 'type') !== growthStagesTypes.Unknown)
      .map((stage) => ({
        ...stage,
        fields: {
          [DataTypes.Number]: _.get(
            fieldsByGrowthStage,
            [_.get(stage, 'type'), 'length'],
            0
          ),
          [DataTypes.Acres]: _.chain(fieldsByGrowthStage)
            .get([_.get(stage, 'type')], [])
            .sumBy('acres')
            .value()
        }
      }))
      .orderBy('order')
      .value();
  }, [fieldsByGrowthStage, growthStagesConfig]);

  const totalFields = useMemo(
    () => _.sumBy(cropProgress, 'fields.count'),
    [cropProgress]
  );

  const totalAcres = useMemo(
    () => _.sumBy(cropProgress, 'fields.acres'),
    [cropProgress]
  );

  const chartData = useMemo(() => {
    const labels = _.map(cropProgress, (item) => _.get(item, 'name'));

    const colors = _.map(cropProgress, (item) => _.get(item, 'outlineColor'));

    const datasets = [
      {
        label: showAcres ? 'Acres' : 'Fields',
        data: _.map(cropProgress, (item) => _.get(item, ['fields', dataType])),
        type: 'bar',
        backgroundColor: colors,
        borderColor: colors
      }
    ].filter((dataset) => !_.isEmpty(dataset.data));

    return {
      labels,
      datasets
    };
  }, [cropProgress, dataType, showAcres]);

  const options = useMemo(
    () => ({
      maintainAspectRatio: false,
      plugins: {
        annotation: {
          annotations: [
            {
              type: 'line',
              mode: 'horizontal',
              yScaleID: 'y-axis',
              xScaleID: 'x-axis',
              yMin: dataType === DataTypes.Acres ? totalAcres : totalFields,
              yMax: dataType === DataTypes.Acres ? totalAcres : totalFields,
              borderColor: 'rgb(27,187,0)',
              borderWidth: 2,
              label: {
                enabled: true,
                content:
                  dataType === DataTypes.Acres
                    ? 'Total Acres'
                    : 'Total Number of Fields'
              }
            }
          ]
        },
        title: {
          display: true,
          text: `Crop Progress by ${showAcres ? 'Acres' : 'Number of Fields'}`,
          font: {
            size: 16
          }
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              const percent =
                Math.round(
                  (context.raw / (showAcres ? totalAcres : totalFields)) * 1000
                ) / 10;

              return ` ${context.label}: ${context.formattedValue} ${
                showAcres
                  ? context.raw === 1
                    ? 'Acre'
                    : 'Acres'
                  : context.raw === 1
                  ? 'Field'
                  : 'Fields'
              } (${percent}%)`;
            }
          }
        },
        legend: {
          display: false
        }
      },
      scales: {
        'x-axis': {
          display: true,
          gridLines: {
            display: true
          },
          ticks: {
            autoSkip: true
          }
        },
        'y-axis': {
          title: {
            display: true,
            text: showAcres ? 'Acres' : 'Fields'
          },
          ticks: {
            precision: 0,
            beginAtZero: true
          }
        }
      }
    }),
    [dataType, showAcres, totalAcres, totalFields]
  );

  return (
    <ReportsChartContainer
      header={
        <ReportChartButtons
          chartRef={chartRef}
          chartFilename={`Crop-Progress-by-${
            showAcres ? 'Acres' : 'Number-of-Fields'
          }-Chart.png`}
          chartDataType={chartDataType}
        />
      }
    >
      {chartData && (
        <Bar ref={chartRef} type="bar" data={chartData} options={options} />
      )}
    </ReportsChartContainer>
  );
}

export default CropProgressReportChart;
