import React from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useResizeDetector } from 'react-resize-detector';

const useStyles = makeStyles((theme) => ({
  outer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '20rem',
    height: `calc(100vh - ${theme.dimensions.headerHeight} - 17rem)`
  },
  header: {
    width: '100%'
  }
}));

const ReportsChartContainer = ({ header, children }) => {
  const styles = useStyles();
  const { height: headerHeight, ref: containerRef } = useResizeDetector();

  return (
    <Box className={styles.outer}>
      {!!header && (
        <Box ref={containerRef} className={styles.header}>
          {header}
        </Box>
      )}
      <Box
        width="100%"
        height={!!headerHeight ? `calc(100% - ${headerHeight}px)` : '100%'}
      >
        {children}
      </Box>
    </Box>
  );
};

export default ReportsChartContainer;
