import React, { useCallback, useEffect, useMemo, useState } from 'react';

import MomentUtils from '@date-io/moment';
import { Paper, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import _ from 'lodash';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import {
  aquariusFieldDevicesTypes,
  aquariusGrowerDevicesTypes,
  aquariusGrowerDevicesTypesByDeviceType,
  aquariusSeasonDevicesTypes,
  growerDevicesVendors
} from '../../../_constants/growerDevicesConstants';
import { useSeasonSelector } from '../../../_store/selectors/seasonsSelectors';
import {
  getGrowerDevicesActions,
  useGetGrowerDevicesSelectors
} from '../../../_store/slices/devices/growerDevices/getGrowerDevicesSlice';
import {
  addSeasonDeviceActions,
  useAddSeasonDeviceSelectors
} from '../../../_store/slices/devices/seasonDevices/addSeasonDeviceSlice';
import {
  updateSeasonDeviceActions,
  useUpdateSeasonDeviceSelectors
} from '../../../_store/slices/devices/seasonDevices/updateSeasonDeviceSlice';
import {
  addSeasonFieldDeviceActions,
  useAddSeasonFieldDeviceSelectors
} from '../../../_store/slices/devices/seasonFieldDevices/addSeasonFieldDeviceSlice';
import {
  updateSeasonFieldDeviceActions,
  useUpdateSeasonFieldDeviceSelectors
} from '../../../_store/slices/devices/seasonFieldDevices/updateSeasonFieldDeviceSlice';
import {
  getSeasonsActions,
  useGetSeasonsSelectors
} from '../../../_store/slices/gff/seasons/getSeasonsSlice';
import LoadingIndicator from '../../../Components/LoadingIndicator';

const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    top: '1rem',
    left: '1rem',
    width: 'calc(100% - 2rem)',
    maxHeight: 'calc(100% - 2rem)',
    maxWidth: '20rem',
    display: 'flex',
    padding: '1rem',
    overflow: 'auto',
    flexDirection: 'column',
    '& > :not(:first-child)': {
      marginTop: '1rem'
    }
  },
  moistureChannels: {
    display: 'flex',
    flexDirection: 'column'
  },
  noDevicesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > :not(:first-child)': {
      marginLeft: '0.5rem'
    }
  }
}));

const MoistureSensorChannel = ({
  channelId,
  channelConfig,
  onMoistureChannelEnabledToggle,
  register,
  errors
}) => {
  const enabled = _.get(channelConfig, 'enabled');

  const handleMoistureChannelEnabledToggle = useCallback(() => {
    onMoistureChannelEnabledToggle(channelId);
  }, [channelId, onMoistureChannelEnabledToggle]);

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            id={`moistureChannel${channelId}`}
            name={`moistureChannel${channelId}`}
            checked={enabled}
            onChange={handleMoistureChannelEnabledToggle}
          />
        }
        label={`Channel ${channelId}`}
      />
      {enabled && (
        <TextField
          inputRef={register({
            required: 'Depth is required',
            valueAsNumber: true
          })}
          variant="outlined"
          size="small"
          fullWidth
          required
          type="number"
          id={`moistureChannels.${channelId}.depth`}
          label={`Channel ${channelId} Depth [in]`}
          name={`moistureChannels.${channelId}.depth`}
          // value={txtDepth}
          // onChange={handleMoistureChannelDepthChange}
          helperText={_.get(
            errors,
            `moistureChannels.${channelId}.depth.message`
          )}
        />
      )}
    </>
  );
};

export default function EditSensorForm({
  growerId,
  seasonId,
  farmId,
  fieldId,
  deviceToEdit,
  sensorType,
  latitude,
  longitude,
  sensorInField,
  onSensorTypeChange,
  onVendorChange,
  onLatitudeChange,
  onLongitudeChange,
  onCancel
}) {
  const styles = useStyles();
  const dispatch = useDispatch();

  const configureFieldDevice = !!fieldId;

  const isEdit = !!deviceToEdit;
  const deviceToEditId = _.get(deviceToEdit, 'id');
  const deviceToEditType = _.get(deviceToEdit, 'deviceType');

  const addSeasonDeviceSelectors = useAddSeasonDeviceSelectors();
  const addSeasonFieldDeviceSelectors = useAddSeasonFieldDeviceSelectors();
  const updateSeasonDeviceSelectors = useUpdateSeasonDeviceSelectors();
  const updateSeasonFieldDeviceSelectors =
    useUpdateSeasonFieldDeviceSelectors();

  const addSelectors = configureFieldDevice
    ? addSeasonFieldDeviceSelectors
    : addSeasonDeviceSelectors;
  const updateSelectors = configureFieldDevice
    ? updateSeasonFieldDeviceSelectors
    : updateSeasonDeviceSelectors;
  const {
    inProgress: saveInProgress,
    success: saveSuccess,
    errorMessage: saveErrorMessage
  } = isEdit ? updateSelectors : addSelectors;

  const { inProgress: seasonsInProgress } = useGetSeasonsSelectors(growerId);
  const { season } = useSeasonSelector(growerId, seasonId);

  const [moistureSensorChannels, setMoistureSensorChannels] = useState({
    0: { enabled: _.get(deviceToEdit, 'moistureChannels.0.enabled', false) },
    1: { enabled: _.get(deviceToEdit, 'moistureChannels.1.enabled', false) },
    2: { enabled: _.get(deviceToEdit, 'moistureChannels.2.enabled', false) },
    3: { enabled: _.get(deviceToEdit, 'moistureChannels.3.enabled', false) }
  });

  const [txtLat, setTxtLat] = useState(latitude);
  const [txtLon, setTxtLon] = useState(longitude);
  const [selectedDevice, setSelectedDevice] = useState(null);

  const { register, unregister, handleSubmit, errors, setValue, watch } =
    useForm({
      defaultValues: isEdit
        ? {
            name: _.get(deviceToEdit, 'name'),
            offset: _.get(deviceToEdit, 'waterLevelOffset'),
            moistureChannels: _.map(
              _.get(deviceToEdit, 'moistureChannels'),
              (channel) => ({ depth: _.get(channel, 'depth') })
            )
          }
        : { name: '', startDate: moment() }
    });

  const {
    inProgress: devicesInProgress,
    errorMessage: devicesErrorMessage,
    response: devicesFromResponse
  } = useGetGrowerDevicesSelectors(growerId);

  const inProgress = devicesInProgress || seasonsInProgress;

  const startDate = watch('date');
  const dateError = _.get(errors, 'date.message');
  const minDate = moment().startOf('year');

  useEffect(() => {
    if (!isEdit) {
      register({ name: 'date' }, { required: 'Date is required' });
      setValue('date', moment());
      return () => {
        unregister('date');
      };
    }
  }, [isEdit, register, setValue, unregister]);

  const existingDevice = useMemo(
    () =>
      _.find(
        devicesFromResponse,
        (device) =>
          _.get(device, 'deviceId') === _.get(deviceToEdit, 'deviceId') &&
          _.get(device, 'deviceType') === _.get(deviceToEdit, 'deviceType')
      ),
    [deviceToEdit, devicesFromResponse]
  );

  const seasonConfiguredDevicesKeys = useMemo(() => {
    const fieldsDevices = _.chain(season)
      .get('farms')
      .values()
      .flatMap((farm) => _.chain(farm).get('fields').values().value())
      .flatMap((field) => _.get(field, 'devices'))
      .value();
    const seasonDevices = _.chain(season).get('devices', []).value();
    return _.chain([...fieldsDevices, ...seasonDevices])
      .map((device) =>
        _.get(device, 'vendor') === growerDevicesVendors.Aquarius.type
          ? `${_.get(device, 'deviceId')}-${_.get(device, 'deviceType')}`
          : _.get(device, 'macAddress')
      )
      .keyBy((d) => d)
      .value();
  }, [season]);

  const devices = useMemo(() => {
    const filteredDevices = _.filter(
      devicesFromResponse,
      (device) =>
        !_.has(
          seasonConfiguredDevicesKeys,
          _.get(device, 'vendor') === growerDevicesVendors.Aquarius.type
            ? `${_.get(device, 'deviceId')}-${_.get(device, 'deviceType')}`
            : _.get(device, 'macAddress')
        ) &&
        ((!configureFieldDevice &&
          _.get(device, 'vendor') === growerDevicesVendors.Ambient.type) ||
          _.includes(
            configureFieldDevice
              ? aquariusFieldDevicesTypes
              : aquariusSeasonDevicesTypes,
            _.get(device, 'deviceType')
          )) &&
        (!isEdit || _.get(device, 'deviceType') === deviceToEditType)
    );
    return deviceToEdit ? [deviceToEdit, ...filteredDevices] : filteredDevices;
  }, [
    devicesFromResponse,
    deviceToEdit,
    seasonConfiguredDevicesKeys,
    configureFieldDevice,
    isEdit,
    deviceToEditType
  ]);

  const isPumpController =
    _.get(selectedDevice, 'vendor') === growerDevicesVendors.Aquarius.type &&
    _.get(selectedDevice, 'deviceType') ===
      aquariusGrowerDevicesTypes.PumpController.deviceType;

  const hasDevices = !_.isEmpty(devices);
  const selectedDeviceId = _.get(selectedDevice, 'id') || '';

  useEffect(() => {
    register(
      {
        name: 'latitude'
      },
      {
        required: 'Latitude is required',
        min: {
          value: -90,
          message: 'Latitude value must be between -90 and 90'
        },
        max: {
          value: 90,
          message: 'Latitude value must be between -90 and 90'
        },
        valueAsNumber: true
      }
    );

    return () => unregister('latitude');
  }, [register, unregister]);

  useEffect(() => {
    register(
      {
        name: 'longitude'
      },
      {
        required: 'Longitude is required',
        min: {
          value: -180,
          message: 'Longitude value must be between -180 and 180'
        },
        max: {
          value: 180,
          message: 'Longitude value must be between -180 and 180'
        },
        valueAsNumber: true
      }
    );

    return () => unregister('longitude');
  }, [register, unregister]);

  useEffect(() => {
    setValue('latitude', latitude);
  }, [latitude, setValue]);

  useEffect(() => {
    setValue('longitude', longitude);
  }, [longitude, setValue]);

  useEffect(() => {
    if (!hasDevices) {
      onSensorTypeChange(undefined);
      onVendorChange(undefined);
      return;
    }
    if (!deviceToEdit) {
      const device = _.first(devices);
      setSelectedDevice(device);
      onSensorTypeChange(_.get(device, 'deviceType'));
      onVendorChange(_.get(device, 'vendor'));
    } else {
      setSelectedDevice(deviceToEdit);
      onSensorTypeChange(_.get(deviceToEdit, 'deviceType'));
      onVendorChange(_.get(deviceToEdit, 'vendor'));
    }
  }, [
    deviceToEdit,
    devices,
    existingDevice,
    hasDevices,
    onSensorTypeChange,
    onVendorChange
  ]);

  useEffect(() => {
    dispatch(getGrowerDevicesActions.request(growerId));
  }, [dispatch, growerId]);

  useEffect(() => {
    if (!!latitude) {
      setTxtLat(latitude.toString());
    }
  }, [latitude]);

  useEffect(() => {
    if (!!longitude) {
      setTxtLon(longitude.toString());
    }
  }, [longitude]);

  useEffect(() => {
    if (saveSuccess) {
      dispatch(addSeasonFieldDeviceActions.clear());
      dispatch(updateSeasonFieldDeviceActions.clear());
      dispatch(addSeasonDeviceActions.clear());
      dispatch(updateSeasonDeviceActions.clear());
      dispatch(getSeasonsActions.request(growerId));
      onCancel();
    }
  }, [dispatch, growerId, onCancel, saveSuccess]);

  const handleSelectedDeviceChange = useCallback(
    (e) => {
      const deviceId = e.target.value;
      const device = _.find(devices, (d) => d.id === deviceId);
      setSelectedDevice(device);
      onSensorTypeChange(_.get(device, 'deviceType'));
      onVendorChange(_.get(device, 'vendor'));
    },
    [devices, onSensorTypeChange, onVendorChange]
  );

  const handleLatitudeChange = useCallback(
    (e) => {
      const lat = Number.parseFloat(e.target.value);
      if (_.isNaN(lat)) {
        onLatitudeChange(null);
      } else {
        onLatitudeChange(lat);
      }
      setTxtLat(e.target.value);
    },
    [onLatitudeChange]
  );

  const handleLongitudeChange = useCallback(
    (e) => {
      const lon = Number.parseFloat(e.target.value);
      if (_.isNaN(lon)) {
        onLongitudeChange(null);
      } else {
        onLongitudeChange(lon);
      }
      setTxtLon(e.target.value);
    },
    [onLongitudeChange]
  );

  const handleMoistureChannelEnabledToggle = useCallback((channelId) => {
    setMoistureSensorChannels((channels) => ({
      ...channels,
      [channelId]: {
        ..._.get(channels, [channelId]),
        enabled: !_.get(channels, [channelId, 'enabled'])
      }
    }));
  }, []);

  const handleFormSubmit = useCallback(
    (params) => {
      if (!sensorInField || !selectedDevice) {
        return;
      }

      const deviceType = _.get(selectedDevice, 'deviceType');
      const vendor = _.get(selectedDevice, 'vendor');

      const sensor = {
        vendor: vendor,
        name: _.get(params, 'name'),
        location: {
          type: 'Point',
          coordinates: [_.get(params, 'longitude'), _.get(params, 'latitude')]
        },
        startDate: !isEdit
          ? startDate.startOf('day').toISOString()
          : moment().toISOString()
      };

      if (vendor === growerDevicesVendors.Aquarius.type) {
        sensor.deviceType = deviceType;
        sensor.deviceId = _.get(selectedDevice, 'deviceId');

        if (
          deviceType === aquariusGrowerDevicesTypes.WaterLevelSensor.deviceType
        ) {
          sensor.waterLevelOffset = _.get(params, 'offset');
        }

        if (
          deviceType === aquariusGrowerDevicesTypes.MoistureSensor.deviceType
        ) {
          sensor.moistureChannels = _.chain(params)
            .get('moistureChannels')
            .map((channel, idx) => {
              if (channel) {
                return { ...channel, channel: idx, enabled: true };
              }
              return { channel: idx, enabled: false };
            })
            .value();
        }

        if (
          deviceType === aquariusGrowerDevicesTypes.PumpController.deviceType
        ) {
          sensor.name = _.get(selectedDevice, 'name');
          sensor.customerId = _.get(selectedDevice, 'customerId');
          sensor.pumpType = _.get(selectedDevice, 'pumpType');
          sensor.timeoutSeconds = _.get(selectedDevice, 'timeoutSeconds');
          sensor.cellBased = _.get(selectedDevice, 'cellBased');
          sensor.smsNumber = _.get(selectedDevice, 'smsNumber');
          sensor.allowAllValvesClosed = _.get(
            selectedDevice,
            'allowAllValvesClosed'
          );
          sensor.hasFlowMeter = _.get(selectedDevice, 'hasFlowMeter');
          sensor.hasFlowMeter = _.get(selectedDevice, 'hasFlowMeter');
          sensor.vfdMin = _.get(selectedDevice, 'vfdMin');
          sensor.vfdMax = _.get(selectedDevice, 'vfdMax');
          sensor.flowMax = _.get(selectedDevice, 'flowMax');
        }
      }

      if (vendor === growerDevicesVendors.Ambient.type) {
        sensor.apiKey = _.get(selectedDevice, 'apiKey');
        sensor.macAddress = _.get(selectedDevice, 'macAddress');
      }

      if (configureFieldDevice) {
        if (isEdit) {
          dispatch(
            updateSeasonFieldDeviceActions.request(
              growerId,
              seasonId,
              farmId,
              fieldId,
              deviceToEditId,
              sensor
            )
          );
        } else {
          dispatch(
            addSeasonFieldDeviceActions.request(
              growerId,
              seasonId,
              farmId,
              fieldId,
              sensor
            )
          );
        }
      } else {
        if (isEdit) {
          dispatch(
            updateSeasonDeviceActions.request(
              growerId,
              seasonId,
              deviceToEditId,
              sensor
            )
          );
        } else {
          dispatch(addSeasonDeviceActions.request(growerId, seasonId, sensor));
        }
      }
    },
    [
      configureFieldDevice,
      deviceToEditId,
      dispatch,
      farmId,
      fieldId,
      growerId,
      isEdit,
      seasonId,
      selectedDevice,
      sensorInField,
      startDate
    ]
  );

  const handleCancel = useCallback(() => {
    dispatch(addSeasonFieldDeviceActions.clear());
    onCancel();
  }, [dispatch, onCancel]);

  const handleDateChange = useCallback(
    (date) => {
      setValue('date', date);
    },
    [setValue]
  );

  return (
    <form noValidate onSubmit={handleSubmit(handleFormSubmit)}>
      <Paper elevation={10} className={styles.container}>
        {!inProgress && !devicesErrorMessage && hasDevices && (
          <>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              id="device"
              label="Device"
              name="device"
              value={selectedDeviceId}
              onChange={handleSelectedDeviceChange}
            >
              {_.map(devices, (d) => (
                <MenuItem key={_.get(d, 'id')} value={_.get(d, 'id')}>
                  {_.get(d, 'vendor') === growerDevicesVendors.Ambient.type
                    ? `Weather Station (${_.get(d, 'macAddress')})`
                    : `${_.get(aquariusGrowerDevicesTypesByDeviceType, [
                        _.get(d, 'deviceType'),
                        'name'
                      ])} (${_.get(d, 'deviceId')})`}
                </MenuItem>
              ))}
            </TextField>
            {!isPumpController && (
              <TextField
                inputRef={register({
                  required: 'Name is required'
                })}
                variant="outlined"
                size="small"
                fullWidth
                required
                id="name"
                label="Name"
                name="name"
                error={!!_.get(errors, 'name')}
                helperText={_.get(errors, 'name.message')}
              />
            )}
            {!!isPumpController && (
              <Typography>{_.get(selectedDevice, 'name')}</Typography>
            )}
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              required
              type="number"
              id="latitude"
              label="Latitude"
              name="latitude"
              value={txtLat}
              onChange={handleLatitudeChange}
              error={!!_.get(errors, 'latitude')}
              helperText={_.get(errors, 'latitude.message')}
            />
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              required
              type="number"
              id="longitude"
              label="Longitude"
              name="longitude"
              value={txtLon}
              onChange={handleLongitudeChange}
              error={!!_.get(errors, 'longitude')}
              helperText={_.get(errors, 'longitude.message')}
            />
            {!isEdit && (
              <>
                <Grid item xs={12}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      autoOk
                      disableToolbar
                      variant="inline"
                      format="L"
                      id="startDate"
                      name="startDate"
                      label="Start Date"
                      value={startDate}
                      onChange={handleDateChange}
                      minDate={minDate}
                      disableFuture
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                {dateError && (
                  <Grid item xs={12}>
                    <FormHelperText error>{dateError}</FormHelperText>
                  </Grid>
                )}
              </>
            )}
            {sensorType ===
              aquariusGrowerDevicesTypes.MoistureSensor.deviceType && (
              <Box className={styles.moistureChannels}>
                {_.map(_.range(4), (channelId) => (
                  <MoistureSensorChannel
                    key={channelId}
                    channelId={channelId}
                    channelConfig={_.get(moistureSensorChannels, [channelId])}
                    onMoistureChannelEnabledToggle={
                      handleMoistureChannelEnabledToggle
                    }
                    register={register}
                    errors={errors}
                  />
                ))}
              </Box>
            )}
            {sensorType ===
              aquariusGrowerDevicesTypes.WaterLevelSensor.deviceType && (
              <TextField
                inputRef={register({
                  required: 'Offset is required',
                  min: 0,
                  valueAsNumber: true
                })}
                variant="outlined"
                size="small"
                fullWidth
                required
                type="number"
                id="offset"
                label="Offset"
                name="offset"
                error={!!_.get(errors, 'offset')}
                helperText={_.get(errors, 'offset.message')}
              />
            )}
            {!sensorInField && (
              <FormHelperText error>
                Sensor is outside of the field
              </FormHelperText>
            )}
            {!!saveErrorMessage && (
              <FormHelperText error>{saveErrorMessage}</FormHelperText>
            )}
            <Box className={styles.buttonsContainer}>
              <Button
                variant="contained"
                size="small"
                color="default"
                disabled={saveInProgress}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                size="small"
                color="primary"
                disabled={saveInProgress}
              >
                Save
              </Button>
            </Box>
          </>
        )}
        {!inProgress && !devicesErrorMessage && !hasDevices && (
          <>
            <Box className={styles.noDevicesContainer}>
              <Typography>There are no devices available.</Typography>
              <Button
                size="small"
                component={RouterLink}
                to={`/growersConfig/${growerId}/devices`}
              >
                Please configure devices.
              </Button>
            </Box>
            <Box className={styles.buttonsContainer}>
              <Button
                variant="contained"
                size="small"
                color="default"
                disabled={saveInProgress}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Box>
          </>
        )}
        {inProgress && <LoadingIndicator />}
        {!inProgress && devicesErrorMessage && (
          <Typography>{devicesErrorMessage}</Typography>
        )}
      </Paper>
    </form>
  );
}
