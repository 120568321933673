import { useMemo } from 'react';

import { growerProtocolsConfigService } from '../../../../../services/growerProtocolsConfig.service';
import { createApiSlice } from '../../../utils/createApiSlice';

const deleteGrowerProtocolSlice = createApiSlice({
  reducerPath: ['gff', 'growers', 'protocols', 'deleteGrowerProtocol'],
  apiFunction: growerProtocolsConfigService.deleteProtocolV2,
  apiArgumentsFn: ({ growerId, bundleId, protocolId }) => [
    growerId,
    bundleId,
    protocolId
  ]
});

const { actions, useApiSelectors } = deleteGrowerProtocolSlice;

export const deleteGrowerProtocolActions = actions;

export const useDeleteGrowerProtocolSelectors = () => {
  const { inProgress, success, errorMessage, response, updatedAt } =
    useApiSelectors();

  return useMemo(
    () => ({ inProgress, success, errorMessage, response, updatedAt }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default deleteGrowerProtocolSlice;
