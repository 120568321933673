import { useMemo } from 'react';

import { growerBundlesConfigService } from '../../../../../services/growerBundlesConfig.service';
import { createApiSlice } from '../../../utils/createApiSlice';

const cloneGrowerBundleSlice = createApiSlice({
  reducerPath: ['gff', 'growers', 'bundles', 'cloneGrowerBundle'],
  apiFunction: growerBundlesConfigService.cloneBundleV2,
  apiArgumentsFn: ({ growerId, bundleId, bundle }) => [
    growerId,
    bundleId,
    bundle
  ]
});

const { actions, useApiSelectors } = cloneGrowerBundleSlice;

export const cloneGrowerBundleActions = actions;

export const useCloneGrowerBundleSelectors = () => {
  const { inProgress, success, errorMessage, response, clonedAt } =
    useApiSelectors();

  return useMemo(
    () => ({ inProgress, success, errorMessage, response, clonedAt }),
    [errorMessage, inProgress, response, success, clonedAt]
  );
};

export default cloneGrowerBundleSlice;
