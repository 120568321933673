import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';

import { usePagination } from '../../_hooks/usePagination';

import { AccordionDataRow } from './AccordionDataRow';
import CommonPagination from './CommonPagination';
import { UtilityButtons } from './UtilityButtons';

const useAccordionContentStyles = makeStyles(() => ({
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

function AccordionContent({ headers, dataRow, onSelectItemToDelete }) {
  const styles = useAccordionContentStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className={styles.buttonsContainer}>
        <UtilityButtons
          dataRow={dataRow}
          onSelectItemToDelete={onSelectItemToDelete}
        />
      </Grid>
      {_.map(headers, (header, idx) => {
        const value = _.get(dataRow, ['values', idx]);
        return (
          <Grid item xs={12} key={idx}>
            {typeof value !== 'boolean' && (
              <AccordionDataRow title={header} text={value} />
            )}
            {typeof value === 'boolean' && (
              <AccordionDataRow title={header}>
                <Box className={styles.buttonsContainer}>
                  <Checkbox checked={value} />
                </Box>
              </AccordionDataRow>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
}

const useDataAccordionStyles = makeStyles(() => ({
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem'
  }
}));

export function DataAccordion({
  headers,
  data,
  onSelectItemToDelete,
  enablePagination = false,
  paginationKey
}) {
  const styles = useDataAccordionStyles();

  const paginationControls = usePagination({ items: data, key: paginationKey });
  const { pagedItems } = paginationControls;

  const dataToRender = enablePagination ? pagedItems : data;

  return (
    <>
      {dataToRender.map((dataRow) => (
        <Accordion key={dataRow.id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id={`${dataRow.id}-header`}
          >
            <Typography>{dataRow.itemName}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AccordionContent
              headers={headers}
              dataRow={dataRow}
              onSelectItemToDelete={onSelectItemToDelete}
            />
          </AccordionDetails>
        </Accordion>
      ))}
      <Box className={styles.paginationContainer}>
        <CommonPagination paginationControls={paginationControls} />
      </Box>
    </>
  );
}
