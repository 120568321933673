import { useContext, useMemo } from 'react';

import { faCloudSunRain } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

import { userRoles } from '../../_constants/userConstants';
import { FarmStructureContext } from '../../_context/FarmStructureContext';
import { useActiveGrowth } from '../../_hooks/Growths/useActiveGrowth';
import { useFieldWithGrowthSelector } from '../../_store/selectors/fieldsSelectors';
import {
  LocationSources,
  useWeatherDashboardSelectors
} from '../../_store/slices/ui/weather/weatherDashboardSlice';

const WeatherDashboardPageKey = 'WEATHER_DASHBOARD_PAGE';

export const DASHBOARD_TABS = {
  CURRENT_CONDITIONS: 'current-conditions',
  FORECAST: 'forecast',
  HISTORICAL: 'historical',
  GROWTH: 'growth'
};

export const WeatherPageSubpageKeys = {
  CurrentConditions: `${WeatherDashboardPageKey}_CURRENT_CONDITIONS`,
  Forecast: `${WeatherDashboardPageKey}_FORECAST`,
  Historical: `${WeatherDashboardPageKey}_HISTORICAL`,
  Growth: `${WeatherDashboardPageKey}_GROWTH`
};

const baseConfig = {
  key: WeatherDashboardPageKey,
  icon: faCloudSunRain,
  label: 'Weather',
  path: 'weather',
  allowedRoles: [
    userRoles.Admin,
    userRoles.Consultant,
    userRoles.User,
    userRoles.UserView
  ],
  subPages: [
    {
      key: WeatherPageSubpageKeys.CurrentConditions,
      label: 'Current Conditions',
      path: DASHBOARD_TABS.CURRENT_CONDITIONS
    },
    {
      key: WeatherPageSubpageKeys.Forecast,
      label: 'Forecast',
      path: DASHBOARD_TABS.FORECAST
    },
    {
      key: WeatherPageSubpageKeys.Historical,
      label: 'Historical',
      path: DASHBOARD_TABS.HISTORICAL
    },
    {
      key: WeatherPageSubpageKeys.Growth,
      label: 'Growth',
      path: DASHBOARD_TABS.GROWTH
    }
  ]
};

export const useWeatherDashboardPageConfig = () => {
  const { locationSource } = useWeatherDashboardSelectors();

  const showFieldLocation = locationSource === LocationSources.Field;

  const {
    selectedGrowerId,
    selectedSeasonId,
    selectedFarmId,
    selectedFieldId
  } = useContext(FarmStructureContext);

  const { field } = useFieldWithGrowthSelector(
    selectedGrowerId,
    selectedSeasonId,
    selectedFarmId,
    selectedFieldId
  );

  const { activeGrowth } = useActiveGrowth(field);
  const hasGrowth = !!_.get(activeGrowth, 'plantingDate');

  const config = useMemo(
    () => ({
      ...baseConfig,
      subPages: _.chain(baseConfig)
        .get('subPages')
        .filter((subPage) => {
          const key = _.get(subPage, 'key');
          if (
            !(hasGrowth && showFieldLocation) &&
            key === WeatherPageSubpageKeys.Growth
          ) {
            return false;
          }
          return !(
            locationSource === LocationSources.WeatherStation &&
            key === WeatherPageSubpageKeys.Forecast
          );
        })
        .value()
    }),
    [hasGrowth, locationSource, showFieldLocation]
  );

  return useMemo(() => ({ config }), [config]);
};
