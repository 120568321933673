import { useMemo } from 'react';

import { growerProtocolsConfigService } from '../../../../../services/growerProtocolsConfig.service';
import { createApiSlice } from '../../../utils/createApiSlice';

const deleteGrowerActivitySlice = createApiSlice({
  reducerPath: ['gff', 'growers', 'protocols', 'deleteGrowerActivity'],
  apiFunction: growerProtocolsConfigService.deleteActivityV2,
  apiArgumentsFn: ({ growerId, bundleId, protocolId, activityId }) => [
    growerId,
    bundleId,
    protocolId,
    activityId
  ]
});

const { actions, useApiSelectors } = deleteGrowerActivitySlice;

export const deleteGrowerActivityActions = actions;

export const useDeleteGrowerActivitySelectors = () => {
  const { inProgress, success, errorMessage, response, updatedAt } =
    useApiSelectors();

  return useMemo(
    () => ({ inProgress, success, errorMessage, response, updatedAt }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default deleteGrowerActivitySlice;
