import { useMemo } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';

import { createDataSlice } from '../utils/createDataSlice';

const menuSlice = createDataSlice({
  reducerPath: ['ui', 'menu'],
  initialState: {
    openSideMenu: true,
    showMobileSideMenu: false,
    expandedMenuItems: {}
  },
  reducers: {
    toggleMobileSideMenu(state) {
      state.showMobileSideMenu = !state.showMobileSideMenu;
    },
    toggleSideMenu(state) {
      state.openSideMenu = !state.openSideMenu;
    },
    toggleMenuItem(state, action) {
      const key = action.payload;
      _.set(
        state,
        ['expandedMenuItems', key],
        !_.get(state, ['expandedMenuItems', key])
      );
    }
  }
});

const { actions, baseSelector: menuSelector } = menuSlice;

export const menuActions = actions;

export const useMenuSelectors = () => {
  const { openSideMenu, showMobileSideMenu } = useSelector(menuSelector);

  return useMemo(
    () => ({
      openSideMenu,
      showMobileSideMenu
    }),
    [openSideMenu, showMobileSideMenu]
  );
};

export const useMenuItemState = (key) => {
  const { expandedMenuItems } = useSelector(menuSelector);
  const isExpanded = !!_.get(expandedMenuItems, [key]);

  return useMemo(
    () => ({
      isExpanded
    }),
    [isExpanded]
  );
};

export default menuSlice;
