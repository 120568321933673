import { gffConfigActionTypes } from '../actionTypes/gffConfigActionTypes';

export const updateGffConfig = (growerId, farmId, fieldId) => ({
  type: gffConfigActionTypes.UPDATE_GFF_CONFIG,
  payload: {
    growerId,
    farmId,
    fieldId
  }
});
