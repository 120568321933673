import {
  ContentBody,
  ContentHeader,
  FormContainer
} from '../../../Layout/Content';

import GrowthStagesColorsConfig from './GrowthStages/GrowthStagesColorsConfig';

const GrowthStagesConfigContent = () => {
  return (
    <FormContainer>
      <ContentHeader>Growth Model - Growth Stages Colors</ContentHeader>
      <ContentBody>
        <GrowthStagesColorsConfig />
      </ContentBody>
    </FormContainer>
  );
};

export default GrowthStagesConfigContent;
