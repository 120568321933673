import React from 'react';

import DialogContent from '@material-ui/core/DialogContent';
import PerfectScrollbar from 'react-perfect-scrollbar';

const ResponsiveDialogContent = ({ children, ...props }) => {
  return (
    <PerfectScrollbar>
      <DialogContent {...props}>{children}</DialogContent>
    </PerfectScrollbar>
  );
};

export default ResponsiveDialogContent;
