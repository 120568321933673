import React from 'react';

import { activityTypes } from '../../../_store/selectors/configSelectors';

import ActivitiesReport from './ActivitiesReport';

const NitrogenReport = () => {
  return (
    <ActivitiesReport
      title="Nitrogen Report"
      activityType={activityTypes.NitrogenApplication.type}
    />
  );
};

export default NitrogenReport;
