import React, { useContext } from 'react';

import { Card, CardContent, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';
import moment from 'moment/moment';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  FeedbackTargetType,
  ZonedActivityTypes
} from '../../_constants/activitiesConstants';
import { FarmStructureContext } from '../../_context/FarmStructureContext';
import GrowthStageBadge from '../Growths/GrowthStageBadge';

import { ActivityTypes } from './CompletedActivitiesTimeline';
import FeedbackTargets from './FeedbackTargets';
import GoToZonedActivityButton from './GoToZonedActivityButton';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    flexWrap: 'nowrap',
    '& > :not(:first-child)': {
      marginLeft: '0.75rem'
    },
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    paddingTop: '0.5rem',
    paddingBottom: '1rem'
  },
  card: {
    flex: '0 0 auto'
  },
  title: {
    textAlign: 'center',
    marginBottom: '0.5rem'
  },
  description: {
    marginTop: '0.25rem',
    fontWeight: 450
  },
  eventName: {
    marginTop: '0.25rem',
    fontWeight: 450
  },
  targetsContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  fieldsTitle: { marginTop: '1rem', marginBottom: '0.5rem' }
}));

const ZonedActivity = ({ activity }) => {
  const styles = useStyles();

  const { selectedFieldId } = useContext(FarmStructureContext);

  const description = _.get(activity, 'description');
  const type = _.get(activity, 'type');
  const growthStage = _.get(activity, 'stage');
  const target = _.get(activity, ['targetsByFieldId', selectedFieldId]);
  const targetType = _.get(target, 'type');

  return (
    <>
      {type === ZonedActivityTypes.CustomNote && (
        <>
          <Typography style={{ marginTop: '0.5rem' }}>Note:</Typography>
          <Typography className={styles.eventName}>
            {_.get(activity, 'name')}
          </Typography>
        </>
      )}
      {type === ZonedActivityTypes.GrowthStage && (
        <>
          <Typography style={{ marginBottom: '0.25rem' }}>
            Growth Stage:
          </Typography>
          <GrowthStageBadge growthStage={growthStage} small />
        </>
      )}
      {!_.isEmpty(description) && (
        <>
          <Typography style={{ marginTop: '0.5rem' }}>Description:</Typography>
          <Typography className={styles.description}>{description}</Typography>
        </>
      )}
      {targetType === FeedbackTargetType.Zones && (
        <>
          <Typography className={styles.fieldsTitle}>Zones:</Typography>
          <Box className={styles.targetsContainer}>
            <FeedbackTargets targets={[target]} />
          </Box>
        </>
      )}
    </>
  );
};

const CompletedActivity = ({ activity }) => {
  const styles = useStyles();

  const description = _.get(activity, 'description');

  return <Typography className={styles.description}>{description}</Typography>;
};

const Activity = ({ activity }) => {
  const styles = useStyles();

  const activityDate = _.get(activity, 'activityDate');
  const type = _.get(activity, 'activityType');
  const photos = _.get(activity, 'photos');

  return (
    <Card elevation={3} className={styles.card}>
      <CardContent>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="0.5rem"
        >
          <Typography className={styles.title}>
            {type === ActivityTypes.ZonedActivity && 'Zoned Activity'}
            {type === ActivityTypes.CompletedRecommendation &&
              'Completed Recommendation'}
          </Typography>
          {type === ActivityTypes.ZonedActivity && (
            <GoToZonedActivityButton activity={activity} />
          )}
        </Box>
        {!_.isEmpty(photos) && (
          <Typography>
            Photos:
            <b> {_.get(photos, 'length')}</b>
          </Typography>
        )}
        <Typography>
          Completed:
          <b> {moment(activityDate).format('ll')}</b>
        </Typography>
        {type === ActivityTypes.ZonedActivity && (
          <ZonedActivity activity={activity} />
        )}
        {type === ActivityTypes.CompletedRecommendation && (
          <CompletedActivity activity={activity} />
        )}
      </CardContent>
    </Card>
  );
};

const FieldCompletedActivities = ({ allActivities }) => {
  const styles = useStyles();

  return (
    <PerfectScrollbar className={styles.container}>
      {_.map(allActivities, (activity) => (
        <Activity key={_.get(activity, 'id')} activity={activity} />
      ))}
    </PerfectScrollbar>
  );
};

export default FieldCompletedActivities;
