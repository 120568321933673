import { useMemo } from 'react';

import { bundlesConfigService } from '../../../../services/bundlesConfig.service';
import { createApiSlice } from '../../utils/createApiSlice';

const createBundleSlice = createApiSlice({
  reducerPath: ['newConfig', 'bundles', 'createBundle'],
  apiFunction: bundlesConfigService.createBundleV2,
  apiArgumentsFn: ({ bundle }) => [bundle]
});

const { actions, useApiSelectors } = createBundleSlice;

export const createBundleActions = actions;

export const useCreateBundleSelectors = () => {
  const { inProgress, success, errorMessage, response, updatedAt } =
    useApiSelectors();

  return useMemo(
    () => ({ inProgress, success, errorMessage, response, updatedAt }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default createBundleSlice;
