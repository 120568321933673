import { useMemo } from 'react';

import { protocolsConfigService } from '../../../../services/protocolsConfig.service';
import { createApiSlice } from '../../utils/createApiSlice';

const deleteActivitySlice = createApiSlice({
  reducerPath: ['newConfig', 'protocols', 'deleteActivity'],
  apiFunction: protocolsConfigService.deleteActivityV2,
  apiArgumentsFn: ({ bundleId, protocolId, activityId }) => [
    bundleId,
    protocolId,
    activityId
  ]
});

const { actions, useApiSelectors } = deleteActivitySlice;

export const deleteActivityActions = actions;

export const useDeleteActivitySelectors = () => {
  const { inProgress, success, errorMessage, response, updatedAt } =
    useApiSelectors();

  return useMemo(
    () => ({ inProgress, success, errorMessage, response, updatedAt }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default deleteActivitySlice;
