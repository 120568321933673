import { useContext, useMemo } from 'react';

import _ from 'lodash';

import { FarmStructureContext } from '../../_context/FarmStructureContext';
import { useFarmWithGrowthsSelectors } from '../../_store/selectors/farmsSelectors';

export const useFarmWorkOrderItems = () => {
  const {
    selectedGrowerId: growerId,
    selectedSeasonId: seasonId,
    selectedFarmId: farmId,
    selectedFieldId: fieldId
  } = useContext(FarmStructureContext);

  const { farm } = useFarmWithGrowthsSelectors(growerId, seasonId, farmId);

  const workOrders = useMemo(() => {
    const orders = _.get(farm, 'workOrders', []);
    if (!fieldId) {
      return orders;
    }

    return _.filter(orders, (f) =>
      _.chain(f).get('targetsByFieldId').has(fieldId).value()
    );
  }, [farm, fieldId]);

  return useMemo(
    () => ({
      workOrders
    }),
    [workOrders]
  );
};
