import React from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import cx from 'classnames';

const useStyles = makeStyles(() => ({
  hidden: {
    display: 'none'
  }
}));

const ReportsDataGridWrapper = ({ hide, children }) => {
  const styles = useStyles();

  return <Box className={cx({ [styles.hidden]: hide })}>{children}</Box>;
};

export default ReportsDataGridWrapper;
