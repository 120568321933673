import React, { useCallback, useContext, useMemo, useState } from 'react';

import { faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import EditIcon from '@material-ui/icons/Edit';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import RefreshIcon from '@material-ui/icons/Refresh';
import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { growthStagesTypes } from '../../../_constants/growthsConstants';
import { AuthContext } from '../../../_context/AuthContext';
import { FarmStructureContext } from '../../../_context/FarmStructureContext';
import { useActiveGrowth } from '../../../_hooks/Growths/useActiveGrowth';
import { openModifyGrowthDialog } from '../../../_store/actions/uiActions';
import { useAllGrowerFieldsSelector } from '../../../_store/selectors/fieldsSelectors';
import { bulkUpdateGrowthModelsActions } from '../../../_store/slices/growths/bulkUpdateGrowthModelsSlice';
import GrowthStageBadge from '../../../Components/Growths/GrowthStageBadge';
import { useTimelineGrowths } from '../../../Components/Growths/GrowthTimeline/_hooks/useTimelineGrowths';
import LoadingIndicator from '../../../Components/LoadingIndicator';

import FieldCollapse from './FieldCollapse';
import Variety from './Variety';

const useStyles = makeStyles(() => ({
  selectedFieldCell: { fontWeight: 'bold' },
  selectedFieldRow: { backgroundColor: '#FAFAFA' },
  table: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderBottom: '1px solid rgba(224, 224, 224, 1)'
  },
  header: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem'
  },
  cell: {
    display: 'flex',
    minWidth: 0,
    flexBasis: 0,
    padding: '1rem',
    fontSize: '0.875rem',
    alignItems: 'center'
  },
  farmCell: {
    flexGrow: 1
  },
  fieldCell: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  seedProductCell: {
    flexGrow: 1.25
  },
  plantingCell: {
    flexGrow: 0.75,
    justifyContent: 'flex-end'
  },
  growthStageCell: {
    flexGrow: 1.5,
    justifyContent: 'center'
  },
  estimatedHarvestCell: {
    flexGrow: 1.5,
    justifyContent: 'flex-end'
  },
  buttonCell: {
    minWidth: '124px',
    flexGrow: 0.5
  },
  referenceIcon: {
    fontSize: '0.75rem',
    marginRight: '0.5rem'
  }
}));

const FieldRow = ({ field }) => {
  const styles = useStyles();

  const dispatch = useDispatch();

  const { isUserView, isAdmin } = useContext(AuthContext);

  const [open, setOpen] = useState(false);

  const { selectedFieldId, isHistoricalSeason } =
    useContext(FarmStructureContext);

  const isSelected = _.get(field, 'id') === selectedFieldId;

  const { activeGrowth } = useActiveGrowth(field);

  const varietyId = _.get(activeGrowth, 'varietyId');
  const isReference = _.get(activeGrowth, 'isReference');

  const plantingDate = useMemo(() => {
    const date = _.get(activeGrowth, 'plantingDate');
    return !!date ? moment(date) : null;
  }, [activeGrowth]);

  const growerId = useMemo(() => _.get(field, 'growerId'), [field]);
  const seasonId = useMemo(() => _.get(field, 'seasonId'), [field]);
  const farmId = useMemo(() => _.get(field, 'farmId'), [field]);
  const fieldId = useMemo(() => _.get(field, 'id'), [field]);
  const growthId = useMemo(() => _.get(field, 'activeGrowth.id'), [field]);

  const growthStage = useMemo(
    () =>
      _.get(
        activeGrowth,
        'estimatedCurrentGrowthStage',
        growthStagesTypes.Unknown
      ),
    [activeGrowth]
  );

  const stageObserved = useMemo(
    () => _.get(activeGrowth, 'stageObserved', false),
    [activeGrowth]
  );
  const stagePending = useMemo(
    () => _.get(activeGrowth, 'stagePending', false),
    [activeGrowth]
  );

  const harvestDate = _.get(activeGrowth, 'harvestDate');

  const handleEditButtonClick = useCallback(() => {
    dispatch(
      openModifyGrowthDialog(growerId, seasonId, farmId, fieldId, growthId)
    );
  }, [dispatch, farmId, fieldId, growerId, growthId, seasonId]);

  const handleCollapseButtonClick = useCallback(() => {
    setOpen((open) => !open);
  }, []);

  const handleUpdateGrowthModel = useCallback(() => {
    dispatch(
      bulkUpdateGrowthModelsActions.submit({
        growerId,
        seasonId,
        data: [{ farmId, fieldId }]
      })
    );
  }, [dispatch, farmId, fieldId, growerId, seasonId]);

  return (
    <>
      <Box className={styles.row}>
        <Box
          className={cx(styles.cell, styles.farmCell, {
            [styles.selectedFieldCell]: isSelected
          })}
        >
          {_.get(field, 'farmName')}
        </Box>
        <Box
          className={cx(styles.cell, styles.fieldCell, {
            [styles.selectedFieldCell]: isSelected
          })}
        >
          {isAdmin && isReference && (
            <Tooltip title="Reference Growth">
              <Box>
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  className={styles.referenceIcon}
                />
              </Box>
            </Tooltip>
          )}
          <span>{_.get(field, 'name')}</span>
        </Box>
        <Box className={cx(styles.cell, styles.seedProductCell)}>
          {!!varietyId && <Variety varietyId={varietyId} />}
        </Box>
        <Box className={cx(styles.cell, styles.plantingCell)}>
          {!!plantingDate && plantingDate.format('ll')}
        </Box>
        <Box className={cx(styles.cell, styles.growthStageCell)}>
          <GrowthStageBadge
            growthStage={growthStage}
            stageObserved={stageObserved}
            stagePending={stagePending}
          />
        </Box>
        <Box className={cx(styles.cell, styles.estimatedHarvestCell)}>
          {!!harvestDate && moment(harvestDate).format('ll')}
        </Box>
        <Box className={cx(styles.cell, styles.buttonCell)}>
          {!isUserView && !isHistoricalSeason && growthId && (
            <IconButton size="small" onClick={handleEditButtonClick}>
              <EditIcon />
            </IconButton>
          )}
          {isAdmin && (
            <Tooltip title="Update Growth Model" placement="top">
              <IconButton size="small" onClick={handleUpdateGrowthModel}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          )}
          <IconButton size="small" onClick={handleCollapseButtonClick}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Box>
      </Box>
      <div>
        <FieldCollapse open={open} field={field} />
      </div>
    </>
  );
};

const FieldsTable = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const {
    isHistoricalSeason,
    selectedGrowerId: growerId,
    selectedSeasonId: seasonId
  } = useContext(FarmStructureContext);
  const { fields } = useAllGrowerFieldsSelector();
  const { isAdmin } = useContext(AuthContext);
  const { inProgress: activeGrowthsInProgress } = useTimelineGrowths();

  const handleUpdateGrowthModels = useCallback(() => {
    const data = _.map(fields, (field) => ({
      farmId: _.get(field, 'farmId'),
      fieldId: _.get(field, 'id')
    }));

    dispatch(
      bulkUpdateGrowthModelsActions.submit({
        growerId,
        seasonId,
        data
      })
    );
  }, [dispatch, fields, growerId, seasonId]);

  return (
    <>
      {isHistoricalSeason && activeGrowthsInProgress && <LoadingIndicator />}
      {(!isHistoricalSeason || !activeGrowthsInProgress) && (
        <Box className={styles.table}>
          <Box className={styles.row}>
            <Box className={cx(styles.cell, styles.header, styles.farmCell)}>
              Farm
            </Box>
            <Box className={cx(styles.cell, styles.header, styles.fieldCell)}>
              Field
            </Box>
            <Box
              className={cx(styles.cell, styles.header, styles.seedProductCell)}
            >
              Seed Product
            </Box>
            <Box
              className={cx(styles.cell, styles.header, styles.plantingCell)}
              align="center"
            >
              Planting Date
            </Box>
            <Box
              className={cx(styles.cell, styles.header, styles.growthStageCell)}
              align="center"
            >
              Growth Stage
            </Box>
            <Box
              className={cx(
                styles.cell,
                styles.header,
                styles.estimatedHarvestCell
              )}
              align="center"
            >
              Estimated Harvest Date
            </Box>
            <Box className={cx(styles.cell, styles.header, styles.buttonCell)}>
              {isAdmin && (
                <Box style={{ marginLeft: '31px' }}>
                  <Tooltip title="Update all Growth Models" placement="top">
                    <IconButton size="small" onClick={handleUpdateGrowthModels}>
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </Box>
          </Box>

          {_.map(fields, (field) => (
            <FieldRow key={_.get(field, 'id')} field={field} />
          ))}
        </Box>
      )}
    </>
  );
};

export default FieldsTable;
