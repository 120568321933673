import React, { useCallback } from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';
import { Marker } from 'react-map-gl';

const useStyles = makeStyles(() => ({
  marker: (props) => ({
    position: 'absolute',
    transform: `translate(-50%, ${props.verticalTranslate}%)`,
    cursor: props.clickable ? 'pointer' : 'default'
  })
}));

const FarmStructureMarker = ({
  item,
  children,
  onClick,
  verticalTranslate = 10
}) => {
  const styles = useStyles({ clickable: !!onClick, verticalTranslate });

  const isField = !!_.get(item, 'acres');

  const maxLat = _.get(item, 'boundingBox.3');

  const handleClick = useCallback(() => {
    onClick?.();
  }, [onClick]);

  return (
    <Marker
      latitude={isField ? maxLat : _.get(item, 'center.coordinates.1')}
      longitude={_.get(item, 'center.coordinates.0')}
    >
      <Box className={styles.marker} onClick={handleClick}>
        {children}
      </Box>
    </Marker>
  );
};

export default FarmStructureMarker;
