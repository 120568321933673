import React, { useCallback, useEffect, useMemo } from 'react';

import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import _ from 'lodash';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext
} from 'react-hook-form';
import { useDispatch } from 'react-redux';

import {
  createTankMixActions,
  useCreateTankMixSelectors
} from '../../../../_store/slices/config/tankMixes/createTankMixSlice';
import {
  getTankMixesConfigActions,
  useGetTankMixesConfigSelectors
} from '../../../../_store/slices/config/tankMixes/getTankMixesConfigSlice';
import {
  updateTankMixActions,
  useUpdateTankMixSelectors
} from '../../../../_store/slices/config/tankMixes/updateTankMixSlice';
import { TankMixUnit } from '../../../TaknMixDemo/Components/TankMixUnit';

const customProductId = '00000000-0000-0000-0000-000000000000';

const ConstituentForm = ({
  idx,
  onRemove,
  productsConfig,
  selectedProductsIds,
  defaultValues
}) => {
  const { register, errors, control, watch } = useFormContext();

  const basePath = `constituents[${idx}]`;
  const namePath = `${basePath}.name`;
  const ratePath = `${basePath}.sprayingRate`;
  const productIdPath = `${basePath}.productId`;
  const unitIdPath = `${basePath}.unit`;

  const productId = watch(productIdPath);
  const isCustom = productId === customProductId;

  const defaultProductId = _.get(defaultValues, 'productId', customProductId);
  const defaultName = _.get(defaultValues, 'name', '');
  const defaultRate = _.get(defaultValues, 'sprayingRate', 10);
  const defaultUnitId = _.get(
    defaultValues,
    'unit',
    _.chain(TankMixUnit).values().first().get('key').value()
  );

  const selectedProduct = useMemo(
    () => _.find(productsConfig, (p) => _.get(p, 'id') === productId),
    [productId, productsConfig]
  );

  const selectedProductUnitId = _.get(selectedProduct, 'unit');

  const availableProducts = useMemo(
    () =>
      _.filter(
        productsConfig,
        (productConfig) =>
          _.get(productConfig, 'id') === productId ||
          !_.has(selectedProductsIds, _.get(productConfig, 'id'))
      ),
    [productId, productsConfig, selectedProductsIds]
  );

  const handleRemove = useCallback(() => {
    onRemove(idx);
  }, [idx, onRemove]);

  return (
    <Grid
      item
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%'
      }}
    >
      <Controller
        control={control}
        id={productIdPath}
        name={productIdPath}
        defaultValue={defaultProductId}
        rules={{ required: true }}
        render={({ onChange, value }) => (
          <FormControl fullWidth>
            <InputLabel>Product</InputLabel>
            <Select
              fullWidth
              variant="outlined"
              value={value || null}
              onChange={(e) => onChange(e.target.value)}
            >
              {_.map(availableProducts, (productConfig) => (
                <MenuItem
                  key={_.get(productConfig, 'id')}
                  value={_.get(productConfig, 'id')}
                >
                  {_.get(productConfig, 'manufacturer')} -{' '}
                  {_.get(productConfig, 'name')}
                </MenuItem>
              ))}
              <MenuItem value={customProductId}>Custom</MenuItem>
            </Select>
          </FormControl>
        )}
      />
      {isCustom && (
        <TextField
          style={{ marginLeft: '0.5rem' }}
          inputRef={register({
            required: 'Name is required'
          })}
          defaultValue={defaultName}
          required
          fullWidth
          variant="outlined"
          id={namePath}
          label="Name"
          name={namePath}
          error={!!_.get(errors, [namePath])}
          helperText={_.get(errors, `${namePath}.message`)}
        />
      )}
      <TextField
        style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}
        inputRef={register({
          min: selectedProduct
            ? {
                value: _.get(selectedProduct, 'minRate'),
                message: `Spraying Rate must be greater than ${_.get(
                  selectedProduct,
                  'minRate'
                )}`
              }
            : undefined,
          max: selectedProduct
            ? {
                value: _.get(selectedProduct, 'maxRate'),
                message: `Spraying Rate must be less than ${_.get(
                  selectedProduct,
                  'maxRate'
                )}`
              }
            : undefined,
          valueAsNumber: true,
          required: 'Spraying Rate is required'
        })}
        defaultValue={defaultRate}
        required
        fullWidth
        variant="outlined"
        type="number"
        id={ratePath}
        label="Spraying Rate"
        name={ratePath}
        error={!!_.get(errors, `${ratePath}.message`)}
        helperText={_.get(errors, `${ratePath}.message`)}
        InputProps={{
          endAdornment: selectedProduct ? (
            <InputAdornment position="end">
              {_.get(TankMixUnit, [selectedProductUnitId, 'namePerAc'])}
            </InputAdornment>
          ) : undefined
        }}
      />
      {isCustom && (
        <Controller
          control={control}
          id={unitIdPath}
          name={unitIdPath}
          defaultValue={defaultUnitId}
          rules={{ required: true }}
          render={({ onChange, value }) => (
            <FormControl style={{ width: '25rem' }}>
              <InputLabel>Unit</InputLabel>
              <Select
                variant="outlined"
                value={value || null}
                onChange={(e) => onChange(e.target.value)}
              >
                {_.map(_.values(TankMixUnit), (unitConfig) => (
                  <MenuItem
                    key={_.get(unitConfig, 'key')}
                    value={_.get(unitConfig, 'key')}
                  >
                    {_.get(unitConfig, 'namePerAc')}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      )}
      <IconButton size="small" onClick={handleRemove}>
        <DeleteIcon />
      </IconButton>
    </Grid>
  );
};

const EditTankMixForm = ({ tankMix, onCancel }) => {
  const dispatch = useDispatch();

  const formMethods = useForm({
    defaultValues: tankMix
      ? _.pick(tankMix, ['name', 'description', 'constituents', 'carrierId'])
      : { name: '', description: '', constituents: [] }
  });

  const { control, register, errors, handleSubmit, watch } = formMethods;

  const {
    fields: constituentsFields,
    append,
    remove
  } = useFieldArray({
    control: control,
    name: 'constituents',
    keyName: 'arrayId'
  });

  const { response: tankMixesConfig } = useGetTankMixesConfigSelectors();

  const productsConfig = _.get(tankMixesConfig, 'products');
  const carriers = _.get(tankMixesConfig, 'carriers');

  const defaultCarrierId = _.chain(carriers).first().get('id').value();

  const selectedProducts = watch('constituents');
  const selectedProductsIds = useMemo(
    () =>
      _.chain(selectedProducts)
        .map((product) => _.get(product, 'productId'))
        .filter((productId) => productId !== customProductId)
        .keyBy((it) => it)
        .value(),
    [selectedProducts]
  );

  const {
    errorMessage: createErrorMessage,
    inProgress: createInProgress,
    success: createSuccess
  } = useCreateTankMixSelectors();

  const {
    errorMessage: updateErrorMessage,
    inProgress: updateInProgress,
    success: updateSuccess
  } = useUpdateTankMixSelectors();

  const inProgress = createInProgress || updateInProgress;
  const errorMessage = createErrorMessage || updateErrorMessage;

  const isEdit = !!tankMix;

  useEffect(() => {
    if (createSuccess || updateSuccess) {
      dispatch(createTankMixActions.clear());
      dispatch(updateTankMixActions.clear());
      dispatch(getTankMixesConfigActions.submit({}));
      onCancel();
    }
  }, [createSuccess, dispatch, onCancel, updateSuccess]);

  const handleFormSubmit = useCallback(
    (formData) => {
      const updatedTankMix = {
        ...formData,
        constituents: _.chain(formData)
          .get('constituents')
          .map((product) => {
            const productId = _.get(product, 'productId');
            if (productId === customProductId) {
              return {
                ..._.pick(product, ['name', 'sprayingRate', 'unit']),
                type: 'Custom'
              };
            }
            return {
              ..._.pick(product, ['productId', 'sprayingRate']),
              type: 'Product'
            };
          })
          .value()
      };

      if (isEdit) {
        dispatch(
          updateTankMixActions.submit({
            tankMixId: _.get(tankMix, 'id'),
            tankMix: updatedTankMix
          })
        );
      } else {
        dispatch(createTankMixActions.submit({ tankMix: updatedTankMix }));
      }
    },
    [dispatch, isEdit, tankMix]
  );

  const handleCancel = useCallback(() => {
    dispatch(createTankMixActions.clear());
    dispatch(updateTankMixActions.clear());
    onCancel();
  }, [dispatch, onCancel]);

  const handleAddProduct = useCallback(() => {
    append({
      name: ''
    });
  }, [append]);

  const handleRemoveProduct = useCallback(
    (idx) => {
      remove(idx);
    },
    [remove]
  );

  return (
    <FormProvider {...formMethods}>
      <form noValidate onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              inputRef={register({
                required: 'Name is required'
              })}
              disabled={inProgress}
              required
              fullWidth
              variant="outlined"
              id="name"
              label="Name"
              name="name"
              error={!!_.get(errors, 'name')}
              helperText={_.get(errors, `name.message`)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register({})}
              disabled={inProgress}
              fullWidth
              variant="outlined"
              id="description"
              label="Description"
              name="description"
              error={!!_.get(errors, 'description')}
              helperText={_.get(errors, `description.message`)}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              id="carrierId"
              name="carrierId"
              disabled={inProgress}
              defaultValue={defaultCarrierId}
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <FormControl fullWidth>
                  <InputLabel>Carrier</InputLabel>
                  <Select
                    fullWidth
                    variant="outlined"
                    value={value || null}
                    onChange={(e) => onChange(e.target.value)}
                  >
                    {_.map(carriers, (carrier) => (
                      <MenuItem
                        key={_.get(carrier, 'id')}
                        value={_.get(carrier, 'id')}
                      >
                        {_.get(carrier, 'name')}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Typography>Products:</Typography>
            <Tooltip title="Add Product" placement="top">
              <IconButton size="small" onClick={handleAddProduct}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          {_.map(constituentsFields, (product, idx) => (
            <ConstituentForm
              key={_.get(product, 'arrayId')}
              idx={idx}
              onRemove={handleRemoveProduct}
              productsConfig={productsConfig}
              selectedProductsIds={selectedProductsIds}
              defaultValues={product}
            />
          ))}
          <Grid item xs={12}>
            {errorMessage && (
              <FormHelperText error>{errorMessage}</FormHelperText>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            {inProgress && <CircularProgress size={30} />}
            <Button
              style={{ marginRight: '0.5rem', marginLeft: '0.5rem' }}
              variant="contained"
              color="default"
              disabled={inProgress}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={inProgress}
            >
              {isEdit ? 'Update' : 'Create'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default EditTankMixForm;
