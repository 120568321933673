import { useCallback, useContext, useMemo } from 'react';

import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { FarmStructureContext } from '../../_context/FarmStructureContext';
import { useFieldsWithGrowthSelector } from '../../_store/selectors/fieldsSelectors';
import {
  managementZonesActions,
  useManagementZonesSelectors
} from '../../_store/slices/ui/managementZonesSlice';
import LoadingIndicator from '../../Components/LoadingIndicator';
import { useLayerCategory } from '../../Pages/Map/Components/Details/_hooks/useLayerCategory';

import DrawerError from './DrawerError';
import DrawerItem from './DrawerItem';

const LayerDrawerItem = ({ layer, even, selected }) => {
  const dispatch = useDispatch();

  const { selectedFieldId } = useContext(FarmStructureContext);

  const category = useLayerCategory(layer);

  const shapes = useMemo(
    () =>
      _.chain(layer)
        .get('zones')
        .initial() // all but parent zone
        .values()
        .map((zone) => _.get(zone, 'shape'))
        .value(),
    [layer]
  );

  const handleClick = useCallback(() => {
    if (selected) {
      dispatch(
        managementZonesActions.setSelectedLayer({
          layer: undefined,
          fieldId: selectedFieldId
        })
      );
    } else {
      dispatch(
        managementZonesActions.setSelectedLayer({
          layer,
          fieldId: selectedFieldId
        })
      );
    }
  }, [dispatch, layer, selected, selectedFieldId]);

  return (
    <DrawerItem
      item={layer}
      title={_.get(layer, 'name')}
      subtitle={_.get(category, 'name')}
      center
      even={even}
      onClick={handleClick}
      selected={selected}
      shapes={shapes}
    />
  );
};

const ManagementZonesLayersDrawerList = () => {
  const {
    selectedGrowerId,
    selectedSeasonId,
    selectedFarmId,
    selectedFieldId
  } = useContext(FarmStructureContext);

  const { fields, inProgress: fieldsInProgress } = useFieldsWithGrowthSelector(
    selectedGrowerId,
    selectedSeasonId,
    selectedFarmId
  );

  const { selectedLayer } = useManagementZonesSelectors(selectedFieldId);

  const selectedLayerId = _.get(selectedLayer, 'id');

  const field = useMemo(
    () => _.find(fields, (f) => _.get(f, 'id') === selectedFieldId),
    [fields, selectedFieldId]
  );

  const managementZonesLayers = _.get(field, 'managementZones.layers');

  return (
    <>
      {fieldsInProgress && <LoadingIndicator />}
      {!fieldsInProgress && _.isEmpty(managementZonesLayers) && (
        <DrawerError message="No Management Zones Layers" />
      )}
      {!fieldsInProgress &&
        _.map(managementZonesLayers, (layer) => (
          <LayerDrawerItem
            key={_.get(layer, 'id')}
            layer={layer}
            selected={_.get(layer, 'id') === selectedLayerId}
          />
        ))}
    </>
  );
};

export default ManagementZonesLayersDrawerList;
