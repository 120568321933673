import React, { useEffect, useRef } from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { geoIdentity, geoPath } from 'd3-geo';
import { select } from 'd3-selection';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
  container: {
    marginRight: '1rem'
  },
  thumbnail: (props) => ({
    fill: props.fillColor,
    strokeWidth: '1',
    stroke: props.strokeColor,
    '*:hover > * > &': {
      fill: props.hoverFillColor,
      strokeWidth: '1',
      stroke: props.hoverStrokeColor
    }
  })
}));

const DrawerThumbnail = ({
  geometries,
  selected = false,
  width = 40,
  height = 40,
  fillColor = '#D1D1D140',
  strokeColor = '#D1D1D1',
  hoverFillColor = '#D1D1D170',
  hoverStrokeColor = '#F8F8F8',
  selectedFillColor = '#92d26640',
  selectedStrokeColor = '#92d266'
}) => {
  const styles = useStyles({
    fillColor: selected ? selectedFillColor : fillColor,
    strokeColor: selected ? selectedStrokeColor : strokeColor,
    hoverFillColor,
    hoverStrokeColor
  });

  const thumbnailRef = useRef(null);

  useEffect(() => {
    // Based on https://stackoverflow.com/a/57120037
    // and  https://www.d3indepth.com/geographic/
    const geoJson = {
      type: 'FeatureCollection',
      features: _.map(geometries, (geometry) => ({
        type: 'Feature',
        properties: {},
        geometry: geometry
      }))
    };

    // See https://gis.stackexchange.com/a/262773
    const projection = geoIdentity()
      .reflectY(true)
      .fitSize([width, height], geoJson);

    const geoGenerator = geoPath().projection(projection);

    // Join the FeatureCollection's features array to path elements
    const u = select(thumbnailRef.current)
      .selectAll('path')
      .data(geoJson.features);

    // Create path elements and update the d attribute using the geo generator
    u.enter().append('path').attr('d', geoGenerator);
  }, [geometries, height, width]);

  return (
    <Box className={styles.container}>
      <svg height={height} width={width} className={styles.thumbnail}>
        <g ref={thumbnailRef} />
      </svg>
    </Box>
  );
};

export default DrawerThumbnail;
