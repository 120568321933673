import React from 'react';

import Alert from '@material-ui/lab/Alert';

import LoadingIndicator from '../../../Components/LoadingIndicator';
import {
  ContentBody,
  ContentContainer,
  ContentHeader,
  ResponsiveHeaderButtonsContainer
} from '../../../Layout/Content';

import { useGrowthModelReportWithDistricts } from './_hooks/useGrowthModelReportWithDistricts';
import { useReportsChartsControls } from './_hooks/useReportsChartsControls';
import CropProgressReportChart from './CropProgressReportChart';
import CropProgressReportTable from './CropProgressReportTable';
import ReportsDataGridWrapper from './ReportsDataGridWrapper';
import TableChartToggle from './TableChartToggle';

const CropProgressReport = () => {
  const { inProgress, errorMessage, response } =
    useGrowthModelReportWithDistricts();

  const { showChart, toggleChart, visibleRows, handleVisibleRowIdsChange } =
    useReportsChartsControls(response);

  return (
    <ContentContainer>
      <ContentHeader>
        <span>Crop Progress Report</span>
        <ResponsiveHeaderButtonsContainer>
          <TableChartToggle showChart={showChart} onToggle={toggleChart} />
        </ResponsiveHeaderButtonsContainer>
      </ContentHeader>
      <ContentBody>
        {inProgress && <LoadingIndicator />}
        {!inProgress && errorMessage && (
          <Alert severity="error">{errorMessage}</Alert>
        )}
        {!inProgress && !errorMessage && (
          <>
            <ReportsDataGridWrapper hide={showChart}>
              <CropProgressReportTable
                growthModelReportResponse={response}
                onVisibleRowIdsChange={handleVisibleRowIdsChange}
              />
            </ReportsDataGridWrapper>
            {showChart && <CropProgressReportChart fields={visibleRows} />}
          </>
        )}
      </ContentBody>
    </ContentContainer>
  );
};

export default CropProgressReport;
